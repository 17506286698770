import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import CEWCPremiumReportDetailsPage from './pages/Details/CEWCPremiumReportDetailsPage';
import CEWCPremiumReportPremiumsPage from './pages/Premiums/CEWCPremiumReportPremiumsPage';

setComponentMapOverrides(
    {
        CEWCPremiumReportDetailsPage: { component: 'CEWCPremiumReportDetailsPage' },
        CEWCPremiumReportPremiumsPage: { component: 'CEWCPremiumReportPremiumsPage' }
    },
    {
        CEWCPremiumReportDetailsPage,
        CEWCPremiumReportPremiumsPage
    }
);

export { default as CEWCPremiumReportWizard } from './CEWCPremiumReportWizard';
