import { defineMessages } from 'react-intl';

export default defineMessages({
    autoLiability: {
        id: 'policycommon.component.policy-umbrella-info.Auto Liability',
        defaultMessage: 'Auto Liability'
    },
    personalLiability: {
        id: 'policycommon.component.policy-umbrella-info.Personal Liability',
        defaultMessage: 'Personal Liability'
    },
    watercraftLiability: {
        id: 'policycommon.component.policy-umbrella-info.Watercraft Liability',
        defaultMessage: 'Watercraft Liability'
    },
    dwellingLiability: {
        id: 'policycommon.component.policy-umbrella-info.Dwelling Liability',
        defaultMessage: 'Dwelling Liability'
    },
    motorcycleLiability: {
        id: 'policycommon.component.policy-umbrella-info.Motorcycle Liability',
        defaultMessage: 'Motorcycle Liability'
    },
    recreationalVehicleLiability: {
        id: 'policycommon.component.policy-umbrella-info.Recreational Vehicle Liability',
        defaultMessage: 'Recreational Vehicle Liability'
    },
    policyNumber: {
        id: 'policycommon.component.policy-umbrella-info.Policy Number of Underlying Insurance',
        defaultMessage: 'Policy Number of Underlying Insurance'
    },
    limitsOfLiability: {
        id: 'policycommon.component.policy-umbrella-info.Limits of Liability of Underlying Insurance',
        defaultMessage: 'Limits of Liability of Underlying Insurance'
    },
    bodilyInjuryEachPerson: {
        id: 'policycommon.component.policy-umbrella-info.Bodily Injury - Each Person',
        defaultMessage: 'Bodily Injury - Each Person'
    },
    bodilyInjuryEachAccident: {
        id: 'policycommon.component.policy-umbrella-info.Bodily Injury - Each Accident',
        defaultMessage: 'Bodily Injury - Each Accident'
    },
    propertyDamageEachAccident: {
        id: 'policycommon.component.policy-umbrella-info.Property Damage - Each Accident',
        defaultMessage: 'Property Damage - Each Accident'
    },
    combinedSingleLimitEachAccident: {
        id: 'policycommon.component.policy-umbrella-info.Combined Single Limit - Each Accident',
        defaultMessage: 'Combined Single Limit - Each Accident'
    }
});
