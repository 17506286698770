import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import RatingPeriodComponent from '../RatingPeriodComponent/RatingPeriodComponent';
import metadata from './RatingPeriodsComponent.metadata.json5';

function RatingPeriodsComponent(props) {
    const { 
        data: ratingPeriodsVM,
        wizardData,
        updateWizardData,
        stateIndex 
    } = props;

    const generateOverrides = useCallback(() => {
        const overrides = ratingPeriodsVM.map((item, index) => {
            return {
                [`ratingPeriodComponent${index}`]: {
                    wizardData,
                    updateWizardData,
                    data: item,
                    ratingPeriodPath: `details.children[${stateIndex}].ratingPeriods.children[${index}]`
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [ratingPeriodsVM, updateWizardData, wizardData, stateIndex]);

    const overrides = {
        ratingPeriodsContainer: {
            data: ratingPeriodsVM
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveComponentMap: {
            ratingPeriodcomponent: RatingPeriodComponent
        }    
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={ratingPeriodsVM}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

RatingPeriodsComponent.propTypes = {
    data: PropTypes.array.isRequired,
    wizardData: PropTypes.object.isRequired,
    updateWizardData: PropTypes.func.isRequired,
    stateIndex: PropTypes.string.isRequired
};

export default RatingPeriodsComponent;
