import { defineMessages } from 'react-intl';

export default defineMessages({
  paymentData: {
    id: 'billing.payments-table.Payment Data',
    defaultMessage: 'Payment Data'
  },
  amount: {
    id: 'billing.payments-table.Amount',
    defaultMessage: 'Amount'
  },
  paymentMethod: {
    id: 'billing.payments-table.Payment Method',
    defaultMessage: 'Payment Method'
  },
  noMatchingPayments: {
    id: 'custom.billing.invoices-table.No matching payments',
    defaultMessage: 'No matching payments.'
  },
});
