import { defineMessages } from 'react-intl';

export default defineMessages({
    equipment: {
        id: 'ce.endorsement.views.pa-vehicles.custom-equipment.Equipment',
        defaultMessage: 'Equipment'
    },
    customEquipment: {
        id: 'ce.endorsement.views.pa-vehicles.custom-equipment.Custom Equipment',
        defaultMessage: 'Custom Equipment'
    },
    value: {
        id: 'ce.endorsement.views.pa-vehicles.custom-equipment.Value',
        defaultMessage: 'Value'
    },
    Add: {
        id: 'ce.endorsement.views.pa-vehicles.custom-equipment.Add',
        defaultMessage: 'Add'
    },
    Save: {
        id: 'ce.endorsement.views.pa-vehicles.custom-equipment.Save',
        defaultMessage: 'Save'
    },
    Edit: {
        id: 'ce.endorsement.views.pa-vehicles.custom-equipment.Edit',
        defaultMessage: 'Edit'
    },
    Delete: {
        id: 'ce.endorsement.views.pa-vehicles.custom-equipment.Delete',
        defaultMessage: 'Delete'
    },
    popOK: {
        id: 'ce.endorsement.views.pa-vehicles.custom-equipment.ok',
        defaultMessage: 'Ok'
    },
    popCancel: {
        id: 'ce.endorsement.views.pa-vehicles.custom-equipment.Cancel',
        defaultMessage: 'Cancel'
    },
    wantToChangeYourAnswer: {
        id: 'ce.endorsement.views.pa-vehicles.custom-equipment.wantToChangeYourAnswer',
        defaultMessage: 'Want To Change Your Answer?'
    },
    changeAnswerMayImpactCov: {
        id: 'ce.endorsement.views.pa-vehicles.custom-equipment.changeAnswerMayImpactCov',
        defaultMessage: 'Changing the answer to this question may impact your coverage. Click "Cancel" to undo the change. If you would like to proceed, click "Yes".'
    },
    yes: {
        id: 'ce.endorsement.views.pa-vehicles.custom-equipment.yes',
        defaultMessage: 'Yes'
    }
});
