import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { VehicleUtil } from 'wni-portals-util-js';
import { useModal } from '@jutro/components';
import CEVehicleCustomEquipmentTable from '../CEVehicleCustomEquipmentTable/CEVehicleCustomEquipmentTable';
import metadata from './CEVehicleQuestionsComponent.metadata.json5';
import styles from './CEVehicleQuestionsComponent.module.scss';
import messages from './CEVehicleQuestionsComponent.messages';

/**
 * Component contains vehicle questions, including fields and question sets
 * @param {object} props
 * @returns {object} React Component
 */

const initUnavailableVehicleQuestions = [
    'HowmanyHoursperweek_Ext',
    'Whatisbeingdelivered_Ext',
    'Other_Ext',
    'NoVehicleNamedNonOwned_Ext'
];

const toggleAvailableValues = [
    {
        code: 'true',
        name: {
            id: 'quoteandbind.ca.directives.vehicle-incident.Yes',
            defaultMessage: 'Yes'
        }
    },
    {
        code: 'false',
        name: {
            id: 'quoteandbind.ca.directives.vehicle-incident.No',
            defaultMessage: 'No'
        }
    }
];

const CEVehicleQuestionsComponent = (props) => {
    const {
        id,
        model: vehicleVM,
        onValueChange,
        showErrors,
        isQuoteForNamedNonOwner : isNamedNonOwner,
        onValidate
    } = props;

    const modalApi = useModal();
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const [beforeTextInput, updateBeforeTextInput] = useState();

    const {
        vehicleType_Ext: vehicleType,
        vehicleExplain_Ext: vehicleExplain,
        whatDelivered_Ext: whatDelivered,
        vehUsedInBusOwned_Ext: vehicleUsedInBusOwned,
        vehDrivenToWorkSchool_Ext: vehDrivenToWorkSchool,
        motorHomeRented_Ext: motorHomeRented,
        vehRecreationalTrailer_Ext: vehRecreationalTrailer,
        vehRTParked_Ext: vehRTParked,
        isVehRTParkedRequired_Ext: isVehRTParkedRequired
    } = vehicleVM.value;

    const isPPAorNNO = VehicleUtil.isPPA(vehicleType) || VehicleUtil.isNamedNonOwner(vehicleType);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, vehicleVM]);

    const writeValue = useCallback((value, path) => {
        onValueChange(value, path);
    }, [onValueChange]);

    const getUnavailableVehicleQuestions = useCallback(() => {
        if (isNamedNonOwner) {
            return [...initUnavailableVehicleQuestions, 'VehModHasSpecialEquipment_Ext'];
        }
        return initUnavailableVehicleQuestions;
    }, [isNamedNonOwner]);

    const isAnswerYes = useCallback((answer) => {
        return !_.isNil(answer) && answer;
    }, []);

    const isAnswerMatched = useCallback((answer, toBeMatched) => {
        return answer === toBeMatched;
    }, []);

    const showQuestionChangeWarning = useCallback((value, path) => {
        // when toggle dropdown value changed
        const beforeValue = _.get(vehicleVM, `${path}.value`);
        if ((!_.isNil(beforeValue) || !_.isEmpty(beforeValue))) {
            modalApi.showConfirm({
                title: messages.wantToChangeYourAnswer,
                message: messages.changeAnswerMayImpactCov,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.yes
            }).then((results) => {
                if (results === 'confirm') {
                    writeValue(value, path);
                }
            }, _.noop);
        } else {
            writeValue(value, path);
        }
    }, [modalApi, vehicleVM, writeValue]);

    const showTextQuestionChangeWarning = useCallback((path) => {
        // when text value blur
        const value = _.get(vehicleVM, `${path}.value`);
        if ((!_.isNil(beforeTextInput) || !_.isEmpty(beforeTextInput)) && (beforeTextInput !== value)) {
            modalApi.showConfirm({
                title: messages.wantToChangeYourAnswer,
                message: messages.changeAnswerMayImpactCov,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.yes
            }).then((results) => {
                if (results !== 'confirm') {
                    writeValue(beforeTextInput, path);
                }
            }, _.noop);
        }
    }, [beforeTextInput, modalApi, vehicleVM, writeValue]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false
        },
        vehDrivenToWorkSchool_Ext: {
            visible: isPPAorNNO
        },
        milesToWorkSchoolOneWay_Ext: {
            visible: isAnswerYes(vehDrivenToWorkSchool) && isPPAorNNO,
            onFocus: () => updateBeforeTextInput(_.get(vehicleVM, 'milesToWorkSchoolOneWay_Ext.value')),
            onBlur: () => showTextQuestionChangeWarning('milesToWorkSchoolOneWay_Ext')
        },
        vehUsedInBusOwned_Ext: {
            visible: isPPAorNNO
        },
        vehicleExplain_Ext: {
            visible: isAnswerYes(vehicleUsedInBusOwned) && isPPAorNNO
        },
        vehUsedInBizExplainOther_Ext: {
            visible: isAnswerMatched(vehicleExplain, 'Other') && isAnswerYes(vehicleUsedInBusOwned) && isPPAorNNO,
            onFocus: () => updateBeforeTextInput(_.get(vehicleVM, 'vehUsedInBizExplainOther_Ext.value')),
            onBlur: () => showTextQuestionChangeWarning('vehUsedInBizExplainOther_Ext')
        },
        howManyHours_Ext: {
            visible: isAnswerYes(vehicleUsedInBusOwned)
                && isPPAorNNO
                && isAnswerMatched(vehicleExplain, 'DeliveryOfFood') // vehicleExplain === 'DeliveryOfFood'
        },
        whatDelivered_Ext: {
            visible: isAnswerYes(vehicleUsedInBusOwned)
                && isPPAorNNO
                && isAnswerMatched(vehicleExplain, 'DeliveryOfFood') // vehicleExplain === 'DeliveryOfFood'
        },
        questionOfOther_Ext: {
            visible: isAnswerYes(vehicleUsedInBusOwned)
                && isPPAorNNO
                && isAnswerMatched(vehicleExplain, 'DeliveryOfFood') // vehicleExplain === 'DeliveryOfFood'
                && isAnswerMatched(whatDelivered, 'Other_Ext'), // whatDelivered === 'Other_Ext'
            onFocus: () => updateBeforeTextInput(_.get(vehicleVM, 'questionOfOther_Ext.value')),
            onBlur: () => showTextQuestionChangeWarning('questionOfOther_Ext')
        },
        vehExclusiveUseToFarmOwned_Ext: {
            visible: isPPAorNNO
        },
        motorHomeRented_Ext: {
            visible: isAnswerMatched(vehicleType, 'motorhome_Ext'), // vehicleType === 'motorhome_Ext',
            availableValues: toggleAvailableValues
        },
        vehRecreationalTrailer_Ext: {
            visible: isAnswerMatched(vehicleType, 'rectrailer_Ext'), // vehicleType === 'rectrailer_Ext',
            availableValues: toggleAvailableValues
        },
        milePercentPerYear_Ext: {
            visible: (isAnswerMatched(vehicleType, 'motorhome_Ext') && isAnswerYes(motorHomeRented))
                || (isAnswerMatched(vehicleType, 'rectrailer_Ext') && isAnswerYes(vehRecreationalTrailer))
        },
        vehRTParked_Ext: {
            visible: isAnswerMatched(vehicleType, 'rectrailer_Ext') && isVehRTParkedRequired, // vehicleType === 'rectrailer_Ext',
            required: isVehRTParkedRequired,
            availableValues: toggleAvailableValues
        },
        vehRTParkedExplain_Ext: {
            visible: (isAnswerMatched(vehicleType, 'rectrailer_Ext') && isAnswerYes(vehRTParked)),
            onFocus: () => updateBeforeTextInput(_.get(vehicleVM, 'vehRTParkedExplain_Ext.value')),
            onBlur: () => showTextQuestionChangeWarning('vehRTParkedExplain_Ext')
        },
        vehicleUnderwritingQuestionSets: {
            visible: !isNamedNonOwner,
            showQuestionChangeWarning: true,
            contentFilter: (metadataContent) => !getUnavailableVehicleQuestions()
                .includes(metadataContent.id)
        },
        vehicleCustomEquipmentTableComponentId: {
            vehicleVM,
            visible: !isNamedNonOwner,
            onValueChange
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            showQuestionChangeWarning,
            onValidate: setComponentValidation
        },
        resolveComponentMap: {
            cevehiclecustomequipmenttable: CEVehicleCustomEquipmentTable,
            questionset: QuestionSetComponent
        },
    };

    const readValue = (qid, path) => {
        return readViewModelValue(
            metadata.componentContent,
            vehicleVM,
            qid,
            path,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={setComponentValidation}
            resolveValue={readValue}
            onValueChange={writeValue}
            showErrors={showErrors}
        />
    );
};

CEVehicleQuestionsComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    isQuoteForNamedNonOwner: PropTypes.bool,
    showErrors: PropTypes.bool
};

CEVehicleQuestionsComponent.defaultProps = {
    model: {
        value: {}
    },
    onValueChange: _.noop,
    isQuoteForNamedNonOwner: false,
    showErrors: false

};

export default CEVehicleQuestionsComponent;
