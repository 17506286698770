import React, {useCallback, useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import { Icon, Loader } from '@jutro/components';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from "wni-components-platform-react";
import { DataTable, DisplayColumn } from '@jutro/legacy/datatable';
import { ServiceManager } from '@jutro/legacy/services';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniDateUtil } from 'wni-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import appConfig from 'app-config';
import { ClaimDocumentService as ClaimDownloadService} from "gw-capability-claimdocument";
import { ClaimService } from "gw-capability-claim";
import { CurrencyField } from '@jutro/legacy/components';
import { Link as DownloadLink } from '@jutro/router';
import ContactUtil from '../../utils/ClaimsDetailsUtil';
import ContactLinkComponent from '../../components/ContactLinkComponent/ContactLinkComponent';
import VendorLinkComponent from '../../components/VendorLinkComponent/VendorLinkComponent';
import metadata from './ClaimDetails.metadata.json5';
import CreateNotePopUp from './CreateNotePopUp';
import claimDetailsStyles from './ClaimDetails.module.scss';
import messages from '../../Claims.messages';
import './ClaimDetails.messages';

function ClaimDetails (props) {
    const {
        match: {
            params: {
                claimNumber
            }
        },
        history
    } = props;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const modalApi = useWniModal();
    const intl = useContext(IntlContext);

    const localeService = ServiceManager.getService('locale-service');
    const [claimDetailsData, updateClaimDetailsData] = useState('');
    const [claimDocuments, updateClaimDocuments] = useState([]);
    const [isClaimDetailsPageVisible, updateIsClaimDetailsPageVisible] = useState(true);
    const [isLoading, updateIsLoading] = useState(true);
    const [redirectPath, updateRedirectPath] = useState({});
    const [uploadToken, updateUploadToken] = useState(null);
    const [documentAlert, updateDocumentAlert] = useState('');
    const [selectedTab, updateSelectedTab] = useState('summaryTab');

    const getFormattedNotesArray = (res) => {
        return res.map((note) => {
            return {
                author: note.author.firstName,
                date: note.dateCreated,
                subject: note.subject,
                note: note.body
            };
        });
    }

    const getReportedByName = (claimDetails) => {
        if (claimDetails.claimReporter?.reportedBy?.displayName) {
            return claimDetails.claimReporter?.reportedBy?.displayName;
        }
        return translator(messages.unknown);
    };

    const getInvolvedVehicles = (claimDetails) => {
        let involvedVehicles = [];
        const pa = claimDetails.lobs.personalAuto;
        const papolicy = claimDetails.policy.lobs.personalAuto;
        if (!pa) {
            return [];
        }
        if (
            pa.vehicleIncidents === null
            || pa.vehicleIncidents.length < 1
            || claimDetails.policy === null
            || papolicy.vehicles === null
        ) {
            return involvedVehicles;
        }

        const involvedVINarray = pa.vehicleIncidents.map((item) => {
            return item.vehicle.vIN;
        });
        involvedVehicles = _.filter(papolicy.vehicles, (vehicle) => {
            return involvedVINarray.indexOf(vehicle.vIN) !== -1;
        }).map((vehicle) => {
            return vehicle;
        });
        return involvedVehicles;
    };

    const getRoleInvolvement = (claimContact) => {
        let roles = [];
        if (
            claimContact
            && claimContact.contactRolesDisplay
            && claimContact.contactRolesDisplay.length > 0
        ) {
            roles = claimContact.contactRolesDisplay;
        }
        return _.join(roles, ', ');
    };

    const getPolicyTable = (claimDetails) => {
        const claimContactsWithoutAgent = _.filter(
            claimDetails.claimContacts,
            (claimContact) => {
                return claimContact.contactRoles.indexOf('agent') === -1;
            }
        ).map((claimContact) => {
            const roleInvolvement = getRoleInvolvement(claimContact);
            return {
                partyname: claimContact.contactDTO.displayName,
                involvement: roleInvolvement,
                primaryaddress: claimContact.contactDTO.primaryAddress,
                contactdetails: claimContact.contactDTO
            };
        });
        return claimContactsWithoutAgent;
    };

    const getClaimDetailsResponse = () => {
        Promise.all([ClaimService.getClaimDetail(claimNumber, authHeader),
            ClaimService.getClaimNotes(claimNumber, authHeader)]).then(
            (response) => {
                const [claimDetails, notesResponse] = response;
                const notesArray = getFormattedNotesArray(notesResponse);
                const pa = claimDetails.lobs.personalAuto;
                if (pa && pa.vehicleIncidents[0]) {
                    pa.vehicleIncidents[0].selected = true;
                }

                const responseData = {
                    claim: claimDetails,
                    notes: notesArray,
                    claimPayments: claimDetails.checks,
                    claimServices: claimDetails.serviceRequests,
                    activities: claimDetails.activities || {},
                    reporter: getReportedByName(claimDetails),
                    claimLosslocation: claimDetails.lossLocation,
                    selectedVehicleIncident:
                        pa && pa.vehicleIncidents[0] ? pa.vehicleIncidents[0] : {},
                    vehicles: getInvolvedVehicles(claimDetails),
                    claimContactsVendors: claimDetails.vendors,
                    claimHospitalsDays: claimDetails.hospitalDays,
                    claimContactsWithoutAgent: getPolicyTable(claimDetails)
                };
                updateClaimDocuments(claimDetails.documents);
                updateClaimDetailsData(responseData);
                updateIsLoading(false);
            }
        ).catch(() => {
            updateIsLoading(false);
        });
        if (_.isFunction(ClaimService.addRecentlyViewedClaim)) {
            ClaimService.addRecentlyViewedClaim(claimNumber, authHeader);
        }
    };

    const getCell = (item, index, { id: property }) => {
        return item[property];
    };

    const getNotesColumnCell = (item, index, { path: property }) => {
        let toolTipMessage = '';
        switch (property) {
            case 'author':
                toolTipMessage = translator(messages.notesAuthor);
                break;
            case 'date':
                toolTipMessage = translator(messages.notesDate);
                break;
            case 'subject':
                toolTipMessage = translator(messages.notesSubject);
                break;
            case 'note':
                toolTipMessage = translator(messages.notesContent);
                break;
            default:
                toolTipMessage = '';
        }
        return (
            <div
                style={{ whiteSpace: 'normal' }}
                className={claimDetailsStyles.gwClaimDetailsNotesWrapper}
            >
                <span title={toolTipMessage}>{item[property]}</span>
            </div>
        );
    };

    const isContactTypeCheck = (contact, contactType) => {
        return ContactUtil.isInstanceOf(contact, contactType);
    };

    const getContactTypeVendorVisible = (item) => {
        return (isContactTypeCheck(item, 'CompanyVendor') || isContactTypeCheck(item, 'PersonVendor'));
    };

    const getPartyNameLink = (item) => {
        const contactDTO = _.get(item, 'contactdetails');
        if (getContactTypeVendorVisible(item)) {
            const contact = { contactDTO };
            return <VendorLinkComponent id="vendorCell" vendors={[contact]} />;
        }
        return <ContactLinkComponent id="contactCell" contactDetails={contactDTO} />;
    };

    const getVehicleDetailsTable = (vehicles) => {
        return vehicles.map((vehicle) => {
            return {
                make: vehicle.make,
                model: vehicle.model,
                year: vehicle.year,
                license: vehicle.license,
                coverages: vehicle.coverages
            };
        });
    };

    const getFormatCurrency = (deductible) => {
        let formattedAmount = '';
        if (deductible) {
            const { amount, currency } = deductible;
            if (amount && currency) {
                const locale = localeService.getPreferredLocale();
                formattedAmount = amount.toLocaleString(locale, {
                    style: 'currency',
                    currency,
                    currencyDisplay: 'code',
                });
            }
        }
        return formattedAmount;
    };

    const getLimitType = (coverage) => {
        if (coverage.incidentLimit && coverage.exposureLimit) {
            return (
                <div>
                    <p>{translator(messages.perAccident)}</p>
                    <p>{translator(messages.perPerson)}</p>
                </div>
            );
        }
        if (coverage.incidentLimit) {
            return translator(messages.perAccident);
        }
        if (coverage.exposureLimit) {
            return translator(messages.perPerson);
        }
        return '-';
    };

    const handleError = (title, message) => {
        return modalApi.showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
    };

    const claimsDocUploadToken = async () => {
        try {
            const sessionID = await ClaimService.claimsDocUploadToken([], authHeader);
            updateUploadToken(sessionID);
        } catch (e) {
            handleError(
                commonMessages.errorUploadTitle,
                commonMessages.errorGenerateUploadToken
            );
        }
    };

    useEffect(() => {
        const {
            location: {
                state = {}
            }
        } = history
        const redirectPathValues = state;
        updateRedirectPath(_.get(redirectPathValues, 'state.redirectPath') || '/claims');
        getClaimDetailsResponse();
        claimsDocUploadToken();
    }, [])

    const getLimitValue = (coverage) => {
        if (coverage.incidentLimit && coverage.exposureLimit) {
            return (
                <div>
                    <p>{getFormatCurrency(coverage.incidentLimit)}</p>
                    <p>{getFormatCurrency(coverage.exposureLimit)}</p>
                </div>
            );
        }
        if (coverage.incidentLimit) {
            return getFormatCurrency(coverage.incidentLimit);
        }
        if (coverage.exposureLimit) {
            return getFormatCurrency(coverage.exposureLimit);
        }
        return '-';
    };

    const getVehicleCoverageTable = (coverages) => {
        return coverages.map((coverage) => {
            const limitValue = getLimitValue(coverage);
            const limitType = getLimitType(coverage);
            const deductibleAmount = coverage.deductible
                ? getFormatCurrency(coverage.deductible)
                : '';
            return {
                type: coverage.name,
                deductible: deductibleAmount,
                limittype: limitType,
                limitvalue: limitValue
            };
        });
    };

    const getBackToPreviousState = () => {
        const newRedirectpath = {
            pathname: redirectPath,
            state: 'claims'
        };
        history.push(newRedirectpath);
    };

    const getClaimNumber = (claimDetails) => {
        return translator(messages.claimDetailNumber,
            { num: claimDetails.claimNumber });
    };

    const getPolicyNumber = (claimDetails) => {
        const newRedirectPath = `/policies/${claimDetails.policy.policyNumber}/summary`;
        if (appConfig.persona === 'policyholder') {
            return claimDetails.policy.policyNumber;
        }
        return (
            <Link to={newRedirectPath} className={claimDetailsStyles.gwClaimDetailsLink}>
                {claimDetails.policy.policyNumber}
            </Link>
        );
    };

    const getAccountNumber = (claimDetails) => {
        const newRedirectPath = `/accounts/${claimDetails.policy.accountNumber}/summary`;
        if (appConfig.persona === 'policyholder') {
            return claimDetails.policy.accountNumber;
        }
        return (
            <Link to={newRedirectPath} className={claimDetailsStyles.gwClaimDetailsLink}>
                {claimDetails.policy.accountNumber}
            </Link>
        );
    };

    const getLossDate = (claimDetails) => {
        return WniDateUtil.formatDateWithPattern(claimDetails.lossDate_Ext)
    }

    const getPrimaryContactName = (claimDetails) => {
        if (claimDetails.mainContact && claimDetails.mainContact.displayName) {
            return claimDetails.mainContact.displayName;
        }
        return '';
    };

    const getAdjusterName = (claimDetails) => {
        if (claimDetails.adjuster.displayName) {
            return claimDetails.adjuster.displayName;
        }
        return translator(messages.unknown);
    };

    const toggleVehicleDetailsContainer = (claimDetails) => {
        if (
            claimDetails.claim.policy.policyType === 'PersonalAuto'
            && claimDetails.vehicles.length > 0
        ) {
            return true;
        }
        return false;
    };

    const getLossLocation = (claimDetails) => {
        let location = '';
        if (claimDetails.lossLocation && claimDetails.lossLocation.displayName) {
            location = claimDetails.lossLocation.displayName;
            location.replace('\n', ', ');
        }
        return location;
    };

    const getLossLocationVisibility = (claimDetails) => {
        if (claimDetails.lossLocation && claimDetails.lossLocation.displayName) {
            return true;
        }
        return false;
    };

    const getEmployerNotifiedVisibility = (claimDetails) => {
        if (claimDetails.lossType === 'WC') {
            return true;
        }
        return false;
    };

    const getEmployerNotifiedDate = (claimDetails ) => {
        let employernotifiedDate;
        if ((claimDetails.lossType === 'WC') && (claimDetails.lobs.workersComp.dateReportedToEmployer_Ext)) {
            employernotifiedDate = WniDateUtil.formatDateWithPattern(claimDetails.lobs.workersComp.dateReportedToEmployer_Ext)
        }
        return employernotifiedDate;
    };

    const getHospitalDaysVisibility = (claimDetails) => {
        if (claimDetails.hospitalDays) {
            return true;
        }
        return false;
    };

    const showModal = () => {
        const componentProps = {
            title: translator(messages.adjusterAddnoteButton),
            actionBtnLabel: translator(messages.createNoteSave),
            cancelBtnLabel: translator(messages.createNoteCancel),
            viewModelService: viewModelService
        };

        return modalApi.showModal(
            <CreateNotePopUp {...componentProps} />
        );
    }

    const saveNoteData = (updatedNoteVMObj) => {
        ClaimService.createClaimNote(
            claimNumber,
            updatedNoteVMObj.value,
            authHeader
        ).then((response) => {
            const newNote = getFormattedNotesArray([response]);
            claimDetailsData.notes = [...claimDetailsData.notes, ...newNote];
            updateSelectedTab('notesTab');
            updateClaimDetailsData(claimDetailsData);
        }).catch(() => {
            handleError(messages.createNoteErrorTitle, messages.createNoteErrorMessage);
        });
    };

    const addNote = () => {
        showModal().then((updatedNoteVMObj) => {
            saveNoteData(updatedNoteVMObj);
        }).catch(_.noop);
    };

    const getEmptyMessageVisibility = (notes) => {
        if (notes.length === 0) {
            return true;
        }
        return false;
    };

    const getNotesTableVisibility = (notes) => {
        if (notes.length > 0) {
            return true;
        }
        return false;
    };

    const getDataCell = (item, index, { path: property }) => {
        let toolTipMessage = '';
        switch (property) {
            case 'documentName':
                toolTipMessage = translator(messages.documentsName);
                break;
            case 'documentDate':
                toolTipMessage = translator(messages.documentsDateUploaded);
                break;
            default:
                toolTipMessage = '';
        }
        return (
            <span title={toolTipMessage}>
                {item[property]}
            </span>
        );
    };

    const onDateCell = (item, index, property) => {
        const { path } = property;
        return (
            _.isNil(item[path]) ? '' : <div>{moment(item[path]).format('l')}</div>
        );
    };

    const onColumnCell = (item, index, property) => {
        const { path, typeKey } = property;
        if (item[path] && item[path].currency) {
            return (
                <CurrencyField
                    id={`currency_${index}`}
                    value={item[path]}
                    readOnly
                    hideLabel
                    showOptional={false}
                />
            );
        }
        let val;
        if (!_.isNil(typeKey)) {
            if (_.isArray(item[path])) {
                val = item[path].map((col)  => {
                    return translator({ id: `${typeKey}.${col}` })
                })
            } else {
                val = translator({ id: `${typeKey}.${item[path]}` })
            }
        } else {
            val = item[path]
        }
        
        return <div>{`${item[path] ? val : ''}`}</div>;
    };

    const getViewandDownload = (item) => {
        const { documentviewdownload: { workingPublicID, sessionID } } = item;
        const claimDocumentLink = ClaimDownloadService
            .getClaimDocument(workingPublicID, sessionID);
        return (
            <DownloadLink
                href={claimDocumentLink}
                target="_blank"
                key={item.documentviewdownload}
                className={claimDetailsStyles.documentIcon}
                title={translator(messages.documentsViewandDownload)}
            >
                <Icon icon="gw-insert-drive-file" />
            </DownloadLink>
        );
    };

    const getDocumentRemove = (item) => {
        const { documentremove } = item;
        const {
            publicID, name, canDelete
        } = documentremove;
        const newClaimNumber = documentremove.claimNumber;
        updateDocumentAlert('')
        if (!canDelete) {
            const alertMsg = translator(messages.unableToDeleteClaims, { documentName: name });
            updateDocumentAlert(alertMsg);
            return;
        }
        modalApi.showConfirm({
            title: messages.confirmationToRemoveTitle,
            message: translator(messages.confirmationToRemoveMessage, { documentname: name }),
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.removeConfirmationYes,
            cancelButtonText: messages.removeConfirmationNo
        }).then(async (results) => {
            if (results === 'cancel') {
                return _.noop();
            }
            try {
                const isDeleteItem = await ClaimService.claimsRemoveDocument(
                    [newClaimNumber, publicID],
                    { Authorization: authHeader.Authorization }
                );
                if (isDeleteItem === false) {
                    return handleError(
                        messages.deletionFailedTitle,
                        messages.deletionFailedMessage
                    );
                }
                claimDetailsData.claim.documents = _.filter(
                    claimDetailsData.claim.documents,
                    (doc) => {
                        return doc.publicID !== publicID;
                    }
                );
                updateClaimDocuments(claimDetailsData.claim.documents);
                updateClaimDetailsData(claimDetailsData);
            } catch (e) {
                return handleError(
                    messages.removeServiceFailedTitle,
                    messages.removeServiceFailedMessage
                );
            }
        }, _.noop);
    };

    const getRemoveIcon = (item) => {
        return (
            <Icon
                icon="gw-delete"
                onClick={() => getDocumentRemove(item)}
                className={claimDetailsStyles.documentIcon}
                title={translator(messages.documentsRemove)}
            />
        );
    };

    const formatAMPM = (date) => {
        let hours = date.getHours() % 12;
        let minutes = date.getMinutes();
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        hours = hours || 12;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        const strTime = `${hours}:${minutes} ${ampm}`;
        return strTime;
    };

    const getDocumentsArray = (documents) => {
        return documents.map((doc) => {
            const docDate = intl.formatDate(new Date(doc.dateModified), { year: 'numeric', month: 'short', day: 'numeric' });
            return {
                documentName: doc.name,
                documentDate: `${docDate} ${formatAMPM(new Date(doc.dateModified))}`,
                documentviewdownload: doc,
                documentremove: doc
            };
        });
    };

    const getNotesArray = (notes) => {
        return notes.map((noteData) => {
            const noteDate = intl.formatDate(new Date(noteData.date), { year: 'numeric', month: 'short', day: 'numeric' });
            return {
                author: noteData.author,
                date: `${noteDate} ${formatAMPM(new Date(noteData.date))}`,
                subject: noteData.subject,
                note: noteData.note
            };
        });
    };

    const showServiceVendorAddress = (serviceData) => {
        const contactDTO = serviceData.vendor || serviceData;
        const vendor = { contactDTO };
        return <VendorLinkComponent id="vendorCell" vendors={[vendor]} />;
    };

    const getVendorNameLink = (item) => {
        return <VendorLinkComponent id="vendorCell" vendors={[item]} />;
    };

    const expanderContent = (data) => {
        const coverageArray = getVehicleCoverageTable(data.coverages);
        const coveragesForVehicleHeader = translator(
            messages.coveragesFor,
            {
                vehicleMake: data.make,
                vehicleModel: data.model
            }
        );
        return (
            <>
                <h4>{coveragesForVehicleHeader}</h4>
                <DataTable
                    data={coverageArray}
                    id="vehicleCoverageTableId"
                    title={coveragesForVehicleHeader}
                    titleId="vehicleCoverageTitleId"
                    titlePosition="left"
                    expandable={false}
                    showSearch={false}
                    showPagination={false}
                >
                    <DisplayColumn
                        cell={(row) => row.type}
                        columnProportion={1}
                        header={translator(messages.type)}
                        id="typeHeader"
                        textAlign="left"
                        onSort={DatatableUtil.sortString}
                    />
                    <DisplayColumn
                        cell={(row) => row.deductible}
                        columnProportion={1}
                        header={translator(messages.deductible)}
                        id="deductibleHeader"
                        textAlign="left"
                        onSort={DatatableUtil.sortString}
                    />
                    <DisplayColumn
                        cell={(row) => row.limittype}
                        columnProportion={1}
                        header={translator(messages.limittype)}
                        id="limitTypeHead"
                        textAlign="left"
                        onSort={DatatableUtil.sortString}
                    />
                    <DisplayColumn
                        cell={(row) => row.limitvalue}
                        columnProportion={1}
                        header={translator(messages.limitvalue)}
                        id="limitValueHead"
                        textAlign="left"
                        onSort={DatatableUtil.sortNumber}
                    />
                </DataTable>
            </>
        );
    };

    const formatPaymentDate = (claimPaymentDate) => {
        return _.isNil(claimPaymentDate) ? '' : WniDateUtil.formatDateWithPattern(claimPaymentDate);
    }

    const getPaymentCheckNumber = (paymentCheckNumber) => {
        return _.isNil(paymentCheckNumber) ? '' : paymentCheckNumber;
    };

    const generatePaymentDetailsOverrides = () => {
        const paymentChecksPath = 'claimPayments';
        const paymentChecks = _.get(claimDetailsData, paymentChecksPath, []);
        const overrides = paymentChecks.map((paymentCheck, index) => {
            return {
                [`paymentScheduledDate${index}`]: {
                    value: formatPaymentDate(paymentCheck.scheduledDate_Ext)
                },
                [`paymentIssueDate${index}`]: {
                    value: formatPaymentDate(paymentCheck.issueDate_Ext)
                },
                [`paymentStatus${index}`]: {
                    value: translator({
                        id: `typekey.TransactionStatus.${paymentCheck.status}`,
                        defaultMessage: paymentCheck.status
                    })
                },
                [`paymentCheckNumber${index}`]: {
                    value: getPaymentCheckNumber(paymentCheck.checkNumber)
                },
                [`paymentPayee${index}`]: {
                    value: paymentCheck.payee.join(', ')
                }
            };
        });

        return Object.assign({}, ...overrides);
    };

    const checkPaymentsHasDetails = () => {
        return claimDetailsData.claim.checks && claimDetailsData.claim.checks.length > 0;
    };

    const mapServiceStatus = (status) => {
        switch (status) {
            case 'workcomplete':
                return translator(messages.statusCompleted);
            case 'specialistwaiting':
            case 'requested':
            case 'inprogress':
            case 'draft':
                return translator(messages.statusInProgress);
            case 'expired':
            case 'declined':
            case 'canceled':
                return translator(messages.statusOffTrack);
            default:
                return translator(messages.statusUnknown);
        }
    };

    const changeStatusIcon = (status) => {
        switch (status) {
            case 'workcomplete':
                return 'check';
            case 'specialistwaiting':
            case 'requested':
            case 'inprogress':
            case 'draft':
                return 'refresh';
            case 'expired':
            case 'declined':
            case 'canceled':
                return 'times';
            default:
                return ' ';
        }
    };

    const showServiceCompletionDate = (serviceRequest) => {
        const checkServiceStatus = false;
        const serviceStatus = mapServiceStatus(serviceRequest.status);
        if (
            serviceStatus === translator(messages.statusInProgress)
            && serviceRequest.expectedServiceCompletionDate
        ) {
            return !checkServiceStatus;
        }
        return checkServiceStatus;
    };

    const getServicePrimaryContact = (serviceRequest) => {
        const serviceContactRoles = `(${serviceRequest.customerClaimContact.contactRolesDisplay.join(
            ', '
        )})`;
        return (
            <>
                <ContactLinkComponent
                    contactDetails={serviceRequest.customerClaimContact.contactDTO}
                />
                <span>{serviceContactRoles}</span>
            </>
        );
    };

    const generateServiceDetailsOverrides = () => {
        const serviceRequestsPath = 'claimServices';
        const serviceRequests = _.get(claimDetailsData, serviceRequestsPath, []);
        const overrides = serviceRequests.map((serviceRequest, index) => {
            return {
                [`serviceCompletionDate${index}`]: {
                    value: intl.formatDate(
                        new Date(serviceRequest.expectedServiceCompletionDate),
                        { year: 'numeric', month: 'short', day: 'numeric' }
                    ),
                    visible: showServiceCompletionDate(serviceRequest),
                },
                [`serviceStatusIcon${index}`]: {
                    icon: changeStatusIcon(serviceRequest.status)
                },
                [`serviceVendorLink${index}`]: {
                    value: showServiceVendorAddress(serviceRequest),
                },
                [`serviceStatus${index}`]: {
                    content: mapServiceStatus(serviceRequest.status)
                },
                [`servicePrimaryContact${index}`]: {
                    value: getServicePrimaryContact(serviceRequest),
                    visible:
                        appConfig.persona === 'producer'
                        || _.get(claimDetailsData, 'claim.policy.policyType', '') === 'WorkersComp'
                },
                [`serviceItems${index}`]: {
                    value: serviceRequest.services.join(', ')
                }
            };
        });

        return Object.assign({}, ...overrides);
    };

    const checkServiceRequestsHasDetails = () => {
        return (claimDetailsData.claim.serviceRequests
            && claimDetailsData.claim.serviceRequests.length > 0);
    };

    const canAccessFinancials = () => {
        return appConfig.persona === 'producer' || appConfig.persona === 'csr';
    };

    const handleDocumentsUpload = async (file) => {
        const documentMetaDataTemplate = {
            docUID: '001',
            documentType: 'fnol',
            securityType: 'unrestricted',
            status: 'approved',
            claimNumber: claimNumber,
            name: file.name,
            mimeType: file.type,
            sessionID: uploadToken
        };
        modalApi.showConfirm({
            title: messages.uploadConfirmationTitle,
            message: messages.uploadConfirmationMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.uploadConfirmationYes,
            cancelButtonText: messages.uploadConfirmationCancel
        }).then(async (results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            try {
                const documentsData = await ClaimService.uploadDocument(
                    file,
                    documentMetaDataTemplate,
                    authHeader
                );
                claimDetailsData.claim.documents = [...claimDetailsData.claim.documents, documentsData];
                updateClaimDocuments(claimDetailsData.claim.documents);
                updateClaimDetailsData(claimDetailsData);
            } catch (e) {
                handleError(commonMessages.errorUploadTitle, commonMessages.uploadFailedMessage);
            }
        }, _.noop);
    };

    const render = () => {
        if (isLoading) {
            return <Loader loaded={!isLoading} />;
        }

        if (!claimDetailsData) {
            return null;
        }

        const policyArray = getPolicyTable(claimDetailsData.claim);
        let vehicleDetailsArray = [];
        let claimNotesArray = [];
        let documentsArray = [];
        let paymentsArray = [];
        if (claimDocuments.length > 0) {
            documentsArray = getDocumentsArray(claimDocuments);
        }
        if (toggleVehicleDetailsContainer(claimDetailsData)) {
            vehicleDetailsArray = getVehicleDetailsTable(claimDetailsData.vehicles);
        }
        if (claimDetailsData.notes.length > 0) {
            claimNotesArray = getNotesArray(claimDetailsData.notes);
        }
        if (claimDetailsData.claimPayments.length > 0) {
            paymentsArray = claimDetailsData.claimPayments
        }
        // hide notes, services, and OOTB payments. use paymentsTableGrid instead
        const overrides = {
            notesTab: {
                visible: false
            },
            servicesTab: {
                visible: false
            },
            claimPayments: {
                visible: false
            },
            claimPaymentMessageContainer: {
                visible: false
            },
            adjusterTitle: {
                content: messages.claimRepresentative
            },
            documentsNameHeader: {
                header: messages.documentNameHeader
            }, 
            uploadDocumentsId: {
                buttonIcon: "gw-cloud-upload"
            },
            partiesInvolvedTableGrid: {
                data: policyArray
            },
            vendorInvolvedTableGrid: {
                data: claimDetailsData.claimContactsVendors
            },
            vehicleDetailsDataTable: {
                data: vehicleDetailsArray,
                renderExpanderContent: expanderContent,
                visible: vehicleDetailsArray.length > 0
            },
            notesTableGrid: {
                data: claimNotesArray,
                visible: getNotesTableVisibility(claimDetailsData.notes)
            },
            emptyDocsmsg: {
                visible: _.isEmpty(documentsArray)
            },
            claimStatus: {
                value: translator({
                    id: `typekey.ClaimState.${claimDetailsData.claim.claimState}`,
                    defaultMessage: claimDetailsData.claim.claimState
                })
            },
            documentsTableGrid: {
                data: documentsArray,
                visible: documentsArray.length > 0
            },
            documentsViewDownloadHeader: {
                visible: false
            },
            documentsRemoveHeader: {
                visible: false
            },
            removeDocumentNotification: {
                message: documentAlert,
                visible: !_.isEmpty(documentAlert)
            },
            paymentsTableGrid: {
                data: paymentsArray
            },
            primaryContact: {
                visible: !!getPrimaryContactName(claimDetailsData.claim),
                value: getPrimaryContactName(claimDetailsData.claim)
            },
            emptyNotesMessage: {
                visible: getEmptyMessageVisibility(claimDetailsData.notes)
            },
            claimDetailNumber: {
                content: getClaimNumber(claimDetailsData.claim)
            },
            policyNumber: {
                value: getPolicyNumber(claimDetailsData.claim)
            },
            accountNumber: {
                value: getAccountNumber(claimDetailsData.claim)
            },
            lossLocation: {
                visible: getLossLocationVisibility(claimDetailsData.claim),
                value: getLossLocation(claimDetailsData.claim)
            },
            employerNotified: {
                visible: getEmployerNotifiedVisibility(claimDetailsData.claim),
                value: getEmployerNotifiedDate(claimDetailsData.claim)
            },
            daysInHospital: {
                visible: getHospitalDaysVisibility(claimDetailsData.claim)
            },
            dateOfLoss: {
                value: getLossDate(claimDetailsData.claim)
            },
            adjusterName: {
                value: getAdjusterName(claimDetailsData.claim)
            },
            vehicleDetailsContainer: {
                visible:
                    ['producer', 'csr', 'policyholder'].includes(appConfig.persona)
                    && toggleVehicleDetailsContainer(claimDetailsData)
            },
            noClaimPaymentsDetails: {
                visible: !checkPaymentsHasDetails()
            },
            claimPaymentsDetails: {
                visible: checkPaymentsHasDetails()
            },
            noClaimServicesDetails: {
                visible: !checkServiceRequestsHasDetails()
            },
            claimServicesDetails: {
                visible: checkServiceRequestsHasDetails()
            },
            claimDetailsPage: {
                visible: isClaimDetailsPageVisible
            },
            summaryRightDiv: {
                visible: canAccessFinancials()
            },
            partyTableContainer: {
                visible: ['producer', 'vendor', 'csr'].includes(appConfig.persona)
            },
            claimDetailsTabset: {
                defaultActiveTab: selectedTab
            },
            vendorTableContainer: {
                visible: false
            },
            ...generatePaymentDetailsOverrides(),
            ...generateServiceDetailsOverrides()
        };
        const resolvers = {
            resolveCallbackMap: {
                getBackToPreviousState: getBackToPreviousState,
                getCell: getCell,
                getDataCell: getDataCell,
                onDateCell: onDateCell,
                onColumnCell: onColumnCell,
                getNotesColumnCell: getNotesColumnCell,
                getLimitType: getLimitType,
                getPartyNameLink: getPartyNameLink,
                getVendorNameLink: getVendorNameLink,
                addNote: addNote,
                getRemoveIcon: getRemoveIcon,
                getViewandDownload: getViewandDownload,
                handleDocumentsUpload: handleDocumentsUpload,
                sortString: DatatableUtil.sortString,
                sortNumber: DatatableUtil.sortNumber,
                onRowClick: (data) => {
                    _.set(data, 'expanded', !data.expanded);
                }
            },
            resolveClassNameMap: claimDetailsStyles
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, claimDetailsData, id, path, overrides);
        };

        const claimDetailsPage = (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimDetailsData}
                overrideProps={overrides}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );

        return <div className={cx(claimDetailsStyles.claimDetails)}>{claimDetailsPage}</div>;
    };
    
    return render();
}
ClaimDetails.PropTypes = {
    match: PropTypes.shape({
        url: PropTypes.string,
        params: PropTypes.shape({
            claimNumber: PropTypes.string
        })
    }).isRequired,
    history: PropTypes.shape({
        location: PropTypes.shape({
            state: PropTypes.shape({})
        })
    }).isRequired
}
export const ClaimDetailsComponent = ClaimDetails;
export default withRouter(ClaimDetails);
