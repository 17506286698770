import React, { useState, useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { PolicyChange, messages as commonMessage, NO_DRIVER_VEHICLE } from 'gw-capability-policychange-common-react';
import { WniCEEndorsementService } from 'wnice-capability-policychange';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import metadata from './CEPADriversChangePage.metadata.json5';
import styles from '../../CEPAPolicyChangeWizard.module.scss';
import messages from './CEPADriversChangePage.messages';


const drivers = 'lobData.personalAuto.coverables.drivers';

function CEPADriversChangePage(props) {
    const modalApi = useModal();
    const translator = useTranslator();
    const [driverPath, setDriverPath] = useState('');
    const [selectedDriver, setSelectedDriver] = useState({});
    const { wizardData, updateWizardData, wizardSnapshot } = props;
    const { submissionVM } = wizardData;
    const [ originalDriversVM, setOriginalDriversVM ] = useState();
    const [isVmIntitialized, updateIsVmInitialized] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);
    const [isRequiredForIssuance, updateIsRequiredForIssuance] = useState(true)
    const jobID = _.get(submissionVM, 'jobID.value');
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        disregardFieldValidation
    } = useValidation('CEPADriversChangePage');
    const { authHeader } = useAuthentication();

    const disregardDriverValidation = useCallback((component) => {
        updateShowErrors(false);
        updateIsRequiredForIssuance(false)
        updateWizardData(wizardData);
        return new Promise((resolve) => {
            setTimeout(() => {
                disregardFieldValidation(component)
                resolve()
            }, 200);
        })
    }, [disregardFieldValidation, updateWizardData, wizardData])

    useEffect(() => {
        setOriginalDriversVM(_.cloneDeep(submissionVM.value.lobData.personalAuto.coverables.drivers))
    },[])

    const writeValue = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            updateWizardData(wizardData);
        },
        [submissionVM, updateWizardData, wizardData]
    );

    const showLicenseGroupModal = useCallback(
        (driverGroup) => {
            return modalApi.showAlert({
                title: messages.licenseNotUnique,
                message: translator(messages.licenceMatchMsg, {
                    driver: driverGroup[0].displayName || driverGroup[0].getDisplayName()
                }),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: platformMessages.ok
            }).catch(_.noop);
        },
        [modalApi, translator]
    );

    const uniqueLicenceNumber = useCallback(() => {
        let licenseCheckError = false;
        const driverList = _.get(submissionVM.value, 'lobData.personalAuto.coverables.drivers');
        const groupedDrivers = _.groupBy(driverList, (driver) => {
            return driver.licenseNumber;
        });
        Object.keys(groupedDrivers).forEach((driver) => {
            if (groupedDrivers[driver].length > 1 && driver !== undefined) {
                showLicenseGroupModal(groupedDrivers[driver]);
                licenseCheckError = true;
                return false;
            }
            return true;
        });
        return licenseCheckError;
    }, [showLicenseGroupModal, submissionVM.value]);

    const onNext = useCallback(async () => {
        if (!uniqueLicenceNumber()) {
            submissionVM.value.beforeSave();
            setLoadingMask(true);
            const driverDTOs = _.get(submissionVM, `${drivers}.value`);
            const policyChangeResponse = await WniCEEndorsementService.saveDrivers(
                jobID, driverDTOs,
                authHeader
            );
            setLoadingMask(false);
            submissionVM.value = new PolicyChange(policyChangeResponse);
            return wizardData;
        }
        return false;
    }, [uniqueLicenceNumber, setLoadingMask, submissionVM, jobID, authHeader, wizardData]);

    const deleteDriver = useCallback(
        async (driver, index) => {
            await disregardDriverValidation(`driver${index}`)
            submissionVM.value.lobData.personalAuto.coverables.removeDriver(driver);
            updateWizardData(wizardData);
        },
        [disregardDriverValidation, submissionVM.value, updateWizardData, wizardData]
    );

    const saveDriver = useCallback(
        (driversList, index, actionType) => {
            if (!isComponentValid) {
                updateShowErrors(true);
                return;
            }
            onNext()
            setDriverPath('');
        },[isComponentValid, onNext]
    )

    const hideDriverDetails = useCallback(
        async (driversList, index, actionType) => {
            if(actionType === 'cancel'){
                const tempID = _.get(driversList.value[index], 'tempID')
                if(tempID && index > 0) {
                    deleteDriver(driversList.value[index], index);
                }
            }
            if (_.get(driversList.value[index], 'fixedId')) {
                const prevValue = _.get(
                    wizardSnapshot,
                    'submissionVM.lobData.personalAuto.coverables.drivers.children'
                ).find(
                    (driver) => driver.value.fixedId === _.get(driversList.value[index], 'fixedId')
                );
                _.set(
                    submissionVM,
                    `lobData.personalAuto.coverables.drivers.children[${index}].value`,
                    prevValue.value
                );
                await disregardDriverValidation(`driver${index}`);
                setDriverPath('');
            }
        },
        [deleteDriver, disregardDriverValidation, submissionVM, wizardSnapshot]
    );

    const showDriverDetails = useCallback((vehicle, index) => {
        setDriverPath(`lobData.personalAuto.coverables.drivers.children[${index}]`);
        setSelectedDriver(vehicle);
    }, []);

    const removeDriver = useCallback(
        (driversList, driver) => {
            modalApi.showConfirm({
                title: messages.wantToRemoveDriver,
                message: messages.wantToRemoveDriverMsg,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: platformMessages.yesModel,
                cancelButtonText: platformMessages.cancelModel
            }).then((results) => {
                if (results === 'cancel' || results === "close") {
                    return _.noop();
                }
                const driverIndex = driversList.value.findIndex(
                    (driverFromList) => _.isEqual(driverFromList, driver)
                );
                deleteDriver(driver, driverIndex);
            }, _.noop);
            
        },
        [deleteDriver, modalApi]
    );

    const driverOverrides = useMemo(() => {
        const availableDriverPath = 'lobData.personalAuto.coverables.drivers.children';
        const driversList = _.get(submissionVM, drivers, []);
        let overrides = [];
        if (!_.isEmpty(driversList)) {
            overrides = driversList.value.map((driver, index) => {
                const driverName = driver.getDisplayName();
                return {
                    [`paDriverContainer${index}`]: {
                        visible: `${availableDriverPath}[${index}]` === driverPath,
                    },
                    [`driver${index}`]: {
                        isRequiredForIssuance,
                        baseData: submissionVM.baseData,
                        updateShowErrors,
                        showErrors,
                        disregardFieldValidation,
                    },
                    [`paDriverSectionNameId${index}`]: {
                        content: driverName || translator(messages.paNewDriver)
                    },
                    [`paDriverSectionTrashId${index}`]: {
                        disabled:(`${availableDriverPath}[${index}]` !== driverPath && !isComponentValid),
                        onClick: () => {
                            removeDriver(driversList, driver);
                        },
                        /**
                         * CEI-4969
                         * UAT Finding - Driver as Primary Named Insured should not be able to delete
                         */
                        visible: !driver.isPNI_Ext
                    },
                    [`paDriverSectionEditId${index}`]: {
                        disabled:
                            `${availableDriverPath}[${index}]` !== driverPath && !isComponentValid,
                        visible: `${availableDriverPath}[${index}]` !== driverPath,
                        onClick: () => {
                            showDriverDetails(driver, index);
                        }
                    },
                    [`saveBtnId${index}`]: {
                        onClick: () => {
                            saveDriver(driversList, index, 'save');
                        }
                    },
                    [`cancelBtnId${index}`]: {
                        onClick: () => {
                            hideDriverDetails(driversList, index, 'cancel');
                        },
                    },
                };
            });
        }
        return Object.assign({}, ...overrides);
    }, [submissionVM, driverPath, isRequiredForIssuance, showErrors, disregardFieldValidation, translator, isComponentValid, removeDriver, showDriverDetails, saveDriver, hideDriverDetails]);

    const setDefaultNewEntry = useCallback(() => {
        const driverDefaultValue = {
            accidents: '0',
            violations: '0',
            licensedDriver_Ext: true,
            driversLicenseStatus_Ext: "valid",
            "driverCovered_Ext": true,
            "driverCoveredReason_Ext": null,
            driverQuestionSets_Ext: [{
                answers: _.clone(defaultQuestionSets),
                code: "PADriverQuestionSet"
            }],
            policyCountry_Ext: 'US',
        }
        const driver = submissionVM.value.lobData.personalAuto.coverables.createDriver(driverDefaultValue);
        setSelectedDriver(driver);
    }, [submissionVM.value.lobData.personalAuto.coverables]);

    const onAddNew = useCallback(() => {
        setDefaultNewEntry();
        const newVehicleIndex = submissionVM.lobData.personalAuto.coverables.drivers.length - 1;
        setDriverPath(`lobData.personalAuto.coverables.drivers.children[${newVehicleIndex}]`);
        updateIsRequiredForIssuance(true)
        updateWizardData(wizardData);
    }, [setDefaultNewEntry, submissionVM, updateWizardData, wizardData]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddDriverClick: onAddNew,
            onValidate: onValidate
        }
    };

    const overrideProps = useMemo(
        () => {
            return {
                '@field': {
                    showOptional: true,
                    phoneWide: {
                        labelPosition: 'top'
                    }
                },
                addDriver: {
                    disabled: !isComponentValid
                },
                ...driverOverrides
            }
        },[driverOverrides, isComponentValid]
    )
    
    

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    if (!isVmIntitialized) {
        updateWizardData(wizardData);
        updateIsVmInitialized(true);
        return null;
    }

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            cancelLabel={(appConfig.persona === 'policyholder' ? commonMessage.cancelAllChanges : commonMessage.cancel)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                callbackMap={resolvers.resolveCallbackMap}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

const defaultQuestionSets = {
    // "DriverWNEmployeeDiscountYN_Ext": null,
    // "DriverLicSuspLastFiveYearsYN_Ext": null,
    // "DriverAnyCurFinResFilingYNExplain_Ext": null,
    "DriverAnyCurFinResFilingYN_Ext": null,
    // "DriverLicSuspLastFiveYearsYNExplain_Ext": null,
    "DriverIsCompanyVehFurnishedYN_Ext": null
}

CEPADriversChangePage.propTypes = wizardProps;
export default CEPADriversChangePage;
