import React, {
    useContext, useState, useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Card
} from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { pageTemplateProps } from '@xengage/gw-portals-wizard-react';

import styles from './PremiumReportWizardPageTemplate.module.scss';
import WizardSidebar from '../components/PremiumReportWizardSidebar';
import MobileWizardSteps from '../components/MobileWizardSteps/MobileWizardSteps';

function PremiumReportWizardPageTemplate(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phoneWide' || breakpoint === 'phone';
    const [showSidebar, setShowSidebar] = useState(false);
    const {
        children: content,
        renderContextComponent,
        renderWidgets,
        wizardData,
        wizardSnapshot,
        showContextComponent,
        showWizardHeader,
        customMessageModal,
        renderCustomWizardPageFooterContent
    } = props;

    const customWizardPageFooterContent = renderCustomWizardPageFooterContent();

    useEffect(() => {
        switch (breakpoint) {
            case 'phoneWide':
            case 'phone':
                setShowSidebar(false);
                break;
            default:
                setShowSidebar(true);
                break;
        }
    }, [breakpoint]);

    const toggleSideBar = useCallback(() => {
        setShowSidebar(!showSidebar);
    }, [showSidebar]);

    const contextComponent = useCallback(() => {
        return <div className={styles.wizardContext}>{renderContextComponent(wizardSnapshot)}</div>;
    }, [renderContextComponent, wizardSnapshot]);

    const wizardClassName = classNames(styles.wizardMain, {
        [styles.wizardMainShowSidebar]: showSidebar && isPhone
    });

    return (
        <div className={styles.digitalWizard}>
            {showContextComponent && contextComponent()}
            <div className={wizardClassName}>
                {!isPhone || showSidebar ? (
                    <div className={classNames(styles.wizardNavigation, 'hiddenPrintElement')}>
                        <WizardSidebar customMessageModal={customMessageModal} />
                        <div className={styles.wizardWidgets}>
                            {renderWidgets(wizardData)}
                        </div>
                    </div>
                ) : null}
                <div className={styles.wizardContent}>
                    {isPhone ? <MobileWizardSteps toggleSideBar={toggleSideBar} /> : null}
                    {showWizardHeader && contextComponent()}
                    <Card
                        id="digitalWizardContent"
                        className={styles.wizardContentContainer}
                        fullWidth
                        isPanel>
                        {content}
                        <div className={classNames(styles.navButtons, 'hiddenPrintElement')}>
                            <div className={styles.navTransitionButtons}>
                                {customWizardPageFooterContent}
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

PremiumReportWizardPageTemplate.propTypes = {
    ...pageTemplateProps,
    /** a function that accepts wizardData to render a React component */
    renderContextComponent: PropTypes.func,
    /** a function that accepts wizardData to render a React component */
    renderWidgets: PropTypes.func,
    showContextComponent: PropTypes.bool,
    showWizardHeader: PropTypes.bool,
    renderCustomWizardPageFooterContent: PropTypes.func
};

PremiumReportWizardPageTemplate.defaultProps = {
    renderContextComponent: () => null,
    renderCustomWizardPageFooterContent: () => null,
    renderWidgets: () => null,
    showContextComponent: true,
    showWizardHeader: false
};

export default PremiumReportWizardPageTemplate;
