import React, {
    useEffect, useContext, useState, useCallback
} from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Wizard } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { Loader, useModal } from '@jutro/components';
import { Claim, fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import wizardConfig from './config/fnol-ca-wizard-config.json5';
import './FNOLCA.messages';

const ACTION_MSG = {
    cancelAction: {
        title: commonMessages.wantToCancel,
        message: fnolCommonMessages.cancelModalMessage,
        status: 'warning',
        icon: 'gw-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: fnolCommonMessages.fnolMessagePopupCancel
    },
    saveAndExitAction: {
        title: fnolCommonMessages.saveAndExitModalTitle,
        message: fnolCommonMessages.saveAndExitModalMessage,
        status: 'warning',
        icon: 'gw-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: fnolCommonMessages.fnolMessagePopupCancel
    },
    previousAction: {
        title: commonMessages.wantToJump,
        message: commonMessages.wantToJumpMessage,
        status: 'warning',
        icon: 'gw-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    }
};

function FNOLCAWizard(props) {
    const modalApi = useModal();
    const { steps, title } = wizardConfig;
    const { location, history } = props;
    const { FNOLService } = useDependencies('FNOLService');
    const { authHeader } = useAuthentication();
    const [getRedirectPath, setRedirectPath] = useState();
    const viewModelService = useContext(ViewModelServiceContext);
    const [initialClaim, setInitialClaim] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);

    useEffect(() => {
        if (!location.state) {
            return history.push('/');
        }
        const { claimData, redirectPath } = location.state;
        setRedirectPath(redirectPath);
        const claimValue = new Claim(claimData);
        const claimVM = viewModelService.create(
            claimValue,
            'cc',
            'wni.edge.capabilities.claim.fnol.dto.WniFnolDTO'
        );

        if (claimVM.lossCause.value?.code === 'otherobjcoll') {
            const claimNumber = _.get(claimVM.value, 'claimNumber');
            history.push({
                pathname: `/contact-agent/${claimNumber}`,
                state: {
                    redirectPath: redirectPath
                }
            });
        }

        return setInitialClaim(claimVM);
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveDraftClaim = useCallback(
        (claimData, toRedirectpath) => {
            setIsLoading(true);
            FNOLService.saveFNOLDetails(claimData, authHeader)
                .then(() => {
                    return history.push({
                        pathname: toRedirectpath,
                        state: 'claims'
                    });
                })
                .catch(() => {
                    modalApi.showAlert({
                        title: fnolCommonMessages.fnolSaveDraftErrorTitle,
                        message: fnolCommonMessages.fnolSaveDraftErrorMessage,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [authHeader, history, FNOLService]
    );

    const handleOnCancel = useCallback(
        ({ wizardSnapshot, param: nextPath = '', wizardData }) => {
            if (
                (!_.isEmpty(nextPath) && nextPath.startsWith('/fnol'))
                || nextPath.startsWith('/contact-agent')
                || nextPath.startsWith('/confirmation')
                || hasErrorOccurred
            ) {
                return null;
            }
            let toRedirectpath = nextPath;
            if (_.isEmpty(nextPath)) {
                toRedirectpath = getRedirectPath;
            }
            const message = wizardSnapshot.claimNumber?.value
                ? ACTION_MSG.saveAndExitAction
                : ACTION_MSG.cancelAction;
            return modalApi.showConfirm(message).then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                setIsLoading(true);
                if (!wizardSnapshot.claimNumber.value) {
                    history.push({
                        pathname: toRedirectpath,
                        state: 'claims'
                    });
                    setIsLoading(false);
                    // returning undefined since arrow functions require return value
                    return undefined;
                }
                const isValid = wizardData.lobs.aspects.valid
                && wizardData.lobs.aspects.subtreeValid
                && wizardData.lossLocation.aspects.subtreeValid
                && wizardData.mainContact.aspects.subtreeValid;
                const claimData = isValid
                    ? _.get(wizardData, 'value')
                    : _.get(wizardSnapshot, 'value');
                return saveDraftClaim(claimData, toRedirectpath);
            }, _.noop);
        },
        [hasErrorOccurred, getRedirectPath, saveDraftClaim, history]
    );

    const handleError = useCallback(
        (error) => {
            const state = {
                pathname: '/fnol-contactAgent',
                state: error
            };

            return modalApi.showAlert({
                title: commonMessages.genericError,
                message: commonMessages.genericErrorMessage,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                setHasErrorOccurred(true);
                return <Redirect to={state} />;
            }, _.noop);
        },
        [modalApi]
    );

    if (isLoading) {
        return <Loader loaded={!isLoading} text={fnolCommonMessages.fnolSaveDraftClaim} />;
    }

    if (!initialClaim) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                skipCompletedSteps
                initialData={initialClaim}
                onCancel={handleOnCancel}
            />
        </ErrorBoundary>
    );
}
FNOLCAWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            claimData: PropTypes.shape({}),
            redirectPath: PropTypes.string
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(withViewModelService(FNOLCAWizard));
