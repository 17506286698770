import React, { useCallback } from 'react';

import { pageTemplateProps } from '@xengage/gw-portals-wizard-react';
import WizardPageHeader from '../../components/WizardPageHeader/PremiumReportWizardPageHeader'
import WizardSingleErrorComponent from '../../../WizardSingleErrorComponent/PremiumReportWizardSingleErrorComponent'
import DigitalWizardPageTemplate from '../PremiumReportWizardPageTemplate';

import styles from './PremiumReportWizardPageWithTitleTemplate.module.scss';

function PremiumReportWizardPageWithTitleTemplate(props) {
    const {
        children, acknowledgeError, errorsForStep, isSideHeader, ...otherProps
    } = props;

    const renderWizardHeader = useCallback(
        (wizardData) => {
            return (
                <WizardPageHeader wizardData={wizardData} isSideHeader={isSideHeader} />
            );
        },
        [isSideHeader]
    );

    return (
        <div className={styles.wizardContainer}>
            <DigitalWizardPageTemplate
                renderContextComponent={renderWizardHeader}
                showContextComponent={false}
                showWizardHeader
                {...otherProps}
            >
                <WizardSingleErrorComponent
                    acknowledgeError={acknowledgeError}
                    issuesList={errorsForStep}
                />
                {children}
            </DigitalWizardPageTemplate>
        </div>
    );
}

PremiumReportWizardPageWithTitleTemplate.propTypes = pageTemplateProps;
export default PremiumReportWizardPageWithTitleTemplate;
