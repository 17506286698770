/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { TooltipIcon, withModalContext } from '@jutro/components';
import { TermConditionsPopup } from 'gw-components-platform-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { CreditCardUtil } from '@xengage/gw-portals-util-js';
import { TranslatorContext } from '@jutro/locale';
import { WniDateField } from 'wni-common-base-components';
import PaymentStyles from './PaymentComponentCE.module.scss';
import metadata from './PaymentComponentCE.metadata.json5';
import messages from './PaymentComponentCE.messages';

import { Link } from '@jutro/router';

const PAYMENT_OPTIONS = [
    {
        "code": "bankaccount_Ext",
        "name": {
          "id": "quoteandbind.views.payment-details.Bank Account",
          "defaultMessage": "Bank Account"
        }
    },
    {
        "code": "creditcard",
        "name": {
            "id": "quoteandbind.views.payment-details.Credit Card",
            "defaultMessage": "Credit Card"
        }
    }
];
class PaymentComponentCE extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        model: PropTypes.shape({
            paymentMethod: PropTypes.string,
            bankAccountDataCE_Ext: PropTypes.shape({}),
            creditCardData: PropTypes.shape({})
        }),
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        onCallback: PropTypes.func.isRequired,
        xCenter: PropTypes.string.isRequired,
        title: PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string.isRequired
        }),
        hideTitle: PropTypes.bool,
        onCancel: PropTypes.func.isRequired,
        nextLabel: PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string.isRequired
        }),
        previousLabel: PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string.isRequired
        }),
        isSetupPayment: PropTypes.bool,
        isCustomFooter: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isSelectedInvoiceAmount: PropTypes.bool,
        // ...validationPropTypes
    };

    static defaultProps = {
        model: undefined,
        title: undefined,
        hideTitle: false,
        nextLabel: undefined,
        previousLabel: undefined,
        isSetupPayment: false,
        isCustomFooter: false,
        isDisabled: false,
        isSelectedInvoiceAmount: false
    }

    state = {
        paymentMethod: '',
        paymentSource: '',
        accountCreditCardVM: {},
        accountBankDetailVM: {},
        showValidationErrors: false,
        checkTermConditions: false,
        payNowClicked: false
    };

    componentDidMount() {
        let { accountCreditCardVM, accountBankDetailVM } = this.state;
        const { viewModelService, xCenter, model, currentAutoPayPublicID, existingBankAccounts } = this.props;
        const initValue = {
            paymentMethod: '',
            paymentSource: '',
            bankAccountDataCE_Ext: {},
            creditCardData: {}
        };

        const mergedModel = { ...initValue, ...model};
        const bakOfBankAccounts = _.cloneDeep(existingBankAccounts);
        const { creditCardData: cardDetailsModel } = mergedModel;
        const { bankAccountDataCE_Ext: bankDetailsModel } = mergedModel;
        let { paymentMethod, paymentSource } = mergedModel;
        if (Object.keys(cardDetailsModel).length > 0) {
            paymentMethod = 'creditcard';
            paymentSource = 'creditcard';
        } else {
            paymentMethod = 'bankaccount_Ext';
            paymentSource = currentAutoPayPublicID || 'bankaccount_Ext';
        }
        accountCreditCardVM = viewModelService.create(
            cardDetailsModel,
            xCenter,
            'edge.capabilities.billing.dto.AccountCreditCardDTO'
        );
        accountBankDetailVM = viewModelService.create(
            bankDetailsModel,
            xCenter,
            'wni.edge.capabilities.billing.dto.CEAccountBankDetailsDTO'
        );

        if (currentAutoPayPublicID != null) {
            const selectedBankAccount = bakOfBankAccounts.find((opt) => _.get(opt, 'bankAccountDataCE_Ext.publicID') === currentAutoPayPublicID);
            if (!_.isEmpty(selectedBankAccount)) {
                _.set(accountBankDetailVM, 'value', selectedBankAccount.bankAccountDataCE_Ext);
            }
        }

        this.setState({
            paymentMethod,
            paymentSource,
            accountCreditCardVM,
            accountBankDetailVM
        });
    };

    isModelValid = (viewModel) => {
        return viewModel.aspects
            ? viewModel.aspects.valid && viewModel.aspects.subtreeValid
            : false;
    };

    handlePaymentOptions = (data) => {
        if (data === 'creditcard') {
            this.setState({
                paymentMethod: data,
                paymentSource: data,
                showValidationErrors: false
            });
        } else {
            const { existingBankAccounts } = this.props;
            const bakOfBankAccounts = _.cloneDeep(existingBankAccounts);
            const { accountBankDetailVM } = this.state;
            if (data !== 'bankaccount_Ext') {
                // when choosing Existing Bank Accounts
                _.set(accountBankDetailVM, 'value', bakOfBankAccounts.find((bankAccount) => 
                    _.get(bankAccount, 'bankAccountDataCE_Ext.publicID') === data).bankAccountDataCE_Ext);
            } else {
                // when choosing Bank Account
                _.set(accountBankDetailVM, 'value.bankAccountType', 'checking_Ext');
                _.set(accountBankDetailVM, 'value.bankAccountNumber', '');
                _.set(accountBankDetailVM, 'value.bankABANumber', '');
                _.set(accountBankDetailVM, 'value.bankName', '');
                _.set(accountBankDetailVM, 'value.publicID', '');
            }
            this.setState({
                paymentMethod: 'bankaccount_Ext',
                paymentSource: data,
                showValidationErrors: false,
                accountBankDetailVM: accountBankDetailVM
            });
        }      
    };

    handleTermsAndConditions = (data) => {
        this.setState({
            checkTermConditions: data,
            showValidationErrors: false
        });
    };

    writeValue = (value, path) => {
        const { paymentMethod, accountCreditCardVM, accountBankDetailVM } = this.state;
        if (paymentMethod === 'bankaccount_Ext') {
            _.set(accountBankDetailVM, path, value);
            this.setState({ accountBankDetailVM });
        } else {
            _.set(accountCreditCardVM, path, value);
            this.setState({ accountCreditCardVM });
        }
    };

    validateAccountNumber = () => {
        const { paymentMethod, accountBankDetailVM } = this.state;
        if (paymentMethod === 'bankaccount_Ext') {
            const bankAccountNumber = _.get(accountBankDetailVM, 'bankAccountNumber.value');
            if (_.isEmpty(bankAccountNumber)) {
                return true;
            }
            const regex = /^\d{1,19}$/g;
            return regex.test(bankAccountNumber);
        }
        return true;
    };

    payNowClicked = () => {
        const { onCallback } = this.props;
        const { paymentMethod, accountCreditCardVM, accountBankDetailVM, checkTermConditions } = this.state;
        const paymentVM = paymentMethod === 'bankaccount_Ext' ? accountBankDetailVM : accountCreditCardVM;
        const isComponentValid = this.isModelValid(paymentVM);
        this.setState({
            payNowClicked: true
        })
        
        if (!isComponentValid) {
            this.setState({
                showValidationErrors: true
            });
        }

        if (!isComponentValid || !checkTermConditions || !this.validateAccountNumber()) {
            return;
        }

        if (onCallback) {
            onCallback(paymentVM, paymentMethod);
        }
    };

    openTermsAndConditionsPopup = () => {
        const translator = this.context;
        const { modalContext } = this.props;
        const renderTermConditionContent = () => {
            return (
                <div>{translator(messages.termAndConditionsContent)}</div>
            );
        };
        const componentProps = {
            title: translator(messages.termsAndConditions),
            renderTermConditionContent: renderTermConditionContent
        };
        modalContext.showModal(<TermConditionsPopup {...componentProps} />).then(() => {
            // when user clicked Agree button for the popup
            this.handleTermsAndConditions(true);
        }, _.noop);

    };

    render() {
        const translator = this.context;
        const { existingBankAccounts } = this.props;
        const bakOfBankAccounts = _.cloneDeep(existingBankAccounts);
        const {
            paymentMethod,
            paymentSource,
            accountCreditCardVM,
            accountBankDetailVM,
            showValidationErrors,
            checkTermConditions,
            payNowClicked
        } = this.state;
        const paymentVM = paymentMethod === 'bankaccount_Ext' ? accountBankDetailVM : accountCreditCardVM;
        const {
            title, nextLabel, previousLabel, onCancel, isCustomFooter, forAutoPay,
            isSetupPayment, hideTitle, setComponentValidation, isSelectedAmount
        } = this.props;

        const creditCardIssuer = _.get(accountCreditCardVM.value, 'creditCardIssuer', undefined);
        
        const getLabelWithTooltip = (label, tooltip) => {
            return (
                <div className={cx(PaymentStyles.labelWithTooltipStyle)}>
                    {translator(label)}
                    <TooltipIcon
                        id="routingNumbertooltipIcon"
                        text={translator(tooltip)}
                    />
                </div>
            )
        }

        const getTermsAndConditionsCheckupLabel = () => {
            return (
            <span>{translator(messages.readAndAccepted)}
                <Link 
                    id="termsAndConditionsCheckupLink"
                    onClick={this.openTermsAndConditionsPopup}
                    style={{display: 'inline'}}>
                    {translator(messages.termsAndConditions)}
                </Link>
            </span>
            )
        }

        const getPaymentOptions = () => {
            let paymentOptions = PAYMENT_OPTIONS;
            if (!_.isEmpty(bakOfBankAccounts)) {
                const existingAccountOptions = bakOfBankAccounts.map((bankAccountPI) => {
                    const bankAccountData = _.get(bankAccountPI, 'bankAccountDataCE_Ext');
                    const bankAccountTypeCode = _.get(bankAccountData, 'bankAccountType');
                    const bankAccountType = translator({
                        id: `typekey.BankAccountType_Ext.${bankAccountTypeCode}`,
                        defaultMessage: bankAccountTypeCode
                    });
                    const bankAccountNumber = _.get(bankAccountData, 'bankAccountNumber', '');
                    const last4Digits = bankAccountNumber?.substring(bankAccountNumber.length - 4);
                    const optionLabel = `${bankAccountType} - ${last4Digits}`;
                    return {
                        "code": bankAccountData.publicID,
                        "name": optionLabel
                    }
                });
                paymentOptions = [ ...PAYMENT_OPTIONS, ...existingAccountOptions ];
            }
            return forAutoPay ? paymentOptions.filter((payment) => payment.code !== 'creditcard') : paymentOptions;
        };

        const overrides = {
            '@field': {
                phoneWide: {
                    labelPosition: 'top'
                }
            },
            automaticPaymentMethod: {
                visible: isSetupPayment,
                value: translator(messages.bankAccountSource)
            },
            paymentOptions: {
                value: paymentSource,
                labelPosition: 'left',
                visible: !isSetupPayment,
                availableValues: getPaymentOptions()
            },
            bankAccountContainer: {
                visible: paymentMethod === 'bankaccount_Ext'
            },
            creditCardNumber: {
                mask: CreditCardUtil.getInputMaskForIssuerCode(creditCardIssuer)
            },
            creditCardContainer: {
                visible: false
            },
            payNow: {
                onClick: this.payNowClicked,
                content: translator(nextLabel) || translator(messages.paymentComponentBuyNow),
                disabled: forAutoPay ? !this.isModelValid(paymentVM) : (!isSelectedAmount || !this.isModelValid(paymentVM))
            },
            paymentOptionsTitle: {
                content: translator(title) || translator(messages.paymentMethod),
                visible: !hideTitle
            },
            cancel: {
                content: translator(previousLabel) || translator(messages.cancel)
            },
            buttonContainer: {
                className: cx(PaymentStyles.paymentButtonFooter, {
                    [PaymentStyles.paymentCustomFooter]: isCustomFooter
                })
            },
            accountNumber: {
                label: getLabelWithTooltip(messages.accountNumber, messages.accountNumberTooltip),
                showErrors: !this.validateAccountNumber(),
                validationMessages: this.validateAccountNumber() ? [] : [translator(messages.accountNumberInvalid)]
            },
            abaNumber: {
                label: getLabelWithTooltip(messages.routingNumber, messages.routingNumberTooltip)
            },
            termsAndConditionsCheckupContainerId: {
                disabled: true,
                label: getTermsAndConditionsCheckupLabel(),
                value: checkTermConditions
            },
            termsAndConditionsCheckupErrorMsg: {
                visible: !checkTermConditions && payNowClicked
            }
        };

        const resolvers = {
            resolveClassNameMap: PaymentStyles,
            resolveCallbackMap: {
                handlePaymentOptions: this.handlePaymentOptions,
                onCancel: onCancel
            },
            resolveComponentMap: {
                WniDateField
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.componentContent, paymentVM, id, path, overrides);
        };

        return (
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={paymentVM}
                showErrors={showValidationErrors}
                overrideProps={overrides}
                resolveValue={readValue}
                onValidationChange={setComponentValidation}
                onValueChange={this.writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        );
    }
}

export default withValidation(withViewModelService(withModalContext(PaymentComponentCE)));
