import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import 'gwce-capability-policychange-common-react-ext';
import CEPAPolicyChangeStartPage from './pages/PolicyChanges/CEPAPolicyChangeStartPage';
import CEPAAddressChangePage from './pages/Address/CEPAAddressChangePage';
import CEPACoverageChangePage from './pages/Coverages/CEPACoverageChangePage';
import CEPAVehiclesChangePage from './pages/Vehicles/CEPAVehiclesChangePage';
import CEPAQuoteChangePage from './pages/Quote/CEPAQuoteChangePage';
import CEPADriversChangePage from './pages/Drivers/CEPADriversChangePage';

setComponentMapOverrides(
    {
        CEPAPolicyChangeStartPage: { component: 'CEPAPolicyChangeStartPage' },
        CEPAAddressChangePage: { component: 'CEPAAddressChangePage' },
        CEPAVehiclesChangePage: { component: 'CEPAVehiclesChangePage' },
        CEPADriversChangePage: { component: 'CEPADriversChangePage' },
        CEPACoverageChangePage: { component: 'CEPACoverageChangePage' },
        CEPAQuoteChangePage: { component: 'CEPAQuoteChangePage' }
    },
    {
        CEPAPolicyChangeStartPage,
        CEPAAddressChangePage,
        CEPAVehiclesChangePage,
        CEPADriversChangePage,
        CEPACoverageChangePage,
        CEPAQuoteChangePage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as CEPAPolicyChangeWizardMessages } from './CEPAPolicyChangeWizard.messages';
export { default as CEPAPolicyChangeWizard } from './CEPAPolicyChangeWizard';
