import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PolicyCommonCoverageTable from '../../PolicyCommonCoverageTable/PolicyCommonCoverageTable';
import metadata from './PolicyDwellingInfo.metadata.json5';
import messages from './PolicyDwellingInfo.messages';
import styles from './PolicyDwellingInfo.module.scss';

function PolicyDwellingInfo(props) {
    const translator = useTranslator();
    const isDPProduct = _.get(props, 'productCode_Ext') === 'DwellingProperty';

    const updateDPCoverages = useCallback((homeOwnersData) => {
        const houseOwners = _.cloneDeep(homeOwnersData);
        const coverages = houseOwners.coverageDTOs.concat(houseOwners.dwellingDTO.coverageDTOs);
        const selectedCoverages = [];
        const sectionICoverages = coverages.filter((cov) => cov.categoryCodeIdentifier === 'HOPSectionICovCat');
        const sectionIICoverages = coverages.filter((cov) => cov.categoryCodeIdentifier === 'HOPSectionIICovCat');
        const additionalCoverages = coverages.filter((cov) => cov.categoryCodeIdentifier === 'HOPAdditionalCovCat');
        const optionalCoverages = coverages.filter((cov) => ['HOPOptionalCovCat', 'HOPBusinessCovCat_Ext'].includes(cov.categoryCodeIdentifier));
        const scheduledPersonalPropertyCov = coverages.find((cov) => cov.categoryCodeIdentifier === 'HOPScheduledPersonalPropertyCat_Ext');
        const filteredCoverages = [ ...sectionICoverages, ...sectionIICoverages, ...additionalCoverages, ...optionalCoverages, scheduledPersonalPropertyCov ];
        filteredCoverages.forEach((cov) => {
            if (!_.isEmpty(cov)) {
                selectedCoverages.push(cov);
            }
        });
        
        return selectedCoverages;
    }, []);

    const updateHOMainCoverages = useCallback((homeOwnersData) => {
        const homeOwners = _.cloneDeep(homeOwnersData);
        const coverages = homeOwners.coverageDTOs.concat(homeOwners.dwellingDTO.coverageDTOs);
        const mainCoveragesCodes = ['HOPCovA', 'HOPCovB', 'HOPCovC', 'HOPCovD', 'HOPCovL_Ext'];
        return coverages.filter((cov) => mainCoveragesCodes.includes(cov.coverageCodeIdentifier_Ext));;
    }, []);

    const updateHOAdditionalCoverages = useCallback((homeOwnersData) => {
        const homeOwners = _.cloneDeep(homeOwnersData);
        const coverages = homeOwners.coverageDTOs.concat(homeOwners.dwellingDTO.coverageDTOs);
        const selectedCoverages = [];
        const additionalCoverages = coverages.filter((cov) => cov.categoryCodeIdentifier === 'HOPAdditionalCovCat');
        const optionalCoverages = coverages.filter((cov) => ['HOPOptionalCovCat', 'HOPBusinessCovCat_Ext'].includes(cov.categoryCodeIdentifier));
        const scheduledPersonalPropertyCov = coverages.find((cov) => cov.categoryCodeIdentifier === 'HOPScheduledPersonalPropertyCat_Ext');
        const otherCoveragesCodes = ['HOPActualCashValue_Ext', 'HOPFuncReplaceCostLossAddAmount_Ext', 'HOPReplacementCostLossSettlementTermsPersonalProperty_Ext', 
            'HOPReplacementCostLossSettlementTerms_Ext', 'HOPPersonalInjury_Ext', 'HOPSpclPersonalPropCov_Ext', 'HOPUnitOwnersCoverageCSpecialCoverage_Ext'];
        const otherCoverages = coverages.filter((cov) => otherCoveragesCodes.includes(cov.coverageCodeIdentifier_Ext));
        const filteredCoverages = [ ...additionalCoverages, ...optionalCoverages, ...otherCoverages, scheduledPersonalPropertyCov ];
        filteredCoverages.forEach((cov) => {
            if (!_.isEmpty(cov)) {
                selectedCoverages.push(cov);
            }
        });
        
        return selectedCoverages;
    }, []);

    const getHomeOwnersData = useCallback(() => {
        const homeOwnersData = _.cloneDeep(props.value);
        if (isDPProduct) {
            homeOwnersData.selectedCoverages = updateDPCoverages(homeOwnersData);
        } else {
            homeOwnersData.mainCoverages = updateHOMainCoverages(homeOwnersData);
            homeOwnersData.additionalCoverages = updateHOAdditionalCoverages(homeOwnersData);
        }
        homeOwnersData.coverageForm = _.get(homeOwnersData, 'dwellingDTO.homeDetails.coverageForm_Ext');
        return homeOwnersData;
    }, [isDPProduct, props, updateDPCoverages, updateHOAdditionalCoverages, updateHOMainCoverages]);

    const homeOwnersData = getHomeOwnersData();

    if (_.isEmpty(homeOwnersData)) {
        return null;
    }

    const overrides = {
        homeDetailsTitle: {
            content: isDPProduct ? translator(messages.DwellingProperty) : translator(messages.HomeDetails)
        },
        dpCoveragesTable: {
            visible: isDPProduct
        },
        dpCoveragesTableSection: {
            data: homeOwnersData.selectedCoverages,
            coverageForm: homeOwnersData.coverageForm
        },
        hoCoveragesTable: {
            visible: !isDPProduct
        },
        mainCoveragesTable: {
            visible: !_.isEmpty(homeOwnersData.mainCoverages)
        },
        mainCoveragesTableSection: {
            data: homeOwnersData.mainCoverages,
            coverageForm: homeOwnersData.coverageForm
        },
        additionalCoveragesTable: {
            visible:  !_.isEmpty(homeOwnersData.additionalCoverages)
        },
        additionalCoveragesTableSection: {
            data: homeOwnersData.additionalCoverages,
            coverageForm: homeOwnersData.coverageForm
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            homecoveragetablecomponent: PolicyCommonCoverageTable
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={homeOwnersData}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyDwellingInfo.propTypes = {
    value: PropTypes.shape({}).isRequired,
    productCode_Ext: PropTypes.string.isRequired
};

export default PolicyDwellingInfo;
