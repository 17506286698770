import { defineMessages } from 'react-intl';

export default defineMessages({
    anErrorOccurred: {
        id: 'wnice-capability-premiumreport-gl.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'wnice-capability-premiumreport-gl.wizard.error.title',
        defaultMessage: 'Error'
    }
});
