
import React, {
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { IntlContext } from '@jutro/locale';

import getTableOverrides from '../utils/policyTableUtils'
import metadata from './MobilePolicyTable.metadata.json5';
import styles from './MobilePolicyTable.module.scss';

function MobilePolicyTable(props) {
    const {
        model: dtoVM,
        onValueChange,
        showErrors,
        data,
        accountData,
        accountPolicyData
    } = props;

    const intl = useContext(IntlContext);

    const writeValue = (value, path) => {
        onValueChange(value, path);
    };

    const getFormattedDate = (rowData, index, property) => {
        const activePeriod = _.first(rowData.periods);
        return intl.formatDate(new Date(activePeriod[property.id]), { year: 'numeric', month: 'numeric', day: 'numeric' });
    };

    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        policiesMobileTable: {
            data
        },
        ...getTableOverrides({accountData, accountPolicyData})
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getFormattedDate
        },
        resolveComponentMap: {

        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            dtoVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={dtoVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            resolveValue={readValue}
            onValueChange={writeValue}
            showErrors={showErrors}
        />
    );
}

MobilePolicyTable.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
};

MobilePolicyTable.defaultProps = {
    model: {},
    onValueChange: _.noop,
    showErrors: false,
};

export default MobilePolicyTable;
