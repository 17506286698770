import React, { useCallback, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate, WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useModal } from '@jutro/components';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { Claim } from 'gw-capability-fnol-common-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './TypeOfIncidentPage.metadata.json5';
import FNOLPACollisionDetailsPage from '../Details/Collision/CollisionDetailsPage';
import FNOLPAGlassDetailsPage from '../Details/Glass/GlassDetailsPage';
import FNOLPATheftDetailsPage from '../Details/Theft/TheftDetailsPage';
import messages from '../../FNOLPA.messages';

function getLossCategory(claimVM) {
    const lossCause = _.get(claimVM, 'lossCause.value.code');
    switch (lossCause) {
        case 'theftentire':
        case 'theftparts':
            return 'theftentire';
        case 'glassbreakage':
            return 'glassbreakage';
        case 'otherobjcoll':
            return 'otherobjcoll';
        default:
            return 'vehcollision';
    }
}

const collision = {
    id: 'details',
    path: '/details',
    component: FNOLPACollisionDetailsPage,
    stepProps: {
        template: WizardPageTemplateWithTitle
    },
    title: messages.paCollisionDetails
};

const theft = {
    id: 'details',
    path: '/details',
    component: FNOLPATheftDetailsPage,
    stepProps: {
        template: WizardPageTemplateWithTitle
    },
    title: messages.paTheftDetails
};

const glass = {
    id: 'details',
    path: '/details',
    component: FNOLPAGlassDetailsPage,
    stepProps: {
        template: WizardPageTemplateWithTitle
    },
    title: messages.paGlassDetails
};

function FNOLPATypeOfIncidentPage(props) {
    const modalApi = useModal();
    const {
        wizardData: claimVM,
        updateWizardData,
        steps,
        changeNextSteps,
        history,
        history: {
            location: { state = {} }
        },
        authHeader
    } = props;
    const translator = useTranslator();
    const [isLoading, setIsLoading] = useState(false);
    const [pageData] = useState(state);
    const { FNOLService } = useDependencies('FNOLService');
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('TypeOfIncidentPage');

    const collisionTypeCodes = useMemo(() => {
        const lossCauseAvailableValues = _.get(claimVM, 'lossCause.aspects.availableValues', []);
        return lossCauseAvailableValues
            .filter((typeCode) => {
                // for purposes of portal 'otherobjcoll' is not a collision code
                const nonCollisionAutoCodes = [
                    'theftentire',
                    'glassbreakage',
                    'theftparts',
                    'otherobjcoll'
                ];
                return !_.includes(nonCollisionAutoCodes, typeCode.code);
            })
            .map((collisionObj) => {
                return {
                    code: collisionObj.code,
                    name: translator({
                        id: collisionObj.name,
                        defaultMessage: collisionObj.name
                    })
                };
            });
    }, [claimVM, translator]);

    const setLossCause = useCallback(
        (selectedLossCause) => {
            const lossCauseTypesArray = _.get(claimVM, 'lossCause.value.typelist.codes', []);
            const lossCause = _.find(lossCauseTypesArray, (cause) => {
                return cause.code === selectedLossCause;
            });
            _.set(claimVM, 'lossCause.value', lossCause);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const incidentPageHandling = useCallback(() => {
        const [, , ...remainingSteps] = steps;
        const detailPageIndex = _.findIndex(remainingSteps, { path: '/details' });
        const vehiclePageIndex = _.findIndex(remainingSteps, { path: '/vehicles' });
        switch (getLossCategory(claimVM)) {
            case 'vehcollision':
                remainingSteps[detailPageIndex] = collision;
                break;
            case 'theftentire':
                remainingSteps[detailPageIndex] = theft;
                break;
            case 'glassbreakage':
                remainingSteps[detailPageIndex] = glass;
                // Remove the vehicle if the type of incident is glassbreakage
                remainingSteps.splice(vehiclePageIndex, 1);
                break;
            default:
                remainingSteps[detailPageIndex] = collision;
        }
        changeNextSteps(remainingSteps);
        return claimVM;
    }, [changeNextSteps, claimVM, steps]);

    useEffect(() => {
        incidentPageHandling();
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isCollisionSelected = useCallback(() => {
        return !!_.find(collisionTypeCodes, {
            code: _.get(claimVM, 'lossCause.value.code')
        });
    }, [claimVM, collisionTypeCodes]);

    const redirectContactAgent = useCallback(() => {
        const { redirectPath } = pageData;
        const claimNumber = _.get(claimVM.value, 'claimNumber');
        return history.push({
            pathname: `/contact-agent/${claimNumber}`,
            state: {
                redirectPath: redirectPath
            }
        });
    }, [claimVM, history, pageData]);

    const onNext = useCallback(() => {
        const miscellaneous = claimVM.lossCause.value.code === 'otherobjcoll';
        const policyNumber = _.get(claimVM, 'policy.policyNumber.value');
        const isClaimNumberAvailable = _.get(claimVM, 'claimNumber.value');
        if (isClaimNumberAvailable) {
            if (miscellaneous) {
                return redirectContactAgent();
            }
            return incidentPageHandling();
        }
        setIsLoading(true);
        return FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = new Claim(response);
                if (miscellaneous) {
                    return redirectContactAgent();
                }
                return incidentPageHandling();
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.paCreateDraftErrorTitle,
                    message: messages.paCreateDraftErrorMessage,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, authHeader, claimVM, incidentPageHandling, redirectContactAgent]);

    const overrides = {
        incidentTypeRadioButton: {
            value: getLossCategory(claimVM)
        },
        typeOfCollisionOccur: {
            value: _.get(claimVM, 'lossCause.value'),
            visible: isCollisionSelected(),
            availableValues: collisionTypeCodes
        },
        typeOfIncidentPageLoader: {
            loaded: !isLoading,
            loadingMessage: translator(messages.paCreateDraftClaim)
        },
        claimsPaTypeOfIncidentPage: {
            visible: !isLoading
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM, overrides]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveCallbackMap: {
            onLossCauseChange: setLossCause
        }
    };

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = state;
        return !_.isEmpty(claimStatus);
    }, [state]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    return (
        <WizardPage
            onNext={onNext}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={resolvers.resolveValue}
                callbackMap={resolvers.resolveCallbackMap}
                overrideProps={overrides}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

FNOLPATypeOfIncidentPage.propTypes = wizardProps;
FNOLPATypeOfIncidentPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(withAuthenticationContext(FNOLPATypeOfIncidentPage));
