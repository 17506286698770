import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { InfoLabel, DateRangeField } from '@jutro/components';
import { ProductUtil } from 'wnice-portals-util-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { DocumentsUtil, ServiceErrorUtil } from 'wni-portals-util-js';
import { useWniModal } from 'wni-components-platform-react';
import { WniDocumentRetrievalService } from 'wni-capability-gateway';
import styles from '../LandingPage.module.scss';
import messages from '../LandingPage.messages';

import { Link } from '@jutro/router';

const getPolicyCellStatus = rowData => {
    const displayStatus = rowData.periods[0].displayStatus_Ext;
    if (rowData.periods[0].pendingCancellationExists_Ext) {
        return (
            <InfoLabel type="info" size="medium" message={messages.canceling} />
        );
    }
    if (displayStatus === 'In Force') {
        return (
            <InfoLabel
                type="success"
                size="medium"
                message={messages.statusActive}
            />
        );
    }

    return <InfoLabel type="info" size="medium" message={displayStatus} />;
};

const getCellPolicy = rowData => {
    const activePeriod = _.first(rowData.periods);
    const periodWithIDCard = rowData.periods.find(
        policy => policy.idCardPublicID
    );
    return (
        <Link
            className={styles.link}
            to={{
                pathname: `/account-policy-details/${activePeriod.policyId}`,
                productCode: activePeriod.lines[0],
                idCard: periodWithIDCard
                    ? {
                          idCardSessionID: _.get(
                              periodWithIDCard,
                              'idCardSessionID'
                          ),
                          idCardPublicID: _.get(
                              periodWithIDCard,
                              'idCardPublicID'
                          ),
                      }
                    : null,
            }}
        >
            {activePeriod.policyId}
        </Link>
    );
};

const getCellDocument = rowData => {
    const activePeriod = _.first(rowData.periods);
    const periodWithIDCard = rowData.periods.find(
        policy => policy.idCardPublicID
    );
    return (
        <Link
            className={styles.link}
            to={{
                pathname: `/account-policy-details/${activePeriod.policyId}`,
                productCode: activePeriod.lines[0],
                idCard: periodWithIDCard
                    ? {
                          idCardSessionID: _.get(
                              periodWithIDCard,
                              'idCardSessionID'
                          ),
                          idCardPublicID: _.get(
                              periodWithIDCard,
                              'idCardPublicID'
                          ),
                      }
                    : null,
                state: { targetId: 'policyDocumentsHeader' },
            }}
        >
            Documents
        </Link>
    );
};

const getCellAgency = rowData => {
    return <span>{rowData.producerContact?.displayName}</span>;
};

const getCellPhone = rowData => {
    return (
        <Link
            className={styles.link}
            href={`tel:${rowData.producerContact.phoneNumber}`}
        >
            {rowData.producerContact.phoneNumber}
        </Link>
    );
};

const getCellEffective = (rowData, rowIndex) => {
    const activePeriod = _.first(rowData.periods);
    const effectiveDate = new Date(activePeriod.effective);
    const expirationDate = new Date(activePeriod.expiration);
    const periodValue = {
        startDate: {
            year: effectiveDate.getFullYear(),
            month: effectiveDate.getMonth(),
            day: effectiveDate.getDate(),
        },
        endDate: {
            year: expirationDate.getFullYear(),
            month: expirationDate.getMonth(),
            day: expirationDate.getDate(),
        },
    };
    return (
        <DateRangeField
            id={`effectiveDate_${rowIndex}`}
            value={periodValue}
            hideLabel
            readOnly
        />
    );
};

const getAccountCell = rowData => {
    return (
        <Link to={{ pathname: '/userprofile' }} className={styles.link}>
            {rowData.accountNumber}
        </Link>
    );
};

const accountNameColumn = rowData => {
    const AccountName = _.get(rowData, 'accountName_Ext');
    return AccountName;
};

const getPolicyCellType = rowData => {
    const activePeriod = _.first(rowData.periods);
    const productCode = _.get(activePeriod, 'productCode_Ext.productCode');
    const policyType = _.get(activePeriod, 'policyType_Ext');
    const productName = ProductUtil.getProductName(productCode);
    if (
        policyType !== null &&
        (productCode === ProductUtil.PA_PRODUCT_CODE ||
            productCode === ProductUtil.HOP_PRODUCT_CODE)
    ) {
        if (
            policyType === 'Named Non-Owner' &&
            productCode === ProductUtil.PA_PRODUCT_CODE
        ) {
            return (
                <div>
                    <div>Select</div>
                    <div>{productName}</div>
                </div>
            );
        }
        if (policyType.indexOf(' ') === -1) {
            return (
                <div>
                    <div>{policyType}</div>
                    <div>{productName}</div>
                </div>
            );
        }
        const displayPolicyType = policyType.substring(
            0,
            policyType.indexOf(' ')
        );
        return (
            <div>
                <div>{displayPolicyType}</div>
                <div>{productName}</div>
            </div>
        );
    }
    return productName;
};

const getCellType = rowData => {
    const activePeriod = _.first(rowData.periods);
    const productCode = _.get(activePeriod, 'productCode_Ext.productCode');
    return ProductUtil.getProductName(productCode);
};

const mailToTrigger = (event, email) => {
    event.preventDefault();
    window.location = `mailto:${email}`;
};

const getIdCardColumnVisible = accountPolicyData => {
    let isShowIDCardColumn = false;
    accountPolicyData.forEach(item => {
        const itemWithIDCard = item.periods.find(
            policy => policy.idCardPublicID
        );
        if (itemWithIDCard) {
            isShowIDCardColumn = true;
        }
    });

    return isShowIDCardColumn;
};

const getCellMail = rowData => (
    <span
        className={styles.link}
        onClick={e => mailToTrigger(e, rowData.producerContact.email)}
        aria-hidden="true"
    >
        {rowData.producerContact.email}
    </span>
);

const getCellPeriodValue = (rowData, v1, props) => {
    const activePeriod = _.first(rowData.periods);
    return activePeriod[props.vpath];
};

function getTableOverrides(props) {
    const intl = useContext(IntlContext);
    const translator = useTranslator();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);
    const modalApi = useWniModal();
    const { authHeader } = useAuthentication();
    const history = useHistory();

    const { accountData, accountPolicyData } = props;

    const getCellPremium = rowData => {
        const activePeriod = _.first(rowData.periods) || {};
        const { amount, currency } = activePeriod.premium || {};
        const formatValue = intl.formatNumber(amount, {
            style: 'currency',
            currency: currency,
            currencyDisplay: 'symbol',
        });
        return formatValue ? <div title={formatValue}>{formatValue}</div> : '-';
    };

    const getCellAgent = rowData => {
        return (
            <>
                <Link
                    className={styles.link}
                    onClick={e =>
                        mailToTrigger(e, rowData.producerContact.email)
                    }
                >
                    {rowData.producerContact.displayName}
                </Link>
                <br />
                {rowData.producerContact.phoneNumber ? (
                    <span>
                        {translator(messages.policyDetailsTel)}
                        {rowData.producerContact.phoneNumber}
                    </span>
                ) : (
                    ''
                )}
            </>
        );
    };

    const successCallback = () => {
        setLoadingMask(false);
    };

    const errorCallback = () => {
        setLoadingMask(false);
        modalApi.showConfirm({
            title: 'Error',
            message: ServiceErrorUtil.prependWithFriendMessage(),
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
        });
    };

    const onIDCardClick = async summaryDocument => {
        setLoadingMask(true);
        await DocumentsUtil.tryDownloadDocument(
            summaryDocument,
            authHeader,
            history,
            WniDocumentRetrievalService,
            successCallback,
            errorCallback
        );
    };

    const getCellIdCard = rowData => {
        const periodWithIDCard = rowData.periods.find(
            policy => policy.idCardPublicID
        );
        if (periodWithIDCard) {
            const idCardDocument = _.get(
                periodWithIDCard,
                'idCardDocument_Ext'
            );
            return (
                <Link
                    className={styles.link}
                    onClick={() => onIDCardClick(idCardDocument)}
                >
                    ID Cards
                </Link>
            );
        }
        return null;
    };

    return {
        policyTypeColumn: { renderCell: getPolicyCellType },
        accountNameColumn: { renderCell: accountNameColumn },
        typeColumn: { renderCell: getCellType },
        accountNumberColumn: {
            renderCell: getAccountCell,
            visible: accountData.length > 1,
        },
        statusColumn: { renderCell: getPolicyCellStatus },
        policyColumn: { renderCell: getCellPolicy },
        effectiveColumn: { renderCell: getCellEffective },
        premiumColumn: { renderCell: getCellPremium },
        paymentPlanColumn: { renderCell: getCellPeriodValue },
        agentColumn: {
            visible: accountData.length > 1,
            renderCell: getCellAgent,
        },
        documentsColumn: {
            renderCell: getCellDocument,
        },
        idCardColumn: {
            visible: getIdCardColumnVisible(accountPolicyData),
            renderCell: getCellIdCard,
        },
        agencyColumn: { renderCell: getCellAgency },
        phoneColumn: { renderCell: getCellPhone },
        emailColumn: { renderCell: getCellMail },
    };
}

export default getTableOverrides;
