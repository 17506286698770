import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLIMWizard from './FNOLIMWizard';
import IMLossTypePage from './pages/LossType/FNOLIMLossTypePage';
import IMIncidentsPage from './pages/IncidentDetail/FNOLIMIncidentsPage';

setComponentMapOverrides(
    {
        FNOLWCWizard: { component: 'FNOLIMWizard' },
        IMLossTypePage: { component: 'IMLossTypePage' },
        IMIncidentsPage: { component: 'IMIncidentsPage' },
    },
    {
        FNOLIMWizard,
        IMLossTypePage,
        IMIncidentsPage,
    }
);

export {
    FNOLIMWizard as default
};
