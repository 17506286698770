import { defineMessages } from 'react-intl';

export default defineMessages({
    accountSummary: {
        id: 'portal.account management.views.home-page-layout.Account Summary',
        defaultMessage: 'Account Summary',
    },
    myBalance: {
        id: 'portal.account management.directives.templates.billing-summary-tiles.My Balance',
        defaultMessage: 'My Balance',
    },
    overdue: {
        id: 'portal.account management.directives.templates.billing-summary-tiles.Overdue',
        defaultMessage: 'Overdue',
    },
    payNow: {
        id: 'portal.account management.directives.templates.billing-summary-tiles.Pay Now',
        defaultMessage: 'Pay Now',
    },
    myNextBill: {
        id: 'portal.account management.directives.templates.billing-summary-tiles.My Next Bill',
        defaultMessage: 'My Next Bill'
    },
    due: {
        id: 'portal.account management.directives.templates.billing-summary-tiles.Due',
        defaultMessage: 'Due',
    },
    updateMyDetails: {
        id: 'portal.account management.views.home-page-layout.Update My Details',
        defaultMessage: 'Update My Details'
    },
    lastPayment: {
        id: 'portal.account management.directives.templates.billing-summary-tiles.My Last Payment',
        defaultMessage: 'My Last Payment',
    },
    paid: {
        id: 'portal.account management.directives.templates.billing-summary-tiles.Paid',
        defaultMessage: 'Paid',
    },
    renewalInfo: {
        id: 'portal.account management.views.home-page-layout.You have policies due for renewal.',
        defaultMessage: 'You have policies due for renewal.'
    },
    policies: {
        id: 'portal.account management.views.home-page-layout.Policies',
        defaultMessage: 'Policies'
    },
    agencyDetails: {
        id: 'portal.account management.views.home-page-layout.Agency Details',
        defaultMessage: 'Agency Details'
    },
    type: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.Policy Type',
        defaultMessage: 'Policy Type'
    },
    accountNumber: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.Account',
        defaultMessage: 'Account'
    },
    insuranceType: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.Insurance Type',
        defaultMessage: 'Insurance Type'
    },
    agency: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.agency',
        defaultMessage: 'Agency'
    },
    phone: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.phone',
        defaultMessage: 'Phone Number'
    },
    email: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.email',
        defaultMessage: 'Email'
    },
    statusActive: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.Active',
        defaultMessage: 'Active'
    },
    statusRenewed: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.Renewed',
        defaultMessage: 'Renewed'
    },
    canceling: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.canceling',
        defaultMessage: 'Pending Cancel'
    },
    status: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.status',
        defaultMessage: 'Status'
    },
    policy: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.Policy Number',
        defaultMessage: 'Policy Number'
    },
    effective: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.effective',
        defaultMessage: 'Effective Date Expiration Date'
    },
    premium: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.premium',
        defaultMessage: 'Premium'
    },
    myQuickLinks: {
        id: 'portal.account management.views.home-page-layout.My Quick Links',
        defaultMessage: 'My Quick Links'
    },
    printanIDCard: {
        id: 'portal.account management.views.home-page-layout.Print an ID Card',
        defaultMessage: 'Print an ID Card'
    },
    makeAPayment: {
        id: 'portal.account management.views.home-page-layout.Billing & Payment',
        defaultMessage: 'Billing & Payment'
    },
    setupAutomaticPayments: {
        id: 'portal.account management.views.home-page-layout.Setup Automatic Payments',
        defaultMessage: 'Setup Automatic Payments'
    },
    changeMyPolicy: {
        id: 'portal.account management.views.home-page-layout.ChangeMyPolicy',
        defaultMessage: 'Change My Policy'
    },
    fileClaim: {
        id: 'portal.account management.views.home-page-layout.FileClaim',
        defaultMessage: 'File a Claim'
    },
    getAPersonalAutoQuote: {
        id: 'portal.account management.views.home-page-layout.Get a Personal Auto Quote',
        defaultMessage: 'Get a Personal Auto Quote'
    },
    getAHomeOwnersQuote: {
        id: 'portal.account management.views.home-page-layout.Get a Home Owners Quote',
        defaultMessage: 'Get a Home Owners Quote'
    },
    policyDetailsAgent: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.agent',
        defaultMessage: 'Agent'
    },
    policyDetailsTel: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.tel',
        defaultMessage: 'T.'
    },
    policyDetailsIdCard: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.id card',
        defaultMessage: 'ID Card'
    },
    policyDetailsFileClaim: {
        id: 'policy.account management.common.directives.templates.policy-account-policy-summaries.fileClaim',
        defaultMessage: 'File a Claim'
    },
    myBalanceTileCurrent: {
        id: 'portal.account management.directives.templates.billing-summary-tiles.Current',
        defaultMessage: 'Current'
    },
    nextPlannedInvoiceAmount: {
        id: 'portal.account management.directives.templates.billing-summary-tiles.Next Planned Invoice Amount *',
        defaultMessage: 'Next Planned Invoice Amount *'
    },
    policyNotShowing: {
        id: 'portal.account management.views.home-page-layout.Do you have a policy with us but it is not showing?',
        defaultMessage: 'Do you have a policy with us but it is not showing?'
    },
    accountNotShowing: {
        id: 'portal.account management.views.home-page-layout.Do you have a account with us but it is not showing?',
        defaultMessage: 'Do you have an account with us but it is not showing?'
    },
    addPolicy: {
        id: 'portal.account management.views.home-page-layout.Add Policy',
        defaultMessage: 'Add Policy'
    },
    addAccount: {
        id: 'portal.account management.views.home-page-layout.Add Account',
        defaultMessage: 'Add Account'
    },
    managePaymentMethods: {
        id: 'portal.account management.views.home-page-layout.Manage Saved Payment Method',
        defaultMessage: 'Manage Saved Payment Method'
    },
    manageDeliveryMethodTooltip: {
        id: 'portal.account management.views.home-page-layout.manageDeliveryMethodTooltip',
        defaultMessage: 'Please Note: to manage your Paperless selection for these account(s), please click on “Manage Delivery Method.”'
    },
});
