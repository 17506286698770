import React from 'react';
import { FieldComponent } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';

import styles from './FieldLinkComponent.module.scss';

import { Link } from '@jutro/router';

class FieldLinkComponent extends React.Component {
    static contextType = TranslatorContext;

    static propTypes = {
        ...FieldComponent.propTypes,
    };

    render() {
        const { value } = this.props;
        return (
            <Link className={styles.fieldLinkColor} {...this.props}>
                {value}
            </Link>
        );
    }
}

export default FieldLinkComponent;
