import _ from 'lodash';

const getDetailInputDTOs = (premiumReportDetails) => {
    const auditedBasisDTOs = [];
    premiumReportDetails.forEach((prd) => {
        const { ratingPeriods } = prd;
        ratingPeriods.forEach((rp) => {
            const { detailItems } = rp;
            detailItems.forEach((item) => {
                const auditedBasisDTO = {
                    covEmpPublicID: item.covEmpPublicID,
                    actual: item.actual,
                    actualUSLH: item.actualUSLH,
                    hasUSLH: item.hasUSLH
                };
                auditedBasisDTOs.push(auditedBasisDTO);
            })
        })
    })
    return auditedBasisDTOs;
};

const hasUSLHItem= (ratingPeriod) => {
    const { detailItems } = ratingPeriod;
    return !_.isEmpty(_.find(detailItems, (item) => item.hasUSLH));
};

const getCostsData = (premiumReportDetails, totalPremium) => {
    const costsData = [];
    let amountForSelectedCodes = 0;
    premiumReportDetails.forEach((prd) => {
        const { ratingPeriods } = prd;
        ratingPeriods.forEach((rp) => {
            const { costItems } = rp;
            costItems.forEach((item) => {
                costsData.push(item);
                amountForSelectedCodes += item.premium.amount;
            })
        })
    })
    const sortedCostsData = _.sortBy(costsData, ['locationDesc', 'classCode', 'type']);
    const otherPremiumDesc = 'Other Premium, Fees and Surcharges';
    const otherPremium = {
        amount: totalPremium.amount - amountForSelectedCodes,
        currency: totalPremium.currency
    }
    const otherPremiumItem = {
        locationDesc: otherPremiumDesc,
        premium: otherPremium
    }
    sortedCostsData.push(otherPremiumItem);
    return sortedCostsData;
};

export {
	getDetailInputDTOs,
    hasUSLHItem,
    getCostsData
};