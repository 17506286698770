import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Icon, InfoLabel } from '@jutro/components';
import { IntlContext, useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/legacy/uiconfig';

import { LobIconUtil } from '@xengage/gw-portals-util-js';
import { FieldLinkComponent, Currency as CurrencyField } from 'gw-components-platform-react';
import { ProductUtil } from 'wnice-portals-util-react';

import metadata from './PoliciesTable.metadata.json5';
import styles from './PoliciesTable.module.scss';
import messages from './PoliciesTable.messages';

const findLatestPolicyPeriod = (policyPeriods) => {
    return _.maxBy(policyPeriods, (period) => {
        return new Date(period.effective).getTime();
    });
};

const isPolicyActive = (periods) => {
    const currentRenewed = periods.length > 1;
    const activePeriod = _.first(periods);
    return new Date(activePeriod.effective).getTime() < new Date().getTime() && !currentRenewed;
};


const sortPolicies = (policies) => {
    // active -> scheduled -> pending cancell -> cancelled -> expired
    const newPolicies =  _.map(policies, (policy) => {
        const displayStatus = policy.displayStatus_Ext
        _.set(policy, 'statusOrderNumber', 6);
        if (displayStatus === 'In Force') {
            _.set(policy, 'statusOrderNumber', 1);
        }
        if (displayStatus === 'Scheduled') {
            _.set(policy, 'statusOrderNumber', 2);
        }
        if (policy.pendingCancellationExists_Ext) {
            _.set(policy, 'statusOrderNumber', 3);
        }
        if (displayStatus === 'Canceled') {
            _.set(policy, 'statusOrderNumber', 4);
        }
        if (displayStatus === 'Expired') {
            _.set(policy, 'statusOrderNumber', 5);
        }
        return policy;
    });

    const sortedPolicies = _.orderBy(newPolicies, ['statusOrderNumber', 'effectiveDate'], ['asc', 'desc']);

    return sortedPolicies;
}

const getPoliciesTableData = (policySummaries, policyNumbers, translator) => {
    let policyList = _.map(policyNumbers, (policy) => {
        // Find policy summary associated with the current policy
        const summaryMatch = policySummaries.find((policySummary) => {
            return policySummary.periods[0].policyId === policy;
        });

        // If the summaryMatch is undefined, then this policy is not in force.
        if (summaryMatch !== undefined) {
            /*  Create summary object. At this point the policy number is known so that is the
             *  only field set.
             */
            let returnObject = {
                policyNumber: policy
            };

            // Find the latest policy period
            const latestPolicyPeriod = findLatestPolicyPeriod(summaryMatch.periods);

            if (latestPolicyPeriod) {
                // If a period was found, merge the summary date with the summary object
                returnObject = Object.assign(returnObject, {
                    productCodes: ProductUtil.getProductName(latestPolicyPeriod.productCode_Ext.productCode),
                    premium: latestPolicyPeriod.premium,
                    effectiveDate: latestPolicyPeriod.effective,
                    expirationDate: latestPolicyPeriod.expiration,
                    status: latestPolicyPeriod.status,
                    displayStatus_Ext: latestPolicyPeriod.displayStatus_Ext,
                    paymentPlanName_Ext: latestPolicyPeriod.paymentPlanName_Ext,
                    pendingCancellationExists_Ext: latestPolicyPeriod.pendingCancellationExists_Ext,
                    active: isPolicyActive(summaryMatch.periods)
                });
            }
            return returnObject;
        }

        return undefined;
    });

    policyList = policyList.filter((policy) => {
        return policy !== undefined;
    });

    return sortPolicies(policyList);  // CEI-4805
};

const getProductImage = (item, index, { id: property }) => {
    const productCode = item[property][0];
    const icon = LobIconUtil.getMaterialIcon(productCode);
    return <Icon icon={icon} title={productCode} key={`${productCode}_${index}`} />;
};

const getLink = (item, index, { id: property }) => {
    return (
        <FieldLinkComponent
            id={`policy${item[property]}`}
            to={`/account-policy-details/${item[property]}`}
            className={styles.policyLinkContainer}
            title={messages.policy}
            value={item[property]}
        />
    );
};

const renderFormattedCurrencyField = (item, index) => {
    return (
        <div className={styles.currencyContainer}>
            <CurrencyField
                id={`currency_${index}`}
                value={item}
                readOnly
                hideLabel
            />
        </div>
    );
};

const getPolicyCellStatus = (rowData) => {
    const displayStatus = rowData.displayStatus_Ext
    if (rowData.pendingCancellationExists_Ext) {
        return <InfoLabel type="info" size="medium" message={messages.canceling} />;
    }
    if (displayStatus === 'In Force') {
        return <InfoLabel type="success" size="medium" message={messages.statusActive} />;
    }

    return <InfoLabel type="info" size="medium" message={displayStatus} />;
};

function PoliciesTable(props) {
    const { policySummaries = [], policyNumbers = [] } = props;
    const intl = useContext(IntlContext);
    const translator = useTranslator();

    const getCellPolicyLine = useCallback((item) => {
        return item.productCodes;
    }, []);

    const getFormattedCurrency = useCallback((item, index, property) => {
        return renderFormattedCurrencyField(item[property.id], index);
    }, []);

    const overrideProps = {
        tablePolicySummary: {
            data: getPoliciesTableData(policySummaries, policyNumbers, translator)
        }
    };

    const getFormattedDate = (item, index, property) => {
        return intl.formatDate(new Date(item[property.id]), { year: 'numeric', month: 'numeric', day: 'numeric' });
    };

    const getPaymentPlanName = (item) => {
        return item.paymentPlanName_Ext;
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getProductImage,
            getCellType: getCellPolicyLine,
            getLink,
            getFormattedDate,
            getFormattedCurrency,
            getPaymentPlanName,
            getCellStatus: getPolicyCellStatus,
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

PoliciesTable.propTypes = {
    policySummaries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    policyNumbers: PropTypes.array.isRequired
};

export default PoliciesTable;

export {
    getPoliciesTableData,
    getPolicyCellStatus
}
