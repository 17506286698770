import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class PersonalizedOfferService {
    static getOfferDataFromServer(currentPath, params, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('personalizedOffer'),
            'getPersonalizedOffer',
            [currentPath, params],
            additionalHeaders
        );
    }
}
