import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import UmbrellaCoverageTable from '../UmbrellaCoverageTable/UmbrellaCoverageTable';
import metadata from './PolicyUmbrellaInfo.metadata.json5';
import messages from './PolicyUmbrellaInfo.messages';
import styles from './PolicyUmbrellaInfo.module.scss';

function PolicyUmbrellaInfo(props) {
    const translator = useTranslator();
    const { value } = props;

    const getUlPolicyType = useCallback((type) => {
        switch(type) {
            case 'personal_auto':
            case 'business_auto':
                return translator(messages.autoLiability);
            case 'homeowner':
                return translator(messages.personalLiability);
            case 'watercraft':
                return translator(messages.watercraftLiability);
            case 'dwelling_property':
                return translator(messages.dwellingLiability);
            case 'motorcycle':
                return translator(messages.motorcycleLiability);
            case 'recreational_vehicle':
                return translator(messages.recreationalVehicleLiability);
            default:
                return '';
        }
    }, [translator]);

    const getUlPolicyLimitLabel = useCallback((fieldName) => {
        switch(fieldName) {
            case 'bodyInjuryLimitPerPerson':
                return translator(messages.bodilyInjuryEachPerson);
            case 'bodyInjuryLimitPerAccident':
                return translator(messages.bodilyInjuryEachAccident);
            case 'propertyDamageLimit':
                return translator(messages.propertyDamageEachAccident);
            case 'combinedSingleLimit':
                return translator(messages.combinedSingleLimitEachAccident);
            default:
                return ' ';
        }
    }, [translator]);

    const fillUlPolicyDetail = useCallback((ulPolicy, ulPolicyDetail, fieldName) => {
        if (ulPolicy[fieldName]) {
            ulPolicyDetail.push({
                itemLabel: translator(messages.limitsOfLiability),
                fieldLabel: getUlPolicyLimitLabel(fieldName),
                fieldValue: ulPolicy[fieldName]
            })
        }
    }, [getUlPolicyLimitLabel, translator]);

    const getUmbrellaData = useCallback(() => {
        const umbrellaData = _.cloneDeep(value);
        const ulPolicyDetails = [];
        if (!_.isEmpty(umbrellaData.ulPolicyDTOs)) {
            const sortedUlPolicies = _.sortBy(umbrellaData.ulPolicyDTOs, 'policyType');
            sortedUlPolicies.forEach((ulPolicy) => {
                const ulPolicyDetail = [];
                const policyTypeLabel = getUlPolicyType(ulPolicy.policyType);
                const limitFields = ['bodyInjuryLimitPerPerson', 'bodyInjuryLimitPerAccident', 'propertyDamageLimit', 'combinedSingleLimit', 'liabilityLimit'];
                limitFields.forEach((field) => {
                    fillUlPolicyDetail(ulPolicy, ulPolicyDetail, field);
                })
                ulPolicyDetail.push({
                    itemLabel: translator(messages.policyNumber),
                    fieldLabel: ' ',
                    fieldValue: ulPolicy.policyNumber
                })
                ulPolicyDetails.push({
                    policyTypeLabel: policyTypeLabel,
                    policyDetailItems: ulPolicyDetail
                })
            })
        }    
        _.set(umbrellaData, 'ulPolicyDetails', ulPolicyDetails);
        return umbrellaData;
    }, [fillUlPolicyDetail, getUlPolicyType, translator, value]);

    const umbrellaData = getUmbrellaData();

    if (_.isEmpty(umbrellaData)) {
        return null;
    }

    const overrides = {
        lineCoveragesTableSection: {
            data: umbrellaData
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            linecoverage: UmbrellaCoverageTable
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={umbrellaData}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyUmbrellaInfo.propTypes = {
    value: PropTypes.shape({
        coverageDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        ulPolicyDTOs: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired
};

export default PolicyUmbrellaInfo;
