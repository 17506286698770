import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Currency as CurrencyField } from 'gw-components-platform-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { CheckboxField } from '@jutro/legacy/components';
import { WniDateUtil } from 'wni-portals-util-js';
import metadata from './PaymentsTable.metadata.json5';

import styles from './PaymentsTable.module.scss';
import messages from './PaymentsTable.messages';

const getBillStatus = (item, index, property) => {
    if (item.paidStatus === 'fullypaid') {
        return messages.paid;
    }
    return {
        id: `typekey.InvoiceStatus.${item[property.id]}`,
        defaultMessage: item[property.id]
    };
};

const renderFormattedCurrencyField = (item, index) => {
    return (
        <div className={styles.currencyContainer}>
            <CurrencyField
                id={`currency_${index}`}
                value={item}
                readOnly
                hideLabel
            />
        </div>
    );
};

const getFormattedCurrency = (item, index, property) => {
    return renderFormattedCurrencyField(item[property.id]);
};

const getCell = (item, index, property) => {
    return item[property.id];
};

function paymentsTable(props) {
    const {
        paymentsHistory,
        selectedInvoices,
        onUpdateSelectedInvoices,
        showPaidDate,
        showSelect,
        invoiceStreamNumber
    } = props;

    const getFormattedDate = useCallback((item, index, property) => {
        return WniDateUtil.formatDateWithPattern(item[property.id], 'MM/DD/YYYY');
    }, []);

    const getCheckboxDataCell = useCallback(
        (item, index, property) => {
            const value = item[property.id];
            const selectedIds = [...selectedInvoices];
            const isChecked = selectedIds.indexOf(value) > -1;
            return (
                <CheckboxField
                    id={`${property.id}_${index}`}
                    name={`${property.id}_${index}`}
                    value={isChecked}
                    onValueChange={(selected) => {
                        if (selected) {
                            selectedIds.push(value);
                            onUpdateSelectedInvoices(selectedIds);
                        } else {
                            const updated = selectedIds.filter((iter) => iter !== value);
                            onUpdateSelectedInvoices(updated);
                        }
                    }}
                />
            );
        },
        [selectedInvoices, onUpdateSelectedInvoices]
    );
    const overrideProps = {
        tablePaymentsHistory: {
            data: paymentsHistory.filter((payment) => payment.invoiceStreamNumber_Ext === invoiceStreamNumber),
            placeholder: messages.noMatchingPayments,
            className: 'tablePaymentScheduleDetail',
        },
        tablePaymentScheduleDetailLastPaymentDate: {
            visible: showPaidDate
        },
        tablePaymentScheduleDetailPay: {
            visible: showSelect
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getFormattedDate,
            getBillStatus,
            getFormattedCurrency,
            getCell,
            getCheckboxDataCell,
            sortDate: DatatableUtil.sortDate,
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

paymentsTable.propTypes = {
    selectedInvoices: PropTypes.arrayOf(PropTypes.string),
    onUpdateSelectedInvoices: PropTypes.func,
    showSelect: PropTypes.bool,
    showPaidDate: PropTypes.bool
};

paymentsTable.defaultProps = {
    selectedInvoices: [],
    onUpdateSelectedInvoices: undefined,
    showSelect: false,
    showPaidDate: false
};

export default paymentsTable;
