import { defineMessages } from 'react-intl';

export default defineMessages({
    previousBtn: {
        id: 'premiumreport.wc.page.details.Previous',
        defaultMessage: 'Previous'
    },
    nextBtn: {
        id: 'premiumreport.wc.page.details.Next',
        defaultMessage: 'Next'
    }
});
