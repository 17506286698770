import { defineMessages } from 'react-intl';

export default defineMessages({
    wantToChangeYourAnswer: {
        id: 'quoteandbind.pa.directives.templates.ce-question-set.wantToChangeYourAnswer',
        defaultMessage: 'Want To Change Your Answer?'
    },
    changeAnswerMayImpactCov: {
        id: 'quoteandbind.pa.directives.templates.ce-question-set.changeAnswerMayImpactCov',
        defaultMessage: 'Changing the answer to this question may impact your coverage. Click "Cancel" to undo the change. If you would like to proceed, click "Yes".'
    },
    yes: {
        id: 'quoteandbind.pa.directives.templates.ce-question-set.yes',
        defaultMessage: 'Yes'
    }
});
