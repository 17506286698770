import { defineMessages } from 'react-intl';

export default defineMessages({
    vinOverrideConfirmationMessage: {
        id: 'ce.endorsement.pa.CEVehicleComponent.vinOverrideConfirmationMessage',
        defaultMessage: 'A different VIN ({vin}) was returned by the service. If you want to replace the user-entered VIN with this service-returned VIN, select “VIN Override” below.'
    },
    vinOverrideYes: {
        id: 'ce.endorsement.pa.CEVehicleComponent.vinOverrideYes',
        defaultMessage: 'Override VIN'
    },
    vinOverrideNo: {
        id: 'ce.endorsement.pa.CEVehicleComponent.vinOverrideNo',
        defaultMessage: 'Do not Override'
    },
    makeNotFound: {
        id: 'ce.endorsement.pa.CEVehicleComponent.Make Not Found',
        defaultMessage: 'Make Not Found'
    },
    newMake: {
        id: 'ce.endorsement.pa.CEVehicleComponent.New Make',
        defaultMessage: 'New Make'
    },
    make: {
        id: 'ce.endorsement.pa.CEVehicleComponent.Make.override',
        defaultMessage: 'Make'
    },
    modelNotFound: {
        id: 'ce.endorsement.pa.CEVehicleComponent.Model Not Found',
        defaultMessage: 'Model Not Found'
    },
    newModel: {
        id: 'ce.endorsement.pa.CEVehicleComponent.New Model',
        defaultMessage: 'New Model'
    },
    model: {
        id: 'ce.endorsement.pa.CEVehicleComponent.Model.override',
        defaultMessage: 'Model'
    },
    vinServiceNotFound: {
        id: 'ce.endorsement.pa.CEVehicleComponent.Vin Service Not Found',
        defaultMessage: 'VIN not found, please re-enter the data.'
    },
    vinLast4Digits: {
        id: 'ce.endorsement.pa.CEVehicleComponent.VIN Last 4 Digits',
        defaultMessage: 'VIN {vinLast4}(Last 4 digits)'
    },
    paNewVehicle: {
        id: 'ce.endorsement.pa.CEVehicleComponent.New Vehicle',
        defaultMessage: 'New Vehicle',
    },
    atleastOneVehicle: {
        id: 'ce.endorsement.pa.CEVehicleComponent.You have to have at least one vehicle',
        defaultMessage: 'You have to have at least one vehicle',
    },
    removeVehicleTitle: {
        id: 'ce.endorsement.pa.CEVehicleComponent.removeVehicleTitle',
        defaultMessage: 'Are you sure to remove this vehicle?'
    },
    removeVehicleDescription: {
        id: 'ce.endorsement.pa.CEVehicleComponent.removeVehicleDescription',
        defaultMessage: 'By clicking "Yes", this vehicle will be removed and no longer insured under this policy. Do you want to continue?'
    }
});
