import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import appConfig from 'app-config';
import { WniCEEndorsementService } from 'wnice-capability-policychange';
import { PolicyChange, messages } from 'gw-capability-policychange-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useHistory } from 'react-router-dom';
import { useModal } from '@jutro/components';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import wizardConfig from '../../config/pa-policychange-wizard-config.json5';
import styles from '../../CEPAPolicyChangeWizard.module.scss';
import metadata from './CEPAPolicyChangeStartPage.metadata.json5';
import startChangePageMessage from './CEPAPolicyChangeStartPage.messages'

function CEPAPolicyChangeStartPage(props) {
    const { steps } = wizardConfig;
    const { authHeader } = useAuthentication();
    const {
        updateWizardData, changeNextSteps, currentStepIndex, wizardData
    } = props;
    const { submissionVM, selectedPages, wizardPageData } = wizardData;
    const [showErrors, updateShowErrors] = useState(false);
    const { isComponentValid, registerComponentValidation } = useValidation('CEPAPolicyChangeStartPage');
    const history = useHistory();
    const modalApi = useModal();
    const {
        jobID,
        policyNumber,
        baseData: {
            effectiveDate,
            newChange_Ext: isNewChangeJob
        }
    } = submissionVM.value;

    const handleChange = useCallback(
        (options) => {
            _.forEach(selectedPages, (value, key) => {
                _.set(wizardData, `selectedPages.${key}`, options.includes(key));
                if (key === 'drivers' && options.includes(key)) {
                    _.set(wizardData, 'selectedPages.vehicles', true);
                }
            });
            updateWizardData(wizardData);
        },
        [updateWizardData, wizardData, selectedPages]
    );

    const loadEndorsementWithEffectiveDate = useCallback(() => {
        if (jobID) {
            return WniCEEndorsementService.loadEndorsementWithjobIDAndEffectiveDate(
                jobID,
                effectiveDate,
                authHeader
            );
        }
        return WniCEEndorsementService.loadEndorsementWithEffectiveDate(
            [
                policyNumber,
                effectiveDate,
            ],
            authHeader
        );
    }, [effectiveDate, jobID, policyNumber, authHeader]);

    const isEffectiveDateValid = useCallback(() => {
        const currentEffectiveDate = _.get(submissionVM, 'value.baseData.effectiveDate');
        if (_.isEmpty(currentEffectiveDate)) {
            return false;
        }
        const minDate = _.get(submissionVM, 'value.baseData.minimumEffectiveDate');
        const maxDate = _.get(submissionVM, 'value.baseData.periodEndDate');
        const min = moment(minDate).toDate();
        const max = moment(maxDate).toDate();
        const current = moment(currentEffectiveDate).toDate();
        if (current >= min && current <= max) {
            return true;
        }
        return false;
    }, [submissionVM])

    const onCancel = useCallback(() => {
        return modalApi.showConfirm({
            title: platformMessages.wantToCancel,
            message: startChangePageMessage.infoWillBeSavedAsDraft,
            status: 'warning',
            icon: 'gw-error-outline'
        }).then((result) => {
            if (result === 'cancel') {
                return _.noop();
            }
            return history.push({
                pathname: `/account-policy-details/${policyNumber}`
            })
        }) 
    }, [])

    const onNext = useCallback(async () => {
        if (!isEffectiveDateValid()) {
            updateShowErrors(true);
            return false;
        }
        const response = await WniCEEndorsementService.checkEffectiveDateIsValid(
            [
                policyNumber,
                effectiveDate,
            ],
            authHeader
        );
        if (response) {
            const effectiveDateResponse = await loadEndorsementWithEffectiveDate();
            submissionVM.value = new PolicyChange(effectiveDateResponse);
            const baseDatachangeDto = _.get(submissionVM, 'baseData.value');
            const policyChangeResponse = await WniCEEndorsementService.savePolicyDetails(
                jobID, policyNumber, null, baseDatachangeDto,
                authHeader
            );
            submissionVM.value = new PolicyChange(policyChangeResponse);
        }
        const newSteps = steps.slice(currentStepIndex + 1, steps.length);
        if (!selectedPages.address) {
            newSteps.splice(
                _.findIndex(newSteps, ({ path }) => path === '/address'),
                1
            );
        }
        if (!selectedPages.vehicles) {
            newSteps.splice(
                _.findIndex(newSteps, ({ path }) => path === '/vehicles'),
                1
            );
        }
        if (!selectedPages.drivers) {
            newSteps.splice(
                _.findIndex(newSteps, ({ path }) => path === '/driver'),
                1
            );
        }
        changeNextSteps(newSteps);
        return wizardData;
    }, [
        submissionVM,
        authHeader,
        steps,
        selectedPages,
        changeNextSteps,
        wizardData,
        currentStepIndex,
        loadEndorsementWithEffectiveDate,
        policyNumber,
        effectiveDate,
        jobID,
        isEffectiveDateValid
    ]);

    const getCheckBoxValue = useCallback(() => {
        const result = [];
        _.forEach(selectedPages, (value, key) => {
            if (value) {
                result.push(key);
            }
        });
        return result;
    }, [selectedPages]);

    const loadCurrentEffectiveDateAsBlank = () => {
        const { setEffectiveDateOfChange } = wizardPageData;
        if (!setEffectiveDateOfChange) {
            if (isNewChangeJob) {
                _.set(submissionVM, 'value.baseData.effectiveDate', '');
            }
            _.set(wizardPageData, 'setEffectiveDateOfChange', true);
        }
        return _.get(submissionVM, 'value.baseData.effectiveDate');
    };

    const handleEffectiveDateChange = (newEffectiveDate) => {
        _.set(submissionVM, 'baseData.effectiveDate', newEffectiveDate);
        updateWizardData({ ...wizardData, submissionVM: submissionVM });
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            }
        },
        paPolicyChangeEffectiveDate: {
            value: loadCurrentEffectiveDateAsBlank(),
            onValueChange: handleEffectiveDateChange,
            minDate: _.get(submissionVM, 'baseData.minimumEffectiveDate.value'),
            maxDate: _.get(submissionVM, 'baseData.periodEndDate.value'),
            showErrors
        },
        typeSelector: {
            value: getCheckBoxValue(),
            onValueChange: handleChange
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const checkSelectedPagesAndEffectiveDateChange = useCallback(() => {
        const pagesSelected = selectedPages.address === true
            || selectedPages.vehicles === true
            || selectedPages.drivers === true
            || selectedPages.coverages === true;
        const effectiveDateChange = _.get(submissionVM, 'value.baseData.effectiveDate'); 
        return pagesSelected && !_.isEmpty(effectiveDateChange);
    }, [selectedPages, submissionVM]);

    useEffect(() => {
        registerComponentValidation(checkSelectedPagesAndEffectiveDateChange);
    }, [checkSelectedPagesAndEffectiveDateChange, registerComponentValidation]);

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            showPrevious={false}
            cancelLabel={(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
            onCancel = {onCancel}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={wizardData}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

CEPAPolicyChangeStartPage.propTypes = wizardProps;
export default CEPAPolicyChangeStartPage;
