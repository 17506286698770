/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { DropdownMenuButton, TooltipIcon } from '@jutro/components';
import classNames from 'classnames';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WindowUtil } from 'wni-portals-util-js';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { WNIAddressLookupService } from 'wni-capability-address';
import { useValidation } from '@xengage/gw-portals-validation-react';
import messages from './CEAdditionalInterestComponent.message';
import metadata from './CEAdditionalInterestComponent.metadata.json5';
import styles from './CEAdditionalInterestComponent.module.scss';

import { Link, DropdownMenuLink } from '@jutro/router';

const initialValue = {
    type: 'ADDITIONALINSURED_Ext',
    policyAdditionalInterest: {
        subtype: 'Company',
        contactType_Ext: 'company',
        primaryAddress: {
            country: 'US'
        },
        phoneRequired_Ext: false,
        companyType_Ext: 'Bank'
    }
};

function CEAdditionalInterestComponent(props) {
    const {
        id,
        additionalInterestsVM,
        onValidate,
        onValueChange,
        authHeader,
        initialContext
    } = props;
    const {
        isComponentValid,
        onValidate: setComponentValidation,
        registerComponentValidation,
        disregardFieldValidation
    } = useValidation(id);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phoneWide' || breakpoint === 'phone';

    const [selectedInterestVM, updateSelectedInterestVM] = useState(undefined);
    const [bakInterestVM, updateBakInterestVM] = useState(undefined);
    const [editIndex, updateEditIndex] = useState(-1);
    const [addLienholderToggle, updateAddLienholderToggle] = useState(false);
    const [isNewInterest, updateIsNewInterest] = useState();
    const [showErrors, updateShowErrors] = useState(false);

    const [showBankNameRequiredMsg, updateShowBankNameRequiredMsg] = useState(false);
    const [isLoading, updateIsLoading] = useState(false);
    const [bankPageConfig, updateBankPageConfig] = useState(WniTableRowUtil.defaultPageConfig);
    const [selectRow, updateSelectRow] = useState({});
    const [showTooManySearchResults, updateShowTooManySearchResults] = useState(false);
    const [isSearched, updateIsSearched] = useState(false);
    const [bankSearchValue, updateBankSearchValue] = useState([]);
    const TOO_MANY_RESULTS = 'ContactManager returned too many results, please make your search criteria more specific.';
    const PROVIDE_MORE_SPECIFIC_SEARCH_CRITERIA = 'Please provide more specific search criteria';

    const selectedContactType = _.get(selectedInterestVM, 'policyAdditionalInterest.value.contactType_Ext');

    const initEmptyVM = useCallback(() => {
        return viewModelService.create(
            initialValue, 'pc', 'edge.capabilities.policyjob.lob.AdditionalInterestDTO', initialContext
        );
    }, [initialContext, viewModelService]);

    const validateAdditionalInterests = useCallback(() => {
        return additionalInterestsVM.aspects.valid && additionalInterestsVM.aspects.subtreeValid
    }, [additionalInterestsVM]);

    useEffect(() => {
        registerComponentValidation(validateAdditionalInterests);
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, registerComponentValidation, validateAdditionalInterests]);

    const writeValue = useCallback((value, path) => {
        const newInterestVM = viewModelService.clone(selectedInterestVM);
        _.set(newInterestVM, path, value);
        updateSelectedInterestVM(newInterestVM);
    }, [selectedInterestVM, viewModelService]);

    const resetSearchBankFields = useCallback(() => {
        updateIsSearched(false);
        updateSelectRow({});
        updateBankSearchValue([]);
    }, []);

    const addInterest = useCallback(() => {
        const newInterestVM = viewModelService.clone(initEmptyVM());
        updateShowErrors(false); 
        updateSelectedInterestVM(newInterestVM);
        updateIsNewInterest(true);
        updateEditIndex(additionalInterestsVM.children.length + 1);
    }, [additionalInterestsVM, initEmptyVM, viewModelService]);

    const editInterest = useCallback((index) => {
        const vm = additionalInterestsVM.children[index];
        if (!_.isNil(vm)
            && !_.isNil(vm.value.policyAdditionalInterest)
            && _.isNil(vm.value.policyAdditionalInterest.primaryAddress)) {
            // prevent converted data don't has primaryAddress
            _.set(vm, 'value.policyAdditionalInterest.primaryAddress', {
                country: 'US'
            });
        }
        // add context to vm, so FilterByCategory can work
        const vmWithContext = viewModelService.create(
            vm.value, 'pc', 'edge.capabilities.policyjob.lob.AdditionalInterestDTO', initialContext
        );
        updateShowErrors(false); 
        updateSelectedInterestVM(viewModelService.clone(vmWithContext));
        updateBakInterestVM(viewModelService.clone(vmWithContext));
        updateIsNewInterest(false);
        updateEditIndex(index);
    }, [additionalInterestsVM, initialContext, viewModelService]);

    const removeInterest = useCallback((index) => {
        const aiIndex = additionalInterestsVM.children.findIndex((interest) => interest.value === additionalInterestsVM.children[index].value);
        additionalInterestsVM.value.splice(aiIndex, 1);
        disregardFieldValidation(id);
        onValueChange(additionalInterestsVM, 'additionalInterests');
    }, [additionalInterestsVM, disregardFieldValidation, id, onValueChange]);

    const cancelInterest = useCallback(() => {
        if (editIndex !== -1 && editIndex !== additionalInterestsVM.children.length + 1) {
            // for interest existing
            const additionalInsuredVM = additionalInterestsVM.children[editIndex];
            _.set(additionalInsuredVM, 'value', bakInterestVM.value);
        }
        onValueChange(additionalInterestsVM, 'additionalInterests');
        updateBakInterestVM(undefined);
        updateSelectedInterestVM(undefined);
        updateEditIndex(-1);
        resetSearchBankFields();
    }, [additionalInterestsVM, bakInterestVM, editIndex, onValueChange, resetSearchBankFields]);

    const saveInterest = useCallback(() => {
        const valid = selectedInterestVM.aspects.valid && selectedInterestVM.aspects.subtreeValid;
        if (!valid) {
            updateShowErrors(true);        
            return false;
        }
        if (_.get(selectedInterestVM, 'policyAdditionalInterest.phoneRequired_Ext.value')) {
            _.set(selectedInterestVM, 'policyAdditionalInterest.phoneRequired_Ext.value', false);
        }
        if (isNewInterest) {
            additionalInterestsVM.pushElement(selectedInterestVM);
        } else {
            additionalInterestsVM.value.splice(editIndex, 1, selectedInterestVM.value);
        }
        onValueChange(additionalInterestsVM, 'additionalInterests');
        updateBakInterestVM(undefined);
        updateSelectedInterestVM(undefined);
        updateEditIndex(-1);
        resetSearchBankFields();
    }, [selectedInterestVM, isNewInterest, onValueChange, additionalInterestsVM, resetSearchBankFields, editIndex]);

    const renderCell = useCallback((item, index, property) => {
        switch(property.id) {
            case 'name':
                const contactType = _.get(item, 'policyAdditionalInterest.value.contactType_Ext');
                if (contactType === 'person') {
                    const firstName = _.get(item, 'policyAdditionalInterest.firstName.value');
                    const lastName = _.get(item, 'policyAdditionalInterest.lastName.value');
                    return `${firstName} ${lastName}`;
                }
                return _.get(item, 'policyAdditionalInterest.contactName.value');
            case 'address':
                return _.get(item, 'policyAdditionalInterest.primaryAddress.addressLine1.value');
            default:
                return item[property.id].value;
        }
    }, []);

    const renderActionCell = useCallback((item, index) => {
        return (
            <DropdownMenuButton
                icon="gw-expand-more"
                id={`dropdownMenuButton${index}`}
                className="ml-10"
                menuClassName="dropDownMenuList"
                alignRight
            >
                <DropdownMenuLink key={`edit${index}`} onClick={() => editInterest(index)} disabled={editIndex !== -1}>
                    <Link 
                        icon="gw-edit"
                        className={styles.actionLink}
                    >
                        {translator(messages.edit)}
                    </Link>
                </DropdownMenuLink>
                <DropdownMenuLink key={`delete${index}`} onClick={() => removeInterest(index)} disabled={editIndex !== -1}>
                    <Link 
                        icon="gw-delete"
                        className={styles.actionLink}
                    >
                        {translator(messages.delete)}
                    </Link>
                </DropdownMenuLink>
            </DropdownMenuButton>
        );
    }, [editInterest, removeInterest, translator, editIndex]);

    const onLienholderToggleChange = useCallback((newValue) => {
        if (newValue) {
            addInterest();
            updateAddLienholderToggle(newValue);
        } else if (additionalInterestsVM.children.length === 0) {
            updateSelectedInterestVM(undefined);
            updateAddLienholderToggle(newValue);
        }
    }, [addInterest, additionalInterestsVM]);

    const filteredInterestTypes = useMemo(() => {
        if (_.isEmpty(selectedInterestVM)) {
            return [];
        }
        const { 
            policyAdditionalInterest: contact,
            interestTypeOptions_Ext: interestTypeOptions
        } = selectedInterestVM.value;
        const { 
            publicID,
            companyType_Ext: companyType
        } = contact;

        let optionCodes = [];
        if (_.isNil(publicID)) {
            // newly added vehicle interest which is not saved to DB, only can add company type in CE
            optionCodes = ['CERTIFICATEOFINSURANCETRUST_Ext'];
            if (companyType === 'Bank') {
                optionCodes = ['ADDITIONALINSURED_Ext', 'LOSSP', 'LOSSPA_Ext'];
            }
        } else {
            // existing interest can have person type, need the interestTypeOptions which is retrieved in PC
            optionCodes = interestTypeOptions;
        }

        if (_.isEmpty(optionCodes)) {
            return [];
        }
        return optionCodes.map((typeCode) => {
            return {
                code: typeCode,
                name: translator({ 
                    id: `typekey.AdditionalInterestType.${typeCode}`, 
                    defaultMessage: `typekey.AdditionalInterestType.${typeCode}`
                })
            };
        });
    }, [selectedInterestVM, translator]);

    const interestTypeTooltipContent = useMemo(() => {
        return (
            <>
               {translator(messages.interestTypeTooltip1)}<br/><br/>
               {translator(messages.interestTypeTooltip2)}<br/><br/>
               {translator(messages.interestTypeTooltip3)}
            </>
        )
    }, [translator]);

    const interestTypeLabelWithTooltip = useMemo(() => {
        return (
            <div className={styles.labelWithTooltipStyle}>
                {translator(messages.interestType)}
                <TooltipIcon
                    id="interestTypetooltipIcon"
                    text={interestTypeTooltipContent}
                />
            </div>
        )
    }, [interestTypeTooltipContent, translator]);

    const handleSearch = () => {
        const bankName = _.get(selectedInterestVM, 'value.policyAdditionalInterest.contactName');
        const primaryAddress = _.get(selectedInterestVM, 'value.policyAdditionalInterest.primaryAddress');
        const bankSearchCritiera = {
            bankName,
            primaryAddress
        };
        if (_.isEmpty(bankName) || _.isNil(bankName)) {
            updateShowBankNameRequiredMsg(true);
            WindowUtil.scrollTo('scrollToHeader');
            return false;
        }
        // enable loading
        updateIsLoading(true);
        // update search flag
        updateIsSearched(true);
        // reset select row
        updateSelectRow({});
        // reset search table value
        updateBankSearchValue([]);
        updateBankPageConfig(WniTableRowUtil.defaultPageConfig);
        return WNIAddressLookupService.searchBank(bankSearchCritiera, authHeader).then((res) => {
            const banks = _.sortBy(_.get(res, 'accountContactDTOs'), (bank) => { return _.get(bank, bankName); });
            updateBankSearchValue(banks);

            const warningMessage = _.get(res, 'warningMessage');
            if (warningMessage && (warningMessage === TOO_MANY_RESULTS || warningMessage.includes(PROVIDE_MORE_SPECIFIC_SEARCH_CRITERIA))) {
                updateShowTooManySearchResults(true);
                WindowUtil.scrollTo('scrollToHeader');
            }
            // disable loading
            updateIsLoading(false);
        }).catch((e) => {
            // handle too many results
            // error code = -32603
            // error msg contains Java.Search.TooManyResults
            // = More than {0} results found. Please provide more specific search criteria.
            const errorCode = _.get(e, 'baseError.code');
            const errorMsg = _.get(e, 'baseError.message');
            if (errorCode === -32603 && _.includes(errorMsg, 'More than')) {
                updateShowTooManySearchResults(true);
                WindowUtil.scrollTo('scrollToHeader');
            }
            _.noop();
            // enable loading
            updateIsLoading(false);
        });
    };

    const hightLight = (activeRow) => {
        const selectedPublicID = _.get(activeRow, 'publicID') || _.get(activeRow, 'rowIdPath');
        WniTableRowUtil.setTablePublicIDSelected(selectedPublicID, 'exisitingAgencyMatchTable');
    };

    const getSelectedFn = (item) => {
        updateSelectRow(item);
        hightLight(item);
        writeValue(item, 'policyAdditionalInterest.value');
    };

    const updateBankName = useCallback((value, path) => {
        updateShowBankNameRequiredMsg(false);
        writeValue(value, path);
    }, [writeValue]);

    const onSort = (a, b) => {
        hightLight(selectRow);
        return DatatableUtil.sortString(a, b);
    };

    const onTableConfigPage = useCallback((obj) => {
        updateBankPageConfig(obj);
    }, [updateBankPageConfig]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false
        },
        addLienholderToggle: {
            visible: additionalInterestsVM.children.length === 0,
            value: addLienholderToggle,
            onValueChange: onLienholderToggleChange
        },
        lienholdersTitle: {
            visible: additionalInterestsVM.children.length > 0
        },
        lienholderTable: {
            visible: addLienholderToggle || additionalInterestsVM.children.length > 0,
            data: additionalInterestsVM.children
        },
        addLienholderbtnDiv: {
            visible: additionalInterestsVM.children.length > 0 || !!addLienholderToggle
        },
        addLienholderButtonId: {
            disabled: editIndex !== -1 || (editIndex === additionalInterestsVM.children.length + 1)
        },
        lienholderDetailGridContainer: {
            visible: !!selectedInterestVM
        },
        companyContainer: {
            visible: selectedContactType === 'company'
        },
        bankSearchContainer: {
            className: classNames('jut__FieldComponent__fieldComponent', isPhone ? 'jut__FieldComponent__top' : 'jut__FieldComponent__left')  
        },
        bankNameLabelContainer: {
            className: classNames('jut__FieldLabel__fieldLabelContainer', isPhone ? 'jut__FieldLabel__top' : 'jut__FieldLabel__left')
        },
        searchBtn: {
            disabled: _.get(selectedInterestVM, 'policyAdditionalInterest.value.companyType_Ext') !== 'Bank'
        },
        personalContainer: {
            visible: selectedContactType === 'person'
        },
        cancelSaveLienholderBtns: {
            visible: !!selectedInterestVM
        },
        exisitingAgencyMatchTable: {
            data: bankSearchValue,
            visible: !isLoading,
            onConfigChange: onTableConfigPage,
            config: bankPageConfig
        },
        bankSearchResultContainer: {
            visible: isSearched
        },
        bankNameRequiredNotification: {
            visible: showBankNameRequiredMsg,
            validationIssues: [{
                type: 'error',
                reason: translator(messages.bankNameRequired)
            }],
            scrollToIssues: false
        },
        searchTooManyNotification: {
            visible: showTooManySearchResults,
            validationIssues: [{
                type: 'error',
                reason: translator(messages.tooManyResult)
            }],
            scrollToIssues: false
        },
        interestType: {
            availableValues: filteredInterestTypes,
            label: interestTypeLabelWithTooltip
        },
        bankName: {
            disabled: !_.isEmpty(selectRow),
            onValueChange: updateBankName
        },
        companyType: {
            disabled: !_.isEmpty(selectRow)
        },
        address: {
            disabled: !_.isEmpty(selectRow)
        },
        city: {
            disabled: !_.isEmpty(selectRow)
        },
        state: {
            disabled: !_.isEmpty(selectRow)
        },
        postalCode: {
            disabled: !_.isEmpty(selectRow)
        },
        tabelLoading: {
            visible: isLoading
        },
        bankColumn: {
            renderCell: (item) => WniTableRowUtil.renderCell(item.publicID || item.rowIdPath, item.contactName)
        }
    };

    const resolvers = {
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent
        },
        resolveCallbackMap: {
            onValidate: setComponentValidation,
            addInterest,
            renderCell,
            renderActionCell,
            onSearchBtnClick: handleSearch,
            getSelectedFn,
            onSort,
            cancelInterest,
            saveInterest
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={selectedInterestVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValueChange={writeValue}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

CEAdditionalInterestComponent.propTypes = {
    additionalInterestsVM: PropTypes.shape({}).isRequired,
    initialContext: PropTypes.shape({}).isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    authHeader: PropTypes.shape({}).isRequired
};

CEAdditionalInterestComponent.defaultProps = {
};

export default CEAdditionalInterestComponent;
