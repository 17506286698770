import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import SublineDetailComponent from '../SublineDetailComponent/SublineDetailComponent';
import metadata from './SublinesDetailComponent.metadata.json5';

function SublinesDetailComponent(props) {
    const { 
        data: sublinesVM,
        wizardData,
        updateWizardData,
        stateIndex 
    } = props;

    const generateOverrides = useCallback(() => {
        const overrides = sublinesVM.map((item, index) => {
            return {
                [`sublineDetailComponent${index}`]: {
                    wizardData,
                    updateWizardData,
                    data: item,
                    sublinePath: `details.children[${stateIndex}].sublines.children[${index}]`
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [sublinesVM, updateWizardData, wizardData, stateIndex]);

    const overrides = {
        sublinesDetailContainer: {
            data: sublinesVM
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveComponentMap: {
            sublinedetailcomponent: SublineDetailComponent
        }    
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={sublinesVM}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

SublinesDetailComponent.propTypes = {
    data: PropTypes.array.isRequired,
    wizardData: PropTypes.object.isRequired,
    updateWizardData: PropTypes.func.isRequired,
    stateIndex: PropTypes.string.isRequired
};

export default SublinesDetailComponent;
