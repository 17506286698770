import React, { useState, useContext }from 'react'
import _ from 'lodash'
import {
    Button,
} from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './SinglePropertyIncident.metadata.json5'
import messages from './SinglePropertyIncident.message'
import { createNewLocationWithoutPublicID } from '../../util/NewAddressUtil';
import { getFullAddressDisplayName } from "../../util/AddressUtil";

const AddNewLocation = 'AddNewLocation'

const SinglePropertyIncident = (props) => {
    const {
        originPropertyIncident,
        editingPropertyIncidentIndex,
        onPopupCancel,
        onPopupSave,
        predefinedLossLocations,
        incidentsAddresses,
    } = props

    const translator = useTranslator()

    const viewModelService = useContext(ViewModelServiceContext);

    const {
        isComponentValid,
        onValidate
    } = useValidation('SinglePropertyIncident')

    const allAvailableExistingAddresses = predefinedLossLocations.concat(incidentsAddresses)
    const [location, setLocation] = useState(() => {
        return allAvailableExistingAddresses.find((address) => address.publicID === originPropertyIncident.locationPublicID_Ext)
    })

    const locationVM = viewModelService.create(
        location,
        'cc',
        'edge.capabilities.address.dto.AddressDTO'
    )
    const [showErrors, setShowErrors] = useState(false);
    const [propertyIncidentVM, setPropertyIncidentVM] = useState(() => viewModelService.create(
            originPropertyIncident,
            'cc',
            'edge.capabilities.claim.lob.shared.incidents.dto.FixedPropertyIncidentDTO'
        ))

    const locationPublicID = _.get(propertyIncidentVM.value, 'locationPublicID_Ext')

    const locationSelectorAvailableValues = allAvailableExistingAddresses.map((address) => {
        return {
            code: _.get(address, 'publicID'),
            name: getFullAddressDisplayName(address)
        }
    }).concat([
        {
            code: AddNewLocation,
            name: translator(messages.addNewLocation)
        }
    ])

    const onVehicleLocationSelectorChange = (value) => {
        if (value === locationPublicID) {
            return
        }
        const newPropertyIncidentVM = _.clone(propertyIncidentVM);
        if (value === AddNewLocation) {
            _.set(newPropertyIncidentVM.value, 'locationPublicID_Ext', AddNewLocation);
            setLocation(createNewLocationWithoutPublicID())
        } else {
            _.set(newPropertyIncidentVM.value, 'locationPublicID_Ext', value);
            setLocation(allAvailableExistingAddresses.find((address) => address.publicID === value))
        }
        setPropertyIncidentVM(newPropertyIncidentVM);
    }

    const writeValue = (value, path) => {
        const newPropertyIncidentVM = _.clone(propertyIncidentVM);
        _.set(newPropertyIncidentVM.value, path, value);
        setPropertyIncidentVM(newPropertyIncidentVM);
    }

    const onLocationChange = (value, path) => {
        const updatedVehicleLocation = {
            ...location,
            [path]: value
        }
        setLocation(updatedVehicleLocation)
    }

    const onSaveClicked = () => {
        if (!isComponentValid) {
            setShowErrors(true)
            return
        }
        onPopupSave(propertyIncidentVM.value, editingPropertyIncidentIndex, location)
    }

    let stateAvailableValues = _.get(locationVM, 'state.aspects.availableValues', [])
    stateAvailableValues = _.map(stateAvailableValues, (stateCode) => {
        const opt = {
            code: _.get(stateCode, 'code'),
            name: translator({
                id: _.get(stateCode, 'name'),
                defaultMessage: _.get(stateCode, 'name')
            })
        }
        return opt;
    });

    const isClaimAddress = predefinedLossLocations.some((address) => address.publicID === locationPublicID)

    const overrideProps = {
        belongsToInsured: {
            availableValues: [
                {
                    code: true,
                    name: translator(messages.Yes)
                },
                {
                    code: false,
                    name: translator(messages.No)
                }
            ]
        },
        locationSelector: {
            value: locationPublicID,
            availableValues: locationSelectorAvailableValues,
            onValueChange: onVehicleLocationSelectorChange
        },
        addressDetailsContainer: {
            visible: !!location
        },
        addressLine1: {
            value: _.get(location, 'addressLine1'),
            onValueChange: (value) => onLocationChange(value, 'addressLine1'),
            disabled: isClaimAddress,
            showOptional: true,
        },
        addressLine2: {
            value: _.get(location, 'addressLine2'),
            onValueChange: (value) => onLocationChange(value, 'addressLine2'),
            disabled: isClaimAddress,
            showOptional: true,
        },
        addressLine3: {
            value: _.get(location, 'addressLine3'),
            onValueChange: (value) => onLocationChange(value, 'addressLine3'),
            disabled: isClaimAddress,
            showOptional: true,
        },
        city: {
            value: _.get(location, 'city'),
            onValueChange: (value) => onLocationChange(value, 'city'),
            disabled: isClaimAddress,
        },
        state: {
            value: _.get(location, 'state'),
            onValueChange: (value) => onLocationChange(value, 'state'),
            availableValues: stateAvailableValues,
            disabled: isClaimAddress,
        },
        zipCode: {
            value: _.get(location, 'postalCode'),
            onValueChange: (value) => onLocationChange(value, 'postalCode'),
            disabled: isClaimAddress,
            showOptional: true,
        }
    }

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return <>
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={propertyIncidentVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={resolvers.resolveValue}
            showErrors={showErrors}
        />
        <div className='d-flex flex-direction-row-reverse'>
            <Button
                type="filled"
                onClick={onSaveClicked}
            >{translator(messages.save)}</Button>
            <Button
                className="wni-button-link"
                type="outlined"
                onClick={() => {onPopupCancel()}}
            >{translator(messages.cancel)}</Button>
        </div>
    </>
}

export default SinglePropertyIncident
