import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { MetadataContent } from '@jutro/legacy/uiconfig';

import { PersonalizedOfferService } from 'gw-capability-personalizedoffers';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import PersonalizedOfferUtil from '../../util/PersonalizedOffersUtil';
import metadata from './PersonalizedOfferComponent.metadata.json5';
import styles from './PersonalizedOfferComponent.module.scss';

function PersonalizedOfferComponent(props) {
    const {
        match, offer, className, history, type
    } = props;
    const { capabilitiesConfig: { personalizedoffers } } = appConfig;
    const { path, params } = match;
    const { authHeader } = useAuthentication();
    const [offerData, setOfferData] = useState({});

    useEffect(() => {
        if (_.isEmpty(offer)) {
            PersonalizedOfferService.getOfferDataFromServer(path, params, authHeader)
                .then((offers) => PersonalizedOfferUtil.pickOffer(offers, type))
                .then(setOfferData);
        }
        // prevent re render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offer, path]);

    const personalizedOfferData = useMemo(() => {
        if (_.isEmpty(offer)) {
            return offerData;
        }
        return offer;
    }, [offer, offerData]);

    const handleOfferClick = useCallback((event) => {
        event.preventDefault();
        history.push(_.get(personalizedOfferData, 'destinationUrl'));
    }, [history, personalizedOfferData]);

    const getImagePath = useCallback(() => {
        const relativeUrl = _.get(personalizedOfferData, 'content.value.url', '');
        const { urlPrefix } = personalizedOfferData;
        return urlPrefix ? PersonalizedOfferUtil.concatUrl(urlPrefix, relativeUrl) : '';
    }, [personalizedOfferData]);

    if (!personalizedoffers || _.isEmpty(personalizedOfferData)) {
        return null;
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onOfferClick: handleOfferClick
        }
    };
    const overrides = {
        offerContainer: {
            className: classNames(className, {
                [styles.rectangleOfferContainer]: personalizedOfferData.offerType === 'rectangle',
                [styles.leaderBoardOfferContainer]: personalizedOfferData.offerType === 'leaderboard'
            })
        },
        text: {
            content: _.get(personalizedOfferData, 'content.value.text'),
            visible: _.get(personalizedOfferData, 'content.type') === 'text'
        },
        image: {
            visible: _.get(personalizedOfferData, 'content.type') === 'image',
            src: _.get(personalizedOfferData, 'content.type') === 'image' ? getImagePath() : ''
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            {...resolvers} />
    );
}

PersonalizedOfferComponent.propTypes = {
    type: PropTypes.string.isRequired
};
export default withRouter(PersonalizedOfferComponent);
