import { defineMessages } from 'react-intl';

export default defineMessages({
    uploadNotification: {
        id: 'endorsement.pa.directives.templates.quote-page.uploadNotification',
        defaultMessage: 'Please upload any supporting documents such as grades, insurance declarations, or photos upon submission of your request.',
    },
    submit: {
        id: 'endorsement.pa.directives.templates.quote-page.submit',
        defaultMessage: 'Submit',
    },
    
});
