import { defineMessages } from 'react-intl';

export default defineMessages({
    hideWatercraftCoverages: {
        id: 'policycommon.component.policy-watercraft-operator-info.hide Watercraft coverages',
        defaultMessage: 'Hide Watercraft Coverages'
    },
    showWatercraftCoverages: {
        id: 'policycommon.component.policy-watercraft-operator-info.show Watercraft coverages',
        defaultMessage: 'Show Watercraft Coverages'
    }
});
