import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { TooltipIcon } from '@jutro/components';
import { Flex } from '@jutro/layout';
import CETooltipContentUtil from '../../utils/CETooltipContentUtil';
import PolicyCoverageUtil from '../../utils/PolicyCoverageUtil';
import CoverageTermsIterableComponent from '../CoverageTermsIterableComponent/CoverageTermsIterableComponent';
import metadata from './PolicyCommonCoverageTable.metadata.json5';
import messages from './PolicyCommonCoverageTable.messages';
import styles from './PolicyCommonCoverageTable.module.scss';

function PolicyCommonCoverageTable(props) {
    const { data: coverages, coverageForm } = props;
    const translator = useTranslator();
    const [openItems, setOpenItems] = useState([]);

    const toggleShowHide = useCallback((item) => {
        const openedItem = _.clone(openItems);
        const index = _.indexOf(openedItem, item);
        if (index === -1) {
            openedItem.push(item);
            setOpenItems(openedItem);
        } else {
            openedItem.splice(index, 1);
            setOpenItems(openedItem);
        }
    }, [openItems]);

    const renderCoverageName = (cov, index) => {
        let clausePatternCode = cov.coverageCodeIdentifier_Ext;
        if(clausePatternCode ==='DPCoverageDFairRentVal_Ext' || clausePatternCode ==='DPCoverageEAddLivExp_Ext') {
            clausePatternCode = coverageForm === 'DP1' ? `${clausePatternCode}_DP1` : `${clausePatternCode}_DP2`;
        }
        return (
            <Flex justifyContent="left" gap="none" alignContent="middle" className={styles.coverageNameWrapper}>
                <span>{cov.name}</span>
                {CETooltipContentUtil[clausePatternCode] && 
                    <TooltipIcon
                        id={`coverage_tooltip_${clausePatternCode}_${index}`}
                        text={CETooltipContentUtil[clausePatternCode]}
                    />
                }
            </Flex>
        );
    };

    const generateCoverageOverrides = () => {
        const overrides = coverages.map((cov, index) => {
            return {
                [`coverageNameContainer${index}`]: {
                    content: renderCoverageName(cov, index)
                }
                
            };
        });
        return Object.assign({}, ...overrides);
    };

    const generateScheduleItemsOverrides = () => {
        const overrides = coverages.map((cov, index) => {
            const scheduleItems = PolicyCoverageUtil.getScheduleItems(cov);
            return {
                [`coverageScheduleTermsContainer${index}`]: {
                    visible: PolicyCoverageUtil.hasScheduleItems(cov)
                }, 
                [`showContentToggleLink${index}`]: {
                    onClick: () => toggleShowHide(`${cov.coverageCodeIdentifier_Ext}_${index}`),
                    icon: _.includes(openItems, index) ? 'gw-arrow-drop-up' : 'gw-arrow-drop-down',
                    content: _.includes(openItems, `${cov.coverageCodeIdentifier_Ext}_${index}`)
                        ? translator(messages.hideScheduleItems)
                        : translator(messages.showScheduleItems)
                },
                [`coverageScheduleTermsTable${index}`]: {
                    visible: _.includes(openItems, `${cov.coverageCodeIdentifier_Ext}_${index}`),
                    data: scheduleItems,
                    content: PolicyCoverageUtil.getScheduleItemTableContent(scheduleItems)
                }
            };
        });
        return Object.assign({}, ...overrides);
    };

    const overrides = {
        '@field': {
            readOnly: true
        },
        commonCoverablesIterable: {
            data: coverages
        },
        ...generateCoverageOverrides(),
        ...generateScheduleItemsOverrides()
    };

    const resolvers = {
        resolveComponentMap: {
            coveragetermsiterablecomponent: CoverageTermsIterableComponent
        },
        resolveClassNameMap: styles
    };

    const readValue = (id, path) => {
        return readViewModelValue(metadata.componentContent, coverages, id, path, overrides);
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coverages}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

PolicyCommonCoverageTable.propTypes = {
    data: PropTypes.array.isRequired,
    coverageForm: PropTypes.string
};

export default PolicyCommonCoverageTable;
