import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    ModalNext,
    ModalHeader,
    ModalBody
} from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './TermConditionsPopup.metadata.json5';
import styles from './TermConditionsPopup.module.scss';


function TermConditionsPopup(props) {
    const {
        title,
        renderTermConditionContent,
        isOpen,
        onResolve,
        onReject
    } = props;

    const handleSave = useCallback(() => {
        onResolve();
    }, [onResolve]);

    const overrideProps = {
        termConditionContentContainer: {
            content: renderTermConditionContent()
        },
        cancelBtn: {
            onClick: onReject
        },
        agreeBtn: {
            onClick: handleSave
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ModalNext isOpen={isOpen} className={styles.termConditionsModal}>
            <ModalHeader title={title} icon='gw-error-outline' status='info' onClose={onReject} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </ModalBody>
        </ModalNext>
    );
}

TermConditionsPopup.propTypes = {
    title: PropTypes.string.isRequired,
    renderTermConditionContent: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func
};

TermConditionsPopup.defaultProps = {
    renderTermConditionContent: _.noop
};

export default TermConditionsPopup;
