import React, { useCallback, useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { Loader, Button } from '@jutro/components';
import { IntlContext, useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { WniPremiumReportService } from 'wnice-capability-premiumreport';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import SublinesDetailComponent from '../../components/SublinesDetailComponent/SublinesDetailComponent';
import { getExposureInputDTOs, inputHasError } from '../../utils/GLPremiumReportUtil';
import metadata from './CEGLPremiumReportDetailsPage.metadata.json5';
import styles from './CEGLPremiumReportDetailsPage.module.scss';
import messages from './CEGLPremiumReportDetailsPage.messages';


function CEGLPremiumReportDetailsPage(props) {
    const { wizardData, updateWizardData, wizardSnapshot, goNext, authHeader, history } = props;
    const { onValidate, isComponentValid, registerComponentValidation } = useValidation('CEGLPremiumReportDetailsPage');
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const { 
        details: premiumReportDetailsVM
    } = wizardData;

    const { 
        summary : { comments },
        details: premiumReportDetails,
        jobNumber,
        jobStatus,
        startDate,
        endDate,
        sessionUUID
    } = wizardData.value;

    const isDraft = jobStatus === 'Draft';
    const exposureInputDTOs = getExposureInputDTOs(premiumReportDetails);

    const checkExposureInputsValid = useCallback(() => {
        return premiumReportDetailsVM.aspects.valid && premiumReportDetailsVM.aspects.subtreeValid
    }, [premiumReportDetailsVM]);

    const getFormattedDate = useCallback((date) => {
        const newDate = new Date(date.year, date.month, date.day);
        return intl.formatDate(newDate, { year: 'numeric', month: 'short', day: 'numeric' })
    }, [intl]);

    const getReportDateHeader = useCallback(() => {
        const periodStart = getFormattedDate(startDate);
        const periodEnd = getFormattedDate(endDate);
        return `${periodStart} - ${periodEnd}`;
    }, [endDate, getFormattedDate, startDate]);

    useEffect(() => {
        registerComponentValidation(checkExposureInputsValid);
    }, [checkExposureInputsValid, registerComponentValidation]);

    const onPrevious = useCallback(async () => {
        if (isComponentValid && !_.isEqual(wizardData.value, wizardSnapshot.value) && isDraft) {
            setIsLoading(true);
            await WniPremiumReportService.saveGLPremiumReport(
                jobNumber, exposureInputDTOs, comments, sessionUUID,
                authHeader
            );
            setIsLoading(false);
        } 
        history.push('/premium-reports');
    }, [isComponentValid, wizardData, wizardSnapshot, isDraft, history, jobNumber, exposureInputDTOs, comments, sessionUUID, authHeader]);

    const onNext = useCallback(async () => {
        if (isDraft) {
            if (!isComponentValid) {
                return;
            }
            if (inputHasError(premiumReportDetails)) {
                setHasError(true);
                return;
            }
            setHasError(false);
            setIsLoading(true);
            wizardData.value = await WniPremiumReportService.saveAndCalculateGLPremiums(
                jobNumber, exposureInputDTOs, comments, sessionUUID,
                authHeader
            );
            setIsLoading(false);
        }
        goNext();
        return wizardData;
    }, [isDraft, goNext, wizardData, isComponentValid, premiumReportDetails, jobNumber, exposureInputDTOs, comments, sessionUUID, authHeader]);

    const generateDetailOverrides = useCallback(() => {
        const detailOverrides = premiumReportDetailsVM.children.map((item, index) => {
            return {
                [`stateDetailHeader${index}`]: {
                    content: translator({
                        id: `typekey.Jurisdiction.${item.value.state}`,
                        defaultMessage: `typekey.Jurisdiction.${item.value.state}`
                    })
                },
                [`reportDateHeader${index}`]: {
                    content: getReportDateHeader()
                },
                [`sublinesDetailComponent${index}`]: {
                    wizardData,
                    updateWizardData,
                    data: item.sublines.children,
                    stateIndex: index
                }
            };
        });

        return Object.assign({}, ...detailOverrides);
    }, [premiumReportDetailsVM, translator, updateWizardData, wizardData, getReportDateHeader]);

    const renderCustomWizardPageFooterContent = useCallback(() => {
        return (
            <>
                <Button
                    id="gw-wizard-previous"
                    onClick={onPrevious}
                    variant="secondary" 
                    label={translator(messages.previousBtn)}
                />
                <Button
                    id="gw-wizard-next"
                    disabled={!isComponentValid}
                    onClick={onNext}
                    variant="primary" 
                    label={translator(messages.nextBtn)}
                />
            </>
        );
    }, [isComponentValid, onNext, onPrevious, translator]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            sublinesdetailcomponent: SublinesDetailComponent
        }      
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        errorNotification: {
            message: translator(messages.amountsMustBeFilled),
            visible: hasError
        },
        ...generateDetailOverrides()
    };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    return (
        <WizardPage
            renderCustomWizardPageFooterContent = {renderCustomWizardPageFooterContent}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={wizardData}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

CEGLPremiumReportDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(withRouter(CEGLPremiumReportDetailsPage));
