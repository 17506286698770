import { defineMessages } from 'react-intl';

export default defineMessages({
    previousBtn: {
        id: 'premiumreport.gl.page.details.Previous',
        defaultMessage: 'Previous'
    },
    nextBtn: {
        id: 'premiumreport.gl.page.details.Next',
        defaultMessage: 'Next'
    },
    amountsMustBeFilled: {
        id: 'premiumreport.gl.page.details.All audited amounts must be filled in to calculate premiums.',
        defaultMessage: 'All audited amounts must be filled in to calculate premiums.'
    }
});
