import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    DocumentsUtil,
    WniDateUtil,
    ServiceErrorUtil,
} from 'wni-portals-util-js';
import { useHistory } from 'react-router-dom';
import { useWniModal } from 'wni-components-platform-react';
import { WniDocumentRetrievalService } from 'wni-capability-gateway';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import PolicyCommonCoverageTable from '../../PolicyCommonCoverageTable/PolicyCommonCoverageTable';
import WatercraftDetailInfo from '../WatercraftDetailInfo/WatercraftDetailInfo';
import metadata from './PolicyWatercraftOperatorInfo.metadata.json5';
import messages from './PolicyWatercraftOperatorInfo.messages';
import styles from './PolicyWatercraftOperatorInfo.module.scss';

const getCell = (items, index, property) => {
    if (property.id === 'dateOfBirth') {
        return WniDateUtil.formatDateWithPattern(items[property.id], "MM/DD/YYYY");
    }
    return items[property.id];
};

function PolicyWatercraftOperatorInfo(props) {
    const translator = useTranslator();
    const [openItems, setOpenItems] = useState([]);
    const { idCardDocument } = props;
    const modalApi = useWniModal();
    const history = useHistory();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);
    const { authHeader } = useAuthentication();

    const getWatercraftData = useCallback(() => {
        const watercraftData = _.cloneDeep(props.value);
        watercraftData.watercraftDTOs?.forEach((watercraft) => {
            const newDetails = [];
            const watercraftDetail = watercraft.watercraftDetails.find((detail) => detail.watercraftDetailType === 'watercraft');
            const motorDetail = watercraft.watercraftDetails.find((detail) => detail.watercraftDetailType === 'motor');
            const trailerDetail = watercraft.watercraftDetails.find((detail) => detail.watercraftDetailType === 'trailer');
            const details = [watercraftDetail, motorDetail, trailerDetail];
            details.forEach((detail) => {
                if (detail) {
                    newDetails.push(detail);
                }
            })
            _.set(watercraft, 'watercraftDetails', newDetails);
        })
        
        return watercraftData;
    }, [props]);

    const watercraftData = getWatercraftData();

    const toggleShowHide = useCallback((item) => {
        const openedItem = _.clone(openItems);
        const index = _.indexOf(openedItem, item);
        if (index === -1) {
            openedItem.push(item);
            setOpenItems(openedItem);
        } else {
            openedItem.splice(index, 1);
            setOpenItems(openedItem);
        }
    }, [openItems]);

    const successCallback = useCallback(() => {
        setLoadingMask(false);
    }, [setLoadingMask]);
    const errorCallback = useCallback(() => {
        setLoadingMask(false);
        modalApi.showConfirm({
            title: 'Error',
            message: ServiceErrorUtil.prependWithFriendMessage(),
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok,
        });
    }, [modalApi, setLoadingMask]);
    const onIDCardClick = useCallback(async (summaryDocument) => {
        setLoadingMask(true);
        await DocumentsUtil.tryDownloadDocument(
            summaryDocument,
            authHeader,
            history,
            WniDocumentRetrievalService,
            successCallback,
            errorCallback
        );
    }, [authHeader, errorCallback, history, setLoadingMask, successCallback]);

    const generateWatercraftOverrides = useCallback(() => {
        const overrides = watercraftData.watercraftDTOs.map((watercraftDTO, index) => {
            const watercraftCoverages = watercraftDTO.coverages;
            return {
                [`itemToBeInsuredTitle${index}`]: {
                    content: translator({
                        id: `typekey.ItemToBeInsuredType_Ext.${watercraftDTO.itemToBeInsured}`,
                        defaultMessage: watercraftDTO.itemToBeInsured
                    })
                },
                [`watercraftCoverageContainer${index}`]: {
                    visible: _.includes(openItems, index)
                },
                [`watercraftCoverageInfo${index}`]: {
                    data: watercraftCoverages,
                    show: openItems
                },
                [`showContentToggleBtn${index}`]: {
                    onClick: () => toggleShowHide(index),
                    content: _.includes(openItems, index)
                        ? translator(messages.hideWatercraftCoverages)
                        : translator(messages.showWatercraftCoverages)
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [watercraftData, translator, openItems, toggleShowHide]);

    
    if (_.isEmpty(watercraftData)) {
        return null;
    }

    const overrides = {
        watercraftOperatorTable: {
            data: watercraftData.operatorDTOs
        },
        lineCoveragesTableSection: {
            data: watercraftData.coverageDTOs
        },
        vehicleIDCardLink: {
            visible: !_.isNil(idCardDocument),
            onClick: () => onIDCardClick(idCardDocument)
        },
        ...generateWatercraftOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            watercraftcoverage: PolicyCommonCoverageTable,
            linecoverage: PolicyCommonCoverageTable,
            watercraftdetailinfo: WatercraftDetailInfo
        },
        resolveCallbackMap: {
            getCell
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={watercraftData}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyWatercraftOperatorInfo.propTypes = {
    value: PropTypes.shape({
        coverageDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        watercraftDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        operatorDTOs: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    idCardDocument: PropTypes.shape({})
};

export default PolicyWatercraftOperatorInfo;
