import { start } from '@jutro/app';
import { initDefaultGA } from '@jutro/events';
import  { BrandingService } from '@xengage/gw-portals-branding-js'
import { LocaleService, G11nstore } from 'gw-portals-i18n-react';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import 'regenerator-runtime';

import _ from 'lodash';

import App from './app/App';

import { configureLegacyComponentMap } from '@jutro/legacy/uiconfig';
configureLegacyComponentMap();

const { trackingConfig = {}, authentication = {}, wniExtendedConfig = {}, } = appConfig;

const gaTrackingId = trackingConfig.GA_TRACKING_ID;

if (gaTrackingId) {
    initDefaultGA(trackingConfig);
}

// const config = {
//     ...authentication.servers.okta,
//     ...appConfig.credentials
// }

// ====================================================
const getThemeName = () => {
    // Determine theme/branc name by URL, match-pattern specified in the config.json5 file
    const urlHref = window.location.href;
    let themeName;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [key, value] of Object.entries(wniExtendedConfig)) {
        if ('urlMatchPattern' in value && urlHref.search(value.urlMatchPattern) !== -1) {
            themeName = value.themeName;
            break;
        }
    }

    return themeName;
};

const getBrandedConfigValue = (themeName, propertyName) => _.get(wniExtendedConfig, `${themeName}.${propertyName}`);

const getBrandedOkta = () => {
    const themeName = getThemeName();
    const brandOverrides = getBrandedConfigValue(themeName, 'okta');
 
    return { ...authentication.servers.okta, ...brandOverrides };
 };


const debugOktaConfigs = () => {
    const ootbOkta = authentication.servers.okta;
    const brandedOkta = getBrandedOkta();

    return {...ootbOkta, ...appConfig.credentials, ...brandedOkta};
};

const wniOktaConfigs = debugOktaConfigs();

// ====================================================


start(App, {
    rootId: 'root',
    themeConfig: BrandingService.getBrandingTheme(),
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,

    // config: [config],
    config: [wniOktaConfigs],

    refreshOnLocaleChange: true,
    routerBasename: '/account-management',
    g11nStore: G11nstore(),

    onInit: () => {
        LocaleService.register();
    },

    appName: {
        id: 'digital.appName',
        defaultMessage: 'CustomerEngage Account Management'
    },

    appDescription: 'CustomerEngage Account Management',
});