import { defineMessages } from 'react-intl';

export default defineMessages({
    referenceNumber: {
        id: 'policy.premium-report-success-page.Premium Report Number',
        defaultMessage: 'Premium Report Number {reportNumber}'
    },
    anErrorOccurred: {
        id: 'policy.premium-report-success-page.Error',
        defaultMessage: 'Something went wrong during this process.'
    }
});
