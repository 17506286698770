// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Icon } from '@jutro/components';
import { withIntl, useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Link } from '@jutro/router';
import metadata from './FileUploadViewerComponent.metadata.json5';
import styles from './FileUploadViewerComponent.module.scss';
import messages from '../PolicyCommonDocuments.messages';


function deduplicationDocument(documents) {
    const result = [];
    // sort by time to make sure show the latest one
    documents.sort((a, b) => {
        return new Date(b.dateModified) - new Date(a.dateModified);
    });
    // duplicate check
    const map = {};
    const filterKeyWord = ['Quote Packet', 'Application', 'Quote Summary']
    _.forEach(documents, (doc) => {
        _.set(doc, 'displayName', _.get(doc, 'displayName_Ext'));
        if(!_.includes(['final', 'approved'], doc.status)){
            return
        }
        // filter quote phase file
        const hasfilterKeyWord = filterKeyWord.find((keyWord) => doc.displayName_Ext.includes(keyWord))
        if(hasfilterKeyWord){
            return
        }
        if (doc.documentType === 'IDC_Ext') {
            result.push(doc);
        } else if (!map[doc.simpleName_Ext]) {
            map[doc.simpleName_Ext] = doc.simpleName_Ext;
            result.push(doc);
        }
    });
    
    // sort by the alphabet
    result.sort((a, b) => {
        return a.displayName.localeCompare(b.displayName);
    });
    return result;
}

function FileUploadViewerComponent(props) {
    const {
        data: policyDocuments,
        intl,
        getDownloadLink,
        onDocumentDelete,
        onDocumentUpload
    } = props;
    
    const { maxFileUploadSize } = appConfig;
    const translator = useTranslator();

    const documents = useMemo(() => {
        return deduplicationDocument(policyDocuments.documents)
    }, [policyDocuments])

    const handleDocumentDelete = useCallback(
        (event, item) => {
            event.preventDefault();
            onDocumentDelete(item);
        },
        [onDocumentDelete]
    );

    const handleDocumentUpload = useCallback(
        (item) => {
            onDocumentUpload(item);
        },
        [onDocumentUpload]
    );

    const getFileLink = useCallback(
        (document) => {
            if(document.available_Ext){
                return (
                    <Link href={document.name} 
                        onClick={(e) => getDownloadLink(e, document)}
                    >
                        <Icon icon="gw-insert-drive-file" className={styles.fileTypeIcon} />
                        {document.name}
                    </Link>
                );
            }
            return document.name;
        },
        [getDownloadLink]
    );

    const getCell = useCallback(
        (document, index, property) => {
            return document[property.id];
        },
        []
    );

    const getTypeCell = useCallback(
        (document, index, property) => {
            const typeCode = document[property.id];
            return translator({ 
                id: `typekey.DocumentType.${typeCode}`, 
                defaultMessage: `typekey.DocumentType.${typeCode}`
            })
        },
        [translator]
    );

    const getFormattedDate = useCallback(
        (document) => {
            if(document.dateModified){
                return intl.formatDate(new Date(document.dateModified), { year: 'numeric', month: 'short', day: 'numeric' });
            }
            return ''
        },
        [intl]
    );

    const getDeleteIcon = useCallback(
        (document) => {
            if (document.canDelete) {
                return (
                    <Link href={document.name} onClick={(e) => handleDocumentDelete(e, document)}>
                        <Icon icon="gw-delete" />
                    </Link>
                );
            }
            return null;
        },
        [handleDocumentDelete]
    );

    const overrides = {
        fileUploadComponent: {
            visible: !policyDocuments.isReadOnly,
            maxFileSizeKB: maxFileUploadSize !== undefined
                ? maxFileUploadSize
                : 50000
        },
        uploadedFileTableDetail: {
            placeholder: policyDocuments.isReadOnly && !documents.length ? translator(messages.noDocumentFound) : '',
            data: documents,
        },
        fileDeleteBtnContainer: {
            id: 'deleteBtn',
            renderCell: getDeleteIcon
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onFileUpload: handleDocumentUpload,
            getCell: getCell,
            getTypeCell: getTypeCell,
            getFileLink: getFileLink,
            getFormattedDate: getFormattedDate,
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.componentContent, documents, id, path, overrides);
        },
        [documents, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={documents}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

FileUploadViewerComponent.propTypes = {
    data: PropTypes.shape({
        canDelete: PropTypes.bool
    }),
    intl: PropTypes.object.isRequired,
    getDownloadLink: PropTypes.func.isRequired,
    onDocumentDelete: PropTypes.func.isRequired,
    onDocumentUpload: PropTypes.func.isRequired
};

FileUploadViewerComponent.defaultProps = {
    data: {
        canDelete: false
    },
    intl: {},
    onDocumentDelete: _.noop,
    onDocumentUpload: _.noop
};

export default withIntl(FileUploadViewerComponent);
