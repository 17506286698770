import { defineMessages } from 'react-intl';

export default defineMessages({
    wantToChangeYourAnswer: {
        id: 'ce.endorsement.pa.CEVehicleQuestionsComponent.wantToChangeYourAnswer',
        defaultMessage: 'Want To Change Your Answer?'
    },
    changeAnswerMayImpactCov: {
        id: 'ce.endorsement.pa.CEVehicleQuestionsComponent.changeAnswerMayImpactCov',
        defaultMessage: 'Changing the answer to this question may impact your coverage. Click "Cancel" to undo the change. If you would like to proceed, click "Yes".'
    },
    yes: {
        id: 'ce.endorsement.pa.CEVehicleQuestionsComponent.yes',
        defaultMessage: 'Yes'
    }
});
