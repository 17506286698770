import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLWCWizard from './FNOLWCWizard';
import WCInjuryPage from './pages/Injury/InjuryPage';
import WCWhatPage from './pages/What/WhatPage';
import WCFnolWherePage from './pages/Where/WCWherePage';

setComponentMapOverrides(
    {
        FNOLWCWizard: { component: 'FNOLWCWizard' },
        WCWhatPage: { component: 'WCWhatPage' },
        WCInjuryPage: { component: 'WCInjuryPage' },
        WCFnolWherePage: { component: 'WCFnolWherePage' }
    },
    {
        FNOLWCWizard,
        WCWhatPage,
        WCInjuryPage,
        WCFnolWherePage
    }
);

export {
    FNOLWCWizard as default
};
