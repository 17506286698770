import { defineMessages } from 'react-intl';

export default defineMessages({
    hideVehicleCoverages: {
        id: 'policycommon.component.policy-roadtrail-operator-info.hide vehicle coverages',
        defaultMessage: 'Hide Vehicle Coverages'
    },
    showVehicleCoverages: {
        id: 'policycommon.component.policy-roadtrail-operator-info.show vehicle coverages',
        defaultMessage: 'Show Vehicle Coverages'
    }
});
