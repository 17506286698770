import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import CEConfirmationPage from './pages/Confirmation/CEConfirmationPage';
import HOPAPaymentPage from './pages/Payments/HOPAPaymentPage/PaymentPage';
import CPBOPPaymentPage from './pages/Payments/CPBOPPaymentPage/PaymentPage';
import PolicyChangeUtil from './utils/PolicyChangeUtil';
import { NO_DRIVER_VEHICLE } from './extension/CEPAPolicyChangeDataExtension';

setComponentMapOverrides(
    {
        HOPAPaymentPage: { component: 'HOPAPaymentPage' },
        CPBOPPaymentPage: { component: 'CPBOPPaymentPage' },
        ConfirmationPage: { component: 'ConfirmationPage' }
    },
    {
        HOPAPaymentPage,
        CPBOPPaymentPage,
        ConfirmationPage: CEConfirmationPage
    }
);
export { PolicyChangeUtil };
export { default as messages } from './PolicyChangeCommon.messages';
export { default as PolicyChange } from './models/PolicyChange';
export { default as ConfirmationQuoteDetailsPage } from './pages/ConfirmationQuoteDetails/ConfirmationQuoteDetailsPage';
export { default as CPBOPPaymentPage } from './pages/Payments/CPBOPPaymentPage/PaymentPage';
export { NO_DRIVER_VEHICLE }
