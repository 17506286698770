import React, {
    useCallback,
    useState,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './UploadDocumentModal.metadata.json5';
import styles from './UploadDocumentModal.module.scss';
import { DocumentTypeNameMap } from '../PolicyCommonDocumentUtil';

import { Button } from '@jutro/legacy/components';

function UploadDocumentModal(props) {
    const {
        title,
        actionBtnLabel,
        cancelBtnLabel,
        uploadDocumentVM: uploadDocument,
        viewModelService,
        isOpen,
        onResolve,
        onReject,
        breakpoint,
        productCode,
        onDocumentUpload,
        state,
        size
    } = props;
    const [uploadDocumentVM, updateUploadDocumentVM] = useState();
    const [showErrors, updateShowErrors] = useState(false);
    const [uploadFiles, updateUploadFiles] = useState();
    const [tableFiles, setTableFiles] = useState([]);
    const translator = useTranslator();

    const {
        isComponentValid,
        onValidate 
    } = useValidation('UploadDocumentModal');

    useEffect(() => {
        const newVM = viewModelService.clone(uploadDocument);
        updateUploadDocumentVM(newVM)
    }, []);
    const writeValue = useCallback(
        (value, path) => {
            const newUploadDocumentVM = viewModelService.clone(uploadDocumentVM);
            _.set(newUploadDocumentVM, path, value);
            updateUploadDocumentVM(newUploadDocumentVM);
        },
        [uploadDocumentVM, viewModelService]
    );

    const handleDocumentUpload = useCallback(
        (item) => {
            onDocumentUpload(item);
        },
        [onDocumentUpload]
    );

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
        },
        [updateShowErrors]
    );

    const handleSave = useCallback(
        () => {
            if (!isComponentValid || !uploadFiles) {
                handleValidation();
            } else {
                onResolve({ file: uploadFiles, vm: uploadDocumentVM });
            }
        }, [uploadDocumentVM, handleValidation, isComponentValid, onResolve, uploadFiles]
    );

    const renderDocumentTypes = () => {
        const documentTypeOptions = _.get(uploadDocumentVM, 'cedocumentType_Ext.aspects.availableValues', [])
        const PAContingencyTypes = ['CDDC_Ext', 'CPCI_Ext', 'C_photos_physical_damage_Ext', 'contingency_grades_Ext', 'other', 'proof_of_insurance_Ext'];
        let HOContingencyTypes = ['CAC_Ext', 'CHIR_Ext', 'CPDC_Ext', 'C_HP_detached_structure_Ext', 'photos_Ext', 'other', 'proof_of_insurance_Ext'];
        let DPContingencyTypes = ['CHIR_Ext', 'CPDC_Ext', 'C_HP_detached_structure_Ext', 'photos_Ext', 'other', 'proof_of_insurance_Ext'];
        const RTContingencyTypes = ['CPSC_Ext', 'C_photos_physical_damage_Ext', 'other', 'proof_of_insurance_Ext'];
        const WTContingencyTypes = ['CPSC_Ext', 'C_photos_physical_damage_Ext', 'other', 'proof_of_insurance_Ext'];
        const PUContingencyTypes = ['other', 'proof_of_insurance_Ext'];
        if(state === "AK") {
            HOContingencyTypes = ['CAC_Ext', 'CHIR_Ext', 'CPDC_Ext', 'C_HP_detached_structure_Ext', 'C_HP_front_back_Ext', 'other', 'proof_of_insurance_Ext'];
            DPContingencyTypes = ['CHIR_Ext', 'CPDC_Ext', 'C_HP_detached_structure_Ext', 'C_HP_front_back_Ext', 'other', 'proof_of_insurance_Ext'];
        }
        const LOBCtrlTypes = _.map(documentTypeOptions, 'code');
        const resTypes = documentTypeOptions.filter(item => {
            if (LOBCtrlTypes.includes(item.code)) {   
                switch (productCode) {
                    case 'PersonalAuto': {
                        return PAContingencyTypes.includes(item.code);
                    }
                    case 'HOPHomeowners': {
                        return HOContingencyTypes.includes(item.code);
                    }
                    case 'Watercraft': {
                        return WTContingencyTypes.includes(item.code);
                    }
                    case 'RoadTrail': {
                        return RTContingencyTypes.includes(item.code);
                    }
                    case 'DwellingProperty': {
                        return DPContingencyTypes.includes(item.code);
                    }
                    case 'PersonalUmbrella': {
                        return PUContingencyTypes.includes(item.code);
                    }
                    default:{
                        return false;
                    }
                }
            }
            return true;
        });
        const availableValues = resTypes.map(item => ({
            code: item.code,
            name:  DocumentTypeNameMap[translator({id: item.name})]
        }))
        return availableValues;
    }

    useEffect(() => {
        setTableFiles(uploadFiles ? [uploadFiles] : []);
    }, [uploadFiles])

    const uploadFn = (file) => {
        updateUploadFiles(file);
    }

    const renderSize = (item, index, { path }) => {
        const num = 1000;
        return `${(item[path]/num).toFixed(2)}KB`;
    }

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: false,
            // showRequired: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        documentType: {
            availableValues: renderDocumentTypes()
        },
        fileUploadTable: {
            visible: !_.isEmpty(tableFiles),
            data: tableFiles
        },
        fileUploader: {
            singleUpload: true,
            onUpload: uploadFn
        }
        
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            uploadDocumentVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onFileUpload: handleDocumentUpload,
            renderSize
        }
    };
    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="uploadDocumentModal">
                <ViewModelForm
                    model={uploadDocumentVM}
                    overrideProps={overrideProps}
                    uiProps={metadata.componentContent}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    resolveValue={readValue}
                    onValueChange={writeValue}
                    onValidationChange={onValidate}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} type="filled">{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

UploadDocumentModal.propTypes = {
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    uploadDocumentVM: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired
};
UploadDocumentModal.defaultProps = {
    size: 'md'
};

export default UploadDocumentModal;
