import { defineMessages } from 'react-intl';

export default defineMessages({
    rtTypeOfIncident: {
        id: 'fnol.rt.views.rt-claim-type.Type of Incident',
        defaultMessage: 'Type of Incident'
    },
    rtCollision: {
        id: 'fnol.rt.views.rt-claim-type.Collision',
        defaultMessage: 'Collision'
    },
    rtTheft: {
        id: 'fnol.rt.views.rt-claim-type.Theft',
        defaultMessage: 'Theft'
    },
    rtBrokenGlass: {
        id: 'fnol.rt.views.rt-claim-type.Broken Glass',
        defaultMessage: 'Broken Glass'
    },
    rtOther: {
        id: 'fnol.rt.views.rt-claim-type.Other',
        defaultMessage: 'Other'
    },
    rtTypeOfCollisionOccured: {
        id: 'fnol.rt.views.rt-claim-type.What type of collision occurred?',
        defaultMessage: 'What type of collision occurred?'
    },
    repairFacility: {
        id: 'fnol.rt.views.rt-new-body-shop.What is your preferred repair facility?',
        defaultMessage: 'What is your preferred repair facility?',
    },
    repairFacilityName: {
        id: 'fnol.rt.views.rt-service-new-vendor.Repair Facility Name',
        defaultMessage: 'Repair Facility Name',
    },
    city: {
        id: 'fnol.common.rt.directives.policy-search-criteria.City',
        defaultMessage: 'City',
    },
    zipCode: {
        id: 'fnol.common.rt.directives.policy-search-criteria.Zip Code',
        defaultMessage: 'ZIP Code',
    },
    chooseState: {
        id: 'fnol.common.rt.directives.policy-search-criteria.Choose State.-- Choose State --',
        defaultMessage: '-- Choose State --'
    },
    taxId: {
        id: 'fnol.rt.views.rt-service-new-vendor.Tax ID',
        defaultMessage: 'Tax ID',
    },
    newVendorTitle: {
        id: 'fnol.rt.views.rt-service-choice.I’ll provide repair facility details myself',
        defaultMessage: 'I’ll provide repair facility details myself'
    },
    newVendorSubTitle: {
        id: 'fnol.rt.views.rt-service-choice.Select this option if you have your own preferred repair facility.',
        defaultMessage: 'Select this option if you have your own preferred repair facility.'
    },
    headerRepair: {
        id: 'fnol.rt.views.rt-service-choice.Do you need a repair facility?',
        defaultMessage: 'Do you need a repair facility?'
    },
    choiceSubtitle: {
        id: 'fnol.rt.views.rt-service-choice.Lifetime guarantee on all parts and labour',
        defaultMessage: 'Lifetime guarantee on all parts and labour'
    },
    noRepair: {
        id: 'fnol.rt.views.rt-service-choice.I don’t need a repair facility',
        defaultMessage: 'I don’t need a repair facility'
    },
    noRepairSubTitle: {
        id: 'fnol.rt.views.rt-service-choice.Don’t worry, you can request service later from an adjuster.',
        defaultMessage: 'Don’t worry, you can request service later from an adjuster.'
    },
    recommendRepairFacility: {
        id: 'fnol.rt.views.rt-service-choice.I want to use a recommended repair facility',
        defaultMessage: 'I want to use a recommended repair facility'
    },
    recommendedFacilityYouGet: {
        id: 'fnol.rt.views.rt-service-choice.With a recommended repair facility you get',
        defaultMessage: 'With a recommended repair facility you get:'
    },
    lifetimeGuarantee: {
        id: 'fnol.rt.views.rt-service-choice.Lifetime guarantee on all parts and labour',
        defaultMessage: 'Lifetime guarantee on all parts and labour'
    },
    discountOnOtherRepairs: {
        id: 'fnol.rt.views.rt-service-choice.10% discount on other repairs',
        defaultMessage: '10% discount on other repairs'
    },
    promptRepairService: {
        id: 'fnol.rt.views.rt-service-choice.Prompt, high quality repair service',
        defaultMessage: 'Prompt, high quality repair service'
    },
    rtCollisionDetails: {
        id: 'fnol.wizard.steps.rt.CollisionDetails',
        defaultMessage: 'Details'
    },
    rtGlassDetails: {
        id: 'fnol.wizard.steps.rt.GlassDetails',
        defaultMessage: 'Damage'
    },
    rtTheftDetails: {
        id: 'fnol.wizard.steps.rt.TheftDetails',
        defaultMessage: 'Details'
    },
    rtWhatTypeOfIncident: {
        id: 'fnol.wizard.steps.rt.Type of Incident',
        defaultMessage: 'Type of Incident'
    },
    rtVehicle: {
        id: 'fnol.wizard.steps.rt.Vehicles',
        defaultMessage: 'Vehicles'
    },
    rtClaimAboutTheVehicle: {
        id: 'fnol.rt.directives.vehicle-incident.About the Vehicle',
        defaultMessage: 'About the Vehicle'
    },
    rtClaimWhichVehicle: {
        id: 'fnol.rt.directives.vehicle-incident.Which vehicle was damaged?',
        defaultMessage: 'Which vehicle was damaged?'
    },
    rtClaimChooseVehicle: {
        id: 'fnol.rt.directives.vehicle-incident.Choose Vehicle',
        defaultMessage: 'Choose Vehicle'
    },
    rtClaimsWhoDriving: {
        id: 'fnol.rt.directives.vehicle-incident.Who was driving?',
        defaultMessage: 'Who was driving?'
    },
    rtClaimChooseDriver: {
        id: 'fnol.rt.directives.vehicle-incident.Choose Driver',
        defaultMessage: 'Choose Driver'
    },
    rtClaimVehicleSafeToDrive: {
        id: 'fnol.rt.directives.vehicle-incident.Is this vehicle safe to drive?',
        defaultMessage: 'Is this vehicle safe to drive?'
    },
    rtClaimYes: {
        id: 'fnol.rt.directives.vehicle-incident.Yes',
        defaultMessage: 'Yes'
    },
    rtClaimNo: {
        id: 'fnol.rt.directives.vehicle-incident.No',
        defaultMessage: 'No'
    },
    rtClaimDidAirbagDeploy: {
        id: 'fnol.rt.directives.vehicle-incident.Did the airbag deploy?',
        defaultMessage: 'Did the airbag deploy?'
    },
    rtClaimEquipmentFailure: {
        id: 'fnol.rt.directives.vehicle-incident.Was there equipment failure?',
        defaultMessage: 'Was there equipment failure?'
    },
    rtClaimWasVehicleTowed: {
        id: 'fnol.rt.directives.vehicle-incident.Was the vehicle towed?',
        defaultMessage: 'Was the vehicle towed?'
    },
    rtClaimWasRentalNeeded: {
        id: 'fnol.rt.directives.vehicle-incident.Is a rental car needed?',
        defaultMessage: 'Is a rental car needed?'
    },
    rtClaimPointOfImpact: {
        id: 'fnol.rt.directives.vehicle-collision.Point of Impact',
        defaultMessage: 'Point of Impact'
    },
    rtClaimPassengers: {
        id: 'fnol.rt.directives.vehicle-incident.Passengers',
        defaultMessage: 'Passengers'
    },
    rtClaimAddAPassenger: {
        id: 'fnol.rt.directives.vehicle-incident.Add a Passenger',
        defaultMessage: 'Add a Passenger'
    },
    rtClaimAnotherPerson: {
        id: 'fnol.rt.directives.vehicle-incident.Another person',
        defaultMessage: 'Another person'
    },
    rtClaimAddVehicle: {
        id: 'fnol.rt.directives.vehicle-incident.Add Another Vehicle',
        defaultMessage: 'Add Another Vehicle'
    },
    rtClaimPassengerFirstName: {
        id: 'fnol.rt.directives.vehicle-incident.First name',
        defaultMessage: 'First name'
    },
    rtClaimPassengerLastName: {
        id: 'fnol.rt.directives.vehicle-incident.Last name',
        defaultMessage: 'Last name'
    },
    rtClaimPassengerPhoneNumber: {
        id: 'fnol.rt.directives.vehicle-incident.Phone number',
        defaultMessage: 'Phone number (optional)'
    },
    rtClaimPassengerEdit: {
        id: 'fnol.rt.directives.vehicle-incident.Remove',
        defaultMessage: 'Remove'
    },
    rtClaimPassengerDelete: {
        id: 'fnol.rt.directives.vehicle-incident.Delete',
        defaultMessage: 'Delete'
    },
    wizardPARepair: {
        id: 'fnol.wizard.steps.rt.Repair',
        defaultMessage: 'Repair'
    },
    wizardPARepairFacility: {
        id: 'fnol.wizard.steps.rt.Repair Facility',
        defaultMessage: 'Repair Facility'
    },
    rtVehicleOtherDriver: {
        id: 'fnol.rt.directives.vehicle-incident.Other Driver',
        defaultMessage: 'Other Driver'
    },
    rtVehicleOtherVehicle: {
        id: 'fnol.rt.directives.vehicle-incident.Other Vehicle',
        defaultMessage: 'Other Vehicle'
    },
    rtVehicleNewVehicle: {
        id: 'fnol.rt.directives.vehicle-incident.New Vehicle',
        defaultMessage: 'New Vehicle'
    },
    rtVehicleColor: {
        id: 'fnol.rt.directives.add-new-vehicle.Vehicle Color',
        defaultMessage: 'Vehicle Color'
    },
    rtVehicleLicensePlate: {
        id: 'fnol.rt.directives.add-new-vehicle.License Plate',
        defaultMessage: 'License Plate Number'
    },
    rtVehicleMake: {
        id: 'fnol.rt.directives.add-new-vehicle.Make',
        defaultMessage: 'Make'
    },
    rtVehicleManufacturer: {
        id: 'fnol.rt.directives.add-new-vehicle.Choose Vehicle Manufacturer',
        defaultMessage: 'Choose Vehicle Manufacturer'
    },
    rtVehicleModel: {
        id: 'fnol.rt.directives.add-new-vehicle.Model',
        defaultMessage: 'Model'
    },
    rtVehicleState: {
        id: 'fnol.rt.directives.add-new-vehicle.State',
        defaultMessage: 'State'
    },
    rtVehicleChooseState: {
        id: 'fnol.rt.directives.add-new-vehicle.Choose Vehicle State',
        defaultMessage: 'Choose Vehicle State'
    },
    rtVehicleVIN: {
        id: 'fnol.rt.directives.add-new-vehicle.VIN',
        defaultMessage: 'VIN'
    },
    rtVehicleYear: {
        id: 'fnol.rt.directives.add-new-vehicle.Year',
        defaultMessage: 'Year'
    },
    rtVehicleChooseYear: {
        id: 'fnol.rt.directives.add-new-vehicle.Choose Vehicle Year',
        defaultMessage: 'Choose Vehicle Year'
    },
    rtVehicleDriverFirstName: {
        id: 'fnol.rt.directives.add-new-driver.First Name',
        defaultMessage: 'First Name'
    },
    rtVehicleDriverLastName: {
        id: 'fnol.rt.directives.add-new-driver.Last Name',
        defaultMessage: 'Last Name'
    },
    rtVehicleDriverHomePhone: {
        id: 'fnol.rt.directives.add-new-driver.Home Phone',
        defaultMessage: 'Home Phone'
    },
    rtVehicleDriverMobilePhone: {
        id: 'fnol.rt.directives.add-new-driver.Mobile Phone',
        defaultMessage: 'Mobile Phone'
    },
    rtVehicleValidPhoneNumber: {
        id: 'fnol.rt.directives.add-new-driver.Value must be a valid phone number',
        defaultMessage: 'Value must be a valid phone number'
    },
    rtCreateDraftClaim: {
        id: 'fnol.rt.config.FlowConfig.Creating personal auto draft claim...',
        defaultMessage: 'Creating personal auto draft claim...'
    },
    rtCreateDraftErrorTitle: {
        id: 'fnol.rt.config.FlowConfig.Error creating claim',
        defaultMessage: 'Error creating claim'
    },
    rtCreateDraftErrorMessage: {
        id: 'fnol.rt.config.FlowConfig.An error occurred attempting to create road & trail claim.',
        defaultMessage: 'An error occurred attempting to create road & trail claim.'
    },
    rtVehicleNewDriver: {
        id: 'fnol.rt.directives.vehicle-incident.New Driver',
        defaultMessage: 'New Driver'
    },
    rtRepairChoiceVehicleDamaged: {
        id: 'fnol.rt.views.rt-repair-choice.Which vehicle you want to repair?',
        defaultMessage: 'Which vehicle you want to repair?'
    },
    rtRepairChoiceChooseVehicle: {
        id: 'fnol.rt.views.rt-repair-choice.Choose Vehicle',
        defaultMessage: 'Choose Vehicle'
    },
    optional: {
        id: 'fnol.rt.directives.add-new-driver.optional',
        defaultMessage: 'optional'
    }
});
