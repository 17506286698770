import { defineMessages } from 'react-intl';

export default defineMessages({
    wantToChangeLine: {
        id: 'fnol.cpp.views.select-line.wantToChangeLine',
        defaultMessage: 'Change Line of Business?'
    },
    claimDataEnteredCleared: {
        id: 'fnol.cpp.views.select-line.claimDataEnteredCleared',
        defaultMessage: 'We will not save the information you have entered, do you want to continue?'
    }
});
