/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { messages, PolicyChange } from 'gw-capability-policychange-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import styles from './CEPAQuoteChangePage.module.scss';
import metadata from './CEPAQuoteChangePage.metadata.json5';
import msg from './CEPAQuoteChangePage.messages'


function CEPAQuoteChangePage(props) {
    const { wizardData, updateWizardData } = props;
    const { submissionVM } = wizardData;
    const { authenticated, authHeader } = useAuthentication();
    const [allDrivers, setAllDrivers] = useState();
    const [allVehicles, setAllVehicles] = useState();
    const { EndorsementService } = useDependencies('EndorsementService');

    const { jobID } = submissionVM.value;

    useEffect(() => {
        const setDriverNames = () => {
            const drivers = _.get(
                submissionVM.value,
                'lobData.personalAuto.coverables.drivers',
                []
            );
            const driverNames = drivers.map((driver) => driver.displayName);
            setAllDrivers(driverNames.join());
        };

        const setVehicleNames = () => {
            const vehicles = _.get(
                submissionVM.value,
                'lobData.personalAuto.coverables.vehicles',
                []
            );
            const vehicleNames = vehicles.map((vehicle) => vehicle.displayName);
            setAllVehicles(vehicleNames.join());
        };

        if (authenticated) {
            setDriverNames();
            setVehicleNames();
        }
    }, [authenticated, submissionVM, updateWizardData]);

    const resolvers = {
        resolveClassNameMap: styles
    };

    const overrideProps = {
        drivers: {
            value: allDrivers
        },
        vehicles: {
            value: allVehicles
        },
        dateId: {
            labelPosition: undefined
        },
        effectiveDateRange: {
            value: {
                startDate: _.get(submissionVM, 'baseData.periodStartDate.value'),
                endDate: _.get(submissionVM, 'baseData.periodEndDate.value')
            }
        },
        costAndPremiumPremiumDifference: {
            value: {
                "amount": _.get(submissionVM, 'totalCost.amount.value') - _.get(submissionVM, 'previousTotalCost.amount.value'),
                "currency": "usd"
            }
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [overrideProps, submissionVM]
    );

    const onNext = useCallback(async () => {
        const policyChangeResponse = await EndorsementService.submitPolicyChange(
            jobID,
            authHeader
        );
        submissionVM.value = new PolicyChange(policyChangeResponse);
        return wizardData;
    }, [wizardData]);

    return (
        <WizardPage
            onNext={onNext}
            cancelLabel={(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
            nextLabel={msg.submit}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

CEPAQuoteChangePage.propTypes = wizardProps;
export default CEPAQuoteChangePage;
