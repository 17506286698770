import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import SublinePremiumComponent from '../SublinePremiumComponent/SublinePremiumComponent';
import metadata from './SublinesPremiumComponent.metadata.json5';

function SublinesPremiumComponent(props) {
    const { 
        data: costsData
    } = props;

    const generateOverrides = useCallback(() => {
        const overrides = costsData.map((item, index) => {
            return {
                [`sublinePremiumComponent${index}`]: {
                    data: item
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [costsData]);

    const overrides = {
        sublinesPremiumContainer: {
            data: costsData
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveComponentMap: {
            sublinepremiumcomponent: SublinePremiumComponent
        }    
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={costsData}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

SublinesPremiumComponent.propTypes = {
    data: PropTypes.array.isRequired
};

export default SublinesPremiumComponent;
