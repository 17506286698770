import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FNOLPAGlassDetailsPage } from 'gwce-capability-fnol-pa-react-ext';
import React from 'react';
import { withRouter } from 'react-router-dom';

function FNOLCAGlassDetailsPage(props) {
    return <FNOLPAGlassDetailsPage {...props} />;

}

FNOLCAGlassDetailsPage.propTypes = wizardProps;
export default withRouter(FNOLCAGlassDetailsPage);
