import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';


import FNOLCAWizard from './FNOLCAWizard';
import FNOLCATypeOfIncidentPage from './pages/TypeOfIncident/FNOLCATypeOfIncidentPage';
import FNOLCATypeOfRepairPage from './pages/TypeOfRepair/FNOLCATypeOfRepairPage';
import FNOLCARepairFacilityPage from './pages/RepairFacility/FNOLCARepairFacilityPage';
import FNOLCACollisionDetailsPage from './pages/Details/Collision/FNOLCACollisionDetailsPage';
import FNOLCAGlassDetailsPage from './pages/Details/Glass/FNOLCAGlassDetailsPage';
import FNOLCATheftDetailsPage from './pages/Details/Theft/FNOLCATheftDetailsPage';
import FNOLCARepairFacilityMapPage from './pages/RepairFacilityMap/FNOLCARepairFacilityMapPage';
import FNOLCALossTypePage from './pages/LossType/FNOLCALossTypePage';
import FNOLCAIncidentsPage from './pages/Incidents/FNOLCAIncidentsPage';
import FNOLCAOtherLossDetailPage from './pages/OtherLossDetail/FNOLCAOtherLossDetailPage';

setComponentMapOverrides(
    {
        FNOLCAWizard: { component: 'FNOLCAWizard' },
        FNOLCATypeOfIncidentPage: { component: 'FNOLCATypeOfIncidentPage' },
        FNOLCATypeOfRepairPage: { component: 'FNOLCATypeOfRepairPage' },
        FNOLCARepairFacilityPage: { component: 'FNOLCARepairFacilityPage' },
        FNOLCACollisionDetailsPage: { component: 'FNOLCACollisionDetailsPage' },
        FNOLCAGlassDetailsPage: { component: 'FNOLCAGlassDetailsPage' },
        FNOLCATheftDetailsPage: { component: 'FNOLCATheftDetailsPage' },
        FNOLCARepairFacilityMapPage: { component: 'FNOLCARepairFacilityMapPage' },
        FNOLCALossTypePage: { component: 'FNOLCALossTypePage' },
        FNOLCAIncidentsPage: { component: 'FNOLCAIncidentsPage' },
        FNOLCAOtherLossDetailPage: { component: 'FNOLCAOtherLossDetailPage' }
    },
    {
        FNOLCAWizard,
        FNOLCATypeOfIncidentPage,
        FNOLCATypeOfRepairPage,
        FNOLCARepairFacilityPage,
        FNOLCACollisionDetailsPage,
        FNOLCAGlassDetailsPage,
        FNOLCATheftDetailsPage,
        FNOLCARepairFacilityMapPage,
        FNOLCALossTypePage,
        FNOLCAIncidentsPage,
        FNOLCAOtherLossDetailPage
    }
);

export {
    FNOLCAWizard as default
};
