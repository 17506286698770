import { defineMessages } from 'react-intl';

export default defineMessages({
    paDriverFirstName: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.First Name',
        defaultMessage: 'First Name',
    },
    paDriverMiddleName: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Middle Name',
        defaultMessage: 'Middle Name',
    },
    paDriverLastName: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Last Name',
        defaultMessage: 'Last Name',
    },
    paDriverDateOfBirth: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Date of Birth',
        defaultMessage: 'Date of Birth',
    },
    paDriverGender: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Gender',
        defaultMessage: 'Gender',
    },
    paDriverGenderPlaceholder: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose Driver Gender --',
        defaultMessage: '-- Choose Driver Gender --',
    },
    paDriversLicenseNumber: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.License Number',
        defaultMessage: 'License Number',
    },
    paDriverLicenceState: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.License State',
        defaultMessage: 'License State',
    },
    paDriverYearFirstLicensed: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Year First Licensed',
        defaultMessage: 'Year First Licensed',
    },
    paDriverAgeFirstLicensed: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Age First Licensed',
        defaultMessage: 'Age First Licensed',
    },
    paDriverNumberOfAccidents: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Number Of Accidents',
        defaultMessage: 'Number Of Accidents',
    },
    paDriverNumberOfViolations: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Number Of Violations',
        defaultMessage: 'Number Of Violations',
    },
    paDriverLicenseYear: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose License Year --',
        defaultMessage: '-- Choose License Year --',
    },
    paDriverLicenseState: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose License State --',
        defaultMessage: '-- Choose License State --',
    },
    driverAnyCurFinResFilingYNMsg: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.override.AnyCurFinResFilingYN',
        defaultMessage: 'Is this driver required to submit a financial responsibility filing?(SR22)'
    },
    wantToChangeYourAnswer: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.override.wantToChangeYourAnswer',
        defaultMessage: 'Want To Change Your Answer?'
    },
    connectWithAgency: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.override.connectWithAgency',
        defaultMessage: 'We will let you connect to your agency to continue this policy change, do you want to continue?'
    },
    yes: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.override.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.override.no',
        defaultMessage: 'No'
    },
    paDriverLicensed: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Licensed Driver',
        defaultMessage: 'Licensed Driver?',
    },
});
