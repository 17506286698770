import _ from 'lodash';

function hasScheduleItems(coverage) {
    return !_.isEmpty(coverage.scheduleItemTerms_Ext);
};

function getScheduleItems(coverage) {
    const items = [];
    if (hasScheduleItems(coverage)) {
        coverage.scheduleItemTerms_Ext.forEach((term) => {
            const item = {};
            for (const key in term.itemData) {
                // eslint-disable-next-line no-prototype-builtins
                if (term.itemData.hasOwnProperty(key)) {
                    _.set(item, key, term.itemData[key].stringValue);
                }
            }
            items.push(item);
        });
    }

    return items;
};

function getScheduleItemTableContent(items) {
    const content = [];
    const item = items[0];
    if (!_.isEmpty(item)) {
        for (const [key] of Object.entries(item)) {
            content.push({
                id: `${key}Column`,
                type: "element",
                component: "DisplayColumn",
                componentProps: {
                    header: key,
                    path: key,
                    textAlign: "left"
                }
            })
        }
    }
    return content;
};

export default {
    hasScheduleItems,
    getScheduleItems,
    getScheduleItemTableContent
};