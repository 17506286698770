import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { Loader, useModal, Tooltip } from '@jutro/components';
import { VehicleInfoLookupService } from 'gw-capability-vehicleinfo';
import { VehicleUtil, WindowUtil, WniFormat } from 'wni-portals-util-js';
import { CEVehicleUtil } from 'wnice-portals-util-js';
import { PolicyChange } from 'gw-capability-policychange-common-react';
import { WniVehicleInfoLookupService } from 'wni-capability-vehicleinfo';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import CEAdditionalInterestComponent from '../CEAdditionalInterest/CEAdditionalInterestComponent';
import CEVehicleDriverAssignment from '../CEVehicleDriverAssignment/CEVehicleDriverAssignment';
import CEVehicleQuestionsComponent from '../CEVehicleQuestions/CEVehicleQuestionsComponent';

import metadata from './CEVehicleComponent.metadata.json5';
import styles from './CEVehicleComponent.module.scss'
import messages from './CEVehicleComponent.messages';

function MetadataTooltip({ children, ...rest }) {
    const translator = useTranslator();
    const child = Array.isArray(children) ? children[0] : children;
    return (
        <Tooltip {...rest} content={translator(messages.atleastOneVehicle)}>
            {child}
        </Tooltip>
    );
}
MetadataTooltip.propTypes = {
    children: PropTypes.shape({}).isRequired
};

const availableVehiclePath = 'lobData.personalAuto.coverables.vehicles.children';
const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';

const initVehiclePageVars = {
    vehiclePath: '',
    selectedVehicleVM: {},
    bakSelectedVehicle: {},
    vehicleErrors: [],
    showErrors: false
};

function CEVehicleComponent(props) {
    const {
        data: vehicleVM = {},
        labelPosition,
        path,
        index,
        id,
        onValidate,
        onValueChange,
        baseRequest,
        assignmentsData, 
        vehiclePageVars,
        setVehiclePageVars,
        wizardPageData,
        wizardData,
        updateWizardData,
        WniCEPolicyChangeVehicleService,
        updateIsVmInitialized,
        disregardFieldValidation     
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const modalApi = useModal();
    const [showLoader, updateShowLoader] = useState(false);
    const [vinInvalid, updateVinInvalid] = useState(false);
    const [makeOptions, updateMakeOptions] = useState([]);
    const [modelOptions, updateModelOptions] = useState([]);

    const {
        vin, year, make, model,
        makeNotFound_Ext: makeNotFound,
        modelNotFound_Ext: modelNotFound,
        vehicleType_Ext: vehicleType,
        baseOnNull_Ext: baseOnNullExt = true,
        fixedId: vehicleFixedId,
        isStatedValueRequired_Ext: isStatedValueRequired,
        isDriverAssignmentAvailable_Ext: isDriverAssignmentAvailable
    } = vehicleVM.value;
    const { submissionVM } = wizardData;
    const {
        lobData: {
            personalAuto: {
                coverables: {
                    vehicles,
                    availableOwners_Ext: availableOwners = []
                },
                isForNamedNonOwner_Ext: isQuoteForNamedNonOwner
            }
        }
    } = submissionVM.value;
    const vehiclesList = _.get(submissionVM, vehiclesPath, []);
    const additionalInterestType = _.get(submissionVM, 'lobData.personalAuto.coverables.addInterestTypeCategory');
    const driverNameMap = VehicleUtil.getDriverNameMap(submissionVM);
    const isPPA = VehicleUtil.isPPAOnVehicle(vehicleVM);
    const { selectedVehicleVM, bakSelectedVehicle, vehiclePath, showErrors } = vehiclePageVars;

    const getVehicleAssignmentData = useCallback(() => {
        const currentAssignment = assignmentsData.find((assignment) => assignment.vehicleID === vehicleFixedId);
        return _.isEmpty(currentAssignment) ? {} : currentAssignment;
    }, [assignmentsData, vehicleFixedId]);

    const [assignmentData,  updateAssignmentData] = useState(getVehicleAssignmentData());

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const handleChange = useCallback((value, changedPath) => {
        let newValue = value;
        if (changedPath === 'vin' && !_.isEmpty(value)) {
            // BR.PL.0038 replace similar letter with number
            newValue = value.replace(/O/g, '0').replace(/I/g, '1').replace(/q/g, '9');
        }
        const fullPath = `${path}.${changedPath}`;
        if (onValueChange) {
            onValueChange(newValue, fullPath);
        }
    }, [onValueChange, path]);

    const handleBodyTypeChange = (vehicleInfo, newBodyTypeCodeRange) => {
        if (newBodyTypeCodeRange.length === 1) {
            handleChange(newBodyTypeCodeRange[0], 'bodyType_Ext');
        } else {
            const BodyTypeSelected = _.get(vehicleInfo, 'bodyTypeSelected_Ext', null);
            if (!_.isNil(BodyTypeSelected)) {
                handleChange(BodyTypeSelected, 'bodyType_Ext');
            }
        }
    };

    const reverseVinLookup = (vehicleInfo) => {
        const {
            errorCode
        } = vehicleInfo;
        const newFoundVehicle = _.isEmpty(errorCode);
        if (newFoundVehicle) {
            updateVinInvalid(false);
        } else {
            updateVinInvalid(true);
        }

        // reset Make not found and Model not found to false
        // and set Not found checkbox to invisible
        handleChange(undefined, 'makeNotFound_Ext');
        handleChange(undefined, 'modelNotFound_Ext');

        handleChange(vehicleInfo.vin, 'vin');

        // update the symbolAndModifiers_Ext from RAPA service
        const symbolAndModifiers = _.get(vehicleInfo, 'symbolAndModifiers_Ext', null);
        if (symbolAndModifiers) {
            handleChange(symbolAndModifiers, 'symbolAndModifiers_Ext');
        }

        // update cost new
        if (_.get(vehicleInfo, 'costNew_Ext.amount')) {
            handleChange(_.get(vehicleInfo, 'costNew_Ext'), 'costNew');
            _.set(vehicleVM, 'value.isCostNewVINPrefilled_Ext', true);
        }

        // set body type options
        const newBodyTypeCodeRange = _.get(vehicleInfo, 'bodyTypeCodeRange_Ext', []);
        handleBodyTypeChange(vehicleInfo, newBodyTypeCodeRange);
    };

    const handleModelBlur = async (target, valueObject) => {
        const isValueChanged = WniFormat.isValueChanged(valueObject);
        if (!isValueChanged) {
            return;
        }
        const isVehicleVMVinEmpty = _.isNil(_.get(vehicleVM, 'value.vin')) || _.isEmpty(_.get(vehicleVM, 'value.vin'));
        if (!(year && make && model) || !isPPA) {
            // any of year make model doesn't have value
            // vehicleType is not PPA
            // not call the service
            return;
        }
        updateShowLoader(true);
        const vehicleInfo = await WniVehicleInfoLookupService.autofillBasedOnDto(
            vehicleVM.value,
            authHeader
        );

        if (vehicleInfo.vin) {
            // if vin is empty and set the value directly
            if (isVehicleVMVinEmpty) {
                reverseVinLookup(vehicleInfo);
            } else if (!vinInvalid) {
                // override the vin automaticly when current is an validVin
                reverseVinLookup(vehicleInfo);
            } else {
                modalApi.showConfirm({
                    title: '',
                    message: translator(messages.vinOverrideConfirmationMessage,
                        { vin: vehicleInfo.vin }),
                    status: 'warning',
                    icon: '',
                    confirmButtonText: messages.vinOverrideYes,
                    cancelButtonText: messages.vinOverrideNo
                }).then((results) => {
                    if (results === 'cancel' || results === 'close') {
                        return _.noop();
                    }
                    reverseVinLookup(vehicleInfo);
                    return true;
                }, _.noop).finally(() => updateShowLoader(false));
            }
        } else if (_.get(vehicleInfo, 'errorCode') === '2'
            && _.get(vehicleInfo, 'errorDescription') === 'Invoke integration service failed!') {
            // vin not found show warning popup
            modalApi.showAlert({
                // title: title,
                message: messages.vinServiceNotFound,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(() => {
                _.noop();
            });
        }

        updateShowLoader(false);
    };

    const handleYearBlur = useCallback(async (target, valueObject) => {
        const isValueChanged = WniFormat.isValueChanged(valueObject);
        if (!isValueChanged) {
            if (makeOptions) {
                const codes = _.map(makeOptions, (item) => {
                    return _.get(item, 'code');
                });
                return codes;
            }
            return [];
        }
        const value = _.get(valueObject, 'value') || year;
        if (_.isNil(value) || !isPPA) {
            return [];
        }
        // try fetch data from cache
        let { yearForMakeOptions } = wizardPageData;
        let newMakes = [];
        // get the value from cache
        const cacheMakeOptions = _.get(yearForMakeOptions, `${value}.makeOptions`);
        if (!_.isEmpty(cacheMakeOptions)) {
            newMakes = cacheMakeOptions;
        } else {
            if (_.isNil(yearForMakeOptions)) {
                yearForMakeOptions = {};
            }
            // fetch from server
            updateShowLoader(true);
            const vehicleInfo = await WniVehicleInfoLookupService.lookupMakes(value, vehicleType, authHeader);
            updateShowLoader(false);
            newMakes = _.get(vehicleInfo, 'makes', []);
            // update make options to cache
            yearForMakeOptions[`${value}.makeOptions`] = newMakes;
            _.set(wizardPageData, 'yearForMakeOptions', yearForMakeOptions);
        }
        const newMakeOptions = _.map(newMakes, (item) => {
            return { code: item, name: item };
        });
        updateMakeOptions(newMakeOptions);
        _.set(vehicleVM, 'value.make', undefined);
        _.set(vehicleVM, 'value.model', undefined);
        _.set(vehicleVM, 'value.costNew', undefined);
        _.set(vehicleVM, 'value.bodyType_Ext', undefined);
        return newMakes;
    }, [authHeader, isPPA, makeOptions, vehicleType, vehicleVM, year, wizardPageData]);

    const handleMakeBlur = useCallback(async (target, valueObject) => {
        const isValueChanged = WniFormat.isValueChanged(valueObject);
        if (!isValueChanged) {
            if (modelOptions) {
                const codes = _.map(modelOptions, (item) => {
                    return _.get(item, 'code');
                });
                return codes;
            }
            return [];
        }
        const value = _.get(valueObject, 'value') || make;
        if (_.isNil(value) || !isPPA) {
            return [];
        }
        const currentYear = _.get(vehicleVM, 'value.year');
        // try fetch data from cache
        let { yearMakeForModelOptions } = wizardPageData;
        let newModels = [];
        // get the value from cache
        const cacheModelOptions = _.get(yearMakeForModelOptions, `${currentYear}.${value}.modelOptions`);
        if (!_.isEmpty(cacheModelOptions)) {
            newModels = cacheModelOptions;
        } else {
            // update make options
            if (_.isNil(yearMakeForModelOptions)) {
                yearMakeForModelOptions = {};
            }
            // fetch from server
            updateShowLoader(true);
            const vehicleInfo = await WniVehicleInfoLookupService.lookupModels(currentYear, value, authHeader);
            updateShowLoader(false);
            newModels = _.get(vehicleInfo, 'models', []);
            // update model options to cache
            yearMakeForModelOptions[`${currentYear}.${value}.modelOptions`] = newModels;
            const { yearForMakeOptions } = wizardPageData;
            // get the value from cache
            const cachedMakeOptions = _.get(yearForMakeOptions, `${currentYear}.makeOptions`);
            if (cachedMakeOptions) {
                _.set(wizardPageData, 'yearForMakeOptions', yearForMakeOptions);
                _.set(wizardPageData, 'yearMakeForModelOptions', yearMakeForModelOptions);
            } else {
                _.set(wizardPageData, 'yearMakeForModelOptions', yearMakeForModelOptions);
            }
        }
        const newModelOptions = _.map(newModels, (item) => {
            return { code: item, name: item };
        });
        updateModelOptions(newModelOptions);
        // clear model after make changed
        _.set(vehicleVM, 'value.model', undefined);
        _.set(vehicleVM, 'value.costNew', undefined);
        _.set(vehicleVM, 'value.bodyType_Ext', undefined);
        return newModels;
    }, [authHeader, isPPA, make, modelOptions, vehicleVM, wizardPageData]);

    const clearVinLookupInfo = () => {
        handleChange(undefined, 'year');
        handleChange(undefined, 'make');
        handleChange(undefined, 'model');
        handleChange(undefined, 'makeNotFound_Ext');
        handleChange(undefined, 'modelNotFound_Ext');
        handleChange(undefined, 'costNew');
        handleChange(undefined, 'bodyType_Ext');
        handleChange(undefined, 'symbolAndModifiers_Ext');
    };

    const handleVinBlur = async (target, valueObject) => {
        const isValueChanged = WniFormat.isValueChanged(valueObject); // after onBlur, if vin value change or not.
        if (!isValueChanged) {
            return;
        }
        _.set(vehicleVM, 'value.isCostNewVINPrefilled_Ext', false);
        if (_.isEmpty(vin) || !isPPA) {
            return;
        }
        if (valueObject) {
            const { beforeValue, value } = valueObject;
            if (value === beforeValue) {
                return;
            }
        }

        updateShowLoader(true);

        const vehicleInfo = await VehicleInfoLookupService.lookupVehicleInfoBasedOnVin(
            vin,
            authHeader
        );

        const {
            errorCode,
            make: vehicleMake,
            model: vehicleModel,
            year: vehicleYear,
            costNew_Ext: costNew,
            bodyTypeCodeRange_Ext: newBodyTypeCodeRange = [],
            symbolAndModifiers_Ext: symbolAndModifiers
        } = vehicleInfo;
        const newFoundVehicle = _.isEmpty(errorCode);
        if (newFoundVehicle) {
            updateVinInvalid(false);
        } else {
            updateVinInvalid(true);
        }

        // reset values to null before update
        clearVinLookupInfo();

        if (!newFoundVehicle) {
            updateShowLoader(false);
            // set make options to empty
            updateMakeOptions([]);
            // set model options to emtpy
            updateModelOptions([]);
            return;
        }

        // set year value
        handleChange(vehicleYear, 'year');
        // trigger year onBlur event to fetch make options
        await handleYearBlur(null, { value: vehicleYear, beforeValue: undefined });
        // set make value
        handleChange(vehicleMake, 'make');
        // trigger make onBlur event to fetch model options
        await handleMakeBlur(null, { value: vehicleMake, beforeValue: undefined });
        // set model value
        handleChange(vehicleModel, 'model');
        // set the RAPA symbols
        handleChange(symbolAndModifiers, 'symbolAndModifiers_Ext');
        // set body type
        handleBodyTypeChange(vehicleInfo, newBodyTypeCodeRange);
        // set cost new
        if (_.get(vehicleInfo, 'costNew_Ext.amount')) {
            handleChange(costNew, 'costNew');
            _.set(vehicleVM, 'value.isCostNewVINPrefilled_Ext', true);
        }
        updateShowLoader(false);
    };

    const searchForMakeOptions = useCallback(async (currentYear, currentMake) => {
        let { yearForMakeOptions } = wizardPageData;
        let newMakes = _.get(yearForMakeOptions, `${currentYear}.makeOptions`);
        if (_.isNil(newMakes)) {
            updateShowLoader(true);
            const vInfoForMakes = await WniVehicleInfoLookupService.lookupMakes(currentYear, vehicleType, authHeader);
            updateShowLoader(false);
            newMakes = _.get(vInfoForMakes, 'makes', []);
            // update make options
            if (_.isNil(yearForMakeOptions)) {
                yearForMakeOptions = {};
            }
            yearForMakeOptions[`${currentYear}.makeOptions`] = newMakes;
            _.set(wizardPageData, 'yearForMakeOptions', yearForMakeOptions);
        }
        let makeOptionsWithValue;
        if (currentMake) {
            makeOptionsWithValue = VehicleUtil.getMakeOptions(currentMake, newMakes);
        } else {
            makeOptionsWithValue = _.map(newMakes, (item) => {
                return { code: item, name: item };
            });
        }
        updateMakeOptions(makeOptionsWithValue);
    }, [authHeader, vehicleType, wizardPageData]);

    const searchForModelOptions = useCallback(async (currentYear, currentMake, currentModel) => {
        let { yearMakeForModelOptions } = wizardPageData;
        let newModels = _.get(yearMakeForModelOptions, `${currentYear}.${currentMake}.modelOptions`);
        if (_.isNil(newModels)) {
            updateShowLoader(true);
            const vInfoForModels = await WniVehicleInfoLookupService.lookupModels(currentYear, currentMake, authHeader);
            updateShowLoader(false);
            newModels = _.get(vInfoForModels, 'models', []);
            // update make options
            if (_.isNil(yearMakeForModelOptions)) {
                yearMakeForModelOptions = {};
            }
            yearMakeForModelOptions[`${currentYear}.${currentMake}.modelOptions`] = newModels;
            _.set(wizardPageData, 'yearMakeForModelOptions', yearMakeForModelOptions);

        }
        let modelOptionsWithValue;
        if (currentModel) {
            modelOptionsWithValue = VehicleUtil.getModelOptions(currentModel, newModels);
        } else {
            modelOptionsWithValue = _.map(newModels, (item) => {
                return { code: item, name: item };
            });
        }
        updateModelOptions(modelOptionsWithValue);
    }, [authHeader, wizardPageData]);

    const searchOptions = useCallback((searchYear, searchMake, searchModel) => {
        if (_.isEmpty(vehicleType)) {
            return;
        }
        searchForMakeOptions(searchYear, searchMake);
        searchForModelOptions(searchYear, searchMake, searchModel);
    }, [searchForMakeOptions, searchForModelOptions, vehicleType]);

    const fetchVehicleMakeModelOptions = useCallback(() => {
        // NB / Change
        // baseOnNullExt == null -> new Vehicle -> can edit
        if (makeNotFound || modelNotFound) {
            updateVinInvalid(true);
        }

        if (baseOnNullExt) {
            // initial dropdown options and call VIN service
            if (!_.isUndefined(year)) {
                searchOptions(year, make, model);
            }
        } else {
            if (make) {
                updateMakeOptions([{ code: make, name: make }]);
            }
            if (model) {
                updateModelOptions([{ code: model, name: model }]);
            }
        }
    }, [baseOnNullExt, make, makeNotFound, model, modelNotFound, searchOptions, year]);

    const yearOptions = VehicleUtil.getYearOptions();
    // hide NNO type for Day 1
    const availableVehicleTypes = VehicleUtil.getAvailableVehicleTypes(
        _.get(vehicleVM, 'vehicleType_Ext.aspects.availableValues'),
        isQuoteForNamedNonOwner,
        translator
    ).filter((option) => option.code !== 'noVehicleNamedNonOwner_Ext');

    const clearModel = (value, changedPath) => {
        // set checkbox value
        handleChange(value, changedPath);
        // reset Model to empty
        handleChange(undefined, 'model');
        // reset costNew to emtpy
        handleChange(undefined, 'costNew');
        // reset bodyType to empty
        handleChange(undefined, 'bodyType_Ext');
    };

    const clearMakeAndModel = (value, changedPath) => {
        // set checkbox value
        handleChange(value, changedPath);
        // reset Make to empty
        handleChange(undefined, 'make');
        // reset Model not found checkbox to empty
        handleChange(undefined, 'modelNotFound_Ext');
        // reset Model to empty
        clearModel(undefined, 'model');
    };

    const getVehicleTitle = useCallback(() => {
        const vehicle = vehicleVM.value;
        const isNewVehicle = vehicle.displayName === '(Newly Created)';
        if (isNewVehicle) {
            return <strong>{translator(messages.paNewVehicle)}</strong>;
        }
        let primaryDriver = '';
        if (!_.isEmpty(assignmentData) && !_.isNil(assignmentData.primaryDriverID)) {
            primaryDriver = driverNameMap[Number(assignmentData.primaryDriverID)];
        }
        if (!isQuoteForNamedNonOwner) {
            const yearMakeModel = `${_.get(vehicle, 'year')} ${_.get(vehicle, 'make')} ${_.get(vehicle, 'model')}`;
            const vinNumber = _.get(vehicle, 'vin');
            let vinLabel = '';
            if (!_.isEmpty(vinNumber) && vinNumber.length >= 4) {
                const vinLast4 = vinNumber.substring(vinNumber.length - 4, vinNumber.length);
                vinLabel = translator(messages.vinLast4Digits, {vinLast4: vinLast4});
            }
            const vinDriver = `${vinLabel} ${primaryDriver}`;
            return (
                <>
                    <strong>{yearMakeModel}</strong>&nbsp;{vinDriver}
                </>
            );
        }
        return primaryDriver;
    }, [assignmentData, driverNameMap, isQuoteForNamedNonOwner, translator, vehicleVM.value]);

    const handleRemoveVehicle = useCallback(async () => {
        const request = {
            ...baseRequest, 
            method: 'delete',
            vehiclePublicID: vehicleVM.value.publicID
        };
        updateIsVmInitialized(false);
        const response = await WniCEPolicyChangeVehicleService.updateVehicle(request, authHeader);
        submissionVM.value = new PolicyChange(response);
        disregardFieldValidation(`vehicle${index}`);
        disregardFieldValidation('vehicleQuestionsComponentId');
        updateWizardData(wizardData);
        setVehiclePageVars(initVehiclePageVars);
        updateIsVmInitialized(true);
    }, [WniCEPolicyChangeVehicleService, authHeader, baseRequest, disregardFieldValidation, index, setVehiclePageVars, submissionVM, updateIsVmInitialized, updateWizardData, vehicleVM, wizardData]);

    const removeVehicle = useCallback(() => {
        modalApi.showConfirm({
            title: messages.removeVehicleTitle,
            message: messages.removeVehicleDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.cancelModel
        })
            .then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                handleRemoveVehicle();
            }, _.noop);
    }, [handleRemoveVehicle, modalApi]);

    const editVehicle = useCallback(() => {
        const initAssignment = getVehicleAssignmentData();
        updateAssignmentData(initAssignment);
        setVehiclePageVars({
            vehiclePath: `lobData.personalAuto.coverables.vehicles.children[${index}].value`,
            selectedVehicleVM: vehicleVM,
            bakSelectedVehicle: _.cloneDeep(vehicleVM.value),
            vehicleErrors: [],
            showErrors: false
        });
        fetchVehicleMakeModelOptions();
    }, [index, setVehiclePageVars, vehicleVM, fetchVehicleMakeModelOptions, getVehicleAssignmentData]);

    const cancelVehicle = useCallback(() => {
        const isNewVehicle = vehicleVM.value.displayName === '(Newly Created)';
        if (isNewVehicle) {
            handleRemoveVehicle(vehicleVM.value, index);
        } else {
        disregardFieldValidation(`vehicle${index}`);
        disregardFieldValidation('vehicleQuestionsComponentId');
        selectedVehicleVM.value = _.cloneDeep(bakSelectedVehicle);
        updateWizardData(wizardData);
        setVehiclePageVars(initVehiclePageVars);
        }
    }, [handleRemoveVehicle, vehicleVM, selectedVehicleVM, index, disregardFieldValidation, bakSelectedVehicle, updateWizardData, wizardData, setVehiclePageVars]);

    const onChangeVehicleType = useCallback(async (value, changedPath) => {
        handleChange(value, changedPath);
        setVehiclePageVars({
            ...vehiclePageVars,
            vehicleErrors: [],
            showErrors: false
        });
        updateShowLoader(true);
        const request = {
            ...baseRequest,
            method: 'updateVehicleType',
            vehiclePublicID: vehicleVM.value.publicID,
            vehicleType: vehicleVM.value.vehicleType_Ext
        };
        const response = await WniCEPolicyChangeVehicleService.updateVehicle(request, authHeader);
        submissionVM.value = new PolicyChange(response);
        updateWizardData(wizardData);
        updateShowLoader(false);
    }, [WniCEPolicyChangeVehicleService, authHeader, baseRequest, handleChange, submissionVM, vehiclePageVars, setVehiclePageVars, updateWizardData, vehicleVM, wizardData]);

    const saveVehicle = useCallback(async () => {
        setVehiclePageVars({
            ...vehiclePageVars,
            vehicleErrors: [],
            showErrors: false
        });

        if (!CEVehicleUtil.isVehicleValid(vehicleVM, vehicles, assignmentData)) {
            const errors = CEVehicleUtil.getVehicleErrors(vehicleVM, vehicles);
            setVehiclePageVars({
                ...vehiclePageVars,
                vehicleErrors: errors,
                showErrors: true
            });
            if (!_.isEmpty(errors)) {
                WindowUtil.scrollTo('errorContainer');
            }
            return;
        }
        const request = {
                ...baseRequest, 
                method: 'update',
                vehicleToUpdate: vehicleVM.value,
                assignments: CEVehicleUtil.divideVehicleAssignmentData(assignmentData)
            };
        updateShowLoader(true);
        const response = await WniCEPolicyChangeVehicleService.updateVehicle(request, authHeader);
        submissionVM.value = new PolicyChange(response);
        updateWizardData(wizardData);
        updateShowLoader(false);
        setVehiclePageVars(initVehiclePageVars);
    }, [setVehiclePageVars, vehiclePageVars, vehicleVM, vehicles, assignmentData, baseRequest, WniCEPolicyChangeVehicleService, authHeader, submissionVM, updateWizardData, wizardData]);

    const getOwnersValue = useCallback(() => {
        const DataValues = vehicleVM.value.owners_Ext ? vehicleVM.value.owners_Ext : [];
        return DataValues.map((owner) => {
            return owner.publicID;
        });
    }, [vehicleVM]);

    const handleOwnersChange = useCallback((value) => {
        handleChange(value ? availableOwners.filter((owner) => {
            return value.indexOf(owner.publicID) > -1;
        }) : undefined, 'owners_Ext');
    }, [handleChange, availableOwners]);

    const getAvailableOwners = useCallback(() => {
        return availableOwners.map((owner) => {
            return {
                code: owner.publicID,
                name: owner.displayName
            };
        });
    }, [availableOwners]);

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition
        },
        paVehicleTitle: {
            content: getVehicleTitle()
        },
        paVehicleSectionTrashId: {
            disabled:
                vehiclesList.length === 1
                || (`${availableVehiclePath}[${index}].value` !== vehiclePath && !_.isEmpty(vehiclePath)),
            onClick: removeVehicle
        },
        paVehicleSectionEditId: {
            disabled: `${availableVehiclePath}[${index}].value` !== vehiclePath && !_.isEmpty(vehiclePath),
            visible: `${availableVehiclePath}[${index}].value` !== vehiclePath,
            onClick: editVehicle
        },
        saveBtnId: {
            onClick: saveVehicle
        },
        cancelBtnId: {
            onClick: cancelVehicle
        },
        vehicleContainer: {
            visible: `${availableVehiclePath}[${index}].value` === vehiclePath
        },
        vehicleType: {
            disabled: !baseOnNullExt,
            availableValues: availableVehicleTypes,
            onValueChange: (value, changedPath) => onChangeVehicleType(value, changedPath)
        },
        vinRelatedFieldsContainer: {
            visible: !isQuoteForNamedNonOwner
        },
        vehicleVin: {
            disabled: !baseOnNullExt,
            onBlur: handleVinBlur
        },
        vehicleYear: {
            disabled: !baseOnNullExt,
            onBlur: handleYearBlur,
            availableValues: yearOptions
        },
        makeNotFound: {
            visible: vinInvalid && isPPA,
            onValueChange: clearMakeAndModel
        },
        vehicleMake: {
            disabled: !baseOnNullExt,
            visible: !makeNotFound && isPPA,
            availableValues: makeOptions,
            onBlur: handleMakeBlur
        },
        vehicleMakeNew: {
            visible: (vinInvalid && makeNotFound) || !isPPA,
            label: isPPA ? messages.newMake : messages.make
        },
        modelNotFound: {
            visible: vinInvalid && !makeNotFound && isPPA,
            onValueChange: clearModel
        },
        vehicleModel: {
            disabled: !baseOnNullExt,
            visible: !makeNotFound && !modelNotFound && isPPA,
            availableValues: modelOptions,
            onBlur: handleModelBlur
        },
        vehicleModelNew: {
            visible: (vinInvalid && (makeNotFound || modelNotFound)) || !isPPA,
            label: isPPA ? messages.newModel : messages.model
        },
        driverLicenceState: {
            visible: !isQuoteForNamedNonOwner
        },
        noVehicleNamedNonOwned: {
            visible: isQuoteForNamedNonOwner
        },
        annualMileage: {
            visible: vehicleType !== 'utilitytrailer_Ext'
        },
        statedValue: {
            visible: isStatedValueRequired,
            required: isStatedValueRequired
        },
        vehicleOwners: {
            onValueChange: handleOwnersChange,
            availableValues: getAvailableOwners(),
            value: getOwnersValue(),
            required: true
        },
        driverAssignmentComponentId: {
            visible: isDriverAssignmentAvailable,
            driverNameMap,
            vehicleVM: vehicleVM,
            submissionVM,
            assignmentsData,
            assignmentData,
            updateAssignmentData,
            showErrors
        },
        additionalInterestComponentId: {
            additionalInterestsVM: _.get(vehicleVM, 'additionalInterests'),
            onValueChange,
            authHeader: authHeader,
            additionalInterestType,
            initialContext: {
                 AdditionalInterestType: additionalInterestType
             },
            onValidate
        },
        vehicleQuestionsComponentId: {
            model: vehicleVM,
            baseOnNullExt,
            onValueChange: handleChange,
            showErrors,
            isQuoteForNamedNonOwner,
            onValidate
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            tooltipcomponent: MetadataTooltip,
            ceadditionalinterestcomponent: CEAdditionalInterestComponent,
            cevehicledriverassigmentcomponent: CEVehicleDriverAssignment,
            cevehiclequestionscomponent: CEVehicleQuestionsComponent
        }
    };

    return showLoader ? (<Loader loaded={!showLoader} />) : (
        <ViewModelForm
            model={vehicleVM}
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleChange}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

CEVehicleComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    index: PropTypes.number,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    baseRequest: PropTypes.shape({}).isRequired,
    assignmentsData: PropTypes.array,
    vehiclePageVars: PropTypes.shape({}).isRequired,
    setVehiclePageVars: PropTypes.func.isRequired,
    wizardPageData: PropTypes.shape({}).isRequired,
    wizardData: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    WniCEPolicyChangeVehicleService: PropTypes.func.isRequired,
    updateIsVmInitialized: PropTypes.func.isRequired,
    disregardFieldValidation: PropTypes.func.isRequired
};
CEVehicleComponent.defaultProps = {
    data: {},
    labelPosition: 'left',
    phoneWide: {
        labelPosition: 'top'
    },
    assignmentsData: [],
    onValueChange: _.noop,
    setVehiclePageVars: _.noop,
    updateWizardData: _.noop,
    updateIsVmInitialized: _.noop,
    disregardFieldValidation: _.noop
};

export default CEVehicleComponent;
