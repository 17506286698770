import { defineMessages } from 'react-intl';

export default defineMessages({
    hideScheduleItems: {
        id: 'policycommon.component.policy-common-coverage-table.hide schedule items',
        defaultMessage: 'Hide Schedule Items'
    },
    showScheduleItems: {
        id: 'policycommon.component.policy-common-coverage-table.show schedule items',
        defaultMessage: 'Show Schedule Items'
    }
});
