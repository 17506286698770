import { defineMessages } from 'react-intl';

export default defineMessages({
    edit: {
        id: 'ce.endorsement.pa.CEAdditionalInterestComponent.Edit',
        defaultMessage: 'Edit'
    },
    delete: {
        id: 'ce.endorsement.pa.CEAdditionalInterestComponent.Delete',
        defaultMessage: 'Delete'
    },
    bankNameRequired: {
        id: 'ce.endorsement.pa.CEAdditionalInterestComponent.Please Specify the Bank Name.',
        defaultMessage: 'Please Specify the Bank Name.'
    },
    tooManyResult: {
        id: 'ce.endorsement.pa.CEAdditionalInterestComponent.Too Many Results',
        defaultMessage: 'The Additional Interest search returned too many results, please refine your search criteria.'
    },
    interestType: {
        id: 'ce.endorsement.pa.CEAdditionalInterestComponent.Interest Type',
        defaultMessage: 'Interest Type'
    },
    interestTypeTooltip1: {
        id: 'ce.endorsement.pa.CEAdditionalInterestComponent.Interest Type Tooltip 1',
        defaultMessage: 'Additional Insured: Select this option to add other individuals who have a financial interest in a vehicle on this policy. They will receive coverage in case of an accident or loss. Typically, these are trusts or other persons listed on the title of a vehicle.'
    },
    interestTypeTooltip2: {
        id: 'ce.endorsement.pa.CEAdditionalInterestComponent.Interest Type Tooltip 2',
        defaultMessage: 'Loss Payee: Select this option if you want to specify a party to whom the insurance company will make payment if the vehicle is damaged or lost. A common example is your auto loan institution or leasing company.'
    },
    interestTypeTooltip3: {
        id: 'ce.endorsement.pa.CEAdditionalInterestComponent.Interest Type Tooltip 3',
        defaultMessage: 'Additional Insured / Loss Payee: This option combines both additional insureds and loss payee. By selecting this option, this person or institution can receive coverage and payment in the event of an accident, loss, or liability dispute.'
    }
});
