import { defineMessages } from 'react-intl';

export default defineMessages({
    noTaxAndSurcharge: {
        id: 'premiumreport.wc.page.premiums.N/A',
        defaultMessage: 'N/A'
    },
    cancelBtn: {
        id: 'premiumreport.wc.page.premiums.Cancel',
        defaultMessage: 'Cancel'
    },
    previousBtn: {
        id: 'premiumreport.wc.page.premiums.Previous',
        defaultMessage: 'Previous'
    },
    editBtn: {
        id: 'premiumreport.wc.page.premiums.Edit',
        defaultMessage: 'Edit'
    },
    saveBtn: {
        id: 'premiumreport.wc.page.premiums.Save',
        defaultMessage: 'Save'
    },
    submitBtn: {
        id: 'premiumreport.wc.page.premiums.Submit',
        defaultMessage: 'Submit'
    }
});
