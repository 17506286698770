import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { withRouter } from 'react-router-dom';
import { ProductUtil } from 'wnice-portals-util-react';
import { WniDateUtil } from 'wni-portals-util-js';
import { Loader, InfoLabel, useModal } from '@jutro/components';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { WniPremiumReportService } from 'wnice-capability-premiumreport';
import { WniDocumentRetrievalService } from 'wni-capability-gateway';
import metadata from './PremiumReportsPage.metadata.json5';
import messages from './PremiumReportsPage.messages';
import styles from './PremiumReportsPage.module.scss';

import { Link } from '@jutro/router';

const GL_CP_PRODUCTS = ['GL7GeneralLiability', 'CommercialPackage'];

function PremiumReportsPage(props) {
    const { authHeader, history } = props;
    const translator = useTranslator();
    const modalApi = useModal();
    const [isLoadingData, updateIsLoadingData] = useState(true);
    const [premiumReportsData, updatePremiumReportsData] = useState([]);

    const getPolicyTypeCell = (item) => {
        const { productCode } = item;
        const productName = ProductUtil.getProductName(productCode);
        return productName;
    };

    const getPolicyNumberCell = (item) => {
        const { policyNumber } = item;
        return <Link to={`/account-policy-details/${policyNumber}`}>{policyNumber}</Link>
    };

    const getReportPeriodCell = (item, index, property) => {
        let { startDate, endDate } = item;
        if (property.id === 'reportAvailAndDueDate') {
            startDate = item.initDate;
            endDate = item.dueDate;
        }
        startDate = WniDateUtil.formatDateWithPattern(startDate, 'MM/DD/YYYY');
        endDate = WniDateUtil.formatDateWithPattern(endDate, 'MM/DD/YYYY');
        return `${startDate} - ${endDate}`;
    };

    const getReportStatusCell = (item) => {
        const { reportStatus } = item;
        if (reportStatus === 'Available') {
            return <InfoLabel type="success" size="medium" message={reportStatus} />;
        }
        if (reportStatus === 'Past Due') {
            return <InfoLabel type="error" size="medium" message={reportStatus} />;
        }  
        return <InfoLabel type="neutral" size="medium" message={reportStatus} />;       
    };

    const clickReportAction = useCallback((jobNumber, item) => {
        const { productCode } = item;
        if (productCode === 'WCMWorkersComp') {
            history.push('/premiumreport-wc', {
                jobNumber: jobNumber
            });
        }
        if (GL_CP_PRODUCTS.includes(productCode)) {
            history.push('/premiumreport-gl', {
                jobNumber: jobNumber
            });
        }
    }, [history]);

    const getReportActionCell = (item) => {
        const { reportNumber, reportStatus } = item;
        if (reportStatus === 'Planned') {
            return '';
        }
        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link onClick={() => clickReportAction(reportNumber, item)}>
                {reportStatus === 'Submitted' ? reportNumber : translator(messages.startReport)}
            </Link>
        )
    };

    const clickForInstructions = useCallback(async () => {
        const periodPublicID = await WniPremiumReportService.getLatestPAYGPolicyPeriodPublicID(authHeader);
        updateIsLoadingData(true);
        const publicID = 'paygInstructions';
        const filename = 'PAY AS YOU GO REPORTING INSTRUCTIONS';
        const successCallback = () => {
            updateIsLoadingData(false);
        };
        const errorCallback = () => {
            updateIsLoadingData(false);
            modalApi.showConfirm({
                title: 'Error',
                message: translator(messages.anErrorOccurred),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: messages.ok,
            }).then(_.noop).catch(_.noop);
        };
        await WniDocumentRetrievalService.getOnDemandPAYGDocument(
            publicID,
            periodPublicID,
            filename,
            authHeader,
            successCallback,
            errorCallback
        );
    }, [authHeader, modalApi, translator]);

    const sortPremiumReports = useCallback((response) => {
        let availableReports = _.filter(response, (res) => res.reportStatus !== 'Planned');
        if (!_.isEmpty(availableReports)) {
            availableReports = availableReports.sort((a, b) => {
                if (a.statusPriority !== b.statusPriority) {
                    return a.statusPriority - b.statusPriority;
                }
                return new Date(b.initDate.year, b.initDate.month, b.initDate.day) - new Date(a.initDate.year, a.initDate.month, a.initDate.day);

            });
        } else {
            availableReports = [];
        }

        let plannedReports = _.filter(response, (res) => res.reportStatus === 'Planned');
        if (!_.isEmpty(plannedReports)) {
            plannedReports = plannedReports.sort((a, b) => {
                return new Date(a.initDate.year, a.initDate.month, a.initDate.day) - new Date(b.initDate.year, b.initDate.month, b.initDate.day);
            });
        } else {
            plannedReports = [];
        }
        
        return availableReports.concat(plannedReports);
    }, []);

    useEffect(() => {
        updateIsLoadingData(true);
        WniPremiumReportService.getPremiumReports(authHeader)
            .then((response) => {
                const premiumReports = sortPremiumReports(response);
                updatePremiumReportsData(premiumReports);
            }, _.noop)
            .finally(() => {
                updateIsLoadingData(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoadingData) {
        return <Loader loaded={!isLoadingData} />;
    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            },
        },
        premiumReportTableID: {
            data: premiumReportsData
        }
    };


    const resolvers = {
        resolveCallbackMap: {
            getPolicyTypeCell,
            getPolicyNumberCell,
            getReportPeriodCell,
            getReportStatusCell,
            getReportActionCell,
            clickForInstructions
        },
        resolveClassNameMap: styles
    };

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

PremiumReportsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({}).isRequired
};

export default withAuthenticationContext(withRouter(PremiumReportsPage));
