import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FNOLPAOtherLossDetailPage } from 'gwce-capability-fnol-pa-react-ext';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

function FNOLRTOtherLossDetailPage(props) {
    return <FNOLPAOtherLossDetailPage {...props}/>;
} 

FNOLRTOtherLossDetailPage.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
    }).isRequired,
};

FNOLRTOtherLossDetailPage.propTypes = wizardProps;
export default withRouter(FNOLRTOtherLossDetailPage);