import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { getPoliciesTableData } from '../PoliciesTable/PoliciesTable'

import metadata from './PoliciesSection.metadata.json5';
import styles from './PoliciesSection.module.scss';

function PoliciesSection(props) {
    const { policySummaries = [], policyNumbers = [], forAutoPay } = props;
    const translator = useTranslator();

    const overrides = useMemo(() => {
        const policyTableData = getPoliciesTableData(policySummaries, policyNumbers, translator)
        const policyOverrides = _.cloneDeep(policyTableData)
            .reduce((partial, policy, index) => ({
                ...partial,
                [`policyType${index}`]: {
                    content: _.get(policy, 'productCodes')
                },
                [`policyNumber${index}`]: {
                    content: _.get(policy, 'policyNumber')
                }
            }), {});
        return {
            ...policyOverrides,
            policiesSectionLabelText: {
                visible: !forAutoPay
            },
            policiesSectionLabelTextAutoPay: {
                visible: forAutoPay
            },
            relatePolicyDataContainer: {
                data: policyTableData,
                className: classNames(styles.policyTable, forAutoPay ? styles.autoPayPolicies : '')
            },
        };
    }, [forAutoPay, policyNumbers, policySummaries, translator]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {}
    };

    const data = {
        policySummaries: getPoliciesTableData(policySummaries, policyNumbers, translator)
    };

    return (
        <ViewModelForm
            model={data}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PoliciesSection.propTypes = {
    policySummaries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    policyNumbers: PropTypes.array.isRequired,
    forAutoPay: PropTypes.bool
};

PoliciesSection.defaultProps = {
    forAutoPay: false
};

export default PoliciesSection;

