import React, {
    useMemo,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './PAYGEmailsComponent.metadata.json5';
import styles from './PAYGEmailsComponent.module.scss';


function PAYGEmailsComponent(props) {
    const {
        id,
        index,
        isReadOnly,
        accountPAYGDetailsVM,
        handlePAYGEmailChange,
        onValidate
    } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const generatedProps = useMemo(() => {
        const overrides = accountPAYGDetailsVM.map((vm, dIndex) => {
            const publicID = _.get(vm, 'contactVM.publicID.value');
            return {
                [`paygEmail${dIndex}`]: {
                    readOnly: isReadOnly(index),
                    label: vm.emailLabel,
                    onValueChange: (value) => handlePAYGEmailChange(value, publicID)
                }
            }
        });
        return Object.assign({}, ...overrides);
    }, [accountPAYGDetailsVM, isReadOnly, index, handlePAYGEmailChange]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            phonewide: {
                labelPosition: 'top',
            },
            phone: {
                labelPosition: 'top',
            }
        },
        paygEmailIterable: {
            data: accountPAYGDetailsVM
        },
        ...generatedProps
    };

    if (!accountPAYGDetailsVM) {
        return null;
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={accountPAYGDetailsVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            classNameMap={styles}
        />
    );
}

PAYGEmailsComponent.propTypes = {
    id: PropTypes.string.isRequired,
    index: PropTypes.func.isRequired,
    isReadOnly: PropTypes.func.isRequired,
    accountPAYGDetailsVM: PropTypes.array.isRequired,
    handlePAYGEmailChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired
};

PAYGEmailsComponent.defaultProps = {
    handlePAYGEmailChange: _.noop
};

export default PAYGEmailsComponent;
