import { defineMessages } from 'react-intl';

export default defineMessages({
    mailingAddress: {
        id: 'endorsement.pa.directives.templates.pa-address-change-edit.Mailing Address',
        defaultMessage: 'Mailing Address',
    },
    address: {
        id: 'endorsement.pa.directives.templates.pa-address-change-edit.Address',
        defaultMessage: 'Address',
    },
    garageAddress: {
        id: 'endorsement.common.views.change-address.Garage Address Label',
        defaultMessage: 'Apply this address as the garaging address for all the vehicles?',
    },
    toggleYes: {
        id: 'endorsement.common.views.Yes',
        defaultMessage: 'Yes',
    },
    toggleNo: {
        id: 'endorsement.common.views.No',
        defaultMessage: 'No',
    },
    wantToSeparateAddress: {
        id: 'endorsement.common.views.change-address.Are you sure you want separate addresses?',
        defaultMessage: 'Are you sure you want separate addresses?',
    },
    confirmNotLinkMailAddress: {
        id: 'endorsement.common.views.change-address.By unchecking the box, your updated mailing address will not match the address of where your vehicles are kept. Do you want to continue?',
        defaultMessage: 'By unchecking the box, your updated mailing address will not match the address of where your vehicles are kept. Do you want to continue?',
    },
    postalCode: {
        id: 'endorsement.pa.directives.templates.pa-address-change-edit.ZIP Code',
        defaultMessage: 'ZIP Code',
    },
    
});
