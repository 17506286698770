import { defineMessages } from 'react-intl';

export default defineMessages({
    removeDocument: {
        id: 'policy.common.component.documents.Remove Document',
        defaultMessage: 'Remove Document'
    },
    confirmRemoveDocument: {
        id: 'policy.common.component.documents.Are you sure you want to remove',
        defaultMessage: 'Are you sure you want to remove'
    },
    documentRemoveConfirmYes: {
        id: 'policy.common.component.documents.Yes',
        defaultMessage: 'Yes'
    },
    documentRemoveConfirmNo: {
        id: 'policy.common.component.documents.No',
        defaultMessage: 'No'
    },
    documentDeletionFailed: {
        id: 'policy.common.component.documents.Document deletion failed',
        defaultMessage: 'Document deletion failed'
    },
    documentCannotDeleted: {
        id: 'policy.common.component.documents.Sorry your document could not be deleted at this time',
        defaultMessage: 'Sorry your document could not be deleted at this time'
    },
    documentDateUploaded: {
        id: 'policy.common.component.documents.Date Uploaded',
        defaultMessage: 'Uploaded'
    },
    documentUploadFailed: {
        id: 'policy.common.component.documents.Document upload failed',
        defaultMessage: 'Document upload failed'
    },
    documentCannotUploaded: {
        id: 'policy.common.component.documents.Failed to upload file, either you do not have permission or a file exists with the same name.',
        defaultMessage: 'Failed to upload file, either you do not have permission or a file exists with the same name.'
    },
    noDocumentFound: {
        id: 'policy.common.component.documents.No Documents Found.',
        defaultMessage: 'No Documents Found.'
    },
    uploadedDocuments: {
        id: 'policy.common.component.documents.Upload Documents',
        defaultMessage: 'Uploaded Documents'
    },
    policyDocuments: {
        id: 'policy.common.component.documents.Policy Document',
        defaultMessage: 'Policy Documents'
    },
    uploadPopupTitle: {
        id: 'policy.common.component.documents.Are you sure you want to upload?',
        defaultMessage: 'Are you sure you want to upload?'
    },
    uploadPopupMessage: {
        id: 'policy.common.component.documents.Please note that once uploaded, the document cannot be deleted. Would you like to continue?',
        defaultMessage: 'Please note that once uploaded, the document cannot be deleted. \n Would you like to continue?'
    },
    modalCancelText: {
        id: 'policy.common.component.documents.Cancel',
        defaultMessage: 'Cancel'
    },
    UploadDocumentTitle: {
        id: 'policy.common.component.documents.UploadDocumentTitle',
        defaultMessage: 'Upload Document'
    },
    uploadModalOk: {
        id: 'policy.common.component.documents.uploadModalOk',
        defaultMessage: 'Save'
    }
});
