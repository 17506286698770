import _ from 'lodash';

const PREMISES_PRODUCTSOPS_SUBLINE = 'Premises/Operations and Products/Completed Operations';

const getExposureInputDTOs = (premiumReportDetails) => {
    const auditedBasisDTOs = [];
    premiumReportDetails.forEach((prd) => {
        const { sublines } = prd;
        sublines.forEach((sl) => {
            const { exposures } = sl;
            exposures.forEach((exp) => {
                const auditedBasisDTO = {
                    publicID: exp.publicID,
                    actual: exp.actual,
                    premisesOperationsActual: exp.premisesOperationsActual,
                    prodsCompltdOpsActual: exp.prodsCompltdOpsActual
                };
                auditedBasisDTOs.push(auditedBasisDTO);
            })
        })
    })
    return auditedBasisDTOs;
};

const showProdsCompltdOpsActual = (items) => {
    return _.some(items, (item) => item.canEditProdsCompltdOpsActual);
};

const getCostsData = (premiumItems) => {
    const costsDataBySubline = [];
    const premiumItemsBySubline = _.groupBy(premiumItems, 'sublineName');
    Object.entries(premiumItemsBySubline).forEach(([key, value]) => {
        const costsData = {
            sublineName: key,
            premiumsData: _.sortBy(value, ['locationDesc', 'classCode', 'classCodeDesc'])
        };
        costsDataBySubline.push(costsData);
    });
    const sortedCostsData = _.sortBy(costsDataBySubline, ['sublineName']);
    return sortedCostsData;
};

const inputHasError = (premiumReportDetails) => {
    let flag = false;
    premiumReportDetails.forEach((prd) => {
        const { sublines } = prd;
        sublines.forEach((sl) => {
            const { exposures } = sl;
            exposures.forEach((exp) => {
                const isPremisesProductsOpsSubline = exp.sublineTypeName === PREMISES_PRODUCTSOPS_SUBLINE;
                if (isPremisesProductsOpsSubline) {
                    const isProdsCompltdOpsActualRequired = exp.canEditProdsCompltdOpsActual
                    if (_.isNil(exp.premisesOperationsActual) || (_.isNil(exp.prodsCompltdOpsActual) && isProdsCompltdOpsActualRequired)) {
                        flag = true;
                    }
                } else if (_.isNil(exp.actual)) {
                    flag = true;
                }
            })
        })
    })
    return flag;
};

export {
    PREMISES_PRODUCTSOPS_SUBLINE,
	getExposureInputDTOs,
    getCostsData,
    showProdsCompltdOpsActual,
    inputHasError
};