import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { ProductUtil } from 'wnice-portals-util-react';
import { useTranslator } from '@jutro/locale';

import styles from './PremiumReportWizardPageHeader.module.scss';

const PremiumReportWizardPageHeader = (props) => {
    const translator = useTranslator();
    const [productType, setProductType] = useState('');
    const [policyNumber, setPolicyNumber] = useState('');
    const {
        wizardData, isSideHeader
    } = props;

    useEffect(() => {
        const productCode = _.get(wizardData.value, 'productCode');
        const productName = ProductUtil.getProductName(productCode);
        setProductType(productName);
        setPolicyNumber(_.get(wizardData.value, 'policyNumber'));
    }, [translator, wizardData]);

    const titleContainerClass = cx({
        [styles.gwWizardPageTitle]: !isSideHeader,
        [styles.gwWizardSidePageTitle]: isSideHeader
    });

    const premiumReportHeader = (
        <div className={titleContainerClass}>
            <h1 className={cx(styles.wizardPageHeader, { [styles.gwWizardSidepageTitle]: isSideHeader })}>
                {`${productType} (${policyNumber})`}
            </h1>
        </div>
    );

    return premiumReportHeader;
};

PremiumReportWizardPageHeader.propTypes = {
    wizardData: PropTypes.shape({
        value: PropTypes.shape({}),
        _dtoName: PropTypes.string
    }).isRequired,
    isSideHeader: PropTypes.bool,
    displayWizardHeader: PropTypes.bool
};

PremiumReportWizardPageHeader.defaultProps = {
    isSideHeader: false,
    displayWizardHeader: true
};

export default PremiumReportWizardPageHeader;
