import React, { useCallback, useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { Claim, fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { ProductUtil } from 'wnice-portals-util-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import metadata from './FNOLCPPSelectLinePage.metadata.json5';
import messages from '../../FNOLCPP.messages';

function FNOLCPPSelectLinePage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        authHeader
    } = props;
    const translator = useTranslator();
    const modalApi = useModal();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const { FNOLService } = useDependencies('FNOLService');
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLCPPSelectLinePage');

    const {
        lossDate,
        claimNumber,
        policy: {
            policyNumber,
            lobCode_Ext: lobCode
        }
    } = claimVM.value;

    const [lineSelected, setLineSelected] = useState(lobCode);
    const [availableLines, setAvailableLines] = useState([]);

    const hasLineChanged = useCallback(() => {
        const previousLine = _.get(claimVM.value, 'policy.lobCode_Ext');
        return previousLine !== lineSelected;
    }, [claimVM, lineSelected]);

    const isSelectedLine = useCallback(() => {
        return !_.isEmpty(lineSelected);
    }, [lineSelected]);

    const getLinesDropdown = useCallback((lobCodes) => {
        return _.map(lobCodes, (code) => {
            let lineName = translator({
                id: `typekey.LOBCode.${code}`,
                defaultMessage: `typekey.LOBCode.${code}`
            });
            const index = lineName.lastIndexOf(' Line');
            if (index !== -1) {
                lineName = lineName.substring(0, index);
            }
            return {
                code: code,
                name: lineName
            };
        });
    }, [translator]);

    const getCPPPoliciesFromService = useCallback(() => {
        const policySearchCriteria = {
            policyNumber: policyNumber,
            lossDate: lossDate,
            policyType: ProductUtil.CPP_PRODUCT_CODE
        };
        setLoadingMask(true);
        return FNOLService.searchPolicies(policySearchCriteria, authHeader).then(
            (response) => {
                setLoadingMask(false);
                const lobCodes = _.map(response, (policy) => policy.lobCode_Ext);
                const lines = getLinesDropdown(lobCodes);
                setAvailableLines(lines);
                const newClaimVM = viewModelService.clone(claimVM);
                _.set(newClaimVM.value, 'policies', response);
                updateWizardData(newClaimVM);
            }
        );
    }, [FNOLService, authHeader, claimVM, getLinesDropdown, lossDate, policyNumber, setLoadingMask, updateWizardData, viewModelService]);

    useEffect(() => {
        registerInitialComponentValidation(isSelectedLine);
    }, [isSelectedLine, registerInitialComponentValidation]);

    useEffect(() => {
        const cppPolicies = _.get(claimVM.value, 'policies');
        if (_.isEmpty(cppPolicies)) {
            getCPPPoliciesFromService();
        } else {
            const lobCodes = _.map(cppPolicies, (policy) => policy.lobCode_Ext);
            const lines = getLinesDropdown(lobCodes);
            setAvailableLines(lines);
            const newClaimVM = viewModelService.clone(claimVM);
            _.set(newClaimVM.value, 'policies', cppPolicies);
            updateWizardData(newClaimVM);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(() => {
        const previousLine = _.get(claimVM.value, 'policy.lobCode_Ext');
        const cppPolicies = _.get(claimVM.value, 'policies');
        const policySelected = _.find(cppPolicies, (pol) => pol.lobCode_Ext === lineSelected);
        // line changed
        if (hasLineChanged()) {
            const claimData = {
                lossDate: lossDate,
                policyNumber: policyNumber,
                policy: policySelected,
                policies: cppPolicies
            };
            const claimValue = new Claim(claimData);
            const newClaimVM = viewModelService.create(
                claimValue,
                'cc',
                'wni.edge.capabilities.claim.fnol.dto.WniFnolDTO'
            );
            if (claimNumber) {
                return modalApi.showConfirm({
                    title: messages.wantToChangeLine,
                    message: messages.claimDataEnteredCleared,
                    status: 'warning',
                    icon: 'gw-error-outline'
                }).then((result) => {
                    if (result === 'cancel' || result === 'close') {
                        setLineSelected(previousLine);
                        return false;
                    }
                    FNOLService.cancelClaim(claimNumber, authHeader);
                    updateWizardData(newClaimVM);
                    return newClaimVM;
                }, _.noop);
            }
            updateWizardData(newClaimVM);
            return newClaimVM;
        }
        return claimVM;
    }, [FNOLService, authHeader, claimNumber, claimVM, hasLineChanged, lineSelected, lossDate, modalApi, policyNumber, updateWizardData, viewModelService]);

    const overrides = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        selectLine: {
            value: lineSelected,
            availableValues: availableLines,
            onValueChange: (val) => setLineSelected(val)
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        }
    };

    return (    
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

FNOLCPPSelectLinePage.propTypes = wizardProps;
export default withRouter(withAuthenticationContext(FNOLCPPSelectLinePage));
