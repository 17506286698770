import { defineMessages } from 'react-intl';

export default defineMessages({
    billingSummaryTabs: {
        id: 'billing.billing-summary-page.Billing Summary',
        defaultMessage: 'Billing Summary'
    },
    billingSummaryTip: {
        id: 'billing.billing-summary-page.Billing Summary Tip',
        defaultMessage: 'Click on a billing account tab for details.'
    },
    failedToLoadPolices: {
        id: 'billing.billing-summary.Failed to load polices',
        defaultMessage: 'Failed to load polices'
    },
    sorryWeCanNotLoadYourPolicyInformationAtThisTime: {
        id: 'billing.billing-summary.Sorry we can not load your policy information at this time',
        defaultMessage: 'Sorry we can not load your policy information at this time'
    },
    noBillingData: {
        id: 'billing.billing-summary.no billing data',
        defaultMessage: 'No Billing Data Available at this time'
    }
});
