import React, {
    useCallback, useEffect, useMemo, useContext, useState
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { PolicyService } from 'gw-capability-policy';
import { WniDocumentRetrievalService } from 'wni-capability-gateway';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import FileUploadViewerComponent from './FileUploadViewerComponent/FileUploadViewerComponent';
import FileUploadTableComponent from './FileUploadTableComponent/FileUploadTableComponent';
import metadata from './PolicyCommonDocuments.metadata.json5';
import messages from './PolicyCommonDocuments.messages';
import styles from './PolicyCommonDocuments.module.scss';
import UploadDocumentModal from './UploadDocumentModal/UploadDocumentModal';

function PolicyCommonDocuments(props) {
    const modalApi = useModal();
    const {
        value: { documentDTOs: policyCommonDocuments, policyNumber, canUploadDocument, 
            productCode_Ext: productCode, state_Ext: state, displayStatus_Ext: displayStatus },
        writableDocuments,
        updateWritableDocuments
    } = props;
    let {
        viewModelService,
    } = props;
    if (_.isNil(viewModelService)) {
        viewModelService = useContext(ViewModelServiceContext);
    }
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader } = useAuthentication();
    const translator = useTranslator();

    const [popupUploadDocumentVM, updatepopupUploadDocumentVM] = useState(null);

    const createVM = useCallback(
        (model) => {
            try {
                return viewModelService.create(
                    model,
                    'pc',
                    'edge.capabilities.gateway.document.dto.DocumentDTO'
                );
            } catch (error) {
                _.noop()
            }
        },
        [viewModelService]
    );

    const publicDocuments = useMemo(() => {
        return _.filter(policyCommonDocuments, (document) => {
            return !document.isAuthor;
        });
    }, [policyCommonDocuments]);

    useEffect(() => {
        const writableDoc = _.filter(policyCommonDocuments, (document) => {
            return document.isAuthor;
        });
        updateWritableDocuments(writableDoc);
        const model = {};
        const nextSubmissionVM = createVM(model);
        updatepopupUploadDocumentVM(nextSubmissionVM);
    }, [policyCommonDocuments]);

    const showModal = useCallback(
        (getPopupUploadDocumentVM) => {
            const componentProps = {
                title: translator(messages.UploadDocumentTitle),
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                actionBtnLabel: translator(messages.uploadModalOk),
                cancelBtnLabel: translator(messages.modalCancelText),
                uploadDocumentVM: getPopupUploadDocumentVM,
                viewModelService: viewModelService,
                breakpoint,
                productCode,
                state,
                size: 'md'
            };
            return modalApi.showModal(
                <UploadDocumentModal {...componentProps} />
            )
        },
        [translator, viewModelService]
    );

    const openUploadDocumentModal = () => {
        showModal(popupUploadDocumentVM).then(async(res) => {
            await uploadDocument(res.file, res.vm);
        }).catch(() => _.noop).finally(() => {
            // repopupDocumentsPage()
        });
    }

    const handleUploadDocumentModal = () => {
        modalApi.showConfirm({
            title: messages.uploadPopupTitle,
            message: messages.uploadPopupMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.documentRemoveConfirmYes,
            cancelButtonText: messages.modalCancelText
        }).then(async (res) => {
            if(res === 'confirm') {
                await openUploadDocumentModal();
            }
        }, _.noop);
    }

    const claimsDocUploadToken = useCallback(async () => {
        try {
            const uploadTokenID = await PolicyService.getDocumentUploadToken([], authHeader);
            return uploadTokenID;
        } catch (e) {
            return modalApi.showAlert({
                title: commonMessages.errorUploadTitle,
                message: commonMessages.errorGenerateUploadToken,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        }
    }, [authHeader]);

    const deleteDocument = useCallback(
        (item) => {
            if (!item.canDelete) {
                return;
            }

            const { publicID } = item;
            modalApi.showConfirm({
                title: messages.removeDocument,
                message: messages.confirmRemoveDocument,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.documentRemoveConfirmYes,
                cancelButtonText: messages.documentRemoveConfirmNo
            }).then(async (results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                try {
                    const isDocumentDeleted = await PolicyService.removeDocument(
                        publicID,
                        authHeader
                    );
                    if (isDocumentDeleted) {
                        const newDocuments = _.filter(writableDocuments, (doc) => {
                            return doc.workingPublicID !== publicID;
                        });
                        updateWritableDocuments(newDocuments);
                    }
                } catch (documentDeletionError) {
                    modalApi.showAlert({
                        title: messages.documentDeletionFailed,
                        message: messages.documentCannotDeleted,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                }
                return true;
            }, _.noop);
        },
        [authHeader, modalApi, updateWritableDocuments, writableDocuments]
    );

    const getDocumentDownloadLink = useCallback(
        (event, item) => {
            event.preventDefault();
            const { publicID, name } = item;
            return WniDocumentRetrievalService.getOnDemandDocument(publicID, name, authHeader)
        },
        []
    );

    const uploadDocument = useCallback(
        async (item, vm) => {
            const documentMetaDataTemplate = {
                docUID: '001',
                CEDocumentType_Ext: _.get(vm, 'value.cedocumentType_Ext'),
                securityType_Ext: 'policyholder_Ext',
                status: 'approved',
                policyNumber: policyNumber,
                Name: item.name,
                mimeType: item.type,
                SessionID: await claimsDocUploadToken(),
                IsCEPolicy_Ext: true
            };
            try {
                const recentUploadedDoc = await PolicyService.uploadDocument(
                    item,
                    documentMetaDataTemplate,
                    authHeader
                );
                if (!_.isEmpty(recentUploadedDoc)) {
                    updateWritableDocuments((docs) => [...docs, recentUploadedDoc]);
                }
            } catch (error) {
                modalApi.showAlert({
                    title: messages.documentUploadFailed,
                    message: messages.documentCannotUploaded,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            }
        },
        [authHeader, claimsDocUploadToken, policyNumber]
    );

    const overrides = {
        policyDocuments: {
            visible: !_.isEmpty(publicDocuments)
        },
        readOnlyDocumentFileUploadComponent: {
            data: {
                isReadOnly: true,
                documents: publicDocuments
            },
            getDownloadLink: getDocumentDownloadLink
        },
        writableFileUploadTableContainer: {
            data: {
                isReadOnly: !canUploadDocument,
                documents: writableDocuments
            },
            getDownloadLink: getDocumentDownloadLink
        },
        uploadFileButton: {
            visible: displayStatus !== 'Canceled' && displayStatus !== 'Expired'
        }
    };

    const resolvers = {
        resolveComponentMap: {
            documentscomponent: FileUploadViewerComponent,
            fileuploadTablecomponent: FileUploadTableComponent
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleUploadDocumentModal,
            onDocumentDelete: deleteDocument,
            // onDocumentUpload: uploadDocument
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                policyCommonDocuments,
                id,
                path,
                overrides
            );
        },
        [policyCommonDocuments, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyCommonDocuments}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

PolicyCommonDocuments.propTypes = {
    value: PropTypes.shape({
        documentDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        policyNumber: PropTypes.string,
        canUploadDocument: PropTypes.bool
    }),
    writableDocuments: PropTypes.array.isRequired,
    updateWritableDocuments: PropTypes.func.isRequired
};

PolicyCommonDocuments.defaultProps = {
    value: {
        documentDTOs: [],
        policyNumber: '',
        canUploadDocument: false
    }
};

export default withRouter(PolicyCommonDocuments);
