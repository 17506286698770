import { defineMessages } from 'react-intl';

export default defineMessages({
    minimumAmountToPay: {
        id: 'billing.amount-selection.Minimum Amount',
        defaultMessage: 'Minimum Amount'
    },
    invoiceBalanceToPay: {
        id: 'billing.amount-selection.Account Balance',
        defaultMessage: 'Account Balance'
    },
    currentDueToPay: {
        id: 'billing.amount-selection.Current Due Total',
        defaultMessage: 'Current Due Total'
    },
    fullPayAamount: {
        id: 'billing.amount-selection.Minimum Amount With Full Pay Discount',
        defaultMessage: 'Minimum Amount With Full Pay Discount'
    },
    fullPayDiscount: {
        id: 'billing.amount-selection.Save ${amount} on your Auto Policy',
        defaultMessage: 'Save ${amount} on your Auto Policy'
    },
    fullPayHelpText: {
        id: 'billing.amount-selection.fullPayHelpText',
        defaultMessage: 'You are eligible for the Paid in Full discount on your auto policy. To take advantage of the ${amount} discount, pay this amount today. Premium for the other policies on your account will continue to be billed for the remainder of the term.'
    },
    pendingCancel: {
        id: 'billing.amount-selection.Pending Cancellation',
        defaultMessage: 'Pending Cancellation'
    },
    cancelled: {
        id: 'billing.amount-selection.Cancelled',
        defaultMessage: 'Cancelled'
    },
    expired: {
        id: 'billing.amount-selection.Expired',
        defaultMessage: 'Expired'
    },
    contactHelpMsg: {
        id: 'billing.amount-selection.Contact Help Msg',
        defaultMessage: 'If you have questions, please contact our Customer Relationship Center at {contactDetail}.'
    },
    uicNumber: {
        id: 'billing.amount-selection.UIC Number',
        defaultMessage: 'UIC 800-251-3563'
    },
    wnNumber: {
        id: 'billing.amount-selection.WN Number',
        defaultMessage: 'WN 800-352-2772'
    },
});
