import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FNOLPALossTypePage } from 'gwce-capability-fnol-pa-react-ext';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import wizardConfig from '../../config/fnol-ca-wizard-config.json5';

function FNOLCALossTypePage(props) {
    return <FNOLPALossTypePage {...props}
        wizardConfig={wizardConfig}
    />;
}

FNOLCALossTypePage.propTypes = wizardProps;
FNOLCALossTypePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};
export default withRouter(withAuthenticationContext(FNOLCALossTypePage));
