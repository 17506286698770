import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WniPremiumReportService {
    static getPayAsYouGoPolicies(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('premiumreports'), 'getPayAsYouGoPolicies', [], authHeader);
    }

    static getPremiumReports(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('premiumreports'), 'getPremiumReports', [], authHeader);
    }

    static getPremiumReportDocument(reportNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('premiumreports'), 'getPremiumReportDocument', [reportNumber], authHeader);
    }

    static getLatestPAYGPolicyPeriodPublicID(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('premiumreports'), 'getLatestPAYGPolicyPeriodPublicID', [], authHeader);
    }

    static retrievePremiumReportWizardData(jobNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wcpremiumreportjob'),
            'retrievePremiumReportWizardData', [jobNumber], authHeader);
    }

    static saveAndCalculatePremiums(jobNumber, auditedBasisDTOs, comments, sessionUUID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wcpremiumreportjob'),
            'saveAndCalculatePremiums', [jobNumber, auditedBasisDTOs, comments, sessionUUID], authHeader);
    }

    static editPremiumReport(jobNumber, comments, sessionUUID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wcpremiumreportjob'),
            'editPremiumReport', [jobNumber, comments, sessionUUID], authHeader);
    }

    static savePremiumReport(jobNumber, auditedBasisDTOs, comments, sessionUUID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wcpremiumreportjob'),
            'savePremiumReport', [jobNumber, auditedBasisDTOs, comments, sessionUUID], authHeader);
    }

    static submitPremiumReport(jobNumber, auditedBasisDTOs, comments, sessionUUID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wcpremiumreportjob'),
            'submitPremiumReport', [jobNumber, auditedBasisDTOs, comments, sessionUUID], authHeader);
    }

    static retrieveGLPremiumReportWizardData(jobNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('glpremiumreportjob'),
            'retrievePremiumReportWizardData', [jobNumber], authHeader);
    }

    static saveAndCalculateGLPremiums(jobNumber, auditedBasisDTOs, comments, sessionUUID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('glpremiumreportjob'),
            'saveAndCalculatePremiums', [jobNumber, auditedBasisDTOs, comments, sessionUUID], authHeader);
    }

    static editGLPremiumReport(jobNumber, comments, sessionUUID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('glpremiumreportjob'),
            'editPremiumReport', [jobNumber, comments, sessionUUID], authHeader);
    }

    static saveGLPremiumReport(jobNumber, auditedBasisDTOs, comments, sessionUUID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('glpremiumreportjob'),
            'savePremiumReport', [jobNumber, auditedBasisDTOs, comments, sessionUUID], authHeader);
    }

    static submitGLPremiumReport(jobNumber, auditedBasisDTOs, comments, sessionUUID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('glpremiumreportjob'),
            'submitPremiumReport', [jobNumber, auditedBasisDTOs, comments, sessionUUID], authHeader);
    }

}
