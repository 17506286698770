import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'policy.view.policy-details.Policy Details',
        defaultMessage: 'Policy Details: {policyNumber} {policyStatus}'
    },
    expiredTab: {
        id: 'policy.view.policy-details.tab.Expired',
        defaultMessage: 'Expired'
    },
    currentTab: {
        id: 'policy.view.policy-details.tab.Current',
        defaultMessage: 'Current'
    },
    changePolicy: {
        id: 'policy.view.policy-details.Change Policy',
        defaultMessage: 'Change Policy'
    },
    renewalTab: {
        id: 'policy.view.policy-details.tab.Renewing',
        defaultMessage: 'Renewing'
    },
    DownloadIDCard: {
        id: 'policy.view.policy-details.Download ID Card',
        defaultMessage: 'Download ID Card'
    },
    noticeOfPolicyChangeBold: {
        id: 'policy.view.policy-details.Policy change request has been submitted',
        defaultMessage: 'Your Policy change request has been submitted!'
    },
    noticeOfPolicyChange: {
        id: 'policy.view.policy-details.Please note that while this change request is in review',
        defaultMessage: 'Please note that while this change request is in review, no additional changes can be submitted through the portal. Please contact your agent for any urgent requests. '
    },
});
