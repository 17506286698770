import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Loader, useModal } from '@jutro/components';
import { Wizard } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import 'gw-components-platform-react/platform-styles.scss';
import { WniPremiumReportService } from 'wnice-capability-premiumreport';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { messages as commonMessages } from 'gw-capability-policychange-common-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';

import messages from './CEWCPremiumReportWizard.messages';
import wizardConfig from './config/wc-premiumreport-wizard-config.json5';


function CEWCPremiumReportWizard(props) {
    const modalApi = useModal();
    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history } = props;
    const { authHeader } = useAuthentication();

    useEffect(
        () => {
            if (!location.state) {
                history.push('/');
                return;
            }
            const {
                state: { jobNumber }
            } = location;
            if (!jobNumber) {
                return;
            }
            const errorModalBox = (errorMessage) => {
                modalApi.showConfirm(errorMessage).then((results) => {
                    if (results === 'cancel') {
                        return _.noop();
                    }
                    setIsLoading(false);
                    return history.push('/premium-reports');
                }, _.noop);
            };
            
            WniPremiumReportService.retrievePremiumReportWizardData(jobNumber, authHeader)
            .then((response) => {
                const submission = viewModelService.create(
                    response,
                    'pc',
                    'wni.edge.capabilities.premiumreport.dto.PremiumReportWizardDataDTO'
                );
                setInitialSubmission(submission);
                setIsLoading(false);
            }).catch(() => {
                errorModalBox({
                    title: commonMessages.unableToStartPolicy,
                    message: commonMessages.anErrorOccurred,
                    status: 'warning',
                    icon: 'gw-error-outline'
                });
            });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleError = useCallback(
        (error) => {
            const jobIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');

            return modalApi.showAlert({
                title: messages.anErrorOccurredTitle,
                message: messages.anErrorOccurred,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: platformMessages.ok
            }).then(() => {
                setHasErrorOccurred(true);
                return history.push('/premium-reports');
            }, _.noop);
        },
        [modalApi, history]
    );

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                initialData={initialSubmission}
            />
        </ErrorBoundary>
    );
}

CEWCPremiumReportWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            jobNumber: PropTypes.string
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(withViewModelService(CEWCPremiumReportWizard));
