import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 * WniCEPolicyChangeVehicleHandler
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniCEPolicyChangeVehicle'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the Vehicle for quote backend operations
 *
 * @export
 * @class WniCEPolicyChangeVehicleService
 */
export default class WniCEPolicyChangeVehicleService {
    /**
     * Invokes WniCEPolicyChangeVehicleHandler.updateVehicle()
     * @param {object} data the WniUpdateVehicleRequestDTO json obj
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains PolicyChangeDataDTO
     */
    static updateVehicle(data, authHeader = {}) {
        return processSubmission('updateVehicle', [data], authHeader);
    }

    /**
     * Invokes WniCEPolicyChangeVehicleHandler.autoAssignAndGetVehicleDriverAssignments()
     * @param {object} jobID String
     * @param {object} authHeader the AuthHeader from AuthenticationContext
     * @returns {Promise} the Promise from the backend call that contains VehicleDriverDTO[]
     */
    static autoAssignAndGetVehicleDriverAssignments(jobID, authHeader = {}) {
        return processSubmission('autoAssignAndGetVehicleDriverAssignments', [jobID], authHeader);
    }

    // /**
    //  * Invokes WniPolicyChangeVehicleHandler.getAvailableDrivers()
    //  * @param {object} jobID String
    //  * @param {object} vehicleID Long vehicle fixedId
    //  * @param {object} authHeader the AuthHeader from AuthenticationContext
    //  * @returns {Promise} the Promise from the backend call that contains SimpleDriverDTO[]
    //  */
    // static getAvailableDrivers(jobID, vehicleID, authHeader = {}) {
    //     return processSubmission('getAvailableDrivers', [jobID, vehicleID], authHeader);
    // }

}
