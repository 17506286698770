import React, { useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue, appendMetadataWithIndex } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { useTranslator } from '@jutro/locale';
import { DriverUtil } from 'wni-portals-util-js';

// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import metadata from './CEDriverComponent.metadata.json5';
import styles from './CEDriverComponent.module.scss'
import messages from './CEDriverComponent.messages';

function getQuestionMapper(translator, msg) {
    return (metaDataContent) => {
        if (metaDataContent.id === 'DriverAnyCurFinResFilingYN_Ext') {
            _.set(metaDataContent, 'componentProps.label', translator(msg.driverAnyCurFinResFilingYNMsg));
        }
        return metaDataContent;
    };
}

function calculateAge(birthDate, currentDate) {
    let age = currentDate.year - birthDate.year;
    const m = currentDate.month - birthDate.month;
    if (m < 0 || (m === 0 && currentDate.day < birthDate.day)) {
        age -= 1;
    }

    return age;
}

function CEDriverComponent(props) {
    const {
        data: driverVM,
        baseData,
        readOnlyFields,
        labelPosition,
        showOptional,
        phoneWide,
        path,
        id,
        index,
        onValidate,
        onValueChange,
        showErrors,
        isRequiredForIssuance,
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const translator = useTranslator();
    
    const [driverQuestionSets, updateDriverQuestionSets] = useState(_.get(driverVM, 'value.driverQuestionSets_Ext'))

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    const isLicensedDriver = useMemo(() => {
        return _.get(driverVM.value, 'licensedDriver_Ext', false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driverVM.value.licensedDriver_Ext]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [driverVM, id, onValidate, isComponentValid]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if(changedPath === 'dateOfBirth' && value){
                onValueChange(calculateAge(value, baseData.effectiveDate.value), `${path}.age_Ext`);
            }
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [baseData, onValueChange, path]
    );

    const writeStepperValue = useCallback(
        (value, changedPath) => {
            const valueToString = _.toString(value);
            handleValueChange(valueToString, changedPath);
        },
        [handleValueChange]
    );

    const driverYearFirstLicencedAvailableValues = useMemo(() => {
        const currentYear = new Date().getUTCFullYear();
        const { ageAllowedToDrive } = config.personalAutoConfig;
        const dateOfBirth = _.get(driverVM, 'dateOfBirth.value');

        const earliestYear = _.isNil(dateOfBirth)
            ? currentYear - ageAllowedToDrive
            : dateOfBirth.year + ageAllowedToDrive;

        if (earliestYear > currentYear) {
            // return an empty object instead of just an empty array so that
            // the dropdown menu will show "No options"
            return [{}];
        }

        // _.range is not inclusive. Need to add -1 to get correct years
        const yearRange = _.range(currentYear, earliestYear - 1);

        return yearRange.map(_.toString).map((year) => ({
            code: year,
            name: year
        }));
    }, [driverVM]);

    useEffect(() => {
        /**
         * Force question set component update when relationshipToInsured_Ext changed
         */
        updateDriverQuestionSets([])
        setTimeout(()=>{
            updateDriverQuestionSets(_.get(driverVM, 'value.driverQuestionSets_Ext'))
        },200)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driverVM.relationshipToInsured_Ext?.value]);

    /*
     * DriverIsCompanyVehFurnishedYN_Ext   Is there a company vehicle furnished or available for regular use of this driver?
     * DriverFurnishedVehicleCompany_Ext   Company Name
     * DriverFurnishedVehiclePolicy_Ext   Policy Number
     * DriverWNEmployeeDiscountYN_Ext      Western National Employee Discount?
     */
    const filterQuestionSets = useCallback((metadataContent) => {
        let hideQuestions = [
            /**
             * CEI-4641
            */
            'DriverIsCompanyVehFurnishedYN_Ext',
            'DriverFurnishedVehicleCompany_Ext',
            'DriverFurnishedVehiclePolicy_Ext',
            'DriverWNEmployeeDiscountYN_Ext',
        ]
        if(driverVM.value.tempID){
            hideQuestions = hideQuestions.concat([
                // CEI-4709
                // 'DriverLicSuspLastFiveYearsYN_Ext',
            ])
        }
        const relationshipToInsured = _.get(driverVM, 'value.relationshipToInsured_Ext');
        // POI-10401 && BAFinding #183
        if (_.get(driverVM, 'driverCovered_Ext.value') !== false
            && (relationshipToInsured === 'Insured_Ext'
            || relationshipToInsured === 'Spouse_Ext'
            || relationshipToInsured === 'Partner_Ext')) {
                const indexCompanyVehFurnishedYN = hideQuestions.indexOf('DriverIsCompanyVehFurnishedYN_Ext')
                if(indexCompanyVehFurnishedYN !== -1){
                    hideQuestions.splice(indexCompanyVehFurnishedYN, 1)
                }
        }
        // CEI-269 update CEI-4709
        if (!_.get(driverVM, 'licensedDriver_Ext.value')) {
            hideQuestions = hideQuestions.concat(['DriverLicSuspLastFiveYearsYN_Ext', 'DriverLicSuspLastFiveYearsYNExplain_Ext']);
        }
        /* 
        // POI-19479
        if (_.get(baseData, 'baseState_Ext') === 'AK') {
            hideQuestions.push('DriverWNEmployeeDiscountYN_Ext');
        } */
        return _.indexOf(hideQuestions, metadataContent.id) < 0;
    }, [driverVM]);

    const overrideProps = useMemo(
        () => {
            return {
                '@field': {
                    showOptional,
                    labelPosition,
                    phoneWide,
                },
                [`driverFirstName${index}`]: { readOnly: _.includes(readOnlyFields, 'driverFirstName') },
                [`driverLastName${index}`]: { readOnly: _.includes(readOnlyFields, 'driverLastName') },
                [`driverDateOfBirth${index}`]: { readOnly: _.includes(readOnlyFields, 'driverDateOfBirth') },
                [`driverGender${index}`]: { readOnly: _.includes(readOnlyFields, 'driverGender') },
                [`driverRelInsured${index}`]: { 
                    readOnly: _.includes(readOnlyFields, 'driverRelInsured'),
                    availableValues: DriverUtil.getAvailableRelationship(
                        _.get(driverVM, 'relationshipToInsured_Ext.aspects.availableValues'), translator
                    ),
                },
                [`isLicensedDriver${index}`]: { 
                    readOnly: true,
                    visible: !_.get(driverVM.value, 'tempID', false) && !isLicensedDriver
                },
                [`driverUnLicensedReason${index}`]: { 
                    readOnly: true,
                    visible: !isLicensedDriver
                },
                [`driverLicenseNumber${index}`]: { 
                    readOnly: _.includes(readOnlyFields, 'driverLicenseNumber'),
                    required: isLicensedDriver,
                    visible: isLicensedDriver,
                },
                [`driverLicenceState${index}`]: { 
                    readOnly: _.includes(readOnlyFields, 'driverLicenceState'),
                    required: isLicensedDriver,
                    visible: isLicensedDriver
                },
                [`driverYearFirstLicenced${index}`]: {
                    readOnly: _.includes(readOnlyFields, 'driverYearFirstLicenced'),
                    availableValues: driverYearFirstLicencedAvailableValues,
                    visible: isLicensedDriver
                },
                // As this value is a typelist in the VM which accepts a string we need
                // to convert the number from the Stepper component to a string
                [`driverNumberOfAccidents${index}`]: {
                    readOnly: _.includes(readOnlyFields, 'driverNumberOfAccidents'),
                    onValueChange: writeStepperValue,
                    value: _.get(driverVM.value, 'accidents')
                },
                [`driverNumberOfViolations${index}`]: {
                    readOnly: _.includes(readOnlyFields, 'driverNumberOfViolations'),
                    onValueChange: writeStepperValue,
                    value: _.get(driverVM.value, 'violations')
                },
                [`driverUnderwritingQuestionSets${index}`]: {
                    visible: !_.isEmpty(driverQuestionSets),
                    contentFilter: filterQuestionSets,
                    contentMapper: getQuestionMapper(translator, messages),
                    onValidate,
                    showQuestionChangeWarning: true,
                    isRequiredForIssuance,
                    // isReadOnly: fromReadOnlyPage
                },
            }
        },[showOptional, labelPosition, phoneWide, index, readOnlyFields, driverVM, translator, isLicensedDriver, driverYearFirstLicencedAvailableValues, writeStepperValue, driverQuestionSets, filterQuestionSets, onValidate, isRequiredForIssuance]
    );

    const readValue = useCallback(
        (fieldId, fieldPath) => {
            return readViewModelValue(
                formattedMetadata,
                driverVM,
                fieldId,
                fieldPath,
                overrideProps
            );
        },
        [driverVM, formattedMetadata, overrideProps]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate: onValidate
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent,
        }
    };

    return (
        <ViewModelForm
            uiProps={formattedMetadata}
            model={driverVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={styles}
            showErrors={showErrors}
        />
    );
}

CEDriverComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    readOnlyFields: PropTypes.arrayOf(PropTypes.string),
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.string,
    id: PropTypes.string
};
CEDriverComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    readOnlyFields: [],
    showOptional: true,
    id: undefined,
    index: 0
};
export default CEDriverComponent;
