import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext, useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { hasUSLHItem } from '../../utils/WCPremiumReportUtil';
import metadata from './RatingPeriodComponent.metadata.json5';
import messages from './RatingPeriodComponent.messages';
import styles from './RatingPeriodComponent.module.scss';
import { InputField } from '@jutro/legacy/components';


function RatingPeriodComponent(props) {
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const {
        data: ratingPeriodVM,
        wizardData,
        updateWizardData,
        ratingPeriodPath
    } = props;

    const { 
        jobStatus
    } = wizardData.value;

    const readOnly = jobStatus === 'AuditComplete' || jobStatus === 'Quoted';
    const hasUSLHData = hasUSLHItem(ratingPeriodVM.value);

    const getFormattedDate = useCallback((date) => {
        const newDate = new Date(date.year, date.month, date.day);
        return intl.formatDate(newDate, { year: 'numeric', month: 'short', day: 'numeric' })
    }, [intl]);

    const getRatingPeriodTitle = useCallback(() => {
        const periodStart = getFormattedDate(ratingPeriodVM.periodStart.value);
        const periodEnd = getFormattedDate(ratingPeriodVM.periodEnd.value);
        return `${periodStart} - ${periodEnd}`;
    }, [getFormattedDate, ratingPeriodVM]);

    const renderCell = (item, index, property) => {
        return item[property.id].value;
     };

     const writeValue = useCallback((newValue, selectedColumnPath) => {
        const isInteger = !_.isNaN(_.parseInt(newValue));
        const value = isInteger ? _.toInteger(newValue) : newValue;
        _.set(wizardData, `${selectedColumnPath}.value`, value);
        const validationMsgPath = `${selectedColumnPath}.validationMsgs`;
        if (!isInteger) {
            _.set(wizardData, validationMsgPath, translator(messages.inputMustBeInteger));
        } else {
            _.set(wizardData, validationMsgPath, undefined);
        }
        updateWizardData(wizardData);
     }, [translator, updateWizardData, wizardData]);

     const renderActualCell = (item, index, property) => {
        const selectedBasePath = `${ratingPeriodPath}.detailItems.children[${index}]`;
        const selectedActualPath = `${selectedBasePath}.${property.id}`;
        const validationErrorMessage = _.get(item, `${property.id}.validationMsgs`);
        if (readOnly) {
            return renderCell(item, index, property); 
        }
        const canInput = property.id === 'actual' || (property.id === 'actualUSLH' && item.value.hasUSLH);
        if (canInput) {
            return (
                <InputField
                    id={`${property.id}_${index}_${_.get(item, 'covEmpPublicID.value')}`}
                    path={selectedActualPath}
                    onValueChange={(newValue) => writeValue(newValue, selectedActualPath)}
                    value={item[property.id].value}
                    required
                    validationMessages={!_.isEmpty(validationErrorMessage) ? [validationErrorMessage] : undefined}
                    showErrors={!_.isEmpty(item[property.id].value)}
                    hideLabel
                />
            );
        }
        return null;
    }

    const overrides = {
        ratingPeriodTitle: {
            content: getRatingPeriodTitle()
        },
        detailItemsTable: {
            visible: !hasUSLHData,
            data: ratingPeriodVM.detailItems.children
        },
        detailItemsTableWithUSLH: {
            visible: hasUSLHData,
            data: ratingPeriodVM.detailItems.children
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderCell,
            renderActualCell
        }     
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={ratingPeriodVM}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

RatingPeriodComponent.propTypes = {
    data: PropTypes.array.isRequired,
    wizardData: PropTypes.object.isRequired,
    updateWizardData: PropTypes.func.isRequired,
    ratingPeriodPath: PropTypes.string.isRequired
};

export default RatingPeriodComponent;
