import _ from 'lodash';
import appConfig from 'app-config';

const {
    wniExtendedConfig = {},
} = appConfig;


// ===============================================================
// Try to extract wniExtendedConfig from config.json5
// When the URL is *.wnins.com, then themeName "ProducerEngage" will be returned.
// When the URL is *.umialik.com, then themeName "ProducerEngageUmialik" will be returned. 
// ====================================================
function getThemeName() {
    // Determine theme/branch name by URL, match-pattern specified in the config.json5 file
    const urlHref = window.location.href;
    let themeName;

    for (const [, value] of Object.entries(wniExtendedConfig)) {
        if ('urlMatchPattern' in value && urlHref.search(value.urlMatchPattern) !== -1) {
            themeName = value.themeName;
            break;
        }
    }
    
    return themeName;
};

/**
 * Retrieve wniExtendedConfig value based on current theme. 
 * 
 * Sample Usage when current URL is *.wnins.com:
 * Input: 'okta'
 * Output: {"JUTRO_AUTH_LOGIN_QUERY_EXTRAS": "{\"idp\": \"{{PRODUCER_ENGAGE_UMIALIK_IDP}}\"}"}
 * @param {string} propertyNameOrPath 
 * @returns {object}
 */
function getBrandedConfig(propertyNameOrPath) {
    const themeName = getThemeName();
    const brandConfigValue =_.get(wniExtendedConfig, `${themeName}.${propertyNameOrPath}`);
    if(brandConfigValue === undefined){
        // localhost and unconfig env return default setting
        return _.get(appConfig, propertyNameOrPath);
    }

    return brandConfigValue;
}

function getBrandedOktaConfig() {
    return getBrandedConfig('okta');
}

function getMyAccountUrl() {
    return getBrandedConfig('myAccountUrl');
}

function getInvoiceCloudBaseUrl() {
    return getBrandedConfig('invoiceCloudUrl');
}

export default {
    getBrandedConfig,
    getBrandedOktaConfig,
    getMyAccountUrl,
    getInvoiceCloudBaseUrl
};
