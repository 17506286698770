import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './CEVehicleCustomEquipmentPopup.metadata.json5';


import { Button } from '@jutro/legacy/components';


function CEVehicleCustomEquipmentPopup(props) {
    const {
        title,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        modalVM,
        viewModelService
    } = props;

    const {
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('CEVehicleCustomEquipmentPopup');

    const [newModalVM, updateNewModalVM] = useState(modalVM);
    const [showErrors, updateShowErrors] = useState(false);

    const getDefaultEequipmentCurrency = useCallback(() => {
        const equipmentValue = _.get(newModalVM, 'equipmentValue.value');
        if (_.isUndefined(equipmentValue)) {
            return undefined;
        }
        return {
            amount: equipmentValue,
            currency: 'USD' 
        };
    }, [newModalVM]);

    const [equipmentCurrency, updateEquipmentCurrency] = useState(getDefaultEequipmentCurrency());

    const isModalValid = useCallback(() => {
        return newModalVM.aspects.valid && newModalVM.aspects.subtreeValid;
    }, [newModalVM]);

    useEffect(() => {
        registerComponentValidation(isModalValid);
    }, [registerComponentValidation, isModalValid]);

    const writeValue = useCallback(
        (value, path) => {
            const getNewModalVM = viewModelService.clone(newModalVM);
            _.set(getNewModalVM, path, value);
            updateNewModalVM(getNewModalVM);
        },
        [newModalVM, viewModelService]
    );

    const changeEquipmentCurrency = useCallback(
        (currencyValue) => {
            updateEquipmentCurrency(currencyValue);
            const value = currencyValue.amount;
            const getNewModalVM = viewModelService.clone(newModalVM);
            _.set(getNewModalVM, 'equipmentValue', value);
            updateNewModalVM(getNewModalVM);
    },
    [newModalVM, viewModelService]
    );

    const handleSave = useCallback(
        () => {
            if (!isComponentValid) {
                updateShowErrors(true);
            } else {
                onResolve(newModalVM.value);
            }
        },
        [onResolve, isComponentValid, newModalVM]
    );

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left'
        },
        equipmentCurrency: {
            value: equipmentCurrency,
            onValueChange: changeEquipmentCurrency,
            showErrors,
            validationMessages: _.isUndefined(equipmentCurrency) ? ['This is a required field'] : [],
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody>
                <ViewModelForm
                    model={newModalVM}
                    overrideProps={overrideProps}
                    uiProps={metadata.componentContent}
                    callbackMap={resolvers.resolveCallbackMap}
                    onValueChange={writeValue}
                    onValidationChange={onValidate}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} type="filled">{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

CEVehicleCustomEquipmentPopup.propTypes = {
    title: PropTypes.string.isRequired,
    size: PropTypes.string,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    modalVM: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired
};

CEVehicleCustomEquipmentPopup.defaultProps = {
    size: 'md'
};

export default CEVehicleCustomEquipmentPopup;
