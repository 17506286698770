import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import metadata from './EmailVerificationPopup.metadata.json5';
import messages from './EmailVerificationPopup.messages';
import styles from '../../pages/UsersProfile/UsersProfile.module.scss';


import { Button } from '@jutro/legacy/components';


function EmailVerificationPopup(props) {
    const {
        deliveryMethod,
        authHeader,
        WniUsersProfileDetailsService,
        isOpen,
        onResolve,
        onReject
    } = props;

    const { accountNumber } = deliveryMethod;
    const translator = useTranslator();
    const [action, setAction] = useState('default');
    const [actionLabel, setActionLabel] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [actionMsg, setActionMsg] = useState();
    const [verificationCode, setVerificationCode] = useState('');

    useEffect(() => {
        switch(action) {
            case 'default': 
                setActionLabel(translator(messages.save));
                setActionMsg('');
                break;
            case 'wrongcode': 
                setActionLabel(translator(messages.save));
                setActionMsg(translator(messages.wrongCodeMsg));
                break;
            case 'expiredcode': 
                setActionLabel(translator(messages.resendCode));
                setActionMsg(translator(messages.resendCodeMsg));
                break;    
            default:
                break; 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action]);

    const writeValue = useCallback((value) => {
        setVerificationCode(value);
    }, []);

    const verifyEmailVerificationCode = useCallback((result) => {
        switch(result) {
            case 'Success':
                _.set(deliveryMethod, 'emailVerified', true);
                onResolve();
                break;
            case 'Invalid SecureCode': 
                setAction('wrongcode');
                break;
            case 'SecureCode is Expired': 
                setAction('expiredcode');
                break;
            default:
                break; 
        }
    }, [deliveryMethod, onResolve]);

    const handleClick = useCallback(() => {
        setIsLoading(true);
        if (actionLabel === translator(messages.save)) {
            const deliveryMethodInput = {
                accountNumber: deliveryMethod.accountNumber,
                emailVerificationCode: verificationCode
            };
            WniUsersProfileDetailsService.verifyEmailVerificationCode(deliveryMethodInput, authHeader)
            .then((result) => {
                verifyEmailVerificationCode(result);
            })
            .finally(() => {
                setIsLoading(false);
            })
        }
        if (actionLabel === translator(messages.resendCode)) {
            WniUsersProfileDetailsService.refreshEmailVerificationCode(accountNumber, authHeader)
            .then(() => {
                setAction('default');
                writeValue('');
            })
            .finally(() => {
                setIsLoading(false);
            })
        }
    }, [WniUsersProfileDetailsService, accountNumber, actionLabel, authHeader, deliveryMethod, translator, verificationCode, verifyEmailVerificationCode, writeValue]);

    const disableClick = useCallback(() => {
        const regex = /^\d{6}$/g;
        return !regex.test(verificationCode) || isLoading;
    }, [isLoading, verificationCode]);

    const overrideProps = {
        emailVerificationErrorMsg: {
            visible: !_.isEmpty(actionMsg),
            content: actionMsg
        },
        emailVerificationCode: {
            value: verificationCode,
            onValueChange: writeValue
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader title={messages.enterEmailVerificationCode} icon='gw-error-outline' status='info' onClose={onReject} />
            <ModalBody>
                <MetadataContent
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    {...resolvers} />
            </ModalBody>
            <ModalFooter>
                <Button 
                    type="filled"
                    onClick={handleClick}
                    disabled={disableClick()}
                >
                    {actionLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

EmailVerificationPopup.propTypes = {
    deliveryMethod: PropTypes.object.isRequired,
    authHeader: PropTypes.object.isRequired,
    WniUsersProfileDetailsService: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func
};

EmailVerificationPopup.defaultProps = {
};

export default EmailVerificationPopup;
