const clauseDisplayNameMap = {
  PAComprehensiveCov: "Comprehensive"
}

const termDisplayNameMap = {
  PACollDeductible_RT_Ext: "Collision Deductible",
  PACollDeductible_UT_Ext: "Collision Deductible",
  PACompDeductible: "Comprehensive Deductible",
  PACompDeductible_RT_Ext: "Comprehensive Deductible",
  PACompDeductible_UT_Ext: "Comprehensive Deductible"
}

const helpTextDisplayMap = {
  PACollisionCov: false,
  PAComprehensiveCov: false,
  PATowingLaborCov: false,
  PAOptLimitTrans_Ext: false,
  // PAOptLimitTrans_Ext: 'Also known as Rental Reimbursement coverage, this covers rental vehicle costs while an insured vehicle is out of service due to a covered loss. Limits start at $30 per day / $900 maximum, with higher limits available.'
}

export {
  clauseDisplayNameMap,
  termDisplayNameMap,
  helpTextDisplayMap
}