import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'usersprofile.popup.custom-confirm-popup.Cancel',
        defaultMessage: 'Cancel'
    },
    continue: {
        id: 'usersprofile.popup.custom-confirm-popup.Continue',
        defaultMessage: 'Continue'
    }
});
