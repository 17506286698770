import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { showProdsCompltdOpsActual, PREMISES_PRODUCTSOPS_SUBLINE } from '../../utils/GLPremiumReportUtil';
import metadata from './SublineDetailComponent.metadata.json5';
import messages from './SublineDetailComponent.messages';
import commonStyles from '../../CEGLPremiumReportWizard.module.scss';
import { InputField } from '@jutro/legacy/components';

function SublineDetailComponent(props) {
    const translator = useTranslator();
    const {
        data: sublineVM,
        wizardData,
        updateWizardData,
        sublinePath
    } = props;

    const { 
        jobStatus
    } = wizardData.value;

    const readOnly = jobStatus === 'AuditComplete' || jobStatus === 'Quoted';
    const sublineName = _.get(sublineVM, 'sublineName.value');
    const isPremisesProductsOpsSubline = sublineName === PREMISES_PRODUCTSOPS_SUBLINE;
    const hasProdsCompltdOpsActualColumn = showProdsCompltdOpsActual(_.get(sublineVM, 'exposures.value'));

    const renderCell = (item, index, property) => {
        return item[property.id].value;
    };

    const writeValue = useCallback((newValue, selectedColumnPath) => {
        const isNumber = !_.isNaN(_.toNumber(newValue));
        const value = isNumber ? _.toNumber(newValue) : newValue;
        _.set(wizardData, `${selectedColumnPath}.value`, value);
        const validationMsgPath = `${selectedColumnPath}.validationMsgs`;
        if (!isNumber) {
            _.set(wizardData, validationMsgPath, translator(messages.inputMustBeNumber));
        } else {
            _.set(wizardData, validationMsgPath, undefined);
        }
        updateWizardData(wizardData);
    }, [translator, updateWizardData, wizardData]);

    const onBlurActual = useCallback((selectedBasePath, item, propertyId) => {
        if (item.value.sublineTypeName === PREMISES_PRODUCTSOPS_SUBLINE) {
            const premisesOperationsActualValue = _.get(item, 'value.premisesOperationsActual');
            const prodsCompltdOpsActualValue = _.get(item, 'value.prodsCompltdOpsActual');
            // default to be the same value(premisesOperationsActual & prodsCompltdOpsActual)
            if (propertyId === 'premisesOperationsActual' && item.canEditProdsCompltdOpsActual.value) {
                if (_.isNil(prodsCompltdOpsActualValue)) {
                    _.set(wizardData, `${selectedBasePath}.prodsCompltdOpsActual.value`, premisesOperationsActualValue);
                    updateWizardData(wizardData);
                }
            } else if (propertyId === 'prodsCompltdOpsActual') {
                if (_.isNil(premisesOperationsActualValue)) {
                    _.set(wizardData, `${selectedBasePath}.premisesOperationsActual.value`, prodsCompltdOpsActualValue);
                    updateWizardData(wizardData);
                }
            }
        }
    }, [updateWizardData, wizardData]);

    const renderActualCell = (item, index, property) => {
        const selectedBasePath = `${sublinePath}.exposures.children[${index}]`;
        const propertyId = property.id;
        const selectedActualPath = `${selectedBasePath}.${propertyId}`;
        const validationErrorMessage = _.get(item, `${propertyId}.validationMsgs`);
        if (readOnly) {
            return renderCell(item, index, property); 
        }
        const canInputFields = ['premisesOperationsActual', 'prodsCompltdOpsActual', 'actual'];
        const canInput = _.includes(canInputFields, propertyId);
        const isActualCanEdit = propertyId === 'prodsCompltdOpsActual' ? _.get(item, 'canEditProdsCompltdOpsActual.value') : true;
        const isActualReadOnly = readOnly ? true : !isActualCanEdit;
        if (canInput) {
            return (
                <InputField
                    id={`${propertyId}_${index}_${_.get(item, 'publicID.value')}`}
                    path={selectedActualPath}
                    onValueChange={(newValue) => writeValue(newValue, selectedActualPath)}
                    onBlur={() => onBlurActual(selectedBasePath, item, propertyId)}
                    value={item[propertyId].value}
                    readOnly={isActualReadOnly}
                    required
                    validationMessages={!_.isEmpty(validationErrorMessage) ? [validationErrorMessage] : undefined}
                    showErrors={!_.isEmpty(item[propertyId].value)}
                    hideLabel
                />
            );
        }
        return null;
    };

    const overrides = {
        sublineTitle: {
            content: sublineName
        },
        premisesProductsOpsExposuresContainer: {
            visible: isPremisesProductsOpsSubline
        },
        exposuresWithProdsCompltdOpsActualTable: {
            visible: hasProdsCompltdOpsActualColumn,
            data: sublineVM.exposures.children
        },
        exposuresWithoutProdsCompltdOpsActualTable: {
            visible: !hasProdsCompltdOpsActualColumn,
            data: sublineVM.exposures.children
        },
        otherSublineExposuresTable: {
            visible: !isPremisesProductsOpsSubline,
            data: sublineVM.exposures.children
        }
    };

    const resolvers = {
        resolveClassNameMap: commonStyles,
        resolveCallbackMap: {
            renderCell,
            renderActualCell
        }     
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={sublineVM}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

SublineDetailComponent.propTypes = {
    data: PropTypes.array.isRequired,
    wizardData: PropTypes.object.isRequired,
    updateWizardData: PropTypes.func.isRequired,
    sublinePath: PropTypes.string.isRequired
};

export default SublineDetailComponent;
