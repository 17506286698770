import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentComponentBuyNow: {
        id: 'integration.quoteandbind.directives.custom-quote.BOP.BuyNow',
        defaultMessage: 'BuyNow'
    },
    paymentSource: {
        id: 'amp.bc3.directives.templates.payment-instrument-bc3.Source',
        defaultMessage: 'Source'
    },
    bankAccountSource: {
        id: 'amp.bc3.directives.templates.payment-instrument-bc3.Bank Account',
        defaultMessage: 'Bank Account'
    },
    cardIssuer: {
        id: 'platform.paymentdetails.credit-card-auto-details.Card Issuer',
        defaultMessage: 'Card Issuer',
    },
    selectIssuer: {
        id: 'platform.paymentdetails.credit-card-auto-details.-- Please Select --',
        defaultMessage: '-- Please Select --'
    },
    paymentMethod: {
        id: 'quoteandbind.views.payment-details.Payment Method',
        defaultMessage: 'Payment Method',
    },
    bankAccount: {
        id: 'quoteandbind.views.payment-details.Bank Account',
        defaultMessage: 'Bank Account',
    },
    creditCard: {
        id: 'quoteandbind.views.payment-details.Credit Card',
        defaultMessage: 'Credit Card',
    },
    accountType: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Account Type',
        defaultMessage: 'Account Type',
    },
    howToPay: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.How do you want to pay?',
        defaultMessage: 'How do you want to pay?',
    },
    accountNumber: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Account Number',
        defaultMessage: 'Account Number',
    },
    accountNumberInvalid: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Account Number Invalid',
        defaultMessage: 'Bank account number must be up to 19 digits.',
    },
    routingNumber: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Routing (ABA) Number',
        defaultMessage: 'Routing (ABA) Number',
    },
    bankName: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Bank Name',
        defaultMessage: 'Bank Name',
    },
    cancel: {
        id: 'platform.paymentdetails.Cancel',
        defaultMessage: 'Cancel',
    },
    readAndAccepted: {
        id: 'paymentdetails.terms-and-conditions.I have read and accepted',
        defaultMessage: 'I have read and accepted the ',
    },
    termsAndConditions: {
        id: 'paymentdetails.terms-and-conditions.Terms & Conditions',
        defaultMessage: 'Terms & Conditions',
    },
    accountNumberTooltip: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Account Number Tooltip',
        defaultMessage: 'The Account Number is the second set of numbers in the bottom left corner of each check.',
    },
    routingNumberTooltip: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Routing (ABA) Number Tooltip',
        defaultMessage: 'The Routing Number is the nine-digit number in the bottom left corner of each check.',
    },
    termAndConditionsContent: {
        id: 'paymentdetails.terms-and-conditions.termAndConditionsContent',
        defaultMessage: 'I authorize Western National Insurance Group and its affiliates (Michigan Millers, Pioneer Specialty, Umialik, Western National Assurance, or Western National Mutual) and the financial institution named above to initiate entries to my checking / savings account. This authority will remain in effect until I notify Western National Insurance Group to cancel this agreement. Likewise, I understand that I can stop payment of any entry by notifying Western National Insurance Group company at least three business days before my account is charged.'
    }
});
