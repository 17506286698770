import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FNOLPARepairFacilityPage } from 'gwce-capability-fnol-pa-react-ext';
import React from 'react';

function FNOLCARepairFacilityPage(props) {
    return <FNOLPARepairFacilityPage {...props} />;
}

FNOLCARepairFacilityPage.propTypes = wizardProps;
export default FNOLCARepairFacilityPage;
