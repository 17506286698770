import React, { useMemo, useCallback } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import { messages as platformMessages} from '@xengage/gw-platform-translations';
import metadata from './ClaimLobOptionsMenu.metadata.json5';
import styles from './ClaimLobOptionsMenu.module.scss';
import { IconButton } from '@jutro/legacy/components';

function ClaimLobOptionsMenu(props) {
    const {
        values, handleFilterChange, isClosed, selectedClaimLOB, isShowClosedClaimFilter
    } = props;
    const translator = useTranslator();

    const generateOverrides = useMemo(() => {
        const lobOptionsOverrides = values.map((item, index) => {
            return {
                [`itemName${index}`]: {
                    content: platformMessages[item.productCode] ? translator(platformMessages[item.productCode]) : item.productCode,
                    onClick: () => {
                        handleFilterChange(item.policyType, 'LOBFilter');
                    },
                    className: item.policyType === selectedClaimLOB ? 'itemNameSelected' : 'itemNameClass'
                },
                [`iconChecked${index}`]: {
                    visible: item.policyType === selectedClaimLOB
                }
            };
        });

        return Object.assign({}, ...lobOptionsOverrides);
    }, [values, handleFilterChange, selectedClaimLOB, translator]);

    const overrides = {
        lobValues: {
            data: values,
        },
        onClosedLink: {
            onValueChange: (value) => handleFilterChange(value, 'closedFilter'),
            value: isClosed,
            visible: isShowClosedClaimFilter
        },
        onClosedMenuTitle: {
            visible: isShowClosedClaimFilter
        },
        ...generateOverrides
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {}
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            {...resolvers} />
    );
}
ClaimLobOptionsMenu.propTypes = {
    values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])).isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    isClosed: PropTypes.bool,
    showClosedClaimFilter: PropTypes.bool,
    selectedClaimLOB: PropTypes.string
};

ClaimLobOptionsMenu.defaultProps = {
    values: [],
    isClosed: false,
    selectedClaimLOB: undefined,
    isShowClosedClaimFilter: true
};

export default ClaimLobOptionsMenu;
