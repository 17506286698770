import { defineMessages } from 'react-intl';

export default defineMessages({
    policies: {
        id: 'ce.billing.policies-table.Policies',
        defaultMessage: 'Policies'
    },
    type: {
        id: 'ce.billing.policies-table.Type',
        defaultMessage: 'Policy Type'
    },
    policy: {
        id: 'ce.billing.policies-table.Policy Number',
        defaultMessage: 'Policy Number'
    },
    effective: {
        id: 'ce.billing.policies-table.Effective',
        defaultMessage: 'Effective Date'
    },
    expiry: {
        id: 'ce.billing.policies-table.Expiry',
        defaultMessage: 'Expiry Date'
    },
    premium: {
        id: 'ce.billing.policies-table.Premium',
        defaultMessage: 'Premium'
    },
    statusActive: {
        id: 'ce.billing.policies-table.Active',
        defaultMessage: 'Active'
    },
    statusRenewed: {
        id: 'ce.billing.policies-table.Renewed',
        defaultMessage: 'Renewed'
    },
    canceling: {
        id: 'ce.billing.policies-table.canceling',
        defaultMessage: 'Pending Cancel'
    },
});
