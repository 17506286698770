import { defineMessages } from 'react-intl';

export default defineMessages({
    startReport: {
        id: 'policy.premium-report-page.Start Report',
        defaultMessage: 'Start Report'
    },
    anErrorOccurred: {
        id: 'policy.premium-report-page.Error',
        defaultMessage: 'Something went wrong during this process.'
    }
});
