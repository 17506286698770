import { defineMessages } from 'react-intl';

export default defineMessages({
    HomeDetails: {
        id: 'policy.common.component.policy-dwelling-info.Home Details',
        defaultMessage: 'Home Details'
    },
    DwellingProperty: {
        id: 'policy.common.component.policy-dwelling-info.Dwelling Property',
        defaultMessage: 'Dwelling Property'
    },
    TotalTaxes: {
        id: 'policy.common.component.policy-dwelling-info.Total Taxes',
        defaultMessage: 'Total Taxes'
    },
    TotalCost: {
        id: 'policy.common.component.policy-dwelling-info.Total Cost',
        defaultMessage: 'Total Cost'
    },
    PolicyType: {
        id: 'policy.common.component.policy-dwelling-info.Policy Type',
        defaultMessage: 'Policy Type'
    },
    coverages: {
        id: 'policy.common.component.policy-dwelling-info.Coverages For',
        defaultMessage: 'Coverages'
    }
});
