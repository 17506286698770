export const DocumentTypeNameMap = {
    'Contingency - Defensive driver certificate': 'Defensive Driver Certificate',
    'Contingency - Grades': 'Report Card / Grades',
    'Contingency - Photos (Physical damage)': 'Photos of Vehicle',
    'Contingency - Proof of Safety Course': 'Safety Course Completion Certificate',
    'Contingency - Home Photos (Front/Back)': 'Photos of Home',
    'Contingency - Proof of Corporate Insurance': 'Copy of Insurance (Business Auto)',
    'Contingency - Appraisal Certificate': 'Appraisal',
    'Contingency - Home Inspection Report': 'Home Inspection Report',
    'Contingency - Home Photos (Detached Structure)': 'Photos of Detached Structure',
    'Contingency - Protective Devices Certificate': 'Alarm / Security Certificate',
    'Other': 'Other',
    'Photos': 'Photos of Home',
    'Proof of Insurance': 'Copy of Insurance',
};