import { defineMessages } from 'react-intl';

export default defineMessages({
    puLineCoverages: {
        id: 'policy.common.component.umbrella-coverage-table.Personal Umbrella Line Coverages',
        defaultMessage: 'Personal Umbrella Line Coverages'
    },
    hideScheduleItems: {
        id: 'policycommon.component.umbrella-coverage-table.hide schedule items',
        defaultMessage: 'Hide Schedule Items'
    },
    showScheduleItems: {
        id: 'policycommon.component.umbrella-coverage-table.show schedule items',
        defaultMessage: 'Show Schedule Items'
    }
});
