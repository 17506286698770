import { defineMessages } from 'react-intl';

export default defineMessages({
    inputMustBeInteger: {
        id: 'premiumreport.wc.component.RatingPeriodComponent.Input must be an integer',
        defaultMessage: 'Input must be an integer'
    },
    location: {
        id: 'premiumreport.wc.component.RatingPeriodComponent.Location',
        defaultMessage: 'Location'
    },
    classCode: {
        id: 'premiumreport.wc.component.RatingPeriodComponent.Class Code',
        defaultMessage: 'Class Code'
    },
    detailType: {
        id: 'premiumreport.wc.component.RatingPeriodComponent.Type',
        defaultMessage: 'Type'
    },
    actual: {
        id: 'premiumreport.wc.component.RatingPeriodComponent.Actual',
        defaultMessage: 'Actual'
    },
    actualUSLH: {
        id: 'premiumreport.wc.component.RatingPeriodComponent.USLH Actual',
        defaultMessage: 'USLH Actual'
    }
});
