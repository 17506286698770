import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WniCEPolicyService {

    static getAccountPolicyDetails(policyNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wnicepolicy'), 'getAccountPolicyDetails', [policyNumber], authHeader);
    }

}
