import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import _ from 'lodash';
import { UWCompanyUtil } from 'wnice-portals-util-js';
// Imported First to allow Jutro styles to be overridden
import './App.scss';
import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import { routeConfirmationModal, DropdownMenuLink } from '@jutro/router';
import { AppFloorPlan } from '@jutro/legacy/floorplan';
import { useModal, ModalNextEmitter, Loader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import {
    useAuthentication,
    withAuthenticationProvider
} from '@xengage/gw-digital-auth-react';
import vmTranslator, { messages as commonMessages } from '@xengage/gw-platform-translations';
import { AccurateBreakpointPropagation } from '@xengage/gw-jutro-adapters-react';
import { EnrollmentService } from 'gw-capability-policyenrollment';
import { ViewModelServiceFactory } from '@xengage/gw-portals-viewmodel-js';
import { PolicyDetails, PremiumReportsPage, PremiumReportSuccessPage } from 'wnice-capability-policy-react';
import { AvailabilityService } from 'gw-capability-policycommon';
import { WniCEEndorsementService as EndorsementService } from 'wnice-capability-policychange';
import { CEPAPolicyChangeWizard } from 'wnice-capability-policychange-pa-react';
import { CEWCPremiumReportWizard } from 'wnice-capability-premiumreport-wc-react';
import { CEGLPremiumReportWizard } from 'wnice-capability-premiumreport-gl-react';
import { HOPolicyChangeWizard } from 'gw-capability-policychange-ho-react';
import { personalizedOfferComponentMap, personalizedOfferComponents } from 'gw-capability-personalizedoffers-react';
import { VehicleInfoLookupComponentMap, VehicleInfoLookupComponents } from 'gw-capability-vehicleinfo-react';
import { policyJobComponentMap, policyJobComponents } from 'gw-capability-policyjob-react';
import {
    BillingSummaryPage,
    MakePaymentPage,
    billingComponentMap,
    billingAdditionalComponents
} from 'gw-capability-billing-react';
import { AdditionalEnrollment } from 'gw-capability-enrollment-react';
import { UsersProfile } from 'gw-capability-profileinfo-react';
import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { PremiumReportWizardPageTemplateWithTitle } from 'wnice-portals-wizard-components-ui';
import { ProducerInfoService } from 'gw-capability-profileinfo';

import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { AccountBillingDetailsService, BillingService } from 'gw-capability-billing';
import { PolicyService } from 'gw-capability-policy';
import { ClaimDocumentService } from 'gw-capability-claimdocument';

import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import {
    platformComponentMap,
    platformComponents,
    ImageComponent,
} from 'gw-components-platform-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import BaseFNOLWizard, { ClaimsConfirmationPage, FNOLContactAdminPage, FNOLContactAgentPage } from 'gw-capability-fnol-common-react';
import { ContactAgentPage } from 'gw-pages-platform-react';
import { PolicyContactAgentPage } from 'gwce-pages-platform-react-ext';
import FNOLPAWizard from 'gw-capability-fnol-pa-react';
import FNOLWCWizard from 'gw-capability-fnol-wc-react';
import FNOLCAWizard from 'gw-capability-fnol-ca-react';
import FNOLHOWizard from 'gw-capability-fnol-ho-react';
import FNOLCPWizard from 'gw-capability-fnol-cp-react';
import FNOLBOPWizard from 'gw-capability-fnol-bop-react';
import FNOLGeneralWizard from 'gw-capability-fnol-gl-react';
import FNOLWTWizard from 'wnice-capability-fnol-wt-react';
import FNOLRTWizard from 'wnice-capability-fnol-rt-react';
import FNOLCPPWizard from 'wnice-capability-fnol-cpp-react';
import FNOLIMWizard from 'wnice-capability-fnol-im-react';

import {
    ClaimList, ClaimDetails, PolicyLevelCoverages
} from 'gw-capability-claim-react';
import { ClaimService } from 'gw-capability-claim';
import { FNOLService } from 'gw-capability-fnol';
import FaqPage from 'gw-capability-faq-react';
import { PolicyDocumentError } from 'gw-capability-document-react';
import { filterCapabilityRoutes } from '@xengage/gw-portals-config-js';
import appRoutes from './App.routes.metadata.json5';
import LandingPage from '../pages/LandingPage/LandingPage';
import messages from './App.messages';
import CustomerHeader from './components/header'

const modalEmitter = new ModalNextEmitter();

const { capabilitiesConfig } = appConfig;
const { getDefaultDomain } = UWCompanyUtil;

const componentMap = {
    landingpage: LandingPage,
    policydocumenterror: PolicyDocumentError,
    fnolwcwizard: FNOLWCWizard,
    basefnolwizard: BaseFNOLWizard,
    claimsconfirmationpage: ClaimsConfirmationPage,
    contactagent: ContactAgentPage,
    policycontactagent: PolicyContactAgentPage,
    fnolpawizard: FNOLPAWizard,
    fnolcontactadminpage: FNOLContactAdminPage,
    fnolcontactagentpage: FNOLContactAgentPage,
    FNOLWTWizard,
    FNOLRTWizard,
    fnolhowizard: FNOLHOWizard,
    additionalenrollmentpage: AdditionalEnrollment,
    claimlist: ClaimList,
    claimdetails: ClaimDetails,
    policydetailspage: PolicyDetails,
    papolicychangewizard: CEPAPolicyChangeWizard,
    wcpremiumreportwizard: CEWCPremiumReportWizard,
    glpremiumreportwizard: CEGLPremiumReportWizard,
    hopolicychangewizard: HOPolicyChangeWizard,
    billingsummarypage: BillingSummaryPage,
    premiumreportspage: PremiumReportsPage,
    premiumreportsuccesspage: PremiumReportSuccessPage,
    makepaymentpage: MakePaymentPage,
    usersprofile: UsersProfile,
    policylevelcoverages: PolicyLevelCoverages,
    faqpage: FaqPage,
    fnolcawizard: FNOLCAWizard,
    fnolcpwizard: FNOLCPWizard,
    fnolcppwizard: FNOLCPPWizard,
    fnolimwizard: FNOLIMWizard,
    fnolbopwizard: FNOLBOPWizard,
    fnolgeneralwizard: FNOLGeneralWizard
};

setComponentMapOverrides(
    {
        ...platformComponentMap,
        ...personalizedOfferComponentMap,
        ...VehicleInfoLookupComponentMap,
        ...policyJobComponentMap,
        ...billingComponentMap,
        WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
        PremiumReportWizardPageTemplateWithTitle: { component: 'PremiumReportWizardPageTemplateWithTitle' },
        // replace the native IMG component with a proxied version
        img: { component: 'img' },
    },
    {
        ...platformComponents,
        ...personalizedOfferComponents,
        ...VehicleInfoLookupComponents,
        ...policyJobComponents,
        ...billingAdditionalComponents,
        WizardPageTemplateWithTitle,
        PremiumReportWizardPageTemplateWithTitle,
        img: ImageComponent,
    }
);

const routesWithTitle = filterCapabilityRoutes(capabilitiesConfig, appRoutes.routes);
const burgerMenuRoutes = _.filter(routesWithTitle, {showOnNavBar: true})

function App() {
    const modalApi = useModal();
    const auth = useAuthentication();
    const translator = useTranslator();
    const [viewModelService, setViewModelService] = useState(undefined);
    const [loadingMask, updateLoadingMask] = useState(false);
    const getAvatarLinks = useCallback(() => {
        return [
            <DropdownMenuLink key={1} to="/userprofile">{translator(messages.account)}</DropdownMenuLink>,
            // <DropdownMenuLink key={2} to="/faq">{translator(messages.faq)}</DropdownMenuLink>,
        ];
    }, [translator]);

    const getLogoSrc = useCallback(() => {
        const domainCompany = getDefaultDomain();
        return domainCompany.src;
    }, []);

    const floorPlansConfig = useMemo(() => {
        return [{
            scrollContent: false,
            callbackMap:{
                renderCustomHeader: CustomerHeader
            },
            showFooter: false,
            routes: routesWithTitle,
            renderHeader: 'renderCustomHeader',
            header: {
                avatarChildren: getAvatarLinks(),
                avatarProps: {
                    username: auth.userInfo.name,
                    title: auth.userInfo.name,
                    subtitle: auth.userInfo.name
                },
                logoSrc: getLogoSrc(),
                showAppSwitcher: false,
                showNotifications: false,
                burgerMenuRoutes
            },
            showSubHeader: true
        }];
    }, [auth, getAvatarLinks, getLogoSrc]);

    useEffect(() => {
        const translatorFn = vmTranslator(translator);
        import(
            /* webpackChunkName: "product-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            setViewModelService(
                ViewModelServiceFactory.getViewModelService(result, translatorFn)
            );
        });
    }, [translator]);

    const handleError = useCallback(() => {
        return modalApi.showAlert({
            title: messages.error,
            message: messages.errorMessage,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(() => {
            updateLoadingMask(false);
        });
    }, []);
    const setLoadingMask = (newMask) => {
        // this method is added for the convenience of debugging
        updateLoadingMask(newMask);
    };

    const loadingRender = () => {
        const loadingDom = (
            <div className="loadingPageMask">
                <div className="loadingMask" />
                <Loader loaded={false} />
            </div>
        );
        return loadingMask ? loadingDom : null;
    };

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider
                value={{
                    AccountBillingDetailsService: AccountBillingDetailsService,
                    PolicyService: PolicyService,
                    EndorsementService: EndorsementService,
                    AvailabilityService: AvailabilityService,
                    FNOLService: FNOLService,
                    ClaimService: ClaimService,
                    ClaimDownloadService: ClaimDocumentService,
                    BillingService: BillingService,
                    EnrollmentService: EnrollmentService,
                    ContactService: ProducerInfoService,
                    loadingMask: { setLoadingMask, isLoadingMask: loadingMask }
                }}
            >
                <ErrorBoundary onError={handleError}>
                    <ViewModelServiceContext.Provider value={viewModelService}>
                        {loadingRender()}
                        <Router
                            basename="/account-management"
                            getUserConfirmation={(message,callback) => routeConfirmationModal(message,callback,modalEmitter)}>
                            <AppFloorPlan
                                callbackMap={{
                                    renderCustomHeader: CustomerHeader
                                }}
                                componentMap={componentMap}
                                floorPlans={floorPlansConfig}
                            />
                        </Router>
                    </ViewModelServiceContext.Provider>
                </ErrorBoundary>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}

export default withAuthenticationProvider()(App);
