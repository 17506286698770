import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate, WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { Claim, saveFNOLDetails } from 'gw-capability-fnol-common-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './FNOLIMLossTypePage.metadata.json5';
import messages from '../../FNOLIM.messages';

function FNOLPALossTypePage(props) {
    const modalApi = useModal();
    const {
        wizardData: claimVM,
        updateWizardData,
        history,
        history: {
            location: { state = {} }
        },
        authHeader
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isGenericWizard, updateIsGenericWizard] = useState(true);
    const { FNOLService } = useDependencies('FNOLService');
    const [pageData] = useState(state);
    const translator = useTranslator();
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLPALossTypePage');

    const {
        lossCause,
        glassHasBeenFixed,
        vehicleHasBeenTowed,
        claimNumber,
        policy: {
            policyType
        }
    } = claimVM.value

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = pageData;
        return !_.isEmpty(claimStatus);
    }, [pageData]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    useEffect(() => {
        if (glassHasBeenFixed === false || vehicleHasBeenTowed === false) {
            updateIsGenericWizard(false);
        } else {
            updateIsGenericWizard(true);
        }
    }, [glassHasBeenFixed, vehicleHasBeenTowed]);

    const handleLossCause = useCallback((value) => {
        const oldValue = _.get(claimVM, 'value.lossCause');
        if (oldValue !== value) {
            _.unset(claimVM, 'value.lossSubCause_Ext');
        }
        _.set(claimVM, 'lossCause', value);
        if (value !== 'glassOnly_EXT') {
            _.set(claimVM, 'glassHasBeenFixed', null);
        }
        if (value !== 'towingLaborOnly_EXT') {
            _.set(claimVM, 'vehicleHasBeenTowed', null);
        }
        updateWizardData(claimVM);
    }, [claimVM, updateWizardData]);

    const overrides = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        lossCause: {
            onValueChange: handleLossCause
        },
        glassHasBeenFixed: {
            visible: lossCause === 'glassOnly_EXT',
            required: lossCause === 'glassOnly_EXT'
        },
        vehicleHasBeenTowed: {
            visible: lossCause === 'towingLaborOnly_EXT',
            required: lossCause === 'towingLaborOnly_EXT',
            label: policyType === 'Watercraft_EXT' ? translator(messages.watercraftHasBeenTowed) : translator(messages.vehicleHasBeenTowed)
        }
    };

    const toContactAdminPage = useCallback(() => {
        history.push({
            pathname: `/fnol-contactAdmin`,
            state: {
                claimNumber,
                lossCause
            }
        });
        return false
    }, [claimNumber, history, lossCause])
    const onNext = useCallback(async () => {
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        if (!claimNumber) {
            if(!isGenericWizard){
                toContactAdminPage()
            }
            setIsLoading(true);
            return FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
                .then((response) => {
                    claimVM.value = new Claim(response);
                    return claimVM;
                })
                .catch(() => {
                    modalApi.showAlert({
                        title: messages.paCreateDraftErrorTitle,
                        message: messages.paCreateDraftErrorMessage,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                    return false;
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        setIsLoading(true)
        claimVM.value = await saveFNOLDetails(claimVM.value, authHeader)
        if(!isGenericWizard){
            toContactAdminPage()
        }
        setIsLoading(false)
        return claimVM;
    }, [FNOLService, authHeader, claimNumber, claimVM, isGenericWizard, modalApi, toContactAdminPage]);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM, overrides]
    );

    return (
        <WizardPage
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={onNext}
            template={WizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={readValue}
                overrideProps={overrides}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

FNOLPALossTypePage.propTypes = wizardProps;
FNOLPALossTypePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(withAuthenticationContext(FNOLPALossTypePage));
