import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader, useModal } from '@jutro/components';
import { withRouter } from 'react-router-dom';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { WniPremiumReportService } from 'wnice-capability-premiumreport';
import { WniDocumentRetrievalService } from 'wni-capability-gateway';
import { WniAccountBillingDetailsService as WniBillingService } from 'wnice-capability-billing';
import { DocumentsUtil } from 'wni-portals-util-js';

import metadata from './PremiumReportSuccessPage.metadata.json5';
import messages from './PremiumReportSuccessPage.messages';
import styles from './PremiumReportSuccessPage.module.scss';

function PremiumReportSuccessPage(props) {
    const {
        history,
        location: { state },
        authHeader
    } = props;

    const {
        jobNumber: reportNumber,
        policyNumber,
        accountNumber
    } = state;

    const translator = useTranslator();
    const modalApi = useModal();

    const [isLoadingData, updateIsLoadingData] = useState(false);

    const handlePrint = useCallback(async () => {
        updateIsLoadingData(true);
        const doc = await WniPremiumReportService.getPremiumReportDocument(reportNumber, authHeader);
        if (doc.publicID) {
            const successCallback = () => {
                updateIsLoadingData(false);
            };
            const errorCallback = () => {
                updateIsLoadingData(false);
                modalApi.showConfirm({
                    title: 'Error',
                    message: translator(messages.anErrorOccurred),
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: messages.ok,
                }).then(_.noop).catch(_.noop);
            };
            await DocumentsUtil.tryDownloadDocument(
                doc, authHeader, history, WniDocumentRetrievalService, successCallback, errorCallback
            );
        } else {
            updateIsLoadingData(false);
        }
    }, [authHeader, history, modalApi, reportNumber, translator]);

    const handleMakeAPayment = useCallback(() => {
        updateIsLoadingData(true);
        WniBillingService.getInvoiceStreamForPolicy(policyNumber, accountNumber, authHeader)
            .then((invoiceStreamID) => {
                history.push(`/billing-summary/${invoiceStreamID}`, {
                    toScreen: 'payment',
                    ignoreDefaultScreen: true
                });
            }, _.noop)
            .finally(() => {
                updateIsLoadingData(false);
            });

    }, [accountNumber, authHeader, history, policyNumber]);

    const overrideProps = useMemo(() => ({
        reportReferenceNumber: {
            visible: !!reportNumber,
            content: translator(messages.referenceNumber, { reportNumber: reportNumber })
        },

    }), [reportNumber, translator]);

    const resolvers = {
        resolveCallbackMap: {
            handlePrint: handlePrint,
            handleMakeAPayment: handleMakeAPayment
        },
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                id,
                path,
                overrideProps
            );
        },
        [overrideProps]
    );

    if (isLoadingData) {
        return <Loader loaded={!isLoadingData} />;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PremiumReportSuccessPage.propTypes = {
    history: PropTypes.shape({
        location: PropTypes.shape({
            state: PropTypes.shape({
                jobNumber: PropTypes.string.isRequired,
                policyNumber: PropTypes.string.isRequired,
                accountNumber: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        push: PropTypes.func.isRequired
    }).isRequired
};

export default withAuthenticationContext(withRouter(PremiumReportSuccessPage));
