import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import PaymentChoice from '../../../components/paymentChoice/PaymentChoice';
import metadata from './PaymentPage.metadata.json5';
import messages from '../PaymentPage.messages';

function PaymentPage(props) {
    const modalApi = useModal();
    const { authHeader } = useAuthentication();
    const { EndorsementService } = useDependencies('EndorsementService');
    const { wizardData: submissionVM, goNext, updateWizardData } = props;
    const [showAction, setShowAction] = useState(true);
    const onWizardFooterUpdate = (flag) => {
        setShowAction(flag);
    };
    const handlePayment = useCallback(
        async (jobID, paymentDetails) => {
            let isBound = false;
            try {
                submissionVM.value = await EndorsementService.bind(
                    [jobID, paymentDetails],
                    authHeader
                );
                isBound = true;
            } catch {
                modalApi.showAlert({
                    title: messages.errorBindingTransaction,
                    message: messages.checkError,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            }
            if (isBound) {
                updateWizardData(submissionVM);
                goNext();
            }
        },
        [EndorsementService, authHeader, goNext, submissionVM, updateWizardData]
    );

    const overrideProps = {
        paymentPage: {
            submissionVM: submissionVM,
            onPaymentCallback: handlePayment
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onWizardFooterUpdate: onWizardFooterUpdate
        },
        resolveComponentMap: {
            paymentchoicecomponent: PaymentChoice
        }
    };

    return (
        <WizardPage showNext={false} showCancel={showAction} showPrevious={showAction}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PaymentPage.propTypes = wizardProps;
export default withRouter(PaymentPage);
