import messages from './CEPATooltip.messages';

const PATooltips = {
    PAAcdntForCov_Ext : messages.PAAcdntForCov_Ext,
    PALiabilityCovExc_Ext : messages.PALiabilityCovExc_Ext,
    PARoadsideAssistCov_Ext : messages.PARoadsideAssistCov_Ext,
    PARoadsideAssistAKCov_Ext : messages.PARoadsideAssistAKCov_Ext,
    PARdsidAsstPlusCov_Ext : messages.PARdsidAsstPlusCov_Ext,
    PARdsidAsstPlusAKCov_EXT : messages.PARdsidAsstPlusAKCov_EXT,
    PANewVehRCOTCCov_EXT : messages.PANewVehRCOTCCov_EXT,
    PANewVehRCAKOTCCov_EXT : messages.PANewVehRCAKOTCCov_EXT,
    PANewVehRCCov_Ext : messages.PANewVehRCCov_Ext,
    PANewVehRCAKCov_Ext : messages.PANewVehRCAKCov_Ext,
    PANewVehRCCollCov_EXT : messages.PANewVehRCCollCov_EXT,
    PANewVehRCAKCollCov_Ext : messages.PANewVehRCAKCollCov_Ext,
    PALoanLeaseOTCCOV_EXT : messages.PALoanLeaseOTCCOV_EXT,
    PALoanLeaseAKOTCCov_EXT : messages.PALoanLeaseAKOTCCov_EXT,
    PALoanLeaseCov_Ext : messages.PALoanLeaseCov_Ext,
    PALoanLeaseAKCov_Ext : messages.PALoanLeaseAKCov_Ext,
    PALoanLeaseCollCov_EXT : messages.PALoanLeaseCollCov_EXT,
    PALoanLeaseCollAKCov_EXT : messages.PALoanLeaseCollAKCov_EXT,
    PATrpIntrptnCov_Ext : messages.PATrpIntrptnCov_Ext,
    PAOptLimitTrans_Ext : messages.PAOptLimitTrans_Ext,
    PAKeyRepCov_Ext : messages.PAKeyRepCov_Ext,
    PAKeyRepAKCov_Ext : messages.PAKeyRepAKCov_Ext,
    PAPetInjuryCov_EXT : messages.PAPetInjuryCov_EXT,
    PATNDCov_Ext : messages.PATNDCov_Ext,
    PATNDAKCov_Ext : messages.PATNDAKCov_Ext,
    PATNDMNCov_Ext : messages.PATNDMNCov_Ext,
    PALtdTNDCov_Ext : messages.PALtdTNDCov_Ext,
    PALtdTNDAKCov_Ext : messages.PALtdTNDAKCov_Ext,
    PALtdTNDMNCov_Ext : messages.PALtdTNDMNCov_Ext,
    PAPerPropCov_Ext : messages.PAPerPropCov_Ext,
    PAPerPropAkCov_Ext : messages.PAPerPropAkCov_Ext,
    PAPerPropSDCov_Ext : messages.PAPerPropSDCov_Ext,
    PAPerPropWICov_Ext : messages.PAPerPropWICov_Ext,
    PAPerPropMNCov_Ext : messages.PAPerPropMNCov_Ext,
    PAENORegVehUseIndCov : messages.PAENORegVehUseIndCov,
    PAENORegVehUseResCov : messages.PAENORegVehUseResCov,
    PAENOPubVehUseCovInd_EXT : messages.PAENOPubVehUseCovInd_EXT,
    PAENOPubVehUseCovRes_EXT : messages.PAENOPubVehUseCovRes_EXT
}

export default PATooltips