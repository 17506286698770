import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetails: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Policy Details',
        defaultMessage: 'Policy Details',
    },
    policyChangeEffectiveDate: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Policy Change Effective Date',
        defaultMessage: 'Policy Change Effective Date',
    },
    typeOfChange: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Select Type of Changes',
        defaultMessage: 'Select Type of Changes',
    },
    address: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Address Change',
        defaultMessage: 'Address Change',
    },
    vehicles: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Add, edit, or remove vehicle',
        defaultMessage: 'Add, edit, or remove vehicle',
    },
    drivers: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Add, edit, or remove driver',
        defaultMessage: 'Add, edit, or remove driver',
    },
    coverages: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Coverage change',
        defaultMessage: 'Coverage change',
    },
    paPolicyChangeTitle: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Policy Change - Personal Auto',
        defaultMessage: 'Policy Change - Personal Auto ({quoteNumber})'
    },
    infoWillBeSavedAsDraft: {
        id: 'endorsement.common.config.pa.infoWillBeSavedAsDraft',
        defaultMessage: 'The information entered previously will not be stored, do you want to continue?'
    }
});
