import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WniAccountBillingDetailsService {

    /**
     * Retrieves an invoice summary for account.
     *
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static getAccountInvoiceStreamInformation(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniBillingData'), 'getAccountInvoiceStreamInformation', [], authHeader);
    }

    /**
     * Retrieves details of invoices for the invoice id.
     *
     * @param {String} invoiceStreamId a public-id of invoice stream data
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static getInvoiceStreamDetails(invoiceStreamId, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniBillingData'), 'getInvoiceStreamDetails', [invoiceStreamId], authHeader);
    }

    /**
     * Set a payment instrument as automatic payment instrument.
     *
     * @param {String} invoiceStreamId a public-id of invoice stream data
     * @param {Object} instrument a payment instrument in PaymentInstrumentDTO
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static setPaymentGroupPaymentInformation(invoiceStreamId, instrument, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniBillingData'),
            'setPaymentGroupPaymentInformation', [invoiceStreamId, instrument], authHeader);
    }

    /**
     * Remove automatic payment.
     *
     * @param {String} invoiceStreamId a public-id of invoice stream data
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static removeAutomaticPayment(invoiceStreamId, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniBillingData'),
            'removeAutomaticPayment', [invoiceStreamId], authHeader);
    }

    /**
     * Pay for selected invoices with a payment instrument.
     *
     * @param {String} InvoiceStreamPublicId array of public-ids of invoices
     * @param {Object} amount a payment amount in BigDecimal
     * @param {Object} instrument a payment instrument in PaymentInstrumentDTO
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static makePaymentWithInstrument(InvoiceStreamPublicId, amount, instrument, authHeader = {}) {
        const directBillPaymentDto = {
            InvoiceStreamPublicId,
            amountPaid: amount,
            paymentInstrument: instrument
        };

        return JsonRPCService.send(getProxiedServiceUrl('wniDirectBillPayment'),
            'makePaymentWithInstrument', [directBillPaymentDto], authHeader);
    }

    static makePaymentByPolicyWithInstrument(InvoiceStreamPublicId, amount, instrument, policyNumber, authHeader = {}) {
        const directBillPaymentDto = {
            InvoiceStreamPublicId,
            amountPaid: amount,
            paymentInstrument: instrument
        };

        return JsonRPCService.send(getProxiedServiceUrl('wniDirectBillPayment'),
            'makePaymentByPolicyWithInstrument', [directBillPaymentDto, policyNumber], authHeader);
    }

    static getInvoiceStreamForPolicy(policyNumber, accountNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniBillingData'), 'getInvoiceStreamForPolicy', [policyNumber, accountNumber], authHeader);
    }

    static getReferenceNumber(middleNumber, isMultiAndNew, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('caPayment'), 'getReferenceNumber', [middleNumber, isMultiAndNew], authHeader);
    }
}
