import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ProductUtil } from 'wnice-portals-util-react';
import { WniCEPolicyService } from 'wnice-capability-policy';
import { WniPremiumReportService } from 'wnice-capability-premiumreport';

import CECLPolicyDetails from '../CECLPolicyDetails/CECLPolicyDetails';
import CEPLPolicyDetails from '../CEPLPolicyDetails/CEPLPolicyDetails';
import styles from './WniPolicyDetails.module.scss';

function WniPolicyDetails(props) {
    const { match, location } = props;
    const { authHeader } = useAuthentication();
    const [policyCommonDetails, updatePolicyCommonDetails] = useState({
        currentPeriodDetails: {},
        renewedPeriodDetails: {},
        hasRenewedPeriod: false
    });
    const [payAsYouGoPolicies, updatePayAsYouGoPolicies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isCommercialLine, setIsCommercialLine] = useState(false);

    const policyNumber = _.get(match, 'params.policyNumber');
    const targetId = _.get(location, 'state.targetId');

    const allProducts = ProductUtil.getAllProductsMap();

    const getPolicyDetails = useCallback(async () => {
        Promise.all([
            WniCEPolicyService.getAccountPolicyDetails(policyNumber, authHeader),
            WniPremiumReportService.getPayAsYouGoPolicies(authHeader)
        ])
            .then((response) => {
                const [policyDetails, payAsYouGoPolicyDetails] = response;
                const productItem = _.find(allProducts, (product) => product.code === policyDetails.currentPeriod.productCode_Ext);
                setIsCommercialLine(productItem.accountType === 'company');
                updatePolicyCommonDetails({
                    hasOpenPolicyChangeJob: policyDetails.hasOpenPolicyChangeJob_Ext,
                    expiredPeriodDetails: policyDetails.expiredPeriod_Ext ? policyDetails.expiredPeriod_Ext : {},
                    currentPeriodDetails: policyDetails.currentPeriod,
                    renewedPeriodDetails: policyDetails.renewedPeriod ? policyDetails.renewedPeriod : {},
                    hasExpiredPeriod: !_.isEmpty(policyDetails.expiredPeriod_Ext),
                    hasRenewedPeriod: !_.isEmpty(policyDetails.renewedPeriod)
                });
                updatePayAsYouGoPolicies(payAsYouGoPolicyDetails);
            })
            .finally(() => {
                setLoading(false);
                
            })
    }, [policyNumber, authHeader, allProducts]);

    useEffect(() => {
        if(targetId){
            setTimeout(()=>{
                const targetEl = document.getElementById(targetId)
                if(targetEl){
                    targetEl.scrollIntoView()
                }
            },200)
        }
    },[policyCommonDetails, targetId])

    useEffect(
        () => {
            try {
                getPolicyDetails();
            } catch (e) {
                throw new Error(e);
            }
        },[]
    );

    return (
        <Loader loaded={!loading}>
            <div className={styles.policyDetailsPageContainer}>
                {isCommercialLine ?
                    <CECLPolicyDetails 
                        {...props}
                        payAsYouGoPolicies={payAsYouGoPolicies}
                        policyCommonDetails={policyCommonDetails}
                    /> :
                    <CEPLPolicyDetails 
                        {...props}
                        policyCommonDetails={policyCommonDetails}
                    />
                }
            </div>
        </Loader>
    );
}

WniPolicyDetails.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        productCode: PropTypes.string
    }).isRequired
};

export default withRouter(WniPolicyDetails);
