import CEClauseComponent from './Clauses/CEClauseComponent';
import CEClausesComponentVM from './Clauses/CEClausesComponentVM';
import SingleClauseComponentVM from './Clauses/SingleClauseComponentVM';

import Currency from './CurrencyField/CurrencyField';
import FieldLinkComponent from './FieldLinkComponent/FieldLinkComponent';
import ImageComponent from './ImageComponent/ImageComponent';

import PaymentComponent from './PaymentComponent/PaymentComponentCE';
// import PaymentList from './PaymentList/PaymentList';
import QuestionSetComponent from './QuestionSetComponent/CEQuestionSetComponent';
import CEScheduleItemsComponent from './ScheduleItemsComponent/CEScheduleItemsComponent';
import TermConditionsPopup from './TermConditionsPopup/TermConditionsPopup';
import InvoiceCloudPopup from './InvoiceCloudPopup/InvoiceCloudPopup';


const platformComponentMap = {
    CEClausesComponentVM: { component: 'CEClausesComponentVM' },
    CEClauseComponent: { component: 'CEClauseComponent' },
    CEScheduleItemsComponent: { component: 'CEScheduleItemsComponent' },
    TermConditionsPopup: { component: 'TermConditionsPopup' },
    InvoiceCloudPopup: { component: 'InvoiceCloudPopup' },
    SingleClauseComponentVM: { component: 'SingleClauseComponentVM' },
    Currency: { component: 'Currency' },
    fieldLinkComponent: { component: 'FieldLinkComponent' },
    ImageComponent: { component: 'ImageComponent' },
    PaymentComponent: { component: 'PaymentComponent' },
    questionSet: { component: 'QuestionSetComponent' },
};

const platformComponents = {
    CEClausesComponentVM,
    CEClauseComponent,
    CEScheduleItemsComponent,
    TermConditionsPopup,
    InvoiceCloudPopup,
    SingleClauseComponentVM,
    Currency,
    FieldLinkComponent,
    ImageComponent,
    PaymentComponent,
    QuestionSetComponent,
};
export {
    CEClausesComponentVM,
    SingleClauseComponentVM,
    CEClauseComponent,
    CEScheduleItemsComponent,
    TermConditionsPopup,
    InvoiceCloudPopup,
    Currency,
    FieldLinkComponent,
    ImageComponent,
    PaymentComponent,
    QuestionSetComponent,
    // for Jutro
    platformComponentMap,
    platformComponents,
};
