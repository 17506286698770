import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Grid, Flex } from '@jutro/layout';
import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { PolicyDiffService } from 'gw-capability-policyjob';
import messages from './PolicyDiffComponent.messages';
import styles from './PolicyDiffComponent.module.scss';

import { Accordion, AccordionCard } from '@jutro/legacy/components';

const MIN_HEADER_LEVEL = 4;

const renderPolicyHeader = (label, children, headerNumber) => {
    const HeaderComponent = `h${headerNumber}`;
    return (
        <div className={styles.policyChangesSection}>
            <HeaderComponent>{label}</HeaderComponent>
            <Grid>
                {children.map((item) => {
                    // eslint-disable-next-line no-use-before-define
                    return renderChildren(item, headerNumber + 1);
                })}
            </Grid>
        </div>
    );
};

const renderPolicyValues = (values) => {
    const {
        label,
        isEdit,
        isAdd,
        isRemove,
        value2
    } = values;

    let changeLabel = ''
    if(isRemove){ changeLabel = 'Delete'}
    if(isAdd){ changeLabel = 'Add'}
    if(isEdit){ changeLabel = 'Update'}

    let displayLabel = label;
    if(isEdit && (label === 'Address Line 1' || label === 'City' || label === 'Postal Code' ||
        label === 'Address Line 2' || label === 'Address Line 3'
    )) {
        displayLabel = value2;
    }

    return (
        <Flex justifyContent="between">
            <span>{changeLabel}</span>
            <div>
                <span className={styles.newValue}>{displayLabel}</span>
            </div>
        </Flex>
    );
};

const renderPolicyAccordin = (label, children, headerNumber, index) => {
    return (
        <AccordionCard
            chevron
            id={`card${index}`}
            title={label}
        >
            <Grid>
                {children.map((item) => {
                    // eslint-disable-next-line no-use-before-define
                    return renderChildren(item, headerNumber + 1);
                })}
            </Grid>
        </AccordionCard>
    );
};

const renderPolicyAccordinCardNoChildren = (values, index) => {
    const {
        label,
    } = values;

    return (
        <AccordionCard
            chevron
            id={`card${index}`}
            title={label}
        >
            {renderPolicyValues(values)}
        </AccordionCard>
    );
};

const renderAccordinCard = (items, headerNumber, index) => {
    const { label, children } = items;

    if (children) {
        const sortedChildren = _.sortBy(children, [(child) => (child.children ? 1 : -1)]);
        return renderPolicyAccordin(label, sortedChildren, headerNumber, index);
    }
    return renderPolicyAccordinCardNoChildren(items);
};

const renderChildren = (items, headerNumber) => {
    const { label, children } = items;

    if (children) {
        const sortedChildren = _.sortBy(children, [(child) => (child.children ? 1 : -1)]);
        return renderPolicyHeader(label, sortedChildren, headerNumber);
    }
    return renderPolicyValues(items);
};

function CEPolicyDiffComponent(props) {
    const { value: jobNumber } = props;
    const { authHeader } = useAuthentication();
    const [policyDiffData, setPolicyDiffData] = useState([]);
    const translator = useTranslator();
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        PolicyDiffService.getPolicyDiffWithPrevious(jobNumber, authHeader).then((policyData) => {
            setPolicyDiffData(policyData);
        }).finally(() => setLoading(false));
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const policyDifference = useMemo(() => {
        if (_.isEmpty(policyDiffData)) {
            return null;
        }
        return (
            <div className={styles.policyChangesSection}>
                <h3 className={styles.policyChangesSectionTitle}>
                    {translator(messages.policyDiffTitle)}
                </h3>
                <Accordion
                    accordionStates={[
                        'card0','card1','card2','card3'
                    ]}
                    id="accordion"
                    closeOthers={false}
                    >
                    {policyDiffData.map((item, index) => renderAccordinCard(item, MIN_HEADER_LEVEL, index))}
                </Accordion>
            </div>
        );
    }, [policyDiffData, translator]);
    return (
        <Loader loaded={!isLoading}>
            <Grid vgap="large">
                {policyDifference}
            </Grid>
        </Loader>
    );
}

CEPolicyDiffComponent.propTypes = {
    value: PropTypes.string.isRequired
};

export default CEPolicyDiffComponent;
