import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './CEVehicleDriverAssignment.metadata.json5';

const CEVehicleDriverAssignment = (props) => {
    const {
        driverNameMap,
        vehicleVM,
        submissionVM,
        assignmentsData, 
        assignmentData,
        updateAssignmentData,
        showErrors
    } = props;

    const {
        fixedId,
        availableDrivers_Ext: availableDrivers,
        isVehicleExcess_Ext: excessVehicle
    } = vehicleVM.value;

    const {
        lobData: {
            personalAuto: {
                coverables: {
                    vehicles
                },
                isExcessVehicleMoreThanDriver_Ext: isExcessVehicleMoreThanDriver
            }
        }
    } = submissionVM.value;

    const initAssignmentData = _.cloneDeep(assignmentData);
    const vehiclesAssignable = vehicles.filter((veh) => veh.isDriverAssignmentAvailable_Ext);

    const onPrimaryDriverChange = useCallback((value) => {
        if (assignmentData.primaryDriverID === value) {
            return;
        }
        const newData = _.cloneDeep(assignmentData);
        newData.primaryDriverID = value;
        updateAssignmentData(newData);
    }, [assignmentData, updateAssignmentData]);

    const onOccasionalDriversChange = useCallback((value) => {
        const newData = _.cloneDeep(assignmentData);
        newData.occasionalDriverIDs = value ? value.map((v) => v.code) : [];
        updateAssignmentData(newData);
    }, [assignmentData, updateAssignmentData]);

    const getPrimaryDriverOptions = useCallback(() => {
        // for existing assigned vehicle, don't allow to modify the primary driver
        const initPrimaryDriverID = initAssignmentData.primaryDriverID;
        if (!_.isEmpty(initPrimaryDriverID)) {
            return [
                {
                    code: initPrimaryDriverID.toString(),
                    name: driverNameMap[initPrimaryDriverID]
                }
            ];
        }
        const availableDriverCopy = _.cloneDeep(availableDrivers);    
        const unassignedDriverOptions = availableDriverCopy.map((driver) => {
            return {
                code: driver.fixedId.toString(),
                name: driverNameMap[driver.fixedId],
            };
        });
        return unassignedDriverOptions;
    },[initAssignmentData, availableDrivers, driverNameMap]);

    const getOccasionalDriversOptions = useCallback(() => {
        if (excessVehicle || isExcessVehicleMoreThanDriver) {
            return [];
        }
        const occasionalDrivers = _.cloneDeep(availableDrivers);
        let occasionalDriversID = occasionalDrivers.map((driver) => driver.fixedId.toString());
        const initPrimaryDriverID = initAssignmentData.primaryDriverID;
        const initOccasionalDriversID = initAssignmentData.occasionalDriverIDs;
        if (!_.isEmpty(initOccasionalDriversID) && initOccasionalDriversID.length > 0) {
            occasionalDriversID = _.union(occasionalDriversID, initOccasionalDriversID);
        }
        if (!_.isEmpty(initPrimaryDriverID)) {
            occasionalDriversID = occasionalDriversID.filter((opt) => opt !== initPrimaryDriverID);
        }

        const otherVehicleAssignments = assignmentsData.filter((assignment) => assignment.vehicleID !== fixedId && !_.isEmpty(assignment.occasionalDriverIDs));
        if (!_.isEmpty(otherVehicleAssignments)) {
            const excludedDriverIDs = otherVehicleAssignments.flatMap((assignment) => assignment.occasionalDriverIDs);
            occasionalDriversID = occasionalDriversID.filter((opt) => !excludedDriverIDs.includes(opt));
        }
        const occasionalDriverOptions = occasionalDriversID.map((id) => {
            return {
                code: id.toString(),
                name: driverNameMap[id],
            };
        });
        return occasionalDriverOptions;
    }, [excessVehicle, isExcessVehicleMoreThanDriver, availableDrivers, initAssignmentData, assignmentsData, fixedId, driverNameMap]);

    const primaryDriverOptions = getPrimaryDriverOptions();
    const occasionalDriversOptions = getOccasionalDriversOptions();

    useEffect(() => {
        let { primaryDriverID, occasionalDriverIDs } = initAssignmentData;
        if (_.isEmpty(primaryDriverID) && 
            !_.isEmpty(primaryDriverOptions) && primaryDriverOptions.length === 1 && !isExcessVehicleMoreThanDriver) {
            primaryDriverID = primaryDriverOptions[0].code;
        }
        if (_.isEmpty(occasionalDriverIDs) && 
            !_.isEmpty(occasionalDriversOptions) && occasionalDriversOptions.length === 1 && 
            !_.isEmpty(vehiclesAssignable) && vehiclesAssignable.length === 1) {
            occasionalDriverIDs = [occasionalDriversOptions[0].code];
        }

        updateAssignmentData({
            vehicleID: fixedId,
            primaryDriverID: primaryDriverID,
            occasionalDriverIDs: occasionalDriverIDs
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getOccasionalDriversValue = useCallback(() => {
        if (_.isEmpty(assignmentData.occasionalDriverIDs)) {
            return [];
        }
        return assignmentData.occasionalDriverIDs.map((id) => {
            return {
                code: id,
                name: driverNameMap[id],
            };
        });
    }, [assignmentData, driverNameMap]);


    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false
        },
        primaryDriver: {
            availableValues: primaryDriverOptions,
            value: assignmentData.primaryDriverID,
            onValueChange: onPrimaryDriverChange,
            required: true,
            showErrors
        },
        occasionalDrivers: {
            availableValues: occasionalDriversOptions,
            value: getOccasionalDriversValue(),
            onValueChange: onOccasionalDriversChange,
            visible: !excessVehicle && occasionalDriversOptions.length > 0
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={assignmentData}
            overrideProps={overrideProps}
            showErrors={showErrors}
        />
    );
};

CEVehicleDriverAssignment.propTypes = {
    vehicleVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    assignmentsData: PropTypes.array,
    assignmentData: PropTypes.shape({}),
    updateAssignmentData: PropTypes.func.isRequired,
    showErrors: PropTypes.bool
};

CEVehicleDriverAssignment.defaultProps = {
    vehicleVM: {
        value: {}
    },
    assignmentsData: [],
    assignmentData: {},
    updateAssignmentData: _.noop,
    showErrors: false
};

export default CEVehicleDriverAssignment;