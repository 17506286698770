import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniDateUtil } from 'wni-portals-util-js';
import { ProductUtil } from 'wnice-portals-util-react';
import { useTranslator } from '@jutro/locale';

import metadata from './PolicyCommonDetails.metadata.json5';
import messages from './PolicyCommonDetails.messages';
import styles from './PolicyCommonDetails.module.scss';


function PolicyCommonDetails(props) {
    const translator = useTranslator();
    const { value: policyCommonDetails } = props;
    const containProducts = _.get(policyCommonDetails, 'lobs.commercialPackage.containProducts', [])
    const getFormattedDate = (path) => {
        const item = _.get(policyCommonDetails, path);
        return WniDateUtil.formatDateWithPattern(item, "MM/DD/YYYY");
    };

    const getPolicyType = (path) => {
        const item = _.get(policyCommonDetails, path);
        return ProductUtil.getProductName(item)
    };

    const overrideProps = {
        policyStartValue: {
            value: getFormattedDate('effective')
        },
        policyExpirationValue: {
            value: getFormattedDate('expiration')
        },
        policyTypeValue: {
            value: getPolicyType("productCode_Ext")
        },
        policyTypeColumn: {
            visible: containProducts.length === 0
        },
        cppPolicyTypeColumn: {
            visible: containProducts.length > 0
        },
        cppPolicyTypeValue: {
            value: `${translator(messages.cppPolicyContent)} ${containProducts}`,
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={policyCommonDetails}
            classNameMap={styles}
        />
    );
}

PolicyCommonDetails.propTypes = {
    value: PropTypes.shape({})
};

PolicyCommonDetails.defaultProps = {
    value: {}
};

export default PolicyCommonDetails;
