import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import {
    withRouter,
} from 'react-router-dom';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { Loader, TooltipIcon } from '@jutro/components';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { WniPremiumReportService } from 'wnice-capability-premiumreport';
import { UsersProfileDetailsService } from 'gw-capability-profileinfo';
import { PolicyService } from 'gw-capability-policy';
import { AMPSubmissionDraftService } from 'gw-capability-quoteandbind';
import { DocumentService } from 'gw-capability-policydocument';
import { InvoiceCloudPopup } from 'gw-components-platform-react';
import { useWniModal } from 'wni-components-platform-react';
import { getNormalizedLOBName, isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';
import { generateURL } from '@xengage/gw-portals-url-js';
import { useTranslator, IntlContext } from '@jutro/locale';
import { CEConfigUtil } from 'wnice-portals-util-js';

import { WniUsersProfileDetailsService } from 'wnice-capability-profileinfo';
import { BreakpointTrackerContext } from '@jutro/layout';

import appConfig from 'app-config';

import MobilePolicyTable from './MobilePolicyTable/MobilePolicyTable'
import styles from './LandingPage.module.scss';
import metadata from './LandingPage.metadata.json5';
import messages from './LandingPage.messages';
import getTableOverrides from './utils/policyTableUtils'

const shouldRenewalNotificationShow = (accountPolicyData) => {
    return accountPolicyData.some((policy) => policy.periods.length > 1);
};

const getAccountNumbers = (policies) => {
    return _.uniq(_.map(policies, 'accountNumber'))
}

const { capabilitiesConfig } = appConfig;
const invoiceCloudBaseUrl = CEConfigUtil.getInvoiceCloudBaseUrl();
const myAccountUrl = CEConfigUtil.getMyAccountUrl();

function LandingPage(props) {
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const modalApi = useWniModal();
    
    const { authHeader } = useAuthentication();
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phoneWide' || breakpoint === 'phone';

    const [accountBillingData, setAccountBillingData] = useState([]);
    const [accountPolicyData, setAccountPolicyData] = useState([]);
    const [payAsYouGoPolicyData, setPayAsYouGoPolicyData] = useState([]);
    const [hasPaperDeliveryMethod, setHasPaperDeliveryMethod] = useState(false);
    const [accountData, setAccountData] = useState([]);
    const [isLoading, setLoadingState] = useState(false);
    const [accountsNumber, setAccountsNumber] = useState([]);

    const sortPolicies = useCallback((policies) => {
        // active -> scheduled -> pending cancell -> cancelled -> expired
        const newPolicies =  _.map(policies, (policy) => {
            const displayStatus = policy.periods[0].displayStatus_Ext
            _.set(policy, 'statusOrderNumber', 6);
            if (displayStatus === 'In Force') {
                _.set(policy, 'statusOrderNumber', 1);
            }
            if (displayStatus === 'Scheduled') {
                _.set(policy, 'statusOrderNumber', 2);
            }
            if (policy.periods[0].pendingCancellationExists_Ext) {
                _.set(policy, 'statusOrderNumber', 3);
            }
            if (displayStatus === 'Canceled') {
                _.set(policy, 'statusOrderNumber', 4);
            }
            if (displayStatus === 'Expired') {
                _.set(policy, 'statusOrderNumber', 5);
            }
            return policy;
        });

        const sortedPolicies = _.orderBy(newPolicies, ['statusOrderNumber', 'periods[0].effective'], ['asc', 'desc']);

        return sortedPolicies;
    }, [])

    useEffect(() => {
        setLoadingState(true);
        let billingPromise = Promise.resolve([]);
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })) {
            billingPromise = AccountBillingDetailsService.getAccountBillingSummary(authHeader);
        }
        Promise.all([billingPromise.then(setAccountBillingData),
            PolicyService.getAccountPolicySummaries(authHeader)
                .then((policies) => {
                    const sortedPolicies = sortPolicies(policies)
                    setAccountPolicyData(sortedPolicies)
                    setAccountsNumber(getAccountNumbers(policies))
                }),
            WniPremiumReportService.getPayAsYouGoPolicies(authHeader).then(setPayAsYouGoPolicyData),
            UsersProfileDetailsService.getAccountsContactSummaries(authHeader).then(setAccountData),
            WniUsersProfileDetailsService.hasPaperDeliveryMethod(authHeader).then(setHasPaperDeliveryMethod),
            WniUsersProfileDetailsService.updatePolicyholderPortalFlag(authHeader)
        ]).finally(() => {
            // fix account number column may not display
            setTimeout(()=> {
                setLoadingState(false);
            }, 300)
        });
        // disable re render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const aggregateBilling = useCallback((invoices) => {
        if (invoices.length === 0) {
            return null;
        }

        let total = 0;
        let due = new Date(invoices[0].dueDate);

        _.each(invoices, (invoice) => {
            total += invoice.amountDue.amount;
            const newDue = new Date(invoice.dueDate);
            if (newDue.getTime() < due.getTime()) {
                due = newDue;
            }
        });

        return {
            amountDue: {
                amount: total,
                currency: invoices[0].amountDue.currency
            },
            earliestDue: due
        };
    }, []);

    const getMyBalanceOverDueAndCurrentData = useCallback(
        (invoiceStatus) => {
            if (accountBillingData.activeInvoices) {
                const invoices = accountBillingData.activeInvoices;
                return aggregateBilling(
                    invoices.filter((invoice) => invoice.invoiceStatus === invoiceStatus)
                );
            }
            return null;
        },
        [accountBillingData.activeInvoices, aggregateBilling]
    );

    const getBalanceData = useCallback(() => {
        const overdue = getMyBalanceOverDueAndCurrentData('due');
        const current = getMyBalanceOverDueAndCurrentData('billed');
        if (overdue && current) {
            return {
                amount: current.amountDue.amount + overdue.amountDue.amount,
                currency: current.amountDue.currency
            };
        }
        if (overdue || current) {
            return (overdue || current).amountDue;
        }
        return null;
    }, [getMyBalanceOverDueAndCurrentData]);

    const readValue = useCallback(
        (id, path) => {
            if (path === 'balanceAmount') {
                return getBalanceData();
            }
            if (path === 'overDueAmount') {
                return _.get(getMyBalanceOverDueAndCurrentData('due'), 'amountDue');
            }
            if (path === 'currentAmount') {
                return _.get(getMyBalanceOverDueAndCurrentData('billed'), 'amountDue');
            }
            if (path === 'dueDate') {
                return _.get(getMyBalanceOverDueAndCurrentData('due'), 'earliestDue');
            }
            return _.get(accountBillingData, path);
        },
        [accountBillingData, getBalanceData, getMyBalanceOverDueAndCurrentData]
    );

    const policyWithIDCard = useMemo(() => {
        let periodWithIDCard;
        accountPolicyData.some((policyData) => {
            return policyData.periods.some((period) => {
                if (period.idCardPublicID) {
                    periodWithIDCard = period;
                    return true;
                }
                return false;
            });
        });
        return periodWithIDCard;
    }, [accountPolicyData]);

    const getIDCardQuickLink = useMemo(() => {
        if (!policyWithIDCard) {
            return null;
        }
        return DocumentService.downloadPolicyDocument(
            policyWithIDCard.idCardPublicID,
            policyWithIDCard.idCardSessionID
        );
    }, [policyWithIDCard]);

    const automatedPaymentURL = useMemo(() => {
        const billingID = _.get(accountBillingData, 'manualPayPaymentGroups[0]');

        if (!billingID) {
            return undefined;
        }
        return `billing-summary/${billingID}`;
    }, [accountBillingData]);

    const firstAccountSummaryDefined = useMemo(() => {
        return accountData.length <= 1 && !_.isEmpty(accountData);
    }, [accountData]);

    const claimCapabilityCheck = useMemo(() => {
        return isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'fnol' });
    }, []);

    const onQuoteQuickLinkClick = useCallback(
        (lob) => () => {
            const accountSummary = accountData[0];
            const submission = {
                baseData: {
                    productCode: lob,
                    accountHolder: accountSummary,
                    accountNumber: accountSummary.accountNumber,
                    policyAddress: accountSummary.accountContact.primaryAddress,
                    periodStartDate: LocalDateUtil.today()
                }
            };
            AMPSubmissionDraftService.createForAccount(submission, authHeader).then(
                (savedSubmission) => {
                    const { url } = appConfig.quoteAndBuy;
                    const location = `${url}/quote-${getNormalizedLOBName(lob)}`;
                    const params = {
                        quoteID: savedSubmission.quoteID,
                        postalCode: savedSubmission.baseData.policyAddress.postalCode
                    };

                    window.location = generateURL(location, params);
                }
            );
        },
        [accountData, authHeader]
    );

    const getFormattedDate = (rowData, index, property) => {
        const activePeriod = _.first(rowData.periods);
        return intl.formatDate(new Date(activePeriod[property.id]), { year: 'numeric', month: 'numeric', day: 'numeric' });
    };

    const onFileAClaimQuickLinkClick = useCallback(
        () => () => {
            const { history } = props;
            return history.push({
                pathname: '/fnol-select-policy',
                state: {
                    redirectPath: '/home',
                    claimData: { lossDate: new Date() }
                }
            });
        },
        [props]
    );

    const handleLinkClick = useCallback(
        (event) => {
            const { history } = props;
            if(event.path === '/billing-summary'){
                return history.push(event.path, {toScreen: 'payment'});
            }
            return history.push(event.path)
            
        },
        [props]
    );

    const showBillingSection = useMemo(() => {
        return isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' });
    }, []);

    const onClickManageSavedPaymentMethodsLink = useCallback(() => {
        const baseUrl = `${invoiceCloudBaseUrl}/-/vendors/ic/payment-methods`;
        // const accountNum = `BC$${invoiceStreamDetails.displayName}`;
        const accountNum = '';
        const icUrl = `${baseUrl}?accountNum=${accountNum}`;
        const componentProps = {
            icTitle: 'IC CloudSSO - Manage Saved Payment Methods',
            icUrl: icUrl
        };
        modalApi.showModal(<InvoiceCloudPopup {...componentProps} />).then(() => {
            // 
        }, _.noop);
    }, [modalApi]);

    const onClickManageScheduledPaymentsLink = useCallback(() => {
        const baseUrl = `${invoiceCloudBaseUrl}/-/vendors/ic/scheduled-payments`;
        // const accountNum = `BC$${invoiceStreamDetails.displayName}`;
        const accountNum = '';
        const icUrl = `${baseUrl}?accountNum=${accountNum}`;
        const componentProps = {
            icTitle: 'IC CloudSSO - Manage Scheduled Payments',
            icUrl: icUrl
        };
        modalApi.showModal(<InvoiceCloudPopup {...componentProps} />).then(() => {
            // 
        }, _.noop); 
    }, [modalApi]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }
    const override = {
        '@field': {
            labelPosition: 'left'
        },
        pageTitle: {
            content: `${translator(messages.accountSummary)} (${accountsNumber.join(', ')})`
        },
        myBalanceOverdueAmount: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('due'))
        },
        myBalanceCurrentAmount: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('billed'))
        },
        dueDateContainer: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('due'))
        },
        dueDateLabel: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('billed'))
        },
        payNowButton: {
            visible: firstAccountSummaryDefined
        },
        mediaSidebar: {
            showContact: accountData.length = 1
        },
        policiesTable: {
            visible: !isPhone,
            data: accountPolicyData
        },
        mobilePolicyTable: {
            visible: isPhone,
            data: accountPolicyData,
            accountData,
            accountPolicyData
        },
        goToMyAccountSystemLink:{
            href: myAccountUrl
        },
        agencyTable: {
            data: accountPolicyData
        },
        printAnIDCardLink: {
            visible: false,
            href: getIDCardQuickLink,
            target: '_blank'
        },
        payAsYouGoReportingLink: {
            visible: payAsYouGoPolicyData.length > 0,
            to: '/premium-reports'
        },
        setupAutomatedPaymentsLink: {
            onClick: {
                callback: 'handleLinkClick',
                callbackProps: {
                    path: automatedPaymentURL,
                }
            },
            visible: false
        },
        managePaymentMethodsLink: {
            visible: false
        },
        manageScheduledPaymentsLink: {
            visible: false
        },
        updateMyDetailsLink: {
            visible: !!hasPaperDeliveryMethod
        },
        manageDeliveryMethodTooltip: {
            visible: !!hasPaperDeliveryMethod
        },
        getAPAQuote: {
            onClick: onQuoteQuickLinkClick('PersonalAuto'),
            visible: appConfig.quoteAndBuy.ampQuote && firstAccountSummaryDefined
        },
        getAHOQuote: {
            onClick: onQuoteQuickLinkClick('Homeowners'),
            visible: appConfig.quoteAndBuy.ampQuote && firstAccountSummaryDefined
        },
        renewalNotification: {
            visible: shouldRenewalNotificationShow(accountPolicyData)
        },
        fileAClaimLink: {
            onClick: onFileAClaimQuickLinkClick(),
            visible: claimCapabilityCheck
        },
        tileGroupContainer: {
            visible: showBillingSection
        },
        makeAPaymentLink: {
            visible: showBillingSection
        },
        ...getTableOverrides({accountData, accountPolicyData}),
        agencyTableContainer: {
            visible: false  // CEI-3969
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveValue: readValue,
        resolveCallbackMap: {
            handleLinkClick,
            getFormattedDate,
            onClickManageSavedPaymentMethodsLink,
            onClickManageScheduledPaymentsLink
        },
        resolveComponentMap: {
            MobilePolicyTable,
            TooltipIcon
        }
    };

    return <MetadataContent uiProps={metadata.pageContent} overrideProps={override} {...resolvers} />;
}

export default withRouter(LandingPage);
