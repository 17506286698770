/* eslint-disable camelcase */
import _ from 'lodash';
import { VehicleUtil } from 'wni-portals-util-js';

const ASSIGNMENT_P = 'Primary';
const ASSIGNMENT_O = 'Occasional';
const ASSIGNMENT_S = 'StudentAway';

function isCustomEquipmentQuestionValid(vehicleVM) {
    const questionSet = _.get(vehicleVM, 'vehicleQuestionSets_Ext.children[0].value');
    // Custom Equipment Endorsement table will be mandatory to be entered when user selects the " Is vehicle modified or does vehicle have any special equipment" as "Yes"
    const isAnsweredYes= _.get(questionSet, 'answers.VehModHasSpecialEquipment_Ext') === 'true';
    if (!isAnsweredYes) {
        return true;
    }
    const customEquipments = _.get(vehicleVM, 'customEquipments_Ext.value');
    return isAnsweredYes && !_.isEmpty(customEquipments);
};

function uniqueVin(vehicles) {
    let vincheckerror = false;
    const groupedVehicles = _.groupBy(vehicles, (vehicle) => {
        return vehicle.vin;
    });
    Object.keys(groupedVehicles).forEach((vehicle) => {
        if (groupedVehicles[vehicle].length > 1) {
            vincheckerror = true;
            return false;
        }
        return true;
    });
    return !vincheckerror;
};

function isVehicleValid(vehicleVM, vehicles, assignmentData) {
    const {
        statedValue_Ext: statedValue,
        isStatedValueRequired_Ext: isStatedValueRequired,
        vehRTParked_Ext: vehRTParked,
        isVehRTParkedRequired_Ext: isVehRTParkedRequired,
        owners_Ext,
        isDriverAssignmentAvailable_Ext: isDriverAssignmentAvailable
    } = vehicleVM.value;
    const dtoValid = vehicleVM.aspects.valid && vehicleVM.aspects.subtreeValid;
    const stateValueValid = !isStatedValueRequired || (isStatedValueRequired && !_.isNil(statedValue) && !_.isNil(statedValue.amount));
    const vehRTParkedValid = !isVehRTParkedRequired || (isVehRTParkedRequired && !_.isNil(vehRTParked));
    const ownersValid = !_.isEmpty(owners_Ext);
    const vehicleAssignmentValid = isDriverAssignmentAvailable ? !_.isEmpty(assignmentData.primaryDriverID) : true;
    return dtoValid && stateValueValid && vehRTParkedValid && ownersValid && vehicleAssignmentValid && isCustomEquipmentQuestionValid(vehicleVM) && uniqueVin(vehicles);
};

function isAllVehicleValid(vehiclesVM, vehicles, assignmentsData) {
    return vehiclesVM.every((vm) => {
        const selectedVehicle = vm.value;
        const assignmentData = assignmentsData.find((data) => data.vehicleID === selectedVehicle.fixedId);
        return isVehicleValid(vm, vehicles, assignmentData);
    });
};

function getVehicleErrors(vehicleVM, vehicles) {
    const errors = [];
    if (!uniqueVin(vehicles)) {
        const error = 'VIN is not unique.';
        errors.push(error);
    }
    if (!isCustomEquipmentQuestionValid(vehicleVM)) {
        const error = 'Underwriting Questions: Please Fill In At Least One Row In Custom Equipment Coverage.';
        errors.push(error);
    }
    return errors;
};

function combineVehicleAssignmentData(vehicleAssignments, availableDrivers, isExcessVehicleMoreThanDriver) {
    const { 
        vehicleID, 
        excessVehicle_Ext: excessVehicle 
    } = vehicleAssignments[0];
    const newData = {};
    newData.vehicleID = vehicleID;
    const primaryAssignment = vehicleAssignments.find((vd) => vd.assignment_Ext === ASSIGNMENT_P);
    const primaryAssignmentID = primaryAssignment && primaryAssignment.driverID
        ? primaryAssignment.driverID.toString() : undefined;
    const primaryOperator = vehicleAssignments.find((vd) => _.isNil(vd.assignment_Ext) && !_.isNil(vd.vehicleID));
    const primaryOperatorID = primaryOperator && primaryOperator.driverID
        ? primaryOperator.driverID.toString() : undefined;
    let primaryDriverID = excessVehicle ? primaryOperatorID : primaryAssignmentID;
    if (_.isEmpty(primaryDriverID) && availableDrivers?.length === 1 && !isExcessVehicleMoreThanDriver) {
        primaryDriverID = availableDrivers[0].fixedId.toString();
    }
    newData.primaryDriverID = primaryDriverID;

    const occasionalAssignments = vehicleAssignments.filter((vd) => vd.driverID && vd.assignment_Ext === ASSIGNMENT_O);
    newData.occasionalDriverIDs = occasionalAssignments.map((vd) => vd.driverID.toString());

    const studentAwayAssignments = vehicleAssignments.filter((vd) => vd.driverID && vd.assignment_Ext === ASSIGNMENT_S);
        newData.studentAwayDriverIDs = studentAwayAssignments.map((vd) => vd.driverID.toString());

    return newData;
};

function combineVehiclesAssignmentData(assignments, vehicles, isExcessVehicleMoreThanDriver) {
    const assignmentsData = [];
    let assignmentData = {};
    _.each(vehicles, (vehicle) => {
        const { 
            fixedId,
            availableDrivers_Ext: availableDrivers 
        } = vehicle;
        const vehicleAssignments = assignments.filter((assignment) => VehicleUtil.isIdEqual(assignment.vehicleID, fixedId));
        const excessVehicle = vehicle.isVehicleExcess_Ext;
        if (!_.isEmpty(vehicleAssignments)) {
            assignmentData = combineVehicleAssignmentData(vehicleAssignments, availableDrivers, isExcessVehicleMoreThanDriver);
        }
        assignmentsData.push({
            vehicleID: vehicle.fixedId,
            excessVehicle,
            ...assignmentData
        });
    })
    return assignmentsData;
}

function divideVehicleAssignmentData(newData) {
    // generate array of VehicleDriverDTO to call service
    const result = [];
    if (!_.isEmpty(newData.primaryDriverID)) {
        const vd = {};
        vd.vehicleID = newData.vehicleID;
        vd.driverID = newData.primaryDriverID;
        if (!newData.excessVehicle) {
            vd.Assignment_Ext = ASSIGNMENT_P;
        }
        result.push(vd);
    }
    if (!_.isEmpty(newData.occasionalDriverIDs)) {
        newData.occasionalDriverIDs.forEach((id) => {
            const vd = {};
            vd.vehicleID = newData.vehicleID;
            vd.driverID = id;
            vd.Assignment_Ext = ASSIGNMENT_O;
            result.push(vd);
        });
    }
    if (!_.isEmpty(newData.studentAwayDriverIDs)) {
        newData.studentAwayDriverIDs.forEach((id) => {
            const vd = {};
            vd.vehicleID = newData.vehicleID;
            vd.driverID = id;
            vd.Assignment_Ext = ASSIGNMENT_S;
            result.push(vd);
        });
    }
    return result;
};
 
export default {
    isVehicleValid,
    getVehicleErrors,
    combineVehicleAssignmentData,
    combineVehiclesAssignmentData,
    divideVehicleAssignmentData,
    isAllVehicleValid
};
