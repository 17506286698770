import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import CEGLPremiumReportDetailsPage from './pages/Details/CEGLPremiumReportDetailsPage';
import CEGLPremiumReportPremiumsPage from './pages/Premiums/CEGLPremiumReportPremiumsPage';

setComponentMapOverrides(
    {
        CEGLPremiumReportDetailsPage: { component: 'CEGLPremiumReportDetailsPage' },
        CEGLPremiumReportPremiumsPage: { component: 'CEGLPremiumReportPremiumsPage' }
    },
    {
        CEGLPremiumReportDetailsPage,
        CEGLPremiumReportPremiumsPage
    }
);

export { default as CEGLPremiumReportWizard } from './CEGLPremiumReportWizard';
