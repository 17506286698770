import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import { FieldLinkComponent, Currency as CurrencyField } from 'gw-components-platform-react';
import { IntlContext } from '@jutro/locale';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { WniDocumentRetrievalService } from 'wni-capability-gateway';
import { CheckboxField } from '@jutro/legacy/components';
import { Link } from '@jutro/router';
import { WniDateUtil } from 'wni-portals-util-js';

import metadata from './InvoicesTable.metadata.json5';
import styles from './InvoicesTable.module.scss';
import messages from './InvoicesTable.messages';
import CE_LOB_MAP from './ce-lob-map'


const getBillStatus = (item, index, property) => {
    if (item.paidStatus === 'fullypaid') {
        return messages.paid;
    }
    return {
        id: `typekey.InvoiceStatus.${item[property.id]}`,
        defaultMessage: item[property.id]
    };
};

const renderFormattedCurrencyField = (item, index) => {
    return (
        <div className={styles.currencyContainer}>
            <CurrencyField
                id={`currency_${index}`}
                value={item}
                readOnly
                hideLabel
            />
        </div>
    );
};

const getFormattedCurrency = (item, index, property) => {
    return renderFormattedCurrencyField(item.policy?.[property.id]);
};



const getPolicyType = (item) => {
    if(item.policy){
        const {productCode} = item.policy;
        return CE_LOB_MAP[productCode] || productCode
    }
    return "No Data"
};

const getPolicyLink = (item) => {
    if(item.policy){
        if(/^\d+$/.test(item.policy.policyNumber)){
            return (
                <FieldLinkComponent
                    id={`policy${item.policy.policyNumber}`}
                    to={`/account-policy-details/${item.policy.policyNumber}`}
                    className={styles.policyLinkContainer}
                    value={item.policy.policyNumber}
                />
            );
        }else{
            return item.policy.policyNumber
        }
    }
    return "No Data"
};
function InvoicesTable(props) {
    const {
        invoiceSummary,
        selectedInvoices,
        onUpdateSelectedInvoices,
        showPaidDate,
        showMinimumDueAndLogTip,
        showSelect
    } = props;

    const intl = useContext(IntlContext);

    const getInvoiceNumberCell = (item, index, property) => {
        if(item.notFirstLine){
            return ''
        }
        if(item.documentPublicId_Ext){
            const link = WniDocumentRetrievalService.getOnDemandDocumentLink(
                item.documentPublicId_Ext,
                "wniBCDocumentRetrieval"
            )
            return (
                <Link href={link} target="_blank">
                    {item[property.id]}
                </Link>
            );
        }
        
        return item[property.id];
    };

    const getFormattedDate = useCallback((item, index, property) => {
        if(item[property.id]){
            return WniDateUtil.formatDateWithPattern(item[property.id], 'MM/DD/YYYY');
        }
        return null
    }, []);

    const getCheckboxDataCell = useCallback(
        (item, index, property) => {
            const value = item[property.id];
            const selectedIds = [...selectedInvoices];
            const isChecked = selectedIds.indexOf(value) > -1;
            return (
                <CheckboxField
                    id={`${property.id}_${index}`}
                    name={`${property.id}_${index}`}
                    value={isChecked}
                    onValueChange={(selected) => {
                        if (selected) {
                            selectedIds.push(value);
                            onUpdateSelectedInvoices(selectedIds);
                        } else {
                            const updated = selectedIds.filter((iter) => iter !== value);
                            onUpdateSelectedInvoices(updated);
                        }
                    }}
                />
            );
        },
        [selectedInvoices, onUpdateSelectedInvoices]
    );

    const handlePrint = () => {
        window.print();
    };

    const overrideProps = {
        tablePaymentScheduleDetail: {
            data: invoiceSummary
        },
        tablePaymentScheduleDetailLastPaymentDate: {
            visible: showPaidDate
        },
        tablePaymentScheduleDetailPay: {
            visible: showSelect
        },
        tablePaymentScheduleDetailOutstandingAmount: {
            visible: showMinimumDueAndLogTip
        },
        clickLogTipId: {
            visible: showMinimumDueAndLogTip
        },
        tablePaymentScheduleDetailBillStatus: {
            visible: showMinimumDueAndLogTip
        },
        printBtn: {
            visible: showMinimumDueAndLogTip
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getFormattedDate,
            getBillStatus,
            getFormattedCurrency,
            getInvoiceNumberCell,
            getCheckboxDataCell,
            getPolicyType,
            getPolicyLink,
            onPrint: handlePrint
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

InvoicesTable.propTypes = {
    invoiceSummary: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    selectedInvoices: PropTypes.arrayOf(PropTypes.string),
    onUpdateSelectedInvoices: PropTypes.func,
    showSelect: PropTypes.bool,
    showPaidDate: PropTypes.bool,
    showMinimumDueAndLogTip: PropTypes.bool
};

InvoicesTable.defaultProps = {
    selectedInvoices: [],
    onUpdateSelectedInvoices: undefined,
    showSelect: false,
    showPaidDate: false,
    showMinimumDueAndLogTip: true
};

export default InvoicesTable;
