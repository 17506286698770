import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { QuestionSetsParser } from '@xengage/gw-portals-questionsets-js';
import { QuestionSetUtils } from '@xengage/gw-portals-util-js';
import { useModal } from '@jutro/components';

// eslint-disable-next-line import/no-unresolved
import questionSetsMetadata from 'question-sets-metadata';
import { R1Config } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import messages from './CEQuestionSetComponent.messages'

const {
    UWQuestionConfig
} = R1Config;

function CEQuestionSetComponent(props) {
    const translator = useTranslator();
    const {
        id,
        onValidate,
        onValueChange,
        value: aQuestionSetAnswersValue,
        path: pathToAnswers,
        labelPosition,
        title,
        hideTitle,
        isReadOnly,
        contentFilter,
        contentMapper,
        showErrors,
        isRequiredForIssuance,
        showQuestionChangeWarning
    } = props;
    const modalApi = useModal();

    const handleAnswerChange = useCallback((value, path) => {
        const {
            code: questionSetCode,
            answers: questionSetAnswer
        } = aQuestionSetAnswersValue;
        const qsOrderedQuestions = questionSetsMetadata[questionSetCode].orderedQuestions;

        QuestionSetUtils.cleanDependantQuestions(
            qsOrderedQuestions,
            value,
            path,
            questionSetAnswer
        );

        let val = value;

        if (value.code) {
            val = value.code;
        }
        if (val.length === 0) {
            val = null;
        }
        _.set(aQuestionSetAnswersValue, `answers.${path}`, val);

        if (onValueChange) {
            onValueChange(aQuestionSetAnswersValue, pathToAnswers);
        }
    }, [aQuestionSetAnswersValue, onValueChange, pathToAnswers]);

    const handleValueChange = useCallback((value, path) => {
        if (value === undefined) {
            return;
        }
        const beforeValue = _.get(aQuestionSetAnswersValue, `answers.${path}`);
        if (showQuestionChangeWarning && (!_.isNil(beforeValue) || !_.isEmpty(beforeValue))) {
            modalApi.showConfirm({
                title: messages.wantToChangeYourAnswer,
                message: messages.changeAnswerMayImpactCov,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.yes
            }).then((results) => {
                if (results === 'confirm') {
                    handleAnswerChange(value, path);
                }
            }, _.noop);
        } else {
            handleAnswerChange(value, path);
        }  
    }, [aQuestionSetAnswersValue, showQuestionChangeWarning, modalApi, handleAnswerChange]);
    
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: false,
            labelPosition: labelPosition,
        }
    };

    const {
        viewModel: qsViewModel,
        presentationMetadata
    } = new QuestionSetsParser(
        aQuestionSetAnswersValue,
        questionSetsMetadata,
        translator,
        contentFilter,
        contentMapper,
        isReadOnly
    );

    const [editingValueMap, setEditingValueMap] = useState({})
    const [qsMetadata, updateQsMetadata] = useState({
        ...presentationMetadata,
        id: 'QuestionSetForm'
    })

    const handleReadValue = useCallback((questionID, path) => {
        if (Object.keys(editingValueMap).includes(path)) {
            return editingValueMap[path]
        }
        return _.get(qsViewModel.answers, path);
    }, [editingValueMap, qsViewModel.answers]);

    const portalRequiredUWQuestionKeys = []
        .concat(UWQuestionConfig.explainBoxUWQuestionCode);

    // _.set(qsMetadata, 'id', 'QuestionSetForm');
    useEffect(()=>{
        if(isRequiredForIssuance) {
            const questionContent = _.get(qsMetadata, 'content[0].content', []);
            questionContent.forEach((item) => {
                item.componentProps.required = true;
                item.componentProps.showRequired = false;
            });
            qsMetadata.content[0].content = questionContent;
        }else{
            const questionContent = _.get(qsMetadata, 'content[0].content', []);
            questionContent.forEach((item) => {
                item.componentProps.required = false;
            });
            qsMetadata.content[0].content = questionContent;
        }
        updateQsMetadata(_.cloneDeep(qsMetadata))
    }, [isRequiredForIssuance])

    /**
     * After transform qsMetadata to react state this phase code don't work anymore
     */
    if (!_.isEmpty(portalRequiredUWQuestionKeys)) {
        const questionContent = _.get(qsMetadata, 'content[0].content', []);
        portalRequiredUWQuestionKeys.forEach((key) => {
            const questionIndex = _.findIndex(questionContent, { id: key });
            if (questionIndex !== -1) {
                questionContent[questionIndex].componentProps.required = true;
                questionContent[questionIndex].componentProps.showRequired = true;
            }
        });
        qsMetadata.content[0].content = questionContent;
    }

    const header = hideTitle ? undefined : (<h2 id={`${id}_questionSetHeader`} className="wizardTitle">{translator(title)}</h2>);
    const resolveCallbackMap = {
        onTextValueChange: (value, path) => {
            setEditingValueMap({
                ...editingValueMap,
                [path]: value
            })
        },
        onTextValueBlur: (i, e) => {
            const path = e.model
            handleValueChange(editingValueMap[path], path)
            const newEditingValueMap = {...editingValueMap}
            delete newEditingValueMap[path]
            setEditingValueMap(newEditingValueMap)
        }
    }
    return (
        <div>
            {header}
            <ViewModelForm
                uiProps={qsMetadata}
                model={qsViewModel}
                resolveValue={handleReadValue}
                overrideProps={overrideProps}
                callbackMap={resolveCallbackMap}
                onValueChange={handleValueChange}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </div>
    );
}

/**
 * @memberof gw-components-platform-react.QuestionSetComponent
 * @property {Object} propTypes.value - value of the property being rendered
 * @property {string} propTypes.path - the path where this component is attached
 * @property {function(newAnswers, pathToAnswers)} propTypes.onValueChange - the
 *                                      function invoked when the value is changed
 */
CEQuestionSetComponent.propTypes = {
    value: PropTypes.shape({
        code: PropTypes.string
    }).isRequired,
    path: PropTypes.string.isRequired,
    onValueChange: PropTypes.func,
    onValidate: PropTypes.func,
    labelPosition: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.shape({}),PropTypes.string]),
    hideTitle: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    contentFilter: PropTypes.func,
    contentMapper: PropTypes.func,
    showErrors: PropTypes.bool,
    showQuestionChangeWarning: PropTypes.bool
};
CEQuestionSetComponent.defaultProps = {
    onValueChange: undefined,
    onValidate: undefined,
    labelPosition: 'left',
    id: undefined,
    title: '',
    hideTitle: false,
    isReadOnly: false,
    contentFilter: undefined,
    contentMapper: (content) => {
        return content;
    },
    showErrors: false,
    showQuestionChangeWarning: false
};
export default CEQuestionSetComponent;
