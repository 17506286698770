import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLPAWizard from './FNOLPAWizard';
import FNOLPATypeOfIncidentPage from './pages/TypeOfIncident/TypeOfIncidentPage';
import FNOLPATypeOfRepairPage from './pages/TypeOfRepair/TypeOfRepairPage';
import FNOLPARepairFacilityPage from './pages/RepairFacility/RepairFacilityPage';
import FNOLPACollisionDetailsPage from './pages/Details/Collision/CollisionDetailsPage';
import FNOLPAGlassDetailsPage from './pages/Details/Glass/GlassDetailsPage';
import FNOLPATheftDetailsPage from './pages/Details/Theft/TheftDetailsPage';
import FNOLPARepairFacilityMapPage from './pages/RepairFacilityMap/RepairFacilityMapPage';
import FNOLPALossTypePage from './pages/LossType/FNOLPALossTypePage';
import FNOLPAIncidentsPage from './pages/LossDetail/FNOLPALossDetailPage';
import FNOLPAOtherLossDetailPage from './pages/OtherLossDetail/FNOLPAOtherLossDetailPage';

setComponentMapOverrides(
    {
        FNOLPAWizard: { component: 'FNOLPAWizard' },
        FNOLPATypeOfIncidentPage: { component: 'FNOLPATypeOfIncidentPage' },
        FNOLPATypeOfRepairPage: { component: 'FNOLPATypeOfRepairPage' },
        FNOLPARepairFacilityPage: { component: 'FNOLPARepairFacilityPage' },
        FNOLPACollisionDetailsPage: { component: 'FNOLPACollisionDetailsPage' },
        FNOLPAGlassDetailsPage: { component: 'FNOLPAGlassDetailsPage' },
        FNOLPATheftDetailsPage: { component: 'FNOLPATheftDetailsPage' },
        FNOLPARepairFacilityMapPage: { component: 'FNOLPARepairFacilityMapPage' },
        FNOLPALossTypePage: { component: 'FNOLPALossTypePage' },
        FNOLPAIncidentsPage: { component: 'FNOLPAIncidentsPage' },
        FNOLPAOtherLossDetailPage: { component: 'FNOLPAOtherLossDetailPage' }
    },
    {
        FNOLPAWizard,
        FNOLPATypeOfIncidentPage,
        FNOLPATypeOfRepairPage,
        FNOLPARepairFacilityPage,
        FNOLPACollisionDetailsPage,
        FNOLPAGlassDetailsPage,
        FNOLPATheftDetailsPage,
        FNOLPARepairFacilityMapPage,
        FNOLPALossTypePage,
        FNOLPAIncidentsPage,
        FNOLPAOtherLossDetailPage
    }
);

export {
    FNOLPAWizard as default,
    FNOLPATypeOfIncidentPage,
    FNOLPATypeOfRepairPage,
    FNOLPARepairFacilityPage,
    FNOLPACollisionDetailsPage,
    FNOLPAGlassDetailsPage,
    FNOLPATheftDetailsPage,
    FNOLPARepairFacilityMapPage,
    FNOLPALossTypePage,
    FNOLPAIncidentsPage,
    FNOLPAOtherLossDetailPage
};
