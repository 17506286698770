import React from 'react';
import {
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { FNOLBusinessLocationPage } from 'gwce-capability-fnol-common-react-ext';
import { ProductUtil } from 'wnice-portals-util-react';
import { withRouter } from 'react-router-dom';

function FNOLCPBusinessLocationPage(props) {
    return <FNOLBusinessLocationPage 
                {...props}
                LOB_NAME={ProductUtil.CP_LOB_NAME}
            />;
}

FNOLCPBusinessLocationPage.propTypes = wizardProps;
export default withRouter(withAuthenticationContext(FNOLCPBusinessLocationPage));
