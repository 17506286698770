import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    DocumentsUtil,
    WniDateUtil,
    ServiceErrorUtil,
} from 'wni-portals-util-js';
import { useHistory } from 'react-router-dom';
import { useWniModal } from 'wni-components-platform-react';
import { WniDocumentRetrievalService } from 'wni-capability-gateway';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import PolicyCommonCoverageTable from '../../PolicyCommonCoverageTable/PolicyCommonCoverageTable';
import metadata from './PolicyRoadTrailOperatorInfo.metadata.json5';
import messages from './PolicyRoadTrailOperatorInfo.messages';
import styles from './PolicyRoadTrailOperatorInfo.module.scss';

function PolicyRoadTrailOperatorInfo(props) {
    const translator = useTranslator();
    const [openItems, setOpenItems] = useState([]);
    const { value: rtCoverages, idCardDocument } = props;
    const modalApi = useWniModal();
    const history = useHistory();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);
    const { authHeader } = useAuthentication();

    const toggleShowHide = useCallback((item) => {
        const openedItem = _.clone(openItems);
        const index = _.indexOf(openedItem, item);
        if (index === -1) {
            openedItem.push(item);
            setOpenItems(openedItem);
        } else {
            openedItem.splice(index, 1);
            setOpenItems(openedItem);
        }
    }, [openItems]);

    const getCell = useCallback((items, index, property) => {
        const value = items[property.id];
        if (property.id === 'dateOfBirth') {
            return WniDateUtil.formatDateWithPattern(value, "MM/DD/YYYY");
        }
        return value;
    }, []);

    const successCallback = useCallback(() => {
        setLoadingMask(false);
    }, [setLoadingMask]);
    const errorCallback = useCallback(() => {
        setLoadingMask(false);
        modalApi.showConfirm({
            title: 'Error',
            message: ServiceErrorUtil.prependWithFriendMessage(),
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok,
        });
    }, [modalApi, setLoadingMask]);
    const onIDCardClick = useCallback(async (summaryDocument) => {
        setLoadingMask(true);
        await DocumentsUtil.tryDownloadDocument(
            summaryDocument,
            authHeader,
            history,
            WniDocumentRetrievalService,
            successCallback,
            errorCallback
        );
    }, [authHeader, errorCallback, history, setLoadingMask, successCallback]);

    const generateVehicleOverrides = useCallback(() => {
        const overrides = rtCoverages.vehicleDTOs.map((vehicleDTO, index) => {
            const vehicleCoverages = vehicleDTO.coverages;
            return {
                [`vehicleCoverageInfo${index}`]: {
                    data: vehicleCoverages,
                    show: openItems
                },
                [`showContentToggleBtn${index}`]: {
                    onClick: () => toggleShowHide(index),
                    content: _.includes(openItems, index)
                        ? translator(messages.hideVehicleCoverages)
                        : translator(messages.showVehicleCoverages)
                },
                [`vehicleCoverageContainer${index}`]: {
                    visible: _.includes(openItems, index)
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [openItems, rtCoverages, toggleShowHide, translator]);

    const overrides = {
        driverTable: {
            data: rtCoverages.operatorDTOs
        },
        lineCoveragesTableSection: {
            data: rtCoverages.coverageDTOs
        },
        vehicleIDCardLink: {
            visible: !_.isNil(idCardDocument),
            onClick: () => onIDCardClick(idCardDocument)
        },
        ...generateVehicleOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            vehiclecoverage: PolicyCommonCoverageTable,
            linecoverage: PolicyCommonCoverageTable
        },
        resolveCallbackMap: {
            getCell
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={rtCoverages}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyRoadTrailOperatorInfo.propTypes = {
    value: PropTypes.shape({
        coverageDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        vehicleDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        operatorDTOs: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    idCardDocument: PropTypes.shape({})
};

export default PolicyRoadTrailOperatorInfo;
