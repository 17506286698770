import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLWTWizard from './FNOLWTWizard';
import FNOLWTLossTypePage from './pages/LossType/FNOLWTLossTypePage';
import FNOLWTOtherLossDetailPage from './pages/OtherLossDetail/FNOLWTOtherLossDetailPage';
// import FNOLPATypeOfIncidentPage from './pages/TypeOfIncident/TypeOfIncidentPage';
// import FNOLPATypeOfRepairPage from './pages/TypeOfRepair/TypeOfRepairPage';
// import FNOLPARepairFacilityPage from './pages/RepairFacility/RepairFacilityPage';
// import FNOLPACollisionDetailsPage from './pages/Details/Collision/CollisionDetailsPage';
// import FNOLPAGlassDetailsPage from './pages/Details/Glass/GlassDetailsPage';
// import FNOLPATheftDetailsPage from './pages/Details/Theft/TheftDetailsPage';
// import FNOLPARepairFacilityMapPage from './pages/RepairFacilityMap/RepairFacilityMapPage';
// import FNOLPALossTypePage from './pages/LossType/FNOLPALossTypePage';
// import FNOLPAContactAdminPage from './pages/ContactAdmin/FNOLPAContactAdminPage';
// import FNOLPAIncidentsPage from './pages/Incidents/FNOLPAIncidentsPage';

setComponentMapOverrides(
    {
        FNOLWTWizard: { component: 'FNOLWTWizard' },
        // FNOLPATypeOfIncidentPage: { component: 'FNOLPATypeOfIncidentPage' },
        FNOLWTLossTypePage: { component: 'FNOLWTLossTypePage' },
        FNOLWTOtherLossDetailPage: { component: 'FNOLWTOtherLossDetailPage' }
        // FNOLPATypeOfRepairPage: { component: 'FNOLPATypeOfRepairPage' },
        // FNOLPARepairFacilityPage: { component: 'FNOLPARepairFacilityPage' },
        // FNOLPACollisionDetailsPage: { component: 'FNOLPACollisionDetailsPage' },
        // FNOLPAGlassDetailsPage: { component: 'FNOLPAGlassDetailsPage' },
        // FNOLPATheftDetailsPage: { component: 'FNOLPATheftDetailsPage' },
        // FNOLPARepairFacilityMapPage: { component: 'FNOLPARepairFacilityMapPage' },
        // FNOLPALossTypePage: { component: 'FNOLPALossTypePage' },
        // FNOLPAContactAdminPage: { component: 'FNOLPAContactAdminPage' },
        // FNOLPAIncidentsPage: { component: 'FNOLPAIncidentsPage' }
    },
    {
        FNOLWTWizard,
        // FNOLPATypeOfIncidentPage,
        FNOLWTLossTypePage: FNOLWTLossTypePage,
        FNOLWTOtherLossDetailPage: FNOLWTOtherLossDetailPage,
        // FNOLPATypeOfRepairPage,
        // FNOLPARepairFacilityPage,
        // FNOLPACollisionDetailsPage,
        // FNOLPAGlassDetailsPage,
        // FNOLPATheftDetailsPage,
        // FNOLPARepairFacilityMapPage,
        // FNOLPALossTypePage,
        // FNOLPAContactAdminPage,
        // FNOLPAIncidentsPage
    }
);

export {
    FNOLWTWizard as default,
    // FNOLPAContactAdminPage
};
