import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { CurrencyValue } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { showProdsCompltdOpsActual, PREMISES_PRODUCTSOPS_SUBLINE } from '../../utils/GLPremiumReportUtil';
import metadata from './SublinePremiumComponent.metadata.json5';
import commonStyles from '../../CEGLPremiumReportWizard.module.scss';


function SublinePremiumComponent(props) {
    const {
        data: costData
    } = props;

    const { sublineName, premiumsData } = costData;
    const isPremisesProductsOpsSubline = sublineName === PREMISES_PRODUCTSOPS_SUBLINE;
    const hasProdsCompltdOpsActualColumn = showProdsCompltdOpsActual(premiumsData);

    const renderCell = (item, index, property) => {
        return item[property.id];
     };

    const renderPremiumCell = (item, index, property) => {
        return (
            <CurrencyValue
                id={`currency_${index}`}
                value={item[property.id]}
                showFractions
            />
        );
    };

    const overrides = {
        sublineTitle: {
            content: sublineName
        },
        premisesProductsOpsPremiumsContainer: {
            visible: isPremisesProductsOpsSubline
        },
        premiumWithProdsCompltdOpsActualTable: {
            visible: hasProdsCompltdOpsActualColumn,
            data: premiumsData
        },
        premiumWithoutProdsCompltdOpsActualTable: {
            visible: !hasProdsCompltdOpsActualColumn,
            data: premiumsData
        },
        otherSublinePremiumsTable: {
            visible: !isPremisesProductsOpsSubline,
            data: premiumsData
        }
    };

    const resolvers = {
        resolveClassNameMap: commonStyles,
        resolveCallbackMap: {
            renderCell,
            renderPremiumCell
        }     
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={costData}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

SublinePremiumComponent.propTypes = {
    data: PropTypes.array.isRequired
};

export default SublinePremiumComponent;
