import React, {useState} from "react"
import _ from "lodash";
import { Button, DropdownMenuButton, ToggleField } from "@jutro/components";
import { DropdownMenuLink } from '@jutro/router';

import { DisplayColumn, Table } from '@jutro/legacy/datatable';
import { Flex, FlexItem } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import SinglePedestrian from "./SinglePedestrian/SinglePedestrian";
import messages from './Pedestrians.messages'

const PedestrianQuestionValue = {
    Yes: 'Yes',
    No: 'No'
}

const AddNewPerson = 'AddNewPerson'

const Pedestrians = (props) => {
    const {
        pedestrianIncidents = [],
        relatedContacts = [],
        savePedestrianIncident,
        removePedestrianIncident
    } = props;

    const translator = useTranslator();

    const [pedestrianQuestionValue, setPedestrianQuestion] = useState(() => {
        return pedestrianIncidents.length > 0 ? PedestrianQuestionValue.Yes : PedestrianQuestionValue.No
    })
    const [singlePedestrianPopupStatus, setSinglePedestrianPopupStatus] = useState({
        isOpen: false,
        originPedestrianIncident: {},
        editingPedestrianIndex: undefined
    })

    const closePopup = () => {
        setSinglePedestrianPopupStatus({
            isOpen: false,
            originPedestrianIncident: {},
            editingPedestrianIndex: undefined
        })
    }

    const onPopupCancel = () => {
        closePopup()
    }

    const onPopupSave = async (pedestrianIncident) => {
        await savePedestrianIncident(pedestrianIncident)
        closePopup()
    }

    return <>
        <ToggleField
            id='pedestrianQuestion'
            label={translator(messages.PedestrianQuestion)}
            labelPosition='left'
            availableValues={[
                {
                    code: PedestrianQuestionValue.Yes,
                    name: translator(messages.Yes)
                },
                {
                    code: PedestrianQuestionValue.No,
                    name: translator(messages.No)
                }
            ]}
            onValueChange={(value) => {
                setPedestrianQuestion(value)
            }}
            disabled={pedestrianIncidents.length > 0}
            value={pedestrianQuestionValue}
        />
        {pedestrianQuestionValue === PedestrianQuestionValue.Yes &&
            <>
                <Flex
                    id="injuryFlexButton"
                    justifyContent="right"
                >
                    <FlexItem>
                        <Button
                            variant="primary"
                            icon="gw-add"
                            onClick={() => setSinglePedestrianPopupStatus({
                                isOpen: true,
                                originPedestrianIncident: {
                                    injuried: {
                                        publicID: AddNewPerson,
                                        subtype: 'Person',
                                        primaryAddress: {
                                            country: 'US'
                                        }
                                    }
                                }
                            })}
                            disabled={singlePedestrianPopupStatus.isOpen}
                            label={translator(messages.AddPedestrian)}
                        />
                    </FlexItem>
                </Flex>
                <Table
                    data={pedestrianIncidents.map((e, index) => ({...e, number: index + 1}))}
                >
                    <DisplayColumn
                        id="pedestrianSectionIDColumn"
                        header={translator(messages.Number)}
                        path="number"
                        textAlign='center'
                    />
                    <DisplayColumn
                        id="pedestrianLastNameColumn"
                        header={translator(messages.PedestrianLastName)}
                        textAlign='center'
                        renderCell={(item) => {
                            const {
                                injuried: {
                                    publicID
                                }
                            } = item
                            const pedestrian = relatedContacts.find((contact) => contact.publicID === publicID)
                            return _.get(pedestrian, 'lastName')
                        }}
                    />
                    <DisplayColumn
                        id="actionColumn"
                        textAlign='right'
                        renderCell={(item, index) => {
                            return <DropdownMenuButton
                                id={`DropdownMenuBtn_${index}`}
                                icon="gw-expand-more"
                                size="small"
                                iconPosition="right"
                                disabled={singlePedestrianPopupStatus.isOpen}
                            >
                                <DropdownMenuLink
                                    icon="gw-edit"
                                    iconPosition="left"
                                    onClick={() => {
                                        const country = _.get(item, 'injuried.primaryAddress.country', 'US');
                                        _.set(item, 'injuried.primaryAddress.country', country); 
                                        setSinglePedestrianPopupStatus({
                                            isOpen: true,
                                            originPedestrianIncident: item,
                                            editingPedestrianIndex: index
                                        })
                                    }
                                }
                                >
                                    {translator(messages.Edit)}
                                </DropdownMenuLink>
                                <DropdownMenuLink
                                    icon="gw-delete"
                                    iconPosition="left"
                                    onClick={() => removePedestrianIncident(pedestrianIncidents[index])}
                                >
                                    {translator(messages.Delete)}
                                </DropdownMenuLink>
                            </DropdownMenuButton>
                        }}
                    />
                </Table>
                {singlePedestrianPopupStatus.isOpen && <SinglePedestrian
                    originPedestrianIncident={singlePedestrianPopupStatus.originPedestrianIncident}
                    editingPedestrianIndex={singlePedestrianPopupStatus.editingPedestrianIndex}
                    onPopupCancel={onPopupCancel}
                    onPopupSave={onPopupSave}
                />}
            </>
        }
        
    </>
}

export default Pedestrians
