import { defineMessages } from 'react-intl';

export default defineMessages({
    enterEmailVerificationCode: {
        id: 'usersprofile.popup.email-verification.Please enter your email verification code.',
        defaultMessage: 'Please enter your email verification code.'
    },
    save: {
        id: 'usersprofile.popup.email-verification.Save',
        defaultMessage: 'Save'
    },
    wrongCodeMsg: {
        id: 'usersprofile.popup.email-verification.The code you entered is not correct. Please try again.',
        defaultMessage: 'The code you entered is not correct. Please try again.'
    },
    resendCode: {
        id: 'usersprofile.popup.email-verification.Resend Code',
        defaultMessage: 'Resend Code'
    },
    resendCodeMsg: {
        id: 'usersprofile.popup.email-verification.The code you entered is no longer valid. Click below to receive a new one.',
        defaultMessage: 'The code you entered is no longer valid. Click below to receive a new one.'
    },


});
