import { defineMessages } from 'react-intl';

export default defineMessages({
    paDriver: {
        id: 'endorsement.pa.directives.templates.pa-edit-driver.Driver',
        defaultMessage: 'Driver',
    },
    paDrivers: {
        id: 'endorsement.pa.directives.templates.pa-edit-driver.Drivers',
        defaultMessage: 'Drivers',
    },
    paNewDriver: {
        id: 'endorsement.pa.directives.templates.pa-edit-driver.New Driver',
        defaultMessage: 'New Driver',
    },
    licenceMatchMsg: {
        id: 'quoteandbind.pa.controllers.PADriverDetailsCtrl.LicenceMatchMsg',
        defaultMessage: 'Sorry, but this drivers license number matches that of {driver}. Please ensure that all licence numbers are unique.',
    },
    licenseNotUnique: {
        id: 'quoteandbind.pa.controllers.PADriverDetailsCtrl.Licence number is not unique',
        defaultMessage: 'Licence number is not unique'
    },
    addAnotherDriver: {
        id: 'endorsement.pa.views.pa-driver-details.Add Another Driver?',
        defaultMessage: 'Add Another Driver?'
    },
    addDriver: {
        id: 'endorsement.pa.views.pa-driver-details.Add Driver',
        defaultMessage: 'Add Driver'
    },
    edit: {
        id: 'endorsement.pa.views.pa-driver-details.Edit',
        defaultMessage: 'Edit'
    },
    delete: {
        id: 'endorsement.pa.views.pa-driver-details.Delete',
        defaultMessage: 'Delete'
    },
    questionTitle: {
        id: 'endorsement.pa.views.pa-driver-details.Driver Questions',
        defaultMessage: 'Driver Questions'
    },
    paDriverMaritalStatus: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Marital Status',
        defaultMessage: 'Marital Status',
    },
    paDriverMaritalStatusPlaceholder: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose Marital Status --',
        defaultMessage: '-- Choose Marital Status --',
    },
    paDriverRelInsured: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Relationship to Insured',
        defaultMessage: 'Relationship to Insured',
    },
    chooseRalInsured: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose Relationship to Insured --',
        defaultMessage: '-- Choose Relationship to Insured --',
    },
    wantToRemoveDriver: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Want to remove this driver?',
        defaultMessage: 'Are you sure you want to remove this driver?',
    },
    wantToRemoveDriverMsg: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.wantToRemoveDriverMsg',
        defaultMessage: 'By clicking "yes", this driver will be removed and no longer insured under this policy. Do you want to continue?',
    },
});
