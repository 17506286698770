import React, { useState, useCallback } from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { isCapabilityEnabled, getNormalizedLOBName } from '@xengage/gw-portals-config-js';
import { DocumentService } from 'gw-capability-policydocument';
import {
    PolicyCommonDetails,
    CECLPolicyCommonDocuments as PolicyCommonDocuments,
    PolicyCommonContacts,
    PolicyCoverages
} from 'gw-capability-policy-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './CECLPolicyDetails.metadata.json5';
import messages from '../PolicyDetails/WniPolicyDetails.messages';
import styles from './CECLPolicyDetails.module.scss';

const handlePrint = () => {
    window.print();
};

function CECLPolicyDetails(props) {
    const { history, match, 
        payAsYouGoPolicies: initPayAsYouGoPolicies,
        policyCommonDetails: initPolicyCommonDetails
    } = props;
    const [policyCommonDetails] = useState(initPolicyCommonDetails);
    const [payAsYouGoPolicies] = useState(initPayAsYouGoPolicies);
    const [writableDocuments, updateWritableDocuments] = useState([]);
    const translator = useTranslator();
    const { capabilitiesConfig } = appConfig;
    const policyNumber = _.get(match, 'params.policyNumber');

    const redirectToEndorsement = useCallback(
        (policyDetails, requestType) => {
            const productCode = _.findKey(policyDetails.lobs, (lob) => !_.isEmpty(lob));
            history.push(`/endorsement-${getNormalizedLOBName(productCode)}`,
                { policyNumber, policyDetails, requestType });
        },
        [history, policyNumber]
    );

    const onClickPayAsYouGoReporting = useCallback(
        () => {
            history.push('/premium-reports');
        },
        [history]
    );

    const canChangePolicy = useCallback(
        (policyPeriod) => {
            if (!_.isEmpty(policyPeriod)) {
                const productCode = _.findKey(policyPeriod.lobs, (lob) => !_.isEmpty(lob));
                if (_.isEmpty(productCode)) {
                    return false;
                }
                const expired = policyPeriod.displayStatus_Ext === "Expired";
                
                return (
                    isCapabilityEnabled({
                        capabilitiesConfig,
                        capabilityName: 'policychange',
                        lob: productCode
                    }) && policyPeriod.canBeChanged && !expired
                );
            }
            return false;
        },
        [capabilitiesConfig]
    );

    const getPolicyDownloadLink = useCallback(() => {
        return DocumentService.downloadPolicyDocument(
            _.get(policyCommonDetails.currentPeriodDetails, 'idCardPublicID'),
            _.get(policyCommonDetails.currentPeriodDetails, 'idCardSessionID')
        );
    }, [policyCommonDetails]);

    const getPolicyStatus = () => {
        const status =  _.get(policyCommonDetails.currentPeriodDetails, 'displayStatus_Ext');
        if(status === 'In Force') {
            return '- Active';
        }
        if(status === 'Scheduled') {
            return '';
        }
        return `- ${status}`;
    };

    const overrideProps = {
        pageTitle: {
            content: translator(messages.pageTitle, {
                policyNumber: policyCommonDetails.currentPeriodDetails.policyNumber,
                policyStatus: getPolicyStatus()
            })
        },
        policyChangeNotification: {
            visible: !!policyCommonDetails.hasOpenPolicyChangeJob
        },
        epxiredPeriod: {
            heading: policyCommonDetails.hasExpiredPeriod ? translator(messages.expiredTab) : '',
            visible: policyCommonDetails.hasExpiredPeriod
        },
        currentPeriod: {
            heading: translator(messages.currentTab)
        },
        renewalPeriod: {
            heading: policyCommonDetails.hasRenewedPeriod ? translator(messages.renewalTab) : '',
            disabled: !policyCommonDetails.hasRenewedPeriod
        },
        changePolicyButtonId: {
            visible: canChangePolicy(
                policyCommonDetails.currentPeriodDetails,
                policyCommonDetails.currentPeriodDetails.productName
            )
        },
        payAsYouGoReportingButtonId: {
            visible: _.includes(payAsYouGoPolicies, policyNumber)
        },
        renewalPolicyChangeButtonContainer: {
            visible: canChangePolicy(
                policyCommonDetails.renewedPeriodDetails,
                policyCommonDetails.currentPeriodDetails.productName
            )
        },
        idCardContainer: {
            visible: !_.isEmpty(_.get(policyCommonDetails.currentPeriodDetails, 'idCardPublicID'))
        },
        idCard: {
            href: getPolicyDownloadLink(),
            target: '_blank'
        },
        policyDocumentsSection: {
            updateWritableDocuments: updateWritableDocuments,
            writableDocuments: writableDocuments,
            visible: false
        }
    };

    const resolvers = {
        resolveComponentMap: {
            policycommondetailscomponent: PolicyCommonDetails,
            policycoveragescomponent: PolicyCoverages,
            policycommondocumentscomponent: PolicyCommonDocuments,
            policycommoncontactscomponent: PolicyCommonContacts
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: handlePrint,
            onClickPayAsYouGoReporting,
            onChangePolicy: () => redirectToEndorsement(policyCommonDetails.currentPeriodDetails, 'policyChange'),
            onChangePolicyRenewal: () => redirectToEndorsement(
                policyCommonDetails.renewedPeriodDetails, 'policyRenew'
            )
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={policyCommonDetails}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

CECLPolicyDetails.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    payAsYouGoPolicies: PropTypes.array.isRequired,
    policyCommonDetails: PropTypes.object.isRequired
};

CECLPolicyDetails.defaultProps = {
    payAsYouGoPolicies: [],
    policyCommonDetails: {}
};

export default CECLPolicyDetails;
