import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FNOLPARepairFacilityMapPage } from 'gwce-capability-fnol-pa-react-ext';
import React from 'react';

function FNOLCARepairFacilityMapPage(props) {
    return <FNOLPARepairFacilityMapPage {...props} />;
}

FNOLCARepairFacilityMapPage.propTypes = wizardProps;
export default FNOLCARepairFacilityMapPage;
