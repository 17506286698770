import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FNOLPAIncidentsPage } from 'gwce-capability-fnol-pa-react-ext';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

function FNOLRTIncidentsPage(props) {
    return <FNOLPAIncidentsPage {...props} />;
}

FNOLRTIncidentsPage.propTypes = wizardProps;
FNOLRTIncidentsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};
export default withRouter(withAuthenticationContext(FNOLRTIncidentsPage));
