import InvoicesTable from './InvoicesTable/InvoicesTable';
import PoliciesTable, { getPolicyCellStatus } from './PoliciesTable/PoliciesTable';
import PaymentsTable from './PaymentsTable/PaymentsTable';
import PoliciesSection from './PoliciesSection/PoliciesSection';
import AmountSelection from './AmountSelection/AmountSelection';
import BillingSummary from './BillingSummary/BillingSummary';
import BillingSummaryPage from './BillingSummaryPage/BillingSummaryPage';
import MakePaymentPage from './MakePaymentPage/MakePaymentPage';

const billingComponentMap = {
    InvoicesTable: { component: 'InvoicesTable' },
    PoliciesTable: { component: 'PoliciesTable' },
    PaymentsTable: { component: 'PaymentsTable' },
    BillingSummary: { component: 'BillingSummary' },
    PoliciesSection: { component: 'PoliciesSection' },
    AmountSelection: { component: 'AmountSelection' },
};

const billingAdditionalComponents = {
    InvoicesTable,
    PoliciesTable,
    PaymentsTable,
    BillingSummary,
    PoliciesSection,
    AmountSelection,
};

export {
    InvoicesTable,
    PoliciesTable,
    PaymentsTable,
    PoliciesSection,
    AmountSelection,
    BillingSummary,
    BillingSummaryPage,
    MakePaymentPage,
    billingComponentMap,
    billingAdditionalComponents,
    getPolicyCellStatus
};
