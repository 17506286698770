import _ from 'lodash';
import appConfig from 'app-config';

const {
    env
} = appConfig;

const prefix = _.get(env, 'DEPLOYMENT_PATH', '').replace(/\/$/, '');

const domains = [
    {
        domainUrl: 'wnins.com',
        src: `${prefix}/wni-images/branding/wni/WNHorizontalNoSloganWhite.png`,
        code: 'WNI'
    },
    {
        domainUrl: 'umialik.com',
        src: `${prefix}/wni-images/branding/uic/UICHorizontalNoSloganWhite.png`,
        code: 'UIC'
    },
    {
        domainUrl: 'mimillers.com',
        src: `${prefix}/wni-images/branding/mm/MM-Horizontal-Header.png`,
        code: 'MM'
    }
];

function getDefaultDomain() {
    const domain = window.location.hostname;
    const domainToAssign = domains.find((brandingArray) => {
        return domain.endsWith(brandingArray.domainUrl);
    });

    return domainToAssign || domains[0];
};

export default {
    getDefaultDomain,
};
