import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './CustomConfirmPopup.metadata.json5';
import messages from './CustomConfirmPopup.messages';
import styles from '../../pages/UsersProfile/UsersProfile.module.scss';


import { Button } from '@jutro/legacy/components';


function CustomConfirmPopup(props) {
    const {
        title,
        message,
        showButtons,
        isOpen,
        onResolve,
        onReject
    } = props;

    const translator = useTranslator();

    const handleContinue = useCallback(() => {
        onResolve();
    }, [onResolve]);

    const overrideProps = {
        customConfirmText: {
            content: message
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader title={title} icon='gw-error-outline' status='info' onClose={onReject} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </ModalBody>
            <ModalFooter>
                {
                    showButtons && (
                        <>
                            <Button type="outlined" onClick={onReject}>
                                {translator(messages.cancel)}
                            </Button>
                            <Button type="filled" onClick={handleContinue}>
                                {translator(messages.continue)}
                            </Button>
                        </>
                    )
                }
            </ModalFooter>
        </ModalNext>
    );
}

CustomConfirmPopup.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    showButtons: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func
};

CustomConfirmPopup.defaultProps = {
    showButtons: false
};

export default CustomConfirmPopup;
