import _ from 'lodash';
import Driver from '../models/Driver';
import Vehicle from '../models/Vehicle';

let nextId;
const NO_DRIVER_VEHICLE = ["motorhome_Ext", "rectrailer_Ext", "utilitytrailer_Ext"]
const PACoverables = function (data) {
    if (data && _.isObject(data)) {
        this.drivers = _.map(data.drivers, (d) => {
            return new Driver(d);
        });

        this.vehicles = _.map(data.vehicles, (v) => {
            return new Vehicle(v);
        });

    } else {
        this.drivers = [];
        this.vehicles = [];
    }
    const otherProperties = Object.keys(data).filter((key) => !['drivers', 'vehicles'].includes(key));
    otherProperties.forEach((property) => {
        this[property] = data[property];
    })
};

PACoverables.prototype.createDriver = function (driverValue) {
    const driver = new Driver(driverValue);
    // extend
    driver.tempID = nextId();
    driver.isNew = true;
    this.drivers.push(driver);
    return driver;
};

PACoverables.prototype.createFromAvailableDriver = function (availableDriver) {
    const driver = new Driver(availableDriver);
    // extend
    driver.isNew = true;
    this.drivers.push(driver);
    return driver;
};

PACoverables.prototype.addDriver = function (driver) {
    this.drivers.push(driver);
};

PACoverables.prototype.removeDriver = function (driver) {
    const idx = _.indexOf(this.drivers, driver);
    if (idx >= 0) {
        _.each(this.vehicles, _.bind(function (v) {
            this.removeDriverAssignment(driver, v);
        }, this));
        this.drivers.splice(idx, 1);
    }
};

PACoverables.prototype.createVehicle = function () {
    const v = new Vehicle({
        tempID: nextId()
    });
    v.isNew = true;
    this.vehicles.push(v);
    return v;
};

PACoverables.prototype.addVehicle = function (vehicle) {
    this.vehicles.push(vehicle);
};

PACoverables.prototype.removeVehicle = function (vehicle) {
    const idx = _.indexOf(this.vehicles, vehicle);
    if (idx >= 0) {
        _.each(this.drivers, _.bind(function (d) {
            this.removeDriverAssignment(d, vehicle);
        }, this));
        this.vehicles.splice(idx, 1);
    }
};

PACoverables.prototype.isDriverAssigned = function (driver, vehicle) {
    return !!_.find(this.vehicleDrivers, {
        driver, vehicle
    });
};

PACoverables.prototype.getAssignedDrivers = function (vehicle) {
    return _.map(_.filter(this.vehicleDrivers, {
        vehicle
    }), 'driver');
};

PACoverables.prototype.getAssignedVehicles = function (driver) {
    if(driver.tempID){
        return _.map(_.filter(this.vehicleDrivers, (vehicleDriver)=>{
            return vehicleDriver.driver.tempID === driver.tempID
        }), 'vehicle');
    }
    return _.map(_.filter(this.vehicleDrivers, (vehicleDriver)=>{
        return vehicleDriver.driver.fixedId === driver.fixedId
    }), 'vehicle');
    
};

PACoverables.prototype.assignDriver = function (driver, vehicle) {
    const assignment = _.find(this.vehicleDrivers, {
        driver, vehicle
    });
    if (!assignment) {
        this.vehicleDrivers.push({
            driver, vehicle
        });
    }
};

PACoverables.prototype.removeDriverAssignment = function (driver, vehicle) {
    const assignment = _.find(this.vehicleDrivers, {
        driver, vehicle
    });
    if (assignment) {
        this.vehicleDrivers.splice(this.vehicleDrivers.indexOf(assignment), 1);
    }
};

function buildHistory(policyChange, lob) {
    for (let i = 0; i < policyChange.history.length; i += 1) {
        const historyItem = policyChange.history[i];
        if (historyItem.entityType === 'driver') {
            historyItem.entity = _.find(lob.coverables.drivers, {
                fixedId: historyItem.fixedId
            });
        } else if (historyItem.entityType === 'vehicle') {
            historyItem.entity = _.find(lob.coverables.vehicles, {
                fixedId: historyItem.fixedId
            });
        }
    }
}


const PAPolicyChangeDataExtension = function (policyChangeModel) {
    const policyChange = policyChangeModel;
    nextId = _.get(policyChange, 'nextId');

    const paData = policyChange.lobData.personalAuto || { coverables: {} };
    this.coverables = new PACoverables(paData.coverables);

    if (policyChange.history) {
        buildHistory(policyChange, this);
    } else {
        policyChange.history = [];
    }

    this.offerings = paData.offerings;
    const otherProperties = Object.keys(paData).filter((key) => !['coverables', 'offerings'].includes(key));
    otherProperties.forEach((property) => {
        this[property] = paData[property];
    })
};

PAPolicyChangeDataExtension.prototype.beforeSave = function () {
    this.coverables.availableDrivers = [];
    _.each(this.coverables.drivers, (driver) => {
        // eslint-disable-next-line camelcase
        const { firstName, middleName_Ext: middleName, lastName, maritalStatus_Ext: maritalStatus, dateOfBirth} = driver
        driver.person = {
            ...driver.person,
            firstName, middleName, lastName, maritalStatus, dateOfBirth
        }
    })
    // _.each(this.coverables.vehicleDrivers, (vehicleDriverAssignment) => {
    //     const assignment = vehicleDriverAssignment;
    //     if (assignment.driver.fixedId) {
    //         assignment.driverID = assignment.driver.fixedId;
    //         delete assignment.driverTempID;
    //     } else {
    //         delete assignment.driverID;
    //         assignment.driverTempID = assignment.driver.tempID;
    //     }
    //     if (assignment.vehicle.fixedId) {
    //         assignment.vehicleID = assignment.vehicle.fixedId;
    //         delete assignment.vehicleTempID;
    //     } else {
    //         delete assignment.vehicleID;
    //         assignment.vehicleTempID = assignment.vehicle.tempID;
    //     }
    // });
};

PAPolicyChangeDataExtension.prototype.updateUnAssignedMapping = function () {
    this.coverables.unassignedDrivers = _.filter(this.coverables.drivers, (d) => {
        return !_.find(this.coverables.vehicleDrivers, {
            driver: d
        });
    });

    this.coverables.unassignedVehicles = _.filter(this.coverables.vehicles, (v) => {
        if(NO_DRIVER_VEHICLE.indexOf(v.vehicleType_Ext) !== -1){
            return false
        }
        return !_.find(this.coverables.vehicleDrivers, {
            vehicle: v
        });
    });

    if (!this.coverables.unassignedDrivers || this.coverables.unassignedDrivers.length === 0) {
        this.coverables.unassignedDrivers = [];
    }
    if (!this.coverables.unassignedVehicles || this.coverables.unassignedVehicles.length === 0) {
        this.coverables.unassignedVehicles = [];
    }
};

PAPolicyChangeDataExtension.prototype.validate = function (p) {
    this.updateUnAssignedMapping();
    return _.isEmpty(this.coverables.unassignedVehicles)
    && _.isEmpty(this.coverables.unassignedDrivers)
        
};

function setInexperiencedDriver(ratedAge, driverVM) {
    const {
        dateOfBirth,
        ageFirstLicensed_Ext: ageFirstLicensed,
    } = driverVM.value || {};
    const year = _.parseInt(_.get(dateOfBirth, 'year')) + _.parseInt(ageFirstLicensed);
    // const resDateFirstLicensed = `${year}-${_.get(dateOfBirth, 'month') + 1}-${_.get(dateOfBirth, 'day')}`;
    const resDateFirstLicensed = {
        year: year,
        month: _.get(dateOfBirth, 'month'),
        day: _.get(dateOfBirth, 'day'),
    };
    if (ageFirstLicensed) {
        if (ratedAge - ageFirstLicensed < 4 && ratedAge - ageFirstLicensed >= 0) {
            _.set(driverVM, 'inexperiencedDriver_Ext', true);
            // _.set(driverVM, 'dateFirstLicensed_Ext', new Date(resDateFirstLicensed));
            _.set(driverVM, 'dateFirstLicensed_Ext', resDateFirstLicensed);
        } else {
            _.set(driverVM, 'inexperiencedDriver_Ext', false);
            if (ratedAge > ageFirstLicensed) {
                // _.set(driverVM, 'dateFirstLicensed_Ext', new Date(resDateFirstLicensed));
                _.set(driverVM, 'dateFirstLicensed_Ext', resDateFirstLicensed);
            } else {
                _.set(driverVM, 'dateFirstLicensed_Ext', null);
            }
        }
    }
}

export default PAPolicyChangeDataExtension;
export {NO_DRIVER_VEHICLE}
