import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import SingleClauseComponentVM from './SingleClauseComponentVM';
import messages from './ClauseComponentVM.messages';

function getPath(path, changedValuePath) {
    // onBlur event returns an object instead of path as a String
    const pathToNormalise = _.isObject(changedValuePath)
        ? changedValuePath.model : changedValuePath;

    const normalisePath = pathToNormalise.replace(/\[/g, '.children[');
    const basePath = path.replace(/\.value/, '');
    return `${basePath}${normalisePath}`;
}

export default class CEClausesComponentVM extends Component {
    /**
     * @memberof gw-components-platform-react.ClausesComponentVM
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {array} propTypes.value - is the clause is seleceted
     * @prop {bool} propTypes.splitByClauseCategory - if clauses should be split by coverageCategory
     * @prop {string} propTypes.categoryDisplayName - title if splitByClauseCategory is false
     * @prop {string} propTypes.isEditable - if the clauses should not be editable
     */

    static propTypes = {
        ...SingleClauseComponentVM.propTypes,
        path: PropTypes.string.isRequired,
        value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        phoneWide: PropTypes.shape({}),
        splitByClauseCategory: PropTypes.bool,
        categoryDisplayName: PropTypes.string,
        labelPosition: PropTypes.string,
        isEditable: PropTypes.bool,
        loadingClauseMessage: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        })
    };

    static defaultProps = {
        splitByClauseCategory: false,
        categoryDisplayName: undefined,
        labelPosition: 'left',
        isEditable: true,
        phoneWide: {
            labelPosition: 'top'
        },
        loadingClauseMessage: messages.updatingSelection,
        value: []
    };

    static contextType = TranslatorContext;

    toMetadata = (clausesCategory) => {
        const {
            value: clauses,
            path,
            labelPosition,
            loadingClauseMessage
        } = this.props;
        const translator = this.context;

        return clausesCategory.map((clause) => {
            const index = clauses.findIndex((element) => element.publicID === clause.publicID);
            const clausePath = `[${index}]`;

            return {
                id: `clause_${clause.publicID}_[${index}]`,
                type: 'field',
                component: 'SingleClauseComponentVM',
                componentProps: {
                    ...this.props,
                    labelPosition,
                    loadingClauseMessage: translator(loadingClauseMessage),
                    value: clause,
                    path: getPath(path, clausePath),
                    containerClassName: 'clauseContainer'
                }
            };
        });
    };

    generateClauseGroup = (clauseCategory, index) => {
        const { splitByClauseCategory, categoryDisplayName } = this.props;
        const clausesMetadata = this.toMetadata(clauseCategory);

        const sortedClausesMetadata = _.sortBy(
            clausesMetadata,
            (clause) => {
                return clause.componentProps.value.required ? -1 : 1;
            }
        );

        const clauseCategoryName = (splitByClauseCategory || categoryDisplayName) && {
            componentProps: { className: 'clause_category_name' },
            id: index ? `clause_category_title_[${index}]` : 'clause_category',
            type: 'element',
            component: 'h3',
            // If splitByClauseCategory is false, categoryDisplayName should be supplied
            content: categoryDisplayName || clauseCategory[0].coverageCategoryDisplayName
        };
        return {
            id: `clause_category_container_[${index}]`,
            type: 'container',
            component: 'div',
            componentProps: { className: 'clauseCategoryContainer' },
            content: clauseCategoryName
                ? [clauseCategoryName, ...sortedClausesMetadata] : sortedClausesMetadata
        };
    };

    generateReadOnlyMetadata = () => {
        const { value: clauses } = this.props;
        const clausesToRender = clauses
            .filter((clause) => clause.selected);

        if (_.isEmpty(clausesToRender)) {
            return [{
                id: 'no_clause',
                type: 'element',
                component: 'h4',
                content: messages.noCoverage,
                componentProps: {
                    className: 'gw-no-coverages'
                }
            }];
        }
        return this.toMetadata(clausesToRender);
    }

    generateEditableMetadata() {
        const { value: clauses, splitByClauseCategory } = this.props;

        if (splitByClauseCategory) {
            // coverageCategoryCode is used in all types of clauses
            const clauseCategory = _.groupBy(clauses, 'coverageCategoryCode');
            const clauseCategoryMetadata = Object.values(clauseCategory)
                .map(this.generateClauseGroup);

            return {
                id: 'clause_container',
                type: 'container',
                component: 'div',
                content: clauseCategoryMetadata
            };
        }
        return this.generateClauseGroup(clauses);
    }

    generateMetadata() {
        const { isEditable } = this.props;

        if (!isEditable) {
            return {
                id: 'clause_container',
                type: 'container',
                component: 'div',
                content: this.generateReadOnlyMetadata()
            };
        }
        return this.generateEditableMetadata();
    }

    render() {
        return <MetadataContent uiProps={this.generateMetadata()} />;
    }
}
