import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLRTWizard from './FNOLRTWizard';
import FNOLRTTypeOfIncidentPage from './pages/TypeOfIncident/FNOLRTTypeOfIncidentPage';
import FNOLRTTypeOfRepairPage from './pages/TypeOfRepair/FNOLRTTypeOfRepairPage';
import FNOLRTRepairFacilityPage from './pages/RepairFacility/FNOLRTRepairFacilityPage';
import FNOLRTCollisionDetailsPage from './pages/Details/Collision/FNOLRTCollisionDetailsPage';
import FNOLRTGlassDetailsPage from './pages/Details/Glass/FNOLRTGlassDetailsPage';
import FNOLRTTheftDetailsPage from './pages/Details/Theft/FNOLRTTheftDetailsPage';
import FNOLRTRepairFacilityMapPage from './pages/RepairFacilityMap/FNOLRepairFacilityMapPage';
import FNOLRTLossTypePage from './pages/LossType/FNOLRTLossTypePage';
import FNOLRTIncidentsPage from './pages/Incidents/FNOLRTIncidentsPage';
import FNOLRTOtherLossDetailPage from './pages/OtherLossDetail/FNOLRTOtherLossDetailPage';

setComponentMapOverrides(
    {
        FNOLRTWizard: { component: 'FNOLRTWizard' },
        FNOLRTTypeOfIncidentPage: { component: 'FNOLRTTypeOfIncidentPage' },
        FNOLRTTypeOfRepairPage: { component: 'FNOLRTTypeOfRepairPage' },
        FNOLRTRepairFacilityPage: { component: 'FNOLRTRepairFacilityPage' },
        FNOLRTCollisionDetailsPage: { component: 'FNOLRTCollisionDetailsPage' },
        FNOLRTGlassDetailsPage: { component: 'FNOLRTGlassDetailsPage' },
        FNOLRTTheftDetailsPage: { component: 'FNOLRTTheftDetailsPage' },
        FNOLRTRepairFacilityMapPage: { component: 'FNOLRTRepairFacilityMapPage' },
        FNOLRTLossTypePage: { component: 'FNOLRTLossTypePage' },
        FNOLRTIncidentsPage: { component: 'FNOLRTIncidentsPage' },
        FNOLRTOtherLossDetailPage: { component: 'FNOLRTOtherLossDetailPage' }
    },
    {
        FNOLRTWizard,
        FNOLRTTypeOfIncidentPage,
        FNOLRTTypeOfRepairPage,
        FNOLRTRepairFacilityPage,
        FNOLRTCollisionDetailsPage,
        FNOLRTGlassDetailsPage,
        FNOLRTTheftDetailsPage,
        FNOLRTRepairFacilityMapPage,
        FNOLRTLossTypePage,
        FNOLRTIncidentsPage,
        FNOLRTOtherLossDetailPage
    }
);

export {
    FNOLRTWizard as default
};
