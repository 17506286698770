/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import appConfig from 'app-config';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { PolicyChange, messages as commonMessage } from 'gw-capability-policychange-common-react';
import { WniCEPolicyChangeVehicleService } from 'wnice-capability-policychange';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WindowUtil, VehicleValidationUtil } from 'wni-portals-util-js';
import { CEVehicleUtil } from 'wnice-portals-util-js';

import CEVehicleComponent from '../../components/CEVehicleComponent/CEVehicleComponent';
import metadata from './CEPAVehiclesChangePage.metadata.json5';
import styles from './CEPAVehiclesChangePage.module.scss';

const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';

const initVehiclePageVars = {
    vehiclePath: '',
    selectedVehicleVM: {},
    bakSelectedVehicle: {},
    vehicleErrors: [],
    showErrors: false
};

function CEPAVehiclesChangePage(props) {
    const { wizardData, updateWizardData, goNext } = props;
    const { submissionVM, wizardPageData } = wizardData;
    const [vehiclePageVars, setVehiclePageVars] = useState(initVehiclePageVars);
    const [isVmIntitialized, updateIsVmInitialized] = useState(true);

    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerInitialComponentValidation,
        disregardFieldValidation
    } = useValidation('CEPAVehiclesChangePage');
    const { authHeader } = useAuthentication();

    const {
        quoteID,
        sessionUUID,
        policyNumber,
        jobID,
        baseData: {
            effectiveDate
        },
        lobData: {
            personalAuto: {
                coverables: {
                    vehicleDrivers: assignments
                },
                isExcessVehicleMoreThanDriver_Ext: isExcessVehicleMoreThanDriver
            }
        }
    } = submissionVM.value;

    const vehicles = _.get(submissionVM, `${vehiclesPath}.value`);
    const assignmentsData = CEVehicleUtil.combineVehiclesAssignmentData(assignments, vehicles, isExcessVehicleMoreThanDriver);

    const isAllVehicleValid = VehicleValidationUtil.allVehiclesValid(_.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children'));

    const baseRequest = {
        quoteID,
        sessionUUID,
        policyNumber,
        jobID,
        effectiveDate
    };

    const { vehiclePath, selectedVehicleVM, vehicleErrors, showErrors } = vehiclePageVars;

    const checkVehicleEmpty = useCallback(() => {
        const vehicleSize = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children');
        return !_.isEmpty(vehicleSize);
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(() => checkVehicleEmpty() && isAllVehicleValid);
    }, [registerInitialComponentValidation, isAllVehicleValid, checkVehicleEmpty]);

    const writeValue = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            updateWizardData(wizardData);
        },
        [submissionVM, updateWizardData, wizardData]
    );

    useEffect(() => {
        updateIsVmInitialized(false);
        WniCEPolicyChangeVehicleService.autoAssignAndGetVehicleDriverAssignments(jobID, authHeader)
            .then((result) => {
               writeValue(result, 'lobData.personalAuto.coverables.vehicleDrivers.value');
            })
            .finally(() => {
                updateIsVmInitialized(true);
            })
    }, [])

    const errorsOverrides = useCallback(() => {
        let overrides = [];
        if (!_.isEmpty(vehicleErrors)) {
            overrides = vehicleErrors.map((vehicleError, index) => {
                return {
                    [`errorNotification${index}`]: {
                        message: vehicleError,
                        visible: true
                    }
                };
            });
        }
        return Object.assign({}, ...overrides);
    }, [vehicleErrors]);

    const addVehicle = useCallback(async () => {
        const updateGarageLocation = _.get(submissionVM, 'lobData.personalAuto.coverables.updateGarageLocation.value');
        const oldVehicles = _.cloneDeep(vehicles) || [];
        const oldVehiclesIds = oldVehicles.map((v) => v.publicID);
        const request = {
            ...baseRequest,
            updateGarageLocation,
            method: 'create'
        };
        updateIsVmInitialized(false);
        const response = await WniCEPolicyChangeVehicleService.updateVehicle(request, authHeader);
        submissionVM.value = new PolicyChange(response);
        updateWizardData(wizardData);
        updateIsVmInitialized(true);
        const vehiclesVM = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children');
        const addedVehicleVM = vehiclesVM.find((vm) => !oldVehiclesIds.includes(vm.value.publicID));
        const vehicleIndex = _.findIndex(vehiclesVM, (vm) => vm.value.publicID === addedVehicleVM.value.publicID);
        setVehiclePageVars({
            vehiclePath: `lobData.personalAuto.coverables.vehicles.children[${vehicleIndex}].value`,
            selectedVehicleVM: addedVehicleVM,
            bakSelectedVehicle: _.cloneDeep(addedVehicleVM.value),
            vehicleErrors: [],
            showErrors: false
        });
    }, [authHeader, baseRequest, submissionVM, updateWizardData, vehicles, wizardData]);

    const disableAddVehicle = () => {
        const hasNNOVehicle = VehicleValidationUtil.namedNonOwnerVehicleExist(_.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children'));
        return hasNNOVehicle || vehiclePath !== '';
    };

    const saveVehicle = useCallback(async () => {
        setVehiclePageVars({
            ...vehiclePageVars,
            vehicleErrors: [],
            showErrors: false
        });
        const { fixedId } = selectedVehicleVM.value;
        const vehicleAssignment = assignmentsData.find((data) => data.vehicleID === fixedId);
        if (!CEVehicleUtil.isVehicleValid(selectedVehicleVM, vehicles, vehicleAssignment)) {
            const errors = CEVehicleUtil.getVehicleErrors(selectedVehicleVM, vehicles);
            setVehiclePageVars({
                ...vehiclePageVars,
                vehicleErrors: errors,
                showErrors: true
            });
            if (!_.isEmpty(errors)) {
                WindowUtil.scrollTo('errorContainer');
            }
            return false;
        }
        const request = {
            ...baseRequest, 
            method: 'update',
            vehicleToUpdate: selectedVehicleVM.value
        };
        updateIsVmInitialized(false);
        const response = await WniCEPolicyChangeVehicleService.updateVehicle(request, authHeader);
        submissionVM.value = new PolicyChange(response);
        updateWizardData(wizardData);
        updateIsVmInitialized(true);
        setVehiclePageVars(initVehiclePageVars);
        goNext();
        return wizardData;
    }, [assignmentsData, authHeader, baseRequest, goNext, selectedVehicleVM, submissionVM, updateWizardData, vehiclePageVars, vehicles, wizardData]);


    const vehicleOverrides = useMemo(() => {
        const vehiclesList = _.get(submissionVM, vehiclesPath, []);
        let overrides = [];
        if (!_.isEmpty(vehiclesList)) {
            overrides = vehiclesList.value.map((vehicle, index) => {
                return {
                    [`vehicle${index}`]: {
                        onValueChange: writeValue,
                        onValidate,
                        baseRequest,
                        assignmentsData, 
                        vehiclePageVars,
                        setVehiclePageVars,
                        wizardPageData,
                        wizardData,
                        updateWizardData,
                        WniCEPolicyChangeVehicleService,
                        updateIsVmInitialized,
                        disregardFieldValidation 
                    }
                };
            });
        }
        return Object.assign({}, ...overrides);
    }, [baseRequest, disregardFieldValidation, onValidate, submissionVM, updateWizardData, vehiclePageVars, wizardData, wizardPageData, writeValue, assignmentsData]);

    const onNext = useCallback(() => {
        const vehiclesVM = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children');
        if (!CEVehicleUtil.isAllVehicleValid(vehiclesVM, vehicles, assignmentsData)) {
            return false;
        }
        if (vehiclePath !== '') {
            saveVehicle();
        } else {
            return wizardData;
        }
    }, [submissionVM, vehicles, assignmentsData, vehiclePath, saveVehicle, wizardData]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddVehicleClick: addVehicle,
            onValidate: onValidate
        },
        resolveComponentMap: {
            cevehiclecomponent: CEVehicleComponent
        }
    };

    const overrideProps = {
        '@field': {
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        errorContainer: {
            data: vehicleErrors
        },
        addVehicle: {
            disabled: disableAddVehicle()
        },
        ...vehicleOverrides,
        ...errorsOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );
    const handleModelChange = useCallback(
        (newModel) => {
            updateWizardData({
                ...wizardData,
                submissionVM: newModel
            });
        },
        [updateWizardData, wizardData]
    );

    if (!isVmIntitialized) {
        return <Loader loaded={isVmIntitialized} />;
    }

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            cancelLabel={(appConfig.persona === 'policyholder' ? commonMessage.cancelAllChanges : commonMessage.cancel)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={handleModelChange}
                onValidationChange={onValidate}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CEPAVehiclesChangePage.propTypes = wizardProps;
export default CEPAVehiclesChangePage;
