import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './WatercraftDetailInfo.metadata.json5';
import messages from './WatercraftDetailInfo.messages';
import styles from './WatercraftDetailInfo.module.scss';

function WatercraftDetailInfo(props) {
    const translator = useTranslator();
    const { value: watercraftDetails } = props;

    const getWatercraftDetailTitle = useCallback((item) => {
        switch (item.watercraftDetailType) {
            case 'watercraft':
                return translator(messages.watercraftDetails);
            case 'motor':
                return translator(messages.motorDetails);
            case 'trailer':
                return translator(messages.trailerDetails);
            default:
                return '';
        }
    }, [translator]);

    const generateWatercraftDetailOverrides = useCallback(() => {
        const overrides = watercraftDetails.map((watercraftDetail, index) => {
            return {
                [`watercraftDetailTitle${index}`]: {
                    content: getWatercraftDetailTitle(watercraftDetail)
                },
                [`watercraftDesc${index}`]: {
                    visible: watercraftDetail.watercraftDetailType === 'watercraft'
                },
                [`watercraftLength${index}`]: {
                    visible: watercraftDetail.watercraftDetailType === 'watercraft'
                },
                [`lossSettlementValuation${index}`]: {
                    visible: watercraftDetail.watercraftDetailType === 'watercraft'
                },
                [`engineHorsepower${index}`]: {
                    visible: watercraftDetail.watercraftDetailType === 'motor'
                },
                [`fuelType${index}`]: {
                    visible: watercraftDetail.watercraftDetailType === 'motor'
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [getWatercraftDetailTitle, watercraftDetails]);

    
    if (_.isEmpty(watercraftDetails)) {
        return null;
    }

    const overrides = {
        watercraftDetailIterable: {
            data: watercraftDetails
        },
        ...generateWatercraftDetailOverrides()
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={watercraftDetails}
            overrideProps={overrides}
            classNameMap={styles}
        />
    );
}

WatercraftDetailInfo.propTypes = {
    value: PropTypes.object.isRequired
};

export default WatercraftDetailInfo;
