import React, {
    useContext, useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Loader, useModal } from '@jutro/components';
import { IntlContext, useTranslator } from '@jutro/locale';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import { CEConfigUtil, UWCompanyUtil } from 'wnice-portals-util-js';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { getNormalizedLOBName } from '@xengage/gw-portals-config-js';
import { PaymentComponent, InvoiceCloudPopup } from 'gw-components-platform-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { PaymentUtil, WniDateUtil } from 'wni-portals-util-js';
import { WniBillingAndPaymentService } from 'wni-capability-gateway';
import { AccountBillingDetailsService as BillingService } from 'gw-capability-billing';
import { WniAccountBillingDetailsService as WniBillingService } from 'wnice-capability-billing';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import moment from 'moment';

import metadata from './BillingSummary.metadata.json5';
import styles from './BillingSummary.module.scss';
import messages from './BillingSummary.messages';

const policyLevelHeaderStrings = {
    pa: {
        autoPaymentEnabled: messages.personalAutoPolicyAutomaticPaymentsEnabled,
        default: messages.personalAutoPolicy
    },
    ho: {
        autoPaymentEnabled: messages.homeownersPolicyAutomaticPaymentsEnabled,
        default: messages.homeownersPolicy
    },
    bop: {
        autoPaymentEnabled: messages.businessOwnersPolicyAutomaticPaymentsEnabled,
        default: messages.businessOwnersPolicy
    },
    ca: {
        autoPaymentEnabled: messages.commercialAutoPolicyAutomaticPaymentsEnabled,
        default: messages.commercialAutoPolicy
    },
    cp: {
        autoPaymentEnabled: messages.commercialPropertyPolicyAutomaticPaymentsEnabled,
        default: messages.commercialPropertyPolicy
    },
    cpkg: {
        autoPaymentEnabled: messages.commercialPackagePolicyAutomaticPaymentsEnabled,
        default: messages.commercialPackagePolicy
    },
    gl: {
        autoPaymentEnabled: messages.generalLiabilityPolicyAutomaticPaymentsEnabled,
        default: messages.generalLiabilityPolicy
    },
    im: {
        autoPaymentEnabled: messages.inlandMarinePolicyAutomaticPaymentsEnabled,
        default: messages.inlandMarinePolicy
    },
    wc: {
        autoPaymentEnabled: messages.workersCompPolicyAutomaticPaymentsEnabled,
        default: messages.workersCompPolicy
    },
    wc7: {
        autoPaymentEnabled: messages.workersComp7PolicyAutomaticPaymentsEnabled,
        default: messages.workersComp7Policy
    },
    default: {
        autoPaymentEnabled: messages.policyAutomaticPaymentsEnabled,
        default: messages.policy
    }
};

const getPaidInvoices = (invoiceSummary) => {
    return _.filter(invoiceSummary, (item) => {
        return item.paidStatus === 'fullypaid';
    })
    .sort((a, b) => {
        return new Date(b.lastPaymentDate) - new Date(a.lastPaymentDate);
    });
};

const getUnpaidInvoices = (invoiceSummary) => {
    return _.filter(invoiceSummary, (item) => item.paidStatus !== 'fullypaid');
};

const getExpandInvoicesByRelatePolicies = (invoiceSummary) => {
    const invoicesWithoutWriteOff = _.filter(invoiceSummary, (invoice) => {
        const isTotalWriteOff = invoice.writeOffAmount_Ext?.amount === invoice.totalValue.amount 
            && invoice.writeOffAmount_Ext?.amount > 0
        return !isTotalWriteOff
    })

    const invoiceFlated = _.flatMap(invoicesWithoutWriteOff, (invoice) => {
        const policies = _.uniqBy(invoice.relatedPolicies_Ext, 'policyNumber')
        const groupPolicyByNumber = _.groupBy(invoice.relatedPolicies_Ext, 'policyNumber')

        // PAYG invoice may have no policy number because the invoice is deposit
        if(policies.length === 0){
            /**
             * CEI-4822 
             * for carried forward, we need to remove no data, and display “Carried Forward” at policy number column.
             */
            if(invoice.invoiceStatus === 'carriedforward'){
                const newInvoice = _.cloneDeep(invoice)
                newInvoice.policy = {
                    policyNumber: 'Carried Forward',
                    productCode: ''
                }
                return newInvoice
            }
            return invoice
        }

        let policiesAfterFilter = []
        // filter canceled policy but remain one
        for (const [, value] of Object.entries(groupPolicyByNumber)) {
            let newPolicies = _.filter(value, poy => poy.cancelStatus !== 'canceled')
            if(newPolicies.length === 0){
                newPolicies = [value[0]]
            }
            policiesAfterFilter = _.concat(policiesAfterFilter, newPolicies)
        }

        return policiesAfterFilter.map((policy, i, policyArr) => {
            const newInvoice = _.cloneDeep(invoice)
            if(policyArr.length > 1 && i !== 0){
                newInvoice.notFirstLine = true
            }
            newInvoice.policy = policy
            
            return newInvoice
        })
    })
    return invoiceFlated
}

const hasUnpaidInvoices = (invoiceSummary) => {
    const unpaidInvoices = getUnpaidInvoices(invoiceSummary);
    return unpaidInvoices.length > 0;
};

const hasBilledOrDueInvoices = (invoiceSummary) => {
    const filteredInvoices = _.filter(invoiceSummary, (item) => _.includes(['billed', 'due'], item.invoiceStatus) && item.paidStatus !== 'fullypaid');
    return filteredInvoices.length > 0;
};

const createPaymentInstrumentObject = (paymentDetailsVm, paymentMethod) => {
    const paymentDetailsValue = paymentDetailsVm.value;

    const bankAccountData = {
        ...paymentDetailsValue
    };

    const creditCardData = {
        ...paymentDetailsValue
    };

    let detailsValue;
    if (paymentMethod === 'bankaccount_Ext') {
        detailsValue = { bankAccountDataCE_Ext: bankAccountData };
    } else {
        detailsValue = { creditCardData };
    }

    const paymentInstrumentValue = {
        paymentMethod,
        ...detailsValue
    };

    return paymentInstrumentValue;
};

const getPolicyLevelInvoiceStreamHeader = (lobCode, isAutoPaymentEnabled) => {
    const normalizedLOBName = getNormalizedLOBName(lobCode);
    const lobHeader = _.has(policyLevelHeaderStrings, normalizedLOBName)
        ? policyLevelHeaderStrings[normalizedLOBName]
        : policyLevelHeaderStrings.default;

    if (isAutoPaymentEnabled) {
        return lobHeader.autoPaymentEnabled;
    }

    return lobHeader.default;
};

const getInvoiceStreamHeader = (isAccLevelStream, currentAutoPayPublicID, lobCode, accounts, accountNumber, selectedInvoiceFilter) => {
    let header = '';
    const isAutomaticPaymentEnabled = currentAutoPayPublicID != null;
    let invoiceTitleForAccount = messages.invoiceDetails
    if(accounts?.length > 1 && accountNumber && selectedInvoiceFilter === 'planned'){
        invoiceTitleForAccount = accountNumber
    }
    if (isAccLevelStream) {
        header = isAutomaticPaymentEnabled
            ? messages.invoicesAutomaticPaymentsEnabled
            : invoiceTitleForAccount;
    } else {
        header = getPolicyLevelInvoiceStreamHeader(lobCode, isAutomaticPaymentEnabled);
    }
    return header;
};

const getAccountNumbers = (policies) => {
    return _.uniq(_.map(policies, 'accountNumber'))
}

const invoiceCloudBaseUrl = CEConfigUtil.getInvoiceCloudBaseUrl();

const initAmountSelectionVars = {
    selectedPolicies: [],
    selectedPAYGCode: [],
    selectedCode: [],
    amountSentToIC: 0
};
function BillingSummary(props) {
    const modalApi = useModal();
    const {
        invoiceStreamId,
        accountLevelBilling,
        lobCode,
        invoiceStreamDetails,
        currency,
        policySummaries,
        payAsYouGoPolicies,
        authHeader,
        reloadInvoiceDetails,
        selectedScreen,
        updateSelectedScreen,
        history,
        skipMakePaymentConfirm
    } = props;

    const intl = useContext(IntlContext);
    const translator = useTranslator();
    
    // screen states
    const [isLoadingData, updateIsLoadingData] = useState(false);
    const [selectedInvoicesForPayment, updateSelectedInvoicesForPayment] = useState([]);
    const [selectedInvoiceFilter, updateSelectedInvoiceFilter] = useState('planned');
    const [manualAmountToPay, updateManualAmount] = useState(null);
    const [showAmountError, updateShowAmountError] = useState(false);  
    const [accountBillingData, setAccountBillingData] = useState([]);
    const [paymentsHistory, setPaymentsHistory] = useState([]);
    const [switchAutoPay, updateSwitchAutoPay] = useState(null);
    const [userMakePayShow, setUserMakePayShow] = useState(skipMakePaymentConfirm);
    const [showConfirmMakePayBtn, setShowConfirmMakePayBtn] = useState(true);
    const [accountsNumbers, setAccountsNumber] = useState(getAccountNumbers(policySummaries));
    const [amountSelectionVars, updateAmountSelectionVars] = useState(initAmountSelectionVars);
    const { loadingMask: { setLoadingMask }, interactionModel, domainCompany } = useDependencies(['loadingMask', 'interactionModel', 'domainCompany']);

    const payingAmountValue = manualAmountToPay ? manualAmountToPay.amount : 0;
    const {
        invoiceSummary = [],
        relatedPolicies = [],
        paymentInstrument,
        paymentInstrumentSummary,
        periodicity,
        showAutoPayFeeMsg_Ext: showAutoPayFeeMsg,
        existingBankAccounts_Ext: existingBankAccounts,
        currentAutoPayPublicID_Ext: currentAutoPayPublicID,
        minDueAmount_Ext: minDueAmount,
        earliestDueDate_Ext: earliestDueDate,
        invoiceCloudDueDate_Ext: invoiceCloudStreamLevelDueDate
    } = invoiceStreamDetails;

    const defaultAmountValue = {
        amount: 0,
        currency: 'usd'
    }

    const onCancelShowAutoPayBtn = useCallback(() => {
        setShowConfirmMakePayBtn(false)
    },[])

    const onSetMakePayShow = useCallback(() => {
        setUserMakePayShow(true)
    },[])

    const firstInvoiceDueDate = useMemo(() => {
        const unPaidInvoices = _.filter(invoiceSummary, (invoice) => {
            return invoice.paidStatus !== 'paid' && invoice.invoiceStatus === 'planned';
        });

        const firstUnpaidInvoice = _.minBy(unPaidInvoices, (invoice) => {
            return moment(invoice.dueLocalDate_Ext).toDate();
        });

        return firstUnpaidInvoice ? firstUnpaidInvoice.dueLocalDate_Ext : null;
    }, [invoiceSummary]);

    const resetInternalStates = useCallback((toScreen, skipConfirmWantToPay) => {
        updateIsLoadingData(true);
        if(toScreen === 'payment'){
            setUserMakePayShow(true)
        }
        reloadInvoiceDetails(invoiceStreamId, toScreen, skipConfirmWantToPay).finally(() => {
            updateSelectedInvoicesForPayment([]);
            updateSelectedInvoiceFilter('planned');
            updateManualAmount(null);
            updateIsLoadingData(false);
        });
    }, [reloadInvoiceDetails, invoiceStreamId]);

    const onClickConfirmMakePayment = useCallback(
        (paymentDetailsVm, paymentMethod) => {
            const paymentInstrumentValue = createPaymentInstrumentObject(
                paymentDetailsVm,
                paymentMethod
            );
            const { selectedPolicies } = amountSelectionVars;
            if(!_.isEmpty(selectedPolicies)){
                WniBillingService.makePaymentByPolicyWithInstrument(
                    invoiceStreamDetails.displayName,
                    manualAmountToPay.amount,
                    paymentInstrumentValue,
                    selectedPolicies[0],
                    authHeader
                )
                    .then(() => {
                        updateSelectedScreen('paymentConfirmation');
                    })
                    .catch(() => {
                        modalApi.showAlert({
                            title: messages.paymentRequestFailed,
                            message: messages.sorryYourPaymentCouldNotBeProcessedAtThisTime,
                            status: 'error',
                            icon: 'gw-error-outline',
                            confirmButtonText: commonMessages.ok
                        }).then(() => {
                            resetInternalStates();
                        }, _.noop);
                    });
            }else{
                WniBillingService.makePaymentWithInstrument(
                    invoiceStreamDetails.displayName,
                    manualAmountToPay.amount,
                    paymentInstrumentValue,
                    authHeader
                )
                    .then(() => {
                        updateSelectedScreen('paymentConfirmation');
                    })
                    .catch(() => {
                        modalApi.showAlert({
                            title: messages.paymentRequestFailed,
                            message: messages.sorryYourPaymentCouldNotBeProcessedAtThisTime,
                            status: 'error',
                            icon: 'gw-error-outline',
                            confirmButtonText: commonMessages.ok
                        }).then(() => {
                            resetInternalStates();
                        }, _.noop);
                    });
            }

            
        },
        [amountSelectionVars, invoiceStreamDetails, manualAmountToPay, authHeader, modalApi, resetInternalStates, updateSelectedScreen]
    );

    const onClickCancelMakePayment = useCallback(() => {
        updateSelectedScreen();
        updateAmountSelectionVars(initAmountSelectionVars);
        updateManualAmount(null);
        updateShowAmountError(false);
    }, [updateSelectedScreen, updateManualAmount]);

    const showSetupAutoPaymentErrorModal = useCallback(() => {
        modalApi.showAlert({
            title: messages.accountUpdateFailed,
            message: messages.sorryWeWereNotAbleToSetupAutomaticPaymentsOnYourAccount,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(() => {
            resetInternalStates();
        }, _.noop);
    }, [modalApi, resetInternalStates]);

    const onClickConfirmSetupAutomaticPayment = useCallback(
        (paymentDetailsVm, paymentMethod) => {
            const paymentInstrumentValue = createPaymentInstrumentObject(
                paymentDetailsVm,
                paymentMethod
            );
            if (switchAutoPay) {
                modalApi.showConfirm({
                    title: messages.confirmUpdateAutoPay,
                    message: messages.updateAutomaticPaymentsMsg,
                    status: 'warning',
                    icon: 'gw-error-outline',
                    confirmButtonText: messages.yes
                }).then((result) => {
                    if (result === 'cancel' || result === 'close') {
                        return;
                    }
                    updateIsLoadingData(true);
                    WniBillingService.setPaymentGroupPaymentInformation(invoiceStreamId, paymentInstrumentValue, authHeader)
                        .then(() => {
                            resetInternalStates('default');
                        })
                        .catch(() => {
                            showSetupAutoPaymentErrorModal();
                        })
                        .finally(() => {
                            updateIsLoadingData(false);
                        });
                })
            } else {
                modalApi.showConfirm({
                    title: messages.confirmAutoPaySetUp,
                    message: messages.automaticPaymentsWillBegin,
                    status: 'warning',
                    icon: 'gw-error-outline',
                    confirmButtonText: messages.yes
                }).then((result) => {
                    if (result === 'cancel' || result === 'close') {
                        return;
                    }
                    updateIsLoadingData(true);
                    WniBillingService.setPaymentGroupPaymentInformation(invoiceStreamId, paymentInstrumentValue, authHeader)
                    .then(() => {
                        if (hasBilledOrDueInvoices(invoiceSummary)) {
                            modalApi.showConfirm({
                                title: messages.wantToPayYourCurrentBill,
                                message: messages.selectPayNow,
                                status: 'warning',
                                icon: 'gw-error-outline',
                                confirmButtonText: messages.payNow,
                                cancelButtonText: messages.home
                            }).then((payResult) => {
                                if (payResult === 'cancel' || payResult === 'close') {
                                    return history.push('/home');
                                }
                                resetInternalStates('payment', true);
                            }, _.noop);
                        } else {
                            resetInternalStates('default');
                        }
                    })
                    .catch(() => {
                        showSetupAutoPaymentErrorModal();
                    })
                    .finally(() => {
                        updateIsLoadingData(false);
                    });
                })
            }
        },
        [switchAutoPay, invoiceStreamId, authHeader, resetInternalStates, showSetupAutoPaymentErrorModal, modalApi, invoiceSummary, history]
    );

    const onClickCancelSetupAutomaticPayment = useCallback(() => {
        updateSelectedScreen();
    }, [updateSelectedScreen]);

    const showTurnOffAutoPayErrorModal = useCallback(() => {
        modalApi.showAlert({
            title: messages.turnOffAutoPay,
            message: messages.sorryWeWereNotAbleToTurnOffAutoPay,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(() => {
            resetInternalStates();
        }, _.noop);
    }, [modalApi, resetInternalStates]);

    const onHandleSwitchAutoPayChange = useCallback((data) => {
        updateSwitchAutoPay(data);
        if (data === false) {
            const turnOffMsg = showAutoPayFeeMsg ? messages.confirmTurnOffAutoPay : messages.confirmTurnOffAutoPayWithoutFee;
            modalApi.showConfirm({
                title: messages.wantToTurnOffAutoPay,
                message: turnOffMsg,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.turnOffAutoPay
            }).then((result) => {
                if (result === 'cancel'|| result === 'close') {
                    return;
                }
                updateIsLoadingData(true);
                WniBillingService.removeAutomaticPayment(invoiceStreamId, authHeader)
                    .then((response) => {
                        if (response) {
                            resetInternalStates('default');
                        } else {
                            showTurnOffAutoPayErrorModal();
                        }
                    })
                    .catch(() => {
                        showTurnOffAutoPayErrorModal();
                    })
                    .finally(() => {
                        updateIsLoadingData(false);
                    });
            }, _.noop);
        } 
    }, [authHeader, invoiceStreamId, modalApi, resetInternalStates, showTurnOffAutoPayErrorModal, showAutoPayFeeMsg]);

    const onClickConfirmChangePaymentSource = useCallback(
        (paymentDetailsVm, paymentMethod) => {
            const paymentInstrumentValue = createPaymentInstrumentObject(
                paymentDetailsVm,
                paymentMethod
            );

            WniBillingService.setPaymentGroupPaymentInformation(
                invoiceStreamId,
                paymentInstrumentValue,
                authHeader
            )
                .then(() => {
                    updateSelectedScreen('paymentSourceConfirmation');
                })
                .catch(() => {
                    modalApi.showAlert({
                        title: messages.changingPaymentMethod,
                        message: messages.thereWasAProblemChangingThePaymentMethod,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).then(() => {
                        resetInternalStates();
                    }, _.noop);
                });
        },
        [invoiceStreamId, authHeader, modalApi, resetInternalStates, updateSelectedScreen]
    );

    const onClickCancelChangePaymentSource = useCallback(() => {
        updateSelectedScreen();
    }, [updateSelectedScreen]);

    const onClickMenuLink = useCallback(
        (evt) => {
            setUserMakePayShow(false)
            const { id: menuLinkId } = evt.currentTarget;
            let screen;
            switch (menuLinkId) {
                case 'makeAPaymentLinkId':
                    screen = 'payment';
                    break;
                case 'payNowButton':
                    screen = 'payment';
                    break;
                case 'setupAutomaticPaymentsLinkId':
                    screen = 'automatic';
                    break;
                case 'changePaymentMethodLinkId':
                    screen = 'payment-source';
                    break;
                case 'viewPoliciesLinkId':
                    screen = 'policy-list';
                    break;
                case 'viewInvoicesLinkId':
                case 'default':
                    screen = 'default';
                    break;
                default:
                    screen = 'default';
                    resetInternalStates();
                    break;
            }
            updateSelectedScreen(screen);
        },
        [resetInternalStates, updateSelectedScreen]
    );

    const onClickPayAsYouGoLink = useCallback(() => {
        history.push('/premium-reports');  
    }, [history]);

    const onClickManageSavedPaymentMethodsLink = useCallback(() => {
        const baseUrl = `${invoiceCloudBaseUrl}/-/vendors/ic/payment-methods`;
        const accountNum = `BC$${invoiceStreamDetails.displayName}`;
        const icUrl = `${baseUrl}?accountNum=${accountNum}`;
        const componentProps = {
            icTitle: 'IC CloudSSO - Manage Saved Payment Methods',
            icUrl: icUrl
        };
        modalApi.showModal(<InvoiceCloudPopup {...componentProps} />).then(() => {
            // 
        }, _.noop);
    }, [invoiceStreamDetails, modalApi]);

    const onClickManageScheduledPaymentsLink = useCallback(() => {
        const baseUrl = `${invoiceCloudBaseUrl}/-/vendors/ic/scheduled-payments`;
        const accountNum = `BC$${invoiceStreamDetails.displayName}`;
        const icUrl = `${baseUrl}?accountNum=${accountNum}`;
        const componentProps = {
            icTitle: 'IC CloudSSO - Manage Scheduled Payments',
            icUrl: icUrl
        };
        modalApi.showModal(<InvoiceCloudPopup {...componentProps} />).then(() => {
            // 
        }, _.noop); 
    }, [invoiceStreamDetails, modalApi]);

    const onClickFilterChange = useCallback((value) => {
        updateSelectedInvoiceFilter(value);
    }, []);

    useEffect(() => {
        const defaultFilter = hasUnpaidInvoices(invoiceSummary) ? 'planned' : 'paid';
        updateSelectedInvoiceFilter(defaultFilter);
    }, [invoiceSummary]);

    useEffect(()=>{
        BillingService.getAccountBillingSummary(authHeader)
            .then(setAccountBillingData)
        WniBillingAndPaymentService.getAccountPaymentList(authHeader)
            .then(setPaymentsHistory)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const aggregateBilling = useCallback((invoices) => {
        if (invoices.length === 0) {
            return null;
        }

        let total = 0;
        let due = moment(invoices[0].dueLocalDate_Ext).toDate();
        let lastDueDate = moment(invoices[0].dueLocalDate_Ext).toDate();
        let nextBillDate = moment(invoices[0].eventLocalDate_Ext).toDate();
        let nextBillAmount = invoices[0].amountDue;

        _.each(invoices, (invoice) => {
            total += invoice.amountDue.amount;
            const newDue = moment(invoice.dueLocalDate_Ext).toDate();
            const newBill = moment(invoice.eventLocalDate_Ext).toDate();
            if (newDue.getTime() < due.getTime()) {
                due = newDue;
            }else{
                lastDueDate = newDue
            }
            if (newBill.getTime() < nextBillDate.getTime()) {
                nextBillDate = newBill;
                nextBillAmount = invoice.amountDue
            }
        });

        return {
            amountDue: {
                amount: total,
                currency: invoices[0].amountDue.currency
            },
            earliestDue: WniDateUtil.formatDateWithPattern(due, 'MM/DD/YYYY'),
            lastDueDate: WniDateUtil.formatDateWithPattern(lastDueDate, 'MM/DD/YYYY'),
            nextBillDate: WniDateUtil.formatDateWithPattern(nextBillDate, 'MM/DD/YYYY'),
            nextBillAmount,
        };
    }, []);

    const getMyBalanceOverDueAndCurrentData = useCallback(
        (invoiceStatus) => {
            if (invoiceSummary) {
                const invoices = invoiceSummary;
                return aggregateBilling(
                    invoices.filter((invoice) => {
                        if(typeof invoice === 'string'){
                            return invoice.invoiceStatus === invoiceStatus
                        }
                        return invoiceStatus.indexOf(invoice.invoiceStatus) !== -1
                    })
                );
            }
            return null;
        },
        [invoiceSummary, aggregateBilling]
    );

    const getExisitingAccountValue = useCallback(() => {
        const currentAutoPay = existingBankAccounts.find((pi) => _.get(pi, 'bankAccountDataCE_Ext.publicID') === currentAutoPayPublicID);
        const bankAccountTypeCode = _.get(currentAutoPay, 'bankAccountDataCE_Ext.bankAccountType');
        const bankAccountType = translator({
            id: `typekey.BankAccountType_Ext.${bankAccountTypeCode}`,
            defaultMessage: bankAccountTypeCode
        });
        const bankAccountNumber = _.get(currentAutoPay, 'bankAccountDataCE_Ext.bankAccountNumber', '');
        const bankAccountNumberLen = bankAccountNumber.length;
        const last4Digits = bankAccountNumber.substring(bankAccountNumberLen - 4);
        return _.isEmpty(last4Digits) ? bankAccountType : `${bankAccountType}(${last4Digits})`
    }, [currentAutoPayPublicID, existingBankAccounts, translator]);

    const isCollection = useMemo(() => {
        return invoiceStreamDetails.isCollection_Ext
    },[invoiceStreamDetails])

    const showPayByAutoPayAndUserSelected = useMemo(() => {
        return !currentAutoPayPublicID || userMakePayShow
    },[currentAutoPayPublicID, userMakePayShow])

    useEffect(() => {
        if(!showPayByAutoPayAndUserSelected && selectedScreen === 'payment' && !isCollection) {
            modalApi.showConfirm({
                title: messages.noticeEnrolledModalTitle,
                message: messages.noticeEnrolledAutoPay,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.yesShowMakePayment,
                cancelButtonText: messages.cancelShowAutoPay
            }).then(async (res) => {
                if(res === 'confirm') {
                    onSetMakePayShow()
                } else {
                    onCancelShowAutoPayBtn()
                }
            }, _.noop);
        }
    },[showPayByAutoPayAndUserSelected, selectedScreen, isCollection])

    const policyOptions = useMemo(() => {
        let policyOptionRes = []
        if (invoiceStreamDetails.hasPayAsYouGo_Ext) {
            policyOptionRes = _.cloneDeep(invoiceStreamDetails.payAsYouGoPolicies_Ext)
        } else if (invoiceStreamDetails.notGoodStatus_Ext) {
            if (!_.isEmpty(invoiceStreamDetails.billOrDueRelatedPolicies_Ext)) {
                policyOptionRes = _.cloneDeep(invoiceStreamDetails.billOrDueRelatedPolicies_Ext)
            }
        }
        return policyOptionRes
    },[invoiceStreamDetails]);

    const amountList = useMemo(() => {
        const fullPayAmount = _.get(invoiceStreamDetails, 'fullPayAmount_Ext')
        const accountBalance = {
            amount: invoiceStreamDetails.totalValue.amount - invoiceStreamDetails.paidAmount.amount,
            currency: currency
        }
        return [minDueAmount, fullPayAmount, accountBalance]
    },[minDueAmount, currency, invoiceStreamDetails]);

    const minToPay = useMemo(() => {
        if (policyOptions.length > 0) {
            let policyMins = policyOptions.map((opt) => opt.minimumDue.amount);
            if (invoiceStreamDetails.hasPayAsYouGo_Ext) {
                policyMins = policyOptions.map((opt) => opt.payAsYouGoMinimumDue.amount);
            }
            const filteredPolicyMins = policyMins.filter((amt) => amt !== 0);
            return _.isEmpty(filteredPolicyMins) ? 0 : _.min(filteredPolicyMins);
        } 
        if (!_.isEmpty(amountList[0])) {
            return amountList[0].amount;
        }
        return 0;
    },[policyOptions, amountList, invoiceStreamDetails]);

    const formatAmountToCurrency = (amount) => {
        return intl.formatNumber(
            _.isNil(amount) ? 0 : amount,
            {
                style: 'currency',
                currency: currency,
                currencyDisplay: 'symbol'
            }
        );
    };

    const getManualAmountDecimalValue = (amountObj) => {
        const enteredAmount = _.get(amountObj, 'amount');
        if (_.isNil(enteredAmount)) {
            return null;
        }
        return _.isNumber(enteredAmount) ? enteredAmount : Number(enteredAmount);
    };

    const canClickPayNow = useCallback(() => {
        const { selectedPAYGCode, selectedCode, selectedPolicies } = amountSelectionVars;
        const enteredAmount = getManualAmountDecimalValue(manualAmountToPay);
        const enteredManualAmountValid = !_.isNil(enteredAmount) && (enteredAmount >= minToPay) || minToPay > 50000;
        let selectedCheckbox;
        if (invoiceStreamDetails.hasPayAsYouGo_Ext) {
            selectedCheckbox = !_.isEmpty(selectedPAYGCode);
        } else {
            selectedCheckbox = !_.isEmpty(selectedCode) || !_.isEmpty(selectedPolicies);
        }
        return selectedCheckbox || enteredManualAmountValid;
    }, [amountSelectionVars, invoiceStreamDetails, manualAmountToPay, minToPay]);

    const onChangeAmountToPay = useCallback((value) => {
        const enteredAmount = getManualAmountDecimalValue(value);
        updateManualAmount(value);
        // when other field has input with checkbox checked, need to uncheck
        if (invoiceStreamDetails.hasPayAsYouGo_Ext) {           
            updateAmountSelectionVars({
                ...amountSelectionVars,
                selectedPAYGCode: [],
                amountSentToIC: enteredAmount
            });
        } else {
            updateAmountSelectionVars({
                ...amountSelectionVars,
                selectedCode: [],
                selectedPolicies: [],
                amountSentToIC: enteredAmount
            });           
        }
    }, [amountSelectionVars, invoiceStreamDetails]);

    const onBlurAmountToPay = useCallback(() => {
        const enteredAmount = getManualAmountDecimalValue(manualAmountToPay);
        if (!_.isNil(minToPay) && !_.isNil(enteredAmount) && enteredAmount < minToPay && minToPay <= 50000) {
            updateShowAmountError(true);
        } else {
            updateShowAmountError(false);
        }
    }, [manualAmountToPay, minToPay]);

    const handleICPayPopupCloase = useCallback(() => {
        updateSelectedScreen('policy-list')
    },[updateSelectedScreen])

    const onClickMakePaymentForInvoiceCloud = useCallback(async () => {
        let paymentDue;
        let requestReferenceNumber;
        let dueDate = _.isEmpty(invoiceCloudStreamLevelDueDate) ? WniDateUtil.formatDateWithPattern(new Date(), 'MM/DD/YYYY') : WniDateUtil.formatDateWithPattern(invoiceCloudStreamLevelDueDate, 'MM/DD/YYYY')

        if(amountSelectionVars.amountSentToIC){
            paymentDue = amountSelectionVars.amountSentToIC
        }
        if(manualAmountToPay?.amount){
            paymentDue = manualAmountToPay.amount
        }
        if(amountSelectionVars.selectedPolicies.length > 0){
            // eslint-disable-next-line prefer-destructuring
            requestReferenceNumber = amountSelectionVars.selectedPolicies[0]
            const selectPolicy = policyOptions.find((policyOpt) => policyOpt.policyNumber === requestReferenceNumber)
            if(selectPolicy.invoiceCloudDueDate_Ext){
                dueDate = WniDateUtil.formatDateWithPattern(selectPolicy.invoiceCloudDueDate_Ext, 'MM/DD/YYYY')
            }
        }else{
            requestReferenceNumber = invoiceStreamDetails.displayName
        }
        // update request params
        const baseUrl = `${invoiceCloudBaseUrl}/-/vendors/ic/pay-invoice`;
        const referenceNumber = await WniBillingService.getReferenceNumber(requestReferenceNumber, false, authHeader);
        const icUrl = `${baseUrl}?accountNum=BC$${invoiceStreamDetails.displayName}&referenceNum=${referenceNumber}&paymentDue=${paymentDue}&dueDate=${dueDate}`;

        const componentProps = {
            icTitle: 'IC CloudSSO - Manage Payment Methods',
            icUrl: icUrl
        };
        modalApi.showModal(<InvoiceCloudPopup onReject={handleICPayPopupCloase} {...componentProps} />)
        .then(async () => {
            handleICPayPopupCloase()
        }, () => {
            handleICPayPopupCloase()
        });
    }, [invoiceCloudStreamLevelDueDate, amountSelectionVars.amountSentToIC, handleICPayPopupCloase, amountSelectionVars.selectedPolicies, manualAmountToPay, authHeader, invoiceStreamDetails.displayName, modalApi, policyOptions]);

    const renderInvoiceCloudMakePaymentIframe = useCallback(() => {
        const { amountSentToIC } = amountSelectionVars;
        const baseUrl = `${invoiceCloudBaseUrl}/-/vendors/ic/pay-invoice`;
        const accountNum = `BC$${invoiceStreamDetails.displayName}`;
        const referenceNum = `4${invoiceStreamDetails.displayName}000`;
        const paymentDue = amountSentToIC;
        const icMakePaymentUrl = `${baseUrl}?accountNum=${accountNum}&referenceNum=${referenceNum}&paymentDue=${paymentDue}`;
        return (
            <iframe
                title="IC CloudSSO - Make a Payment"
                width="100%"
                height="100%"
                src={icMakePaymentUrl}
            />
        );
    }, [amountSelectionVars, invoiceStreamDetails]);
    
    const showPayAsYouGoLink = useCallback(() => {
        const invoiceStreamPolicies = invoiceStreamDetails.relatedPolicies;
        return !_.isEmpty(payAsYouGoPolicies) && payAsYouGoPolicies.findIndex((policy) => _.includes(invoiceStreamPolicies, policy)) !== -1;
    },[payAsYouGoPolicies, invoiceStreamDetails])

    if (isLoadingData) {
        return <Loader loaded={!isLoadingData} />;
    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            },
        },
        menuLinkContainerId: {
            visible: (invoiceStreamId || accountLevelBilling) && !(selectedScreen === 'automaticConfirmation')
        },
        makeAPaymentLinkId: {
        },
        payNowButton: {
            visible: hasBilledOrDueInvoices(invoiceSummary)
        },
        viewPoliciesLinkId: {
            visible: accountLevelBilling
        },
        changePaymentMethodLinkId: {
            visible: false
        },
        payAsYouGoReportingLinkId: {
            visible: showPayAsYouGoLink()
        },
        setupAutomaticPaymentsLinkId: {
            visible: !invoiceStreamDetails.hasPayAsYouGo_Ext
        },
        nextBillContainer: {
            visible: !invoiceStreamDetails.hasPayAsYouGo_Ext
        },
        billingInvoicesContainerId: {
            visible: selectedScreen === 'default' && !!invoiceStreamId
        },
        tileWithAdContainer: {
            visible: selectedScreen === 'default' || selectedScreen === 'policy-list',
        },
        billingInvoicesHeaderId: {
            content: getInvoiceStreamHeader(accountLevelBilling, currentAutoPayPublicID, lobCode,
                accountsNumbers, invoiceStreamDetails.accountNumber_Ext, selectedInvoiceFilter),
            visible: invoiceStreamId || accountLevelBilling
        },
        invoicesFilterToggleId: {
            onValueChange: onClickFilterChange
        },
        billingInvoicesComponentId: {
            invoiceSummary:
                selectedInvoiceFilter === 'planned'
                    ? getExpandInvoicesByRelatePolicies(getUnpaidInvoices(invoiceSummary))
                    : getExpandInvoicesByRelatePolicies(getPaidInvoices(invoiceSummary)),
            showPaidDate: selectedInvoiceFilter === 'paid',
            showMinimumDueAndLogTip: selectedInvoiceFilter === 'planned',
            visible: selectedInvoiceFilter !== 'payments'
        },
        paymentsTableComponentId: {
            paymentsHistory,
            invoiceStreamNumber: invoiceStreamDetails.displayName,
            visible: selectedInvoiceFilter === 'payments'
        },
        makePaymentContainerId: {
            visible: selectedScreen === 'payment'
        },
        makePaymentInvoicesTableTitleId: {
            visible: !isCollection && showPayByAutoPayAndUserSelected
        },
        amountSelectionId: {
            policySummaries: policySummaries,
            invoiceStreamDetails,
            visible: !isCollection && showPayByAutoPayAndUserSelected,
            policyOptions,
            amountList,
            formatAmountToCurrency,
            amountSelectionVars,
            updateAmountSelectionVars,
            updateManualAmount,
            updateShowAmountError,
            minToPay
        },
        makePaymentSourceComponentId: {
            title: messages.setPaymentSource,
            xCenter: 'bc',
            model: paymentInstrument,
            forAutoPay: false,
            hasAutoPay: false,
            existingBankAccounts,
            isDisabled: selectedInvoicesForPayment.length === 0,
            isSelectedAmount: canClickPayNow(),
            visible: false
            // visible: !isCollection && showPayByAutoPayAndUserSelected
        },
        makePaymentAmountToPayContainer: {
            visible: !isCollection && showPayByAutoPayAndUserSelected
        },
        makePaymentAmountToPayId: {
            defaultCurrency: currency,
            // CEI-3810 1.003
            // disabled: !(invoiceStreamDetails.hasPayAsYouGo_Ext && !currentDueTotal?.amount),
            onValueChange: onChangeAmountToPay,
            onBlur: onBlurAmountToPay
        },
        amountToPayMsg: {
            visible: showAmountError,
            value: translator(messages.amountToPayMsg, {minPayAmount: formatAmountToCurrency(minToPay)})
        },
        invoiceCloudMakePaymentContainerId: {
            visible: selectedScreen === 'icMakeAPayment',
            content: renderInvoiceCloudMakePaymentIframe()
        },
        invoiceCloudPayButtonContainer: {
            visible: !isCollection && showPayByAutoPayAndUserSelected
        },
        invoiceCloudPayNow: {
            disabled: !canClickPayNow()
        },
        makePaymentConfirmationContainerId: {
            visible: selectedScreen === 'paymentConfirmation'
        },
        makePaymentConfirmationMessageId: {
            content: translator(messages.paymentOfHasBeenAppliedToYourAccount, {
                paidAmount: intl.formatNumber(
                    payingAmountValue,
                    {
                        style: 'currency',
                        currency: currency,
                        currencyDisplay: 'code'
                    }
                )
            })
        },
        nothingToPayContainerId: {
            visible: isCollection && selectedScreen === 'payment'
        },
        enrolledAutoPayButtonContainer: {
            visible: showConfirmMakePayBtn
        },
        setUpAutomaticPaymentContainerId: {
            visible: selectedScreen === 'automatic'
        },
        setupAutomaticPaymentPoliciesId: {
            policySummaries: policySummaries,
            policyNumbers: relatedPolicies,
            forAutoPay: true
        },
        setupAutomaticPaymentExistingAccountId: {
            visible: !showPayByAutoPayAndUserSelected,
            value: getExisitingAccountValue()
        },
        switchAutoPayId: {
            visible: !!currentAutoPayPublicID,
            value: switchAutoPay,
            onValueChange: onHandleSwitchAutoPayChange
        },
        autoPayForFuturePaymentsMsgId: {
            visible: !!switchAutoPay || !currentAutoPayPublicID
        },
        setupAutomaticPaymentComponentId: {
            visible: !!switchAutoPay || !currentAutoPayPublicID,
            xCenter: 'bc',
            model: paymentInstrument,
            forAutoPay: true,
            existingBankAccounts,
            currentAutoPayPublicID,
            nextLabel: _.isEmpty(currentAutoPayPublicID) ? translator(messages.setUpAutoPay) : translator(messages.updateAutoPay)
        },
        setUpAutomaticPaymentConfirmationContainerId: {
            visible: selectedScreen === 'automaticConfirmation'
        },
        setUpAutomaticPaymentConfirmationInfoId: {
            content: translator(messages.yourAutomaticPaymentsWillBeginOn, {
                startingDate: WniDateUtil.formatDateWithPattern(firstInvoiceDueDate, 'MM/DD/YYYY')
            })
        },
        changePaymentSourceContainerId: {
            visible: selectedScreen === 'payment-source'
        },
        changePaymentSourceFormComponentId: {
            title: messages.changePaymentMethod,
            xCenter: 'bc',
            model: paymentInstrument
        },
        changePaymentSourceConfirmationContainerId: {
            visible: selectedScreen === 'paymentSourceConfirmation'
        },
        policyListContainerId: {
            visible: selectedScreen === 'policy-list'
        },
        viewPoliciesComponentId: {
            policySummaries: policySummaries,
            policyNumbers: relatedPolicies
        },
        relatePolicyDataContainer:{
            policySummaries: policySummaries,
            policyNumbers: relatedPolicies
        },
        minimumdueAmount: {
            value: minDueAmount || defaultAmountValue
        },
        balanceDate: {
            value: _.isEmpty(earliestDueDate) ? '-' : WniDateUtil.formatDateWithPattern(earliestDueDate, 'MM/DD/YYYY')
        },
        accountBalance: {
            value: _.get(invoiceStreamDetails, 'balance'),
        },
        nextBillAmount: {
            value: _.get(getMyBalanceOverDueAndCurrentData('planned'), 'nextBillAmount') || defaultAmountValue,
        },
        nextPaymentDue: {
            value: _.get(getMyBalanceOverDueAndCurrentData('planned'), 'earliestDue'),
        },
        lastPaymentAmount: {
            value: _.get(invoiceStreamDetails, 'lastPayment_Ext.amount') || defaultAmountValue,
        },
        lastPaymentDate: {
            value: _.get(invoiceStreamDetails, 'lastPayment_Ext.paymentLocalDate_Ext') && WniDateUtil.formatDateWithPattern(_.get(invoiceStreamDetails, 'lastPayment_Ext.paymentLocalDate_Ext'), 'MM/DD/YYYY'),
        },
    };


    const dataForComponent = {
        invoiceStreamId,
        selectedInvoiceFilter,
        periodicity,
        startingDate: firstInvoiceDueDate,
        manualAmountToPay,
        accountBillingData
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            paymentcomponent: PaymentComponent
        },
        resolveCallbackMap: {
            onClickMenuLink,
            onClickManageSavedPaymentMethodsLink,
            onClickManageScheduledPaymentsLink,
            onClickPayAsYouGoLink,
            onClickConfirmMakePayment,
            onClickCancelMakePayment,
            onClickConfirmSetupAutomaticPayment,
            onClickCancelSetupAutomaticPayment,
            onClickConfirmChangePaymentSource,
            onClickCancelChangePaymentSource,
            onCancelShowAutoPayBtn,
            onSetMakePayShow,
            onClickMakePaymentForInvoiceCloud
        }
    };

    return (
        <MetadataForm
            data={dataForComponent}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            isUsingNewValidation />
    );
}

BillingSummary.propTypes = {
    invoiceStreamId: PropTypes.string,
    accountLevelBilling: PropTypes.bool,
    lobCode: PropTypes.string,
    paymentGroupDetails: PropTypes.shape({
        invoiceSummary: PropTypes.array,
        relatedPolicies: PropTypes.array,
        paymentInstrument: PropTypes.object,
        paymentInstrumentSummary: PropTypes.object,
        periodicity: PropTypes.string,
        showAutoPayFeeMsg_Ext: PropTypes.bool,
        existingBankAccounts_Ext: PropTypes.array,
        currentAutoPayPublicID_Ext: PropTypes.string
    }),
    currency: PropTypes.string.isRequired,
    policySummaries: PropTypes.arrayOf(PropTypes.object),
    payAsYouGoPolicies: PropTypes.arrayOf(PropTypes.string),
    authHeader: PropTypes.shape({}).isRequired,
    reloadInvoiceDetails: PropTypes.func.isRequired,
    selectedScreen: PropTypes.string.isRequired,
    updateSelectedScreen: PropTypes.func.isRequired
};

BillingSummary.defaultProps = {
    invoiceStreamId: null,
    accountLevelBilling: null,
    lobCode: null,
    paymentGroupDetails: {
        invoiceSummary: [],
        relatedPolicies: [],
        paymentInstrument: null,
        paymentInstrumentSummary: null,
        periodicity: null,
        showAutoPayFeeMsg_Ext: true
    },
    policySummaries: [],
    payAsYouGoPolicies: [],
    selectedScreen: 'policy-list'
};

export default withRouter(withAuthenticationContext(BillingSummary));
