/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
    useCallback, useContext, useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DropdownMenuButton, useModal } from '@jutro/components';
import { IntlContext, useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import CEVehicleCustomEquipmentPopup from '../CEVehicleCustomEquipmentPopup/CEVehicleCustomEquipmentPopup';
import metadata from './CEVehicleCustomEquipmentTable.metadata.json5';
import messages from './CEVehicleCustomEquipmentTable.message';
import styles from '../CEAdditionalInterest/CEAdditionalInterestComponent.module.scss';

import { Link, DropdownMenuLink } from '@jutro/router';

function CEVehicleCustomEquipmentTable(props) {
    const modalApi = useModal();
    const {
        id,
        vehicleVM,
        onValueChange,
        onValidate
    } = props;
    const {
        isComponentValid,
    } = useValidation(id);
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const viewModelService = useContext(ViewModelServiceContext);
    const customEquipmentPath = 'customEquipments_Ext.value';

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, vehicleVM, onValidate, isComponentValid, viewModelService]);


    const deleteDataRow = useCallback((row, index) => {
        const rows = _.get(vehicleVM, customEquipmentPath);
        if (rows) {
            rows.splice(index, 1);
        }
        onValueChange(rows, customEquipmentPath);
    }, [onValueChange, vehicleVM]);

    const showModalFn = useCallback((modalVM, modelProps) => {
        const componentProps = {
            title: modelProps.title,
            actionBtnLabel: translator(messages.popOK),
            cancelBtnLabel: translator(messages.popCancel),
            modalVM,
            viewModelService
        };
        return modalApi.showModal(<CEVehicleCustomEquipmentPopup {...componentProps} />);
    }, [modalApi, translator, viewModelService]);

    const addDataRow = useCallback(() => {
        const modalVM = viewModelService.create({}, 'pc', 'wni.edge.capabilities.quote.submission.dto.WniVehicleCustomEquipmentDTO');
        const modelInfo = {
            title: `${translator(messages.Add)} ${translator(messages.customEquipment)}`
        };
        showModalFn(modalVM, modelInfo)
            .then((result) => {
                const { customEquipment, equipmentValue } = result;
                const data = {
                    customEquipment,
                    equipmentValue: equipmentValue.amount || equipmentValue
                };
                // Add default variable type in case get func returns undefined object
                const rows = _.get(vehicleVM, customEquipmentPath, []);
                rows.unshift(data);
                onValueChange(rows, customEquipmentPath);
            })
            .catch(() => {
                // do nothing when close the popup
                _.noop();
            });
    }, [onValueChange, showModalFn, translator, vehicleVM, viewModelService]);

    const editDataRow = useCallback((rowValue, index) => {
        const modalVM = viewModelService.create(rowValue, 'pc', 'wni.edge.capabilities.quote.submission.dto.WniVehicleCustomEquipmentDTO');
        const modelInfo = {
            title: `${translator(messages.Edit)} ${translator(messages.customEquipment)}`
        };
        showModalFn(modalVM, modelInfo)
            .then((result) => {
                const { customEquipment, equipmentValue } = result;
                const data = {
                    customEquipment,
                    equipmentValue: equipmentValue.amount || equipmentValue
                };
                const rows = _.get(vehicleVM, customEquipmentPath);
                rows[index] = data;
                onValueChange(rows, customEquipmentPath);
            })
            .catch(() => {
                // do nothing when close the popup
                _.noop();
            });   
    }, [onValueChange, showModalFn, translator, vehicleVM, viewModelService]);

    const getCell = useCallback((item, index, property) => {
        const { path, currency } = property;
        let formattedContent = item[path];
        if (currency && formattedContent) {
            formattedContent = intl.formatNumber(formattedContent, {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'symbol' // 'code'
            });
        }
        return (
            <span>{formattedContent}</span>

        );
    }, [intl]);

    const getActionCell = useCallback((item, index) => {
        return (
            <DropdownMenuButton
                icon="gw-expand-more"
                id={`dropdownMenuButton${index}`}
                className="ml-10"
                menuClassName="dropDownMenuList"
                alignRight
            >
                <DropdownMenuLink key={`edit${index}`} onClick={() => editDataRow(item, index)}>
                    <Link 
                        icon="gw-edit"
                        className={styles.actionLink}
                    >
                        {translator(messages.Edit)}
                    </Link>
                </DropdownMenuLink>
                <DropdownMenuLink key={`delete${index}`} onClick={() => deleteDataRow(item, index)}>
                    <Link 
                        icon="gw-delete"
                        className={styles.actionLink}
                    >
                        {translator(messages.Delete)}
                    </Link>
                </DropdownMenuLink>
            </DropdownMenuButton>
        );
    }, [deleteDataRow, editDataRow, translator]);

    const overrideProps = {
        addButton: {
            onClick: addDataRow
        },
        customEquipment: {
            renderCell: (item, index) => {
                const vm = _.get(vehicleVM, `customEquipments_Ext.children.${index}.customEquipment.value`);
                return translator({
                    id: vm.name
                });
            }
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            getCell: getCell,
            getActionCell: getActionCell
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

CEVehicleCustomEquipmentTable.propTypes = {
    vehicleVM: PropTypes.object.isRequired,
    onValidate: PropTypes.func,
    onValueChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
};

CEVehicleCustomEquipmentTable.defaultProps = {
};

export default CEVehicleCustomEquipmentTable;
