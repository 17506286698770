import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './UnderlyingPolicyDetailIterable.metadata.json5';
import styles from './UnderlyingPolicyDetailIterable.module.scss';

function UnderlyingPolicyDetailIterable(props) {
    const { data: ulPolicyDetailData } = props;

    const generateOverrides = useCallback(() => {
        const overrides = ulPolicyDetailData.policyDetailItems?.map((item, index) => {
            return {
                [`ulPolicyDetailItemLabel${index}`]: {
                    content: item.itemLabel
                },
                [`ulPolicyDetailFieldContent${index}`]: {
                    label: item.fieldLabel,
                    value: item.fieldValue
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [ulPolicyDetailData]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        ulPolicyDetailItemIterable: {
            data: ulPolicyDetailData.policyDetailItems
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={ulPolicyDetailData}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

UnderlyingPolicyDetailIterable.propTypes = {
    data: PropTypes.shape({}).isRequired
};

export default UnderlyingPolicyDetailIterable;
