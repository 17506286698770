import { defineMessages } from 'react-intl';

export default defineMessages({
    watercraftDetails: {
        id: 'policycommon.component.policy-watercraft-operator-info.Watercraft Details',
        defaultMessage: 'Watercraft Details'
    },
    motorDetails: {
        id: 'policycommon.component.policy-watercraft-operator-info.Motor Details',
        defaultMessage: 'Motor Details'
    },
    trailerDetails: {
        id: 'policycommon.component.policy-watercraft-operator-info.Trailer Details',
        defaultMessage: 'Trailer Details'
    }
});
