import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PolicyVehiclesDriversInfo from '../PA/PolicyVehiclesDriversInfo/PolicyVehiclesDriversInfo';
import PolicyWatercraftOperatorInfo from '../WAL/PolicyWatercraftOperatorInfo/PolicyWatercraftOperatorInfo';
import PolicyRoadTrailOperatorInfo from '../RT/PolicyRoadTrailOperatorInfo/PolicyRoadTrailOperatorInfo';
import PolicyDwellingInfo from '../HO/PolicyDwellingInfo/PolicyDwellingInfo';
import PolicyUmbrellaInfo from '../PU/PolicyUmbrellaInfo/PolicyUmbrellaInfo';
import PolicyCommonCoverables from '../PolicyCommonCoverables/PolicyCommonCoverables';
import metadata from './PolicyCoverages.metadata.json5';

function PolicyCoverages(props) {
    const { value: policy } = props;

    if (_.isEmpty(policy)) {
        return null;
    }

    const allDocuments = _.get(policy, 'documentDTOs', [])

    const idCardDocumentID = _.get(policy, 'idCardPublicID')

    const idCardDocument = allDocuments.find((doc) => doc.publicID === idCardDocumentID)

    const overrides = {
        policyVehiclesDriversInfoComponent: {
            visible: !_.isEmpty(policy.lobs.personalAuto),
            idCardDocument: idCardDocument
        },
        policyWatercraftOperatorInfoComponent: {
            visible: !_.isEmpty(policy.lobs.watercraft),
            idCardDocument: idCardDocument
        },
        policyRoadTrailOperatorInfoComponent: {
            visible: !_.isEmpty(policy.lobs.roadTrail),
            idCardDocument: idCardDocument
        },
        policyHomeOwnersInfoComponent: {
            visible: !_.isEmpty(policy.lobs.homeOwners),
            productCode_Ext: policy.productCode_Ext
        },
        policyUmbrellaInfoComponent: {
            visible: !_.isEmpty(policy.lobs.personalUmbrella)
        },
        genericCoveragesComponent: {
            visible:
                _.isEmpty(policy.lobs.homeOwners)
                && _.isEmpty(policy.lobs.personalAuto)
                && _.isEmpty(policy.lobs.watercraft)
                && _.isEmpty(policy.lobs.roadTrail)
                && _.isEmpty(policy.lobs.personalUmbrella)
                && !_.isEmpty(policy.coverables)
        }
    };

    const resolvers = {
        resolveComponentMap: {
            policyvehiclesdriversinfocomponent: PolicyVehiclesDriversInfo,
            policywatercraftoperatorcomponent: PolicyWatercraftOperatorInfo,
            policyroadtrailoperatorcomponent: PolicyRoadTrailOperatorInfo,
            policydwellingcomponent: PolicyDwellingInfo,
            policyumbrellacomponent: PolicyUmbrellaInfo,
            genericcoveragecomponent: PolicyCommonCoverables
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policy}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyCoverages.propTypes = {
    value: PropTypes.shape({})
};

PolicyCoverages.defaultProps = {
    value: {
        lobs: {
            homeOwners: {},
            personalAuto: {},
            watercraft: {},
            roadTrail: {},
            personalUmbrella: {},
            coverables: []
        }
    }
};

export default PolicyCoverages;
