import { defineMessages } from 'react-intl';

export default defineMessages({
    // Accident Forgiveness
    PAAcdntForCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PAAcdntForCov_Ext',
        defaultMessage: 'Accident Forgiveness coverage protects a policyholder\'s rates from being impacted by an accident.',
    },
    // Exclude Liability
    PALiabilityCovExc_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PALiabilityCovExc_Ext',
        defaultMessage: 'If a private passenger vehicle has no liability, uninsured motorist, underinsured motorist, and medical or personal injury coverage (for example, if the vehicle is in storage), then select this option.',
    },
    // Roadside Assistance Coverage
    PARoadsideAssistCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PARoadsideAssistCov_Ext',
        defaultMessage: 'Towing Service-up to 25 miles *\nBattery Jump Start *\nFuel and Fluid Deliver *\nLocksmith Service *\nFlat Tire Service *\n\n*The maximum payable for all services combined is $150 per occurrence. All terms and conditions of the policy apply.',
    },
    // Roadside Assistance Coverage - AK
    PARoadsideAssistAKCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PARoadsideAssistAKCov_Ext',
        defaultMessage: 'Towing Service-up to 25 miles *\nBattery Jump Start *\nFuel and Fluid Deliver *\nLocksmith Service *\nFlat Tire Service *\n\n*The maximum payable for all services combined is $250 per occurrence. All terms and conditions of the policy apply.',
    },
    // Roadside Assistance Plus
    PARdsidAsstPlusCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PARdsidAsstPlusCov_Ext',
        defaultMessage: 'Transportation Expenses - Max $1500 up to $60 per day;\nRoadside Assistance - Up to $150;\nTrip Interruption Coverage - Up to $750;\nand Air Bag Replacement',
    },
    // Roadside Assistance Plus - AK
    PARdsidAsstPlusAKCov_EXT: {
        id: 'portal.components.ClauseComponent.tooltip.PARdsidAsstPlusAKCov_EXT',
        defaultMessage: 'Transportation Expenses - Max $1500 up to $60 per day;\nRoadside Assistance - Up to $250;\nTrip Interruption Coverage - Up to $750;\nand Air Bag Replacement',
    },
    // New Vehicle Replacement Cost Other than Collision Coverage
    PANewVehRCOTCCov_EXT: {
        id: 'portal.components.ClauseComponent.tooltip.PANewVehRCOTCCov_EXT',
        defaultMessage: 'Available on newer vehicles (less than two years old); modifies the loss settlement basis from actual cash value to replacement cost.',
    },
    // New Vehicle Replacement Cost Other than Collision Coverage - AK
    PANewVehRCAKOTCCov_EXT: {
        id: 'portal.components.ClauseComponent.tooltip.PANewVehRCAKOTCCov_EXT',
        defaultMessage: 'Available on newer vehicles (less than two years old); modifies the loss settlement basis from actual cash value to replacement cost.',
    },
    // New Vehicle Replacement Cost Coverage
    PANewVehRCCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PANewVehRCCov_Ext',
        defaultMessage: 'Available on newer vehicles (less than two years old); modifies the loss settlement basis from actual cash value to replacement cost.',
    },
    // New Vehicle Replacement Cost Coverage - AK
    PANewVehRCAKCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PANewVehRCAKCov_Ext',
        defaultMessage: 'Available on newer vehicles (less than two years old); modifies the loss settlement basis from actual cash value to replacement cost.',
    },
    // New Vehicle Replacement Cost Collision Coverage
    PANewVehRCCollCov_EXT: {
        id: 'portal.components.ClauseComponent.tooltip.PANewVehRCCollCov_EXT',
        defaultMessage: 'Available on newer vehicles (less than two years old); modifies the loss settlement basis from actual cash value to replacement cost.',
    },
    // New Vehicle Replacement Cost Collision Coverage - AK
    PANewVehRCAKCollCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PANewVehRCAKCollCov_Ext',
        defaultMessage: 'Available on newer vehicles (less than two years old); modifies the loss settlement basis from actual cash value to replacement cost.',
    },
    // Auto Loan/Lease Other Than Collision
    PALoanLeaseOTCCOV_EXT: {
        id: 'portal.components.ClauseComponent.tooltip.PALoanLeaseOTCCOV_EXT',
        defaultMessage: 'Provides coverage if the loan or lease balance is greater than the value of the vehicle. Also known as "gap coverage".',
    },
    // Auto Loan/Lease Other Than Collision - AK
    PALoanLeaseAKOTCCov_EXT: {
        id: 'portal.components.ClauseComponent.tooltip.PALoanLeaseAKOTCCov_EXT',
        defaultMessage: 'Provides coverage if the loan or lease balance is greater than the value of the vehicle. Also known as "gap coverage".',
    },
    // Auto Loan/Lease Coverage
    PALoanLeaseCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PALoanLeaseCov_Ext',
        defaultMessage: 'Provides coverage if the loan or lease balance is greater than the value of the vehicle. Also known as "gap coverage".',
    },
    // Auto Loan/Lease Coverage - AK
    PALoanLeaseAKCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PALoanLeaseAKCov_Ext',
        defaultMessage: 'Provides coverage if the loan or lease balance is greater than the value of the vehicle. Also known as "gap coverage".',
    },
    // Auto Loan/Lease Collision
    PALoanLeaseCollCov_EXT: {
        id: 'portal.components.ClauseComponent.tooltip.PALoanLeaseCollCov_EXT',
        defaultMessage: 'Provides coverage if the loan or lease balance is greater than the value of the vehicle. Also known as "gap coverage".',
    },
    // Auto Loan/Lease Collision - AK
    PALoanLeaseCollAKCov_EXT: {
        id: 'portal.components.ClauseComponent.tooltip.PALoanLeaseCollAKCov_EXT',
        defaultMessage: 'Provides coverage if the loan or lease balance is greater than the value of the vehicle. Also known as "gap coverage".',
    },
    // Trip Interruption Coverage
    PATrpIntrptnCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PATrpIntrptnCov_Ext',
        defaultMessage: 'This covers expenses for lodging and meals (up to $600) in the event of a covered physical damage loss or mechanical or electrical breakdown of a specified auto.',
    },
    // Optional Limits Transportation Expenses
    PAOptLimitTrans_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PAOptLimitTrans_Ext',
        defaultMessage: 'Also known as Rental Reimbursement coverage, this covers rental vehicle costs while an insured vehicle is out of service due to a covered loss. Limits start at $30 per day / $900 maximum, with higher limits available.',
    },
    // Key Replacement And Related Services Coverage
    PAKeyRepCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PAKeyRepCov_Ext',
        defaultMessage: 'This covers the replacement cost for exterior door locks if the key(s) are lost or stolen.',
    },
    // Key Replacement And Related Services Coverage - AK
    PAKeyRepAKCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PAKeyRepAKCov_Ext',
        defaultMessage: 'This covers the replacement cost for exterior door locks if the key(s) are lost or stolen.',
    },
    // Pet Injury
    PAPetInjuryCov_EXT: {
        id: 'portal.components.ClauseComponent.tooltip.PAPetInjuryCov_EXT',
        defaultMessage: 'This provides coverage for your family cat or dog that is injured in a collision while riding in a covered vehicle. $500 per collision is included in the policy, with higher limits available.',
    },
    // Transportation Network Driver Coverage (No Passenger)
    PATNDCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PATNDCov_Ext',
        defaultMessage: 'This provides coverage for when the vehicle is being used by the insured who is logged into a "transportation network platform" (such as Uber or Lyft) as a driver and is in transit to pick up a passenger, but does not have a passenger occupying the vehicle.',
    },
    // Transportation Network Driver Coverage (No Passenger) - AK
    PATNDAKCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PATNDAKCov_Ext',
        defaultMessage: 'This provides coverage for when the vehicle is being used by the insured who is logged into a "transportation network platform" (such as Uber or Lyft) as a driver and is in transit to pick up a passenger, but does not have a passenger occupying the vehicle.',
    },
    // Transportation Network Driver Coverage (No Passenger) - MN
    PATNDMNCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PATNDMNCov_Ext',
        defaultMessage: 'This provides coverage for when the vehicle is being used by the insured who is logged into a "transportation network platform" (such as Uber or Lyft) as a driver and is in transit to pick up a passenger, but does not have a passenger occupying the vehicle.',
    },
    // Limited  Transportation Network Driver Coverage (No Passenger)
    PALtdTNDCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PALtdTNDCov_Ext',
        defaultMessage: 'This provides coverage for when the vehicle is being used by the insured who is logged into a "transportation network platform" (such as Uber or Lyft) as a driver, but neither has a passenger occupying the vehicle nor is in transit to pick up a passenger (or has not accepted a ride request from a passenger).',
    },
    // Limited  Transportation Network Driver Coverage (No Passenger) - AK
    PALtdTNDAKCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PALtdTNDAKCov_Ext',
        defaultMessage: 'This provides coverage for when the vehicle is being used by the insured who is logged into a "transportation network platform" (such as Uber or Lyft) as a driver, but neither has a passenger occupying the vehicle nor is in transit to pick up a passenger (or has not accepted a ride request from a passenger).',
    },
    // Limited Transportation Network Driver Coverage (No Passenger) - MN
    PALtdTNDMNCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PALtdTNDMNCov_Ext',
        defaultMessage: 'This provides coverage for when the vehicle is being used by the insured who is logged into a "transportation network platform" (such as Uber or Lyft) as a driver, but neither has a passenger occupying the vehicle nor is in transit to pick up a passenger (or has not accepted a ride request from a passenger).',
    },
    // Personal Property Coverage
    PAPerPropCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PAPerPropCov_Ext',
        defaultMessage: 'This provides worldwide coverage to personal property owned by you less the applicable deductible with the exception of some exclusions.',
    },
    // Personal Property Coverage - AK
    PAPerPropAkCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PAPerPropAkCov_Ext',
        defaultMessage: 'This provides worldwide coverage to personal property owned by you less the applicable deductible with the exception of some exclusions.',
    },
    // Personal Property Coverage - SD
    PAPerPropSDCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PAPerPropSDCov_Ext',
        defaultMessage: 'This provides worldwide coverage to personal property owned by you less the applicable deductible with the exception of some exclusions.',
    },
    // Personal Property Coverage - WI
    PAPerPropWICov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PAPerPropWICov_Ext',
        defaultMessage: 'This provides worldwide coverage to personal property owned by you less the applicable deductible with the exception of some exclusions.',
    },
    // Personal Property Coverage - MN
    PAPerPropMNCov_Ext: {
        id: 'portal.components.ClauseComponent.tooltip.PAPerPropMNCov_Ext',
        defaultMessage: 'This provides worldwide coverage to personal property owned by you less the applicable deductible with the exception of some exclusions.',
    },
    // Extended Non-Owned Coverage - Vehicles Furnished Or Available For Regular Use Individual
    PAENORegVehUseIndCov: {
        id: 'portal.components.ClauseComponent.tooltip.PAENORegVehUseIndCov',
        defaultMessage: 'This extends liability coverage to vehicles furnished or available for regular use (except as public or livery conveyances) for the named individual and resident relatives.',
    },
    // Extended Non-Owned Coverage - Vehicles Furnished Or Available For Regular Use Individual&Residents
    PAENORegVehUseResCov: {
        id: 'portal.components.ClauseComponent.tooltip.PAENORegVehUseResCov',
        defaultMessage: 'This extends liability coverage to vehicles furnished or available for regular use (except as public or livery conveyances) for the named individual and resident relatives.',
    },
    // Extended Non-Owned Coverage - Vehicles Furnished or Available For Use As Public or Livery Conveyances Individual
    PAENOPubVehUseCovInd_EXT: {
        id: 'portal.components.ClauseComponent.tooltip.PAENOPubVehUseCovInd_EXT',
        defaultMessage: 'This extends liability coverage to vehicles furnished or available for regular use (except as public or livery conveyances) for the named individual and resident relatives.',
    },
    // Extended Non-Owned Coverage - Vehicles Furnished or Available For Use As Public or Livery Conveyances Individual&Residents
    PAENOPubVehUseCovRes_EXT: {
        id: 'portal.components.ClauseComponent.tooltip.PAENOPubVehUseCovRes_EXT',
        defaultMessage: 'This extends liability coverage to vehicles furnished or available for regular use (except as public or livery conveyances) for the named individual and resident relatives.',
    },
})