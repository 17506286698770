import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { Loader, Button } from '@jutro/components';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { withRouter } from 'react-router-dom';
import { WniPremiumReportService } from 'wnice-capability-premiumreport';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import SublinesPremiumComponent from '../../components/SublinesPremiumComponent/SublinesPremiumComponent';
import { getCostsData } from '../../utils/GLPremiumReportUtil';
import metadata from './CEGLPremiumReportPremiumsPage.metadata.json5';
import styles from './CEGLPremiumReportPremiumsPage.module.scss';
import commonStyles from '../../CEGLPremiumReportWizard.module.scss';
import messages from './CEGLPremiumReportPremiumsPage.messages';

function CEGLPremiumReportPremiumsPage(props) {
    const { wizardData, updateWizardData, wizardSnapshot, goPrevious, steps, currentStepIndex, authHeader, history } = props;
    const { onValidate } = useValidation('CEGLPremiumReportPremiumsPage');
    const translator = useTranslator();
    const [isLoading, setIsLoading] = useState(false);

    const {
        summary : { taxAndSurcharges, comments, premiumItems },
        jobNumber,
        policyNumber,
        accountNumber,
        jobStatus,
        sessionUUID
    } = wizardData.value;
    const isAuditComplete = jobStatus === 'AuditComplete';

    const onSave = useCallback(async () => {
        if (comments !== _.get(wizardSnapshot, 'value.summary.comments')) {
            setIsLoading(true);
            await WniPremiumReportService.saveGLPremiumReport(
                jobNumber, [], comments, sessionUUID,
                authHeader
            );
            setIsLoading(false);
        }
    }, [authHeader, comments, jobNumber, sessionUUID, wizardSnapshot]);

    const onCancel = useCallback(() => {
        onSave();
        history.push('/premium-reports');
    }, [history, onSave]);

    const onPrevious = useCallback(() => {
        // jump to Details page
       // jumpTo(0);
       _.set(steps, `${currentStepIndex}.visited`, true);
       goPrevious();
    }, [currentStepIndex, goPrevious, steps]);

    const onEdit = useCallback(async () => {
        setIsLoading(true);
        wizardData.value = await WniPremiumReportService.editGLPremiumReport(
            jobNumber, comments, sessionUUID,
            authHeader
        );
        setIsLoading(false);
        // jump to Details page
        _.set(steps, `${currentStepIndex}.visited`, false);
        goPrevious();
    }, [wizardData, jobNumber, comments, sessionUUID, authHeader, steps, currentStepIndex, goPrevious]);

    const onSubmit = useCallback(async () => {
        setIsLoading(true);
        await WniPremiumReportService.submitGLPremiumReport(
            jobNumber, premiumItems, comments, sessionUUID,
            authHeader
        );
        setIsLoading(false);
        // jump to the confirmation screen
        history.push('/premium-report-success', {
            jobNumber: jobNumber,
            policyNumber: policyNumber,
            accountNumber: accountNumber
        });
    }, [accountNumber, authHeader, comments, premiumItems, history, jobNumber, policyNumber, sessionUUID]);

    const renderCustomWizardPageFooterContent = useCallback(() => {
        return (
            <>
                <Button
                    id="gw-wizard-cancel"
                    disabled={isAuditComplete}
                    onClick={onCancel}
                    variant="secondary" 
                    label={translator(messages.cancelBtn)}
                />
                <Button
                    id="gw-wizard-previous"
                    onClick={onPrevious}
                    variant="secondary" 
                    label={translator(messages.previousBtn)}
                />
                <Button
                    id="gw-wizard-edit"
                    disabled={isAuditComplete}
                    onClick={onEdit}
                    variant="secondary" 
                    label={translator(messages.editBtn)}
                />
                <Button
                    id="gw-wizard-save"
                    disabled={isAuditComplete}
                    onClick={onSave}
                    variant="secondary" 
                    label={translator(messages.saveBtn)}
                />
                <Button
                    id="gw-wizard-submit"
                    disabled={isAuditComplete}
                    onClick={onSubmit}
                    variant="primary" 
                    label={translator(messages.submitBtn)}
                />
            </>
        );
    }, [isAuditComplete, onCancel, onEdit, onPrevious, onSave, onSubmit, translator]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            sublinespremiumcomponent: SublinesPremiumComponent
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        premiumsHeader: {
            className: commonStyles.premiumReportWizardTitle
        },
        taxAndSurcharges: {
            visible: taxAndSurcharges.amount > 0
        },
        noTaxAndSurcharges: {
            visible: taxAndSurcharges.amount === 0,
            value: translator(messages.noTaxAndSurcharge)
        },
        comments: {
            readOnly: isAuditComplete
        },
        sublinesPremiumComponent: {
            data: getCostsData(premiumItems)
        }
    };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    return (
        <WizardPage
            renderCustomWizardPageFooterContent = {renderCustomWizardPageFooterContent}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={wizardData}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

CEGLPremiumReportPremiumsPage.propTypes = wizardProps;
export default withAuthenticationContext(withRouter(CEGLPremiumReportPremiumsPage));
