import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { PolicyChange, messages } from 'gw-capability-policychange-common-react';
import { WniCEEndorsementService } from 'wnice-capability-policychange';
import { LookupAddressComponent, LookupZipcodeComponent } from 'wnice-capability-address-react';
import { WNIAddressLookupService } from 'wni-capability-address';
import appConfig from 'app-config';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './CEPAAddressChangePage.metadata.json5';
import styles from './CEPAAddressChangePage.module.scss';
import addressChangeMessages from './CEPAAddressChangePage.messages';


function CEPAAddressChangePage(props) {
    const { wizardData, updateWizardData, wizardSnapshot, authHeader } = props;
    const { submissionVM } = wizardData;
    const { onValidate, initialValidation, isComponentValid } = useValidation(
        'CEPAAddressChangePage'
    );
    const [showWarn, updateShowWarn] = useState(false);
    const [showError, updateShowError] = useState(false);
    const [isAddressFlag, updateAddressFlag] = useState(false);

    const modalApi = useModal();
    const policyAddressPath = 'baseData.policyAddress';
    const addressBasePath = 'submissionVM.baseData.policyAddress';
    const policyAddress = _.get(submissionVM, `${policyAddressPath}.value`);
    const stateBackup = _.get(wizardSnapshot, `${addressBasePath}.value.state`);
    const jobID = _.get(submissionVM, 'jobID.value');

    const writeValue = useCallback((value, path) => {
        if (path === 'policyAddress') {
            _.set(wizardData, addressBasePath, value);
        } else {
            const fieldsToCheck = [`${addressBasePath}.addressLine1`, `${addressBasePath}.city`, `${addressBasePath}.state`, `${addressBasePath}.postalCode`];
            const addressChanged = fieldsToCheck.includes(path);
            if (addressChanged) {
                updateAddressFlag(false);
                updateShowError(false);
                updateShowWarn(false);
            }
            _.set(wizardData, path, value); 
        }
        updateWizardData(wizardData);
    }, [updateWizardData, wizardData]);

    const resetCityCountyState = (addressDTOValue) => {
        _.unset(addressDTOValue, 'city');
        _.unset(addressDTOValue, 'county');
        _.unset(addressDTOValue, 'state');
        _.unset(addressDTOValue, 'countySelection_Ext');
        _.unset(addressDTOValue, 'citySelection_Ext');
    };

    const onSelectionFn = async (item) => {
        resetCityCountyState(policyAddress);     
        const address = {
            ...policyAddress,
            ...item,
            addressLine2: ''
        };
        writeValue(address, 'policyAddress');
    };

    const lookupZipcode = (item) => {
        resetCityCountyState(policyAddress);
        const address = {
            ...policyAddress, 
            ...item
        };
        writeValue(address, 'policyAddress');
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            lookupaddress: LookupAddressComponent,
            lookupzipcode: LookupZipcodeComponent
        }
    };

    const cityOptions = useCallback(() => {
        let cityOptionValues = [];
        const citySelections = _.get(policyAddress, 'citySelection_Ext');
        if (!_.isNil(citySelections)) {
            cityOptionValues = citySelections.map((cityOpt) => {
                return {
                    code: cityOpt,
                    name: cityOpt
                };
            });
        }
        return cityOptionValues;
    }, [policyAddress]);

    const onVerifiedAddress = useCallback(async () => {
        if (isAddressFlag) {
            return true;
        }
        const addressPramas = _.omit(policyAddress, ['code', 'name', 'displayName', 'recommendCode']);
        const verifiedData = await WNIAddressLookupService.verifyAddress(
            addressPramas, authHeader
        );
        if (!verifiedData) {
            return false;
        }
        const { verifiedAddresses } = verifiedData;
        if (!verifiedAddresses || verifiedAddresses.length === 0) {
            writeValue({ 
                ...policyAddress, 
                isAutofilled: false 
            });
            updateShowWarn(true);
            updateAddressFlag(true);
            return false;
        }    
        const suggestedAddress = verifiedAddresses[0];
        writeValue({
            ...policyAddress,
            ...suggestedAddress,
            isAutofilled: true
        });
        updateAddressFlag(true);

        return true;
    }, [authHeader, isAddressFlag, policyAddress, writeValue]);

    const onNext = useCallback(async () => {
        const isVerified = await onVerifiedAddress();
        const stateChanged = stateBackup !== policyAddress.state;
        if (stateChanged) {
            updateShowError(true);
        }
        if (!isVerified || stateChanged) {
            return false;
        }
        const baseDatachangeDto = _.get(submissionVM, 'baseData.value');
        const updateGarageLocation = _.get(submissionVM, 'lobData.personalAuto.coverables.updateGarageLocation.value');
        const policyChangeResponse = await WniCEEndorsementService.savePolicyAddress(
            jobID, baseDatachangeDto, updateGarageLocation,
            authHeader
        );
        submissionVM.value = new PolicyChange(policyChangeResponse);
        return wizardData;
    }, [onVerifiedAddress, stateBackup, policyAddress, submissionVM, jobID, authHeader, wizardData]);

    const handleGarageAddressChange = useCallback((newValue, path) => {
        if (!newValue) {
            modalApi.showConfirm({
                title: addressChangeMessages.wantToSeparateAddress,
                message: addressChangeMessages.confirmNotLinkMailAddress,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.yes
            }).then((result) => {
                if (result === 'confirm') {
                    _.set(wizardData, `${path}.value`, newValue);
                    updateWizardData({...wizardData});
                }
            });
        } else {
            _.set(wizardData, `${path}.value`, newValue);
            updateWizardData({...wizardData});
        }
    }, [modalApi, updateWizardData, wizardData]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        errorNotification: {
            visible: showError
        },
        warnNotification: {
            visible: showWarn
        },
        garageAddress: {
            showOptional: false,
            onValueChange: handleGarageAddressChange
        },
        addressLine1: {
            path: `${addressBasePath}.addressLine1`,
            value: _.get(policyAddress, 'addressLine1'),
            onSelection: onSelectionFn,
            writeValue,
            autoFilled: true
        },
        city: {
            visible: _.isNil(_.get(policyAddress, 'citySelection_Ext'))
        },
        multiCity: {
            visible: !_.isNil(_.get(policyAddress, 'citySelection_Ext')),
            availableValues: cityOptions()
        },
        state: {
            className: showError ? styles.stateErrorContainer : ''
        },
        postalCode: {
            onSelection: lookupZipcode,
            value: _.get(policyAddress, 'postalCode'),
            writeValue,
            autoFilled: true,
            label: addressChangeMessages.postalCode
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            cancelLabel={(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            showPrevious
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={wizardData}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

CEPAAddressChangePage.propTypes = wizardProps;
export default withAuthenticationContext(CEPAAddressChangePage);
