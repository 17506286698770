import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { TooltipContentUtil } from 'wni-portals-tooltip';
import { TooltipIcon } from '@jutro/components';
import { Flex } from '@jutro/layout';
import metadata from './CoverageTermsIterableComponent.metadata.json5';
import styles from './CoverageTermsIterableComponent.module.scss';

function CoverageTermsIterableComponent(props) {
    const { data: coveragesTermsData } = props;

    const getLabelWithTooltip = useCallback((term, tindex) => {
        return (
            <Flex justifyContent="left" gap="none" alignContent="middle" className={styles.termNameWrapper}>
                <span>{term.name}</span>
                {TooltipContentUtil[term.code_Ext] && 
                    <TooltipIcon
                        id={`term_tooltip_${term.code_Ext}_${tindex}`}
                        text={TooltipContentUtil[term.code_Ext]}
                    />
                }
            </Flex>
        );
    }, []);

    const generateCoverageTermsOverrides = useCallback(() => {
        const overrides = coveragesTermsData.terms.map((term, tindex) => {
            return {
                [`coveragesTermsContent${tindex}`]: {
                    label: getLabelWithTooltip(term)
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [coveragesTermsData, getLabelWithTooltip]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        ...generateCoverageTermsOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coveragesTermsData}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

CoverageTermsIterableComponent.propTypes = {
    data: PropTypes.shape({}).isRequired
};

export default CoverageTermsIterableComponent;
