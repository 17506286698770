import PolicyChangeDetailsComponent from 'gw-capability-policyjob-react/components/PolicyChangeDetailsComponent/PolicyChangeDetailsComponent';

import CEPolicyDiffComponent from './components/PolicyDiffComponent/CEPolicyDiffComponent';
import CEDriverComponent from './components/DriverComponent/CEDriverComponent';
import CEVehicleComponent from './components/VehicleComponent/CEVehicleComponent';


const policyJobComponentMap = {
    drivercomponent: { component: 'DriverComponent' },
    vehiclecomponent: { component: 'VehicleComponent' },
    PolicyDiffComponent: { component: 'PolicyDiffComponent' },
};

const policyJobComponents = {
    DriverComponent: CEDriverComponent,
    VehicleComponent: CEVehicleComponent,
    PolicyDiffComponent: CEPolicyDiffComponent
};

export {
    CEDriverComponent as DriverComponent,
    CEVehicleComponent as VehicleComponent,
    PolicyChangeDetailsComponent,
    CEPolicyDiffComponent as PolicyDiffComponent,
    policyJobComponentMap,
    policyJobComponents
};
