import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { Loader, Button } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { WniPremiumReportService } from 'wnice-capability-premiumreport';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import RatingPeriodsComponent from '../../components/RatingPeriodsComponent/RatingPeriodsComponent';
import { getDetailInputDTOs } from '../../utils/WCPremiumReportUtil';
import metadata from './CEWCPremiumReportDetailsPage.metadata.json5';
import commonStyles from '../../CEWCPremiumReportWizard.module.scss';
import messages from './CEWCPremiumReportDetailsPage.messages';


function CEWCPremiumReportDetailsPage(props) {
    const { wizardData, updateWizardData, wizardSnapshot, goNext, authHeader, history } = props;
    const { onValidate, isComponentValid, registerComponentValidation } = useValidation('CEWCPremiumReportDetailsPage');
    const translator = useTranslator();

    const [isLoading, setIsLoading] = useState(false);

    const { 
        details: premiumReportDetailsVM
    } = wizardData;

    const { 
        summary : { comments },
        details: premiumReportDetails,
        jobNumber,
        jobStatus,
        sessionUUID
    } = wizardData.value;

    const isDraft = jobStatus === 'Draft';
    const detailInputDTOs = getDetailInputDTOs(premiumReportDetails);

    const checkCoveredEmployeeInputsValid = useCallback(() => {
        return premiumReportDetailsVM.aspects.valid && premiumReportDetailsVM.aspects.subtreeValid
    }, [premiumReportDetailsVM]);

    useEffect(() => {
        registerComponentValidation(checkCoveredEmployeeInputsValid);
    }, [checkCoveredEmployeeInputsValid, registerComponentValidation]);

    const onPrevious = useCallback(async () => {
        if (isComponentValid && !_.isEqual(wizardData.value, wizardSnapshot.value) && isDraft) {
            setIsLoading(true);
            await WniPremiumReportService.savePremiumReport(
                jobNumber, detailInputDTOs, comments, sessionUUID,
                authHeader
            );
            setIsLoading(false);
        } 
        history.push('/premium-reports');
    }, [isComponentValid, wizardData, wizardSnapshot, isDraft, history, jobNumber, detailInputDTOs, comments, sessionUUID, authHeader]);

    const onNext = useCallback(async () => {
        if (isDraft) {
            if (!isComponentValid) {
                return;
            }
            setIsLoading(true);
            wizardData.value = await WniPremiumReportService.saveAndCalculatePremiums(
                jobNumber, detailInputDTOs, comments, sessionUUID,
                authHeader
            );
            setIsLoading(false);
        }
        goNext();
        return wizardData;
    }, [isDraft, goNext, wizardData, isComponentValid, jobNumber, detailInputDTOs, comments, sessionUUID, authHeader]);

    const generateDetailOverrides = useCallback(() => {
        const detailOverrides = premiumReportDetailsVM.children.map((item, index) => {
            return {
                [`stateDetailHeader${index}`]: {
                    content: translator({
                        id: `typekey.Jurisdiction.${item.value.state}`,
                        defaultMessage: `typekey.Jurisdiction.${item.value.state}`
                    }),
                    className: commonStyles.premiumReportWizardTitle
                },
                [`ratingPeriodsComponent${index}`]: {
                    wizardData,
                    updateWizardData,
                    data: item.ratingPeriods.children,
                    stateIndex: index
                }
            };
        });

        return Object.assign({}, ...detailOverrides);
    }, [premiumReportDetailsVM, translator, updateWizardData, wizardData]);

    const renderCustomWizardPageFooterContent = useCallback(() => {
        return (
            <>
                <Button
                    id="gw-wizard-previous"
                    onClick={onPrevious}
                    variant="secondary" 
                    label={translator(messages.previousBtn)}
                />
                <Button
                    id="gw-wizard-next"
                    disabled={!isComponentValid}
                    onClick={onNext}
                    variant="primary" 
                    label={translator(messages.nextBtn)}
                />
            </>
        );
    }, [isComponentValid, onNext, onPrevious, translator]);

    const resolvers = {
        resolveComponentMap: {
            ratingPeriodscomponent: RatingPeriodsComponent
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        ...generateDetailOverrides()
    };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    return (
        <WizardPage
            renderCustomWizardPageFooterContent = {renderCustomWizardPageFooterContent}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={wizardData}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

CEWCPremiumReportDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(withRouter(CEWCPremiumReportDetailsPage));
