import { defineMessages } from 'react-intl';

export default defineMessages({
    dueDate: {
        id: 'billing.invoices-table.Due Date',
        defaultMessage: 'Due Date'
    },
    billStatus: {
        id: 'billing.invoices-table.Bill Status',
        defaultMessage: 'Bill Status'
    },
    lastPaymentDate: {
        id: 'billing.invoices-table.Last Payment Date',
        defaultMessage: 'Last Payment Date'
    },
    invoiceTotal: {
        id: 'billing.invoices-table.Amount',
        defaultMessage: 'Amount'
    },
    paid: {
        id: 'billing.invoices-table.Payment/Credit',
        defaultMessage: 'Payment/Credits'
    },
    outstanding: {
        id: 'billing.invoices-table.Min Due',
        defaultMessage: 'Minimum Due'
    },
    invoice: {
        id: 'billing.invoices-table.Invoice Number',
        defaultMessage: 'Invoice Number'
    },
    pay: {
        id: 'billing.invoices-table.Pay',
        defaultMessage: 'Pay'
    },
    noMatchingPayments: {
        id: 'billing.invoices-table.No matching payments.',
        defaultMessage: 'No matching payments.'
    },
    insuranceType: {
        id: 'billing.invoices-table.Insurance Type',
        defaultMessage: 'Insurance Type'
    },
    policyType: {
        id: 'billing.invoices-table.Policy Type',
        defaultMessage: 'Policy Type'
    },
});
