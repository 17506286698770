import { defineMessages } from 'react-intl';

export default defineMessages({
    anErrorOccurred: {
        id: 'gw-portals-policytransaction-pc-pa.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'gw-portals-policytransaction-pc-pa.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'gw-portals-policytransaction-pc-pa.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'gw-portals-policytransaction-pc-pa.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    infoWillBeSavedAsDraft: {
        id: 'gw-portals-policytransaction-pc-pa.The information entered previously will not be stored, do you want to continue?',
        defaultMessage: 'The information entered previously will not be stored, do you want to continue?'
    }
});
