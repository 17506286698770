import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLCPPWizard from './FNOLCPPWizard';
import FNOLCPPSelectLinePage from './pages/LineSelection/FNOLCPPSelectLinePage';
import FNOLCPPLossTypePage from './pages/LossType/FNOLCPPLossTypePage';
import FNOLCPPBusinessLocationPage from './pages/BusinessLocation/FNOLCPPBusinessLocationPage';
import FNOLCPPIncidentDetailsPage from './pages/IncidentDetails/FNOLCPPIncidentDetailsPage';

setComponentMapOverrides(
    {
        FNOLCPPWizard: { component: 'FNOLCPPWizard' },
        FNOLCPPSelectLinePage: { component: 'FNOLCPPSelectLinePage' },
        FNOLCPPLossTypePage: { component: 'FNOLCPPLossTypePage' },
        FNOLCPPBusinessLocationPage: { component: 'FNOLCPPBusinessLocationPage' },
        FNOLCPPIncidentDetailsPage: { component: 'FNOLCPPIncidentDetailsPage' }
    },
    {
        FNOLCPPWizard,
        FNOLCPPSelectLinePage,
        FNOLCPPLossTypePage,
        FNOLCPPBusinessLocationPage,
        FNOLCPPIncidentDetailsPage
    }
);

export {
    FNOLCPPWizard as default,
    FNOLCPPSelectLinePage,
    FNOLCPPLossTypePage,
    FNOLCPPBusinessLocationPage,
    FNOLCPPIncidentDetailsPage
} ;
