import { defineMessages } from 'react-intl';

export default defineMessages({
    viewInvoices: {
        id: 'billing.billing-summary.View Invoices',
        defaultMessage: 'View Invoices'
    },
    makeAPayment: {
        id: 'billing.billing-summary.Make a Payment',
        defaultMessage: 'Make a Payment'
    },
    viewPolicies: {
        id: 'billing.billing-summary.View Policies',
        defaultMessage: 'View Policies'
    },
    changePaymentMethod: {
        id: 'billing.billing-summary.Change Payment Method',
        defaultMessage: 'Change Payment Method'
    },
    setupAutomaticPayments: {
        id: 'billing.billing-summary.Manage AutoPay',
        defaultMessage: 'Manage AutoPay'
    },
    invoiceDetails: {
        id: 'billing.billing-summary.Invoices Detail',
        defaultMessage: 'Invoices Detail'
    },
    clickLogoTip: {
        id: 'billing.billing-summary.Click Log Tip',
        defaultMessage: 'Please note, if you do not see the policy you wish to pay, additional policies and invoices may be found by clicking on the logo above to be redirected.'
    },
    selectAmountToPay: {
        id: 'billing.billing-summary.Select Amount to Pay',
        defaultMessage: 'Select Amount to Pay'
    },
    policies: {
        id: 'billing.billing-summary.Policies',
        defaultMessage: 'Policies'
    },
    payNow: {
        id: 'billing.billing-summary.Pay Now',
        defaultMessage: 'Pay Now'
    },
    personalAutoPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Personal Auto Policy (Automatic Payments Enabled)',
        defaultMessage: 'Personal Auto Policy (Automatic Payments Enabled)'
    },
    personalAutoPolicy: {
        id: 'billing.billing-summary.Personal Auto Policy',
        defaultMessage: 'Personal Auto Policy'
    },
    homeownersPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Homeowners Policy (Automatic Payments Enabled)',
        defaultMessage: 'Homeowners Policy (Automatic Payments Enabled)'
    },
    homeownersPolicy: {
        id: 'billing.billing-summary.Homeowners Policy',
        defaultMessage: 'Homeowners Policy'
    },
    businessOwnersPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Business Owners Policy (Automatic Payments Enabled)',
        defaultMessage: 'Business Owners Policy (Automatic Payments Enabled)'
    },
    businessOwnersPolicy: {
        id: 'billing.billing-summary.Business Owners Policy',
        defaultMessage: 'Business Owners Policy'
    },
    commercialAutoPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Commercial Auto Policy (Automatic Payments Enabled)',
        defaultMessage: 'Commercial Auto Policy (Automatic Payments Enabled)'
    },
    commercialAutoPolicy: {
        id: 'billing.billing-summary.Commercial Auto Policy',
        defaultMessage: 'Commercial Auto Policy'
    },
    commercialPropertyPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Commercial Property Policy (Automatic Payments Enabled)',
        defaultMessage: 'Commercial Property Policy (Automatic Payments Enabled)'
    },
    commercialPropertyPolicy: {
        id: 'billing.billing-summary.Commercial Property Policy',
        defaultMessage: 'Commercial Property Policy'
    },
    commercialPackagePolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Commercial Package Policy (Automatic Payments Enabled)',
        defaultMessage: 'Commercial Package Policy (Automatic Payments Enabled)'
    },
    commercialPackagePolicy: {
        id: 'billing.billing-summary.Commercial Package Policy',
        defaultMessage: 'Commercial Package Policy'
    },
    generalLiabilityPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.General Liability Policy (Automatic Payments Enabled)',
        defaultMessage: 'General Liability Policy (Automatic Payments Enabled)'
    },
    generalLiabilityPolicy: {
        id: 'billing.billing-summary.General Liability Policy',
        defaultMessage: 'General Liability Policy'
    },
    inlandMarinePolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Inland Marine Policy (Automatic Payments Enabled)',
        defaultMessage: 'Inland Marine Policy (Automatic Payments Enabled)'
    },
    inlandMarinePolicy: {
        id: 'billing.billing-summary.Inland Marine Policy',
        defaultMessage: 'Inland Marine Policy'
    },
    workersCompPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Workers Compensation Policy (Automatic Payments Enabled)',
        defaultMessage: 'Workers Compensation Policy (Automatic Payments Enabled)'
    },
    workersCompPolicy: {
        id: 'billing.billing-summary.Workers Compensation Policy',
        defaultMessage: 'Workers Compensation Policy'
    },
    workersComp7PolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Workers Compensation (v7) Policy (Automatic Payments Enabled)',
        defaultMessage: 'Workers Compensation (v7) Policy (Automatic Payments Enabled)'
    },
    workersComp7Policy: {
        id: 'billing.billing-summary.Workers Compensation (v7) Policy',
        defaultMessage: 'Workers Compensation (v7) Policy'
    },
    policyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Policy (Automatic Payments Enabled)',
        defaultMessage: 'Policy (Automatic Payments Enabled)'
    },
    policy: {
        id: 'billing.billing-summary.Policy',
        defaultMessage: 'Policy'
    },
    ok: {
        id: 'billing.billing-summary.OK',
        defaultMessage: 'OK'
    },
    invoicesAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Enrolled In AutoPay',
        defaultMessage: 'Enrolled In AutoPay'
    },
    paymentSchedule: {
        id: 'billing.billing-summary.Payment Schedule',
        defaultMessage: 'Payment Schedule'
    },
    startingDate: {
        id: 'billing.billing-summary.Starting Date',
        defaultMessage: 'Starting Date'
    },
    paymentMethodHasBeenChanged: {
        id: 'billing.billing-summary.Payment method has been changed.',
        defaultMessage: 'Payment method has been changed.'
    },
    automaticPaymentOfHasBeenAppliedToYourAccount: {
        id: 'billing.billing-summary.Your payment method has been successfully updated to automatic renewal.',
        defaultMessage: 'Your payment method has been successfully updated to automatic renewal.'
    },
    yourAutomaticPaymentsWillBeginOn: {
        id: 'billing.billing-summary.Your automatic payments will begin on x.',
        defaultMessage: 'Your automatic payments will begin on {startingDate}.'
    },
    paymentOfHasBeenAppliedToYourAccount: {
        id: 'billing.billing-summary.Payment of x has been applied to your account.',
        defaultMessage: 'Payment of {paidAmount} has been applied to your account.'
    },
    amountToPay: {
        id: 'billing.billing-summary.Amount to Pay',
        defaultMessage: 'Amount to Pay'
    },
    paymentRequestFailed: {
        id: 'billing.billing-summary.Payment request failed',
        defaultMessage: 'Payment request failed'
    },
    sorryYourPaymentCouldNotBeProcessedAtThisTime: {
        id: 'billing.billing-summary.Sorry your payment could not be processed at this time',
        defaultMessage: 'Sorry your payment could not be processed at this time'
    },
    accountUpdateFailed: {
        id: 'billing.billing-summary.Account update failed',
        defaultMessage: 'Account update failed'
    },
    sorryWeWereNotAbleToSetupAutomaticPaymentsOnYourAccount: {
        id: 'billing.billing-summary.Sorry, we were not able to setup automatic payments on your account',
        defaultMessage: 'Sorry, we were not able to setup automatic payments on your account'
    },
    changingPaymentMethod: {
        id: 'billing.billing-summary.Changing payment method',
        defaultMessage: 'Changing payment method'
    },
    thereWasAProblemChangingThePaymentMethod: {
        id: 'billing.billing-summary.There was a problem changing the payment method',
        defaultMessage: 'There was a problem changing the payment method'
    },
    invoicesToPay: {
        id: 'billing.billing-summary.Invoices to Pay',
        defaultMessage: 'Invoices to Pay'
    },
    paidInvoices: {
        id: 'billing.billing-summary.Paid Invoices',
        defaultMessage: 'Paid Invoices'
    },
    paymentHistory: {
        id: 'billing.billing-summary.Payments History',
        defaultMessage: 'Payments History'
    },
    minimumDue: {
        id: 'billing.billing-summary.Minimum Due',
        defaultMessage: 'Minimum Due'
    },
    billed: {
        id: 'billing.billing-summary.Billed',
        defaultMessage: 'Billed'
    },
    declaration: {
        id: 'billing.billing-summary.*This amount is subject to change.',
        defaultMessage: '*This amount is subject to change.'
    },
    setUpAutoPay: {
        id: 'billing.billing-summary.Set Up AutoPay',
        defaultMessage: 'Set Up AutoPay'
    },
    updateAutoPay: {
        id: 'billing.billing-summary.Update AutoPay',
        defaultMessage: 'Update AutoPay'
    },
    confirmAutoPaySetUp: {
        id: 'billing.billing-summary.Confirm AutoPay Set Up',
        defaultMessage: 'Confirm AutoPay Set Up'
    },
    automaticPaymentsWillBegin: {
        id: 'billing.billing-summary.AutoPay will start with your next billing cycle',
        defaultMessage: 'AutoPay will start with your next billing cycle.'
    },
    confirmUpdateAutoPay: {
        id: 'billing.billing-summary.Want to Update Autopay?',
        defaultMessage: 'Want to Update Autopay?'
    },
    updateAutomaticPaymentsMsg: {
        id: 'billing.billing-summary.Updated automatic payments will begin on the due date of your next bill.',
        defaultMessage: 'Updated automatic payments will begin on the due date of your next bill.'
    },
    wantToPayYourCurrentBill: {
        id: 'billing.billing-summary.Would you like to pay your current bill now',
        defaultMessage: 'Would you like to pay your current bill now?'
    },
    selectPayNow: {
        id: 'billing.billing-summary.Select Pay Now',
        defaultMessage: 'Select Pay Now.'
    },
    yes: {
        id: 'billing.billing-summary.Yes',
        defaultMessage: 'Yes'
    },
    home: {
        id: 'billing.billing-summary.Home',
        defaultMessage: 'Home'
    },
    wantToTurnOffAutoPay: {
        id: 'billing.billing-summary.Want to Turn Off AutoPay',
        defaultMessage: 'Want to Turn Off AutoPay?'
    },
    confirmTurnOffAutoPay: {
        id: 'billing.billing-summary.Confirm Turn Off AutoPay',
        defaultMessage: 'You have requested to turn off AutoPay. Turning off AutoPay will result in a $5 installment fee per bill (this fee is waived for AutoPay users). Do you want to continue?'
    },
    confirmTurnOffAutoPayWithoutFee: {
        id: 'billing.billing-summary.Confirm Turn Off AutoPay Without Fee',
        defaultMessage: 'You have requested to turn off AutoPay. Do you want to continue?'
    },
    turnOffAutoPay: {
        id: 'billing.billing-summary.Turn Off AutoPay',
        defaultMessage: 'Turn Off AutoPay'
    },
    sorryWeWereNotAbleToTurnOffAutoPay: {
        id: 'billing.billing-summary.Sorry, we were not able to turn off autopay on your account',
        defaultMessage: 'Sorry, we were not able to turn off autopay on your account'
    },
    nothingToPay: {
        id: 'billing.billing-summary.nothingToPay',
        defaultMessage: 'Your account has been referred to collections. To make a payment, please refer to the collection agency notice you received.'
    },
    noticeEnrolledModalTitle: {
        id: 'billing.billing-summary.noticeEnrolledModalTitle',
        defaultMessage: 'Do you want to continue to make a payment?'
    },
    noticeEnrolledAutoPay: {
        id: 'billing.billing-summary.noticeEnrolledAutoPay',
        defaultMessage: 'Your account is enrolled in AutoPay. An automated payment will be deducted from your account on the due date of your next bill. \nDo you want to continue to make a payment?'
    },
    cancelShowAutoPay: {
        id: 'billing.billing-summary.cancelShowAutoPay',
        defaultMessage: 'Cancel'
    },
    yesShowMakePayment: {
        id: 'billing.billing-summary.yesShowMakePayment',
        defaultMessage: 'Yes'
    },
    amountToPayMsg: {
        id: 'billing.billing-summary.Amount to pay message',
        defaultMessage: 'The payment amount must be greater than or equal to {minPayAmount}.'
    },
});
